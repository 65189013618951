import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { useEffect, useMemo, useRef } from "react";
import { useSafetyContext } from "../../../../../contexts/safetyContext";
import { useCompositeSafetyIndexContext } from "../../../../../contexts/compositeSafetyIndexContext";
import { SafetyGraphTooltip, safetyGraphXAxisTickFormatter } from "../SafetyGraphTooltip";

export const CompositeSafetyIndexGraph = () => {
  const {state: safetyState} = useSafetyContext();
  const {state: compositeSafetyIndexState} = useCompositeSafetyIndexContext();

  const {graphData} = compositeSafetyIndexState;

  const seenTicks = useRef<Set<string>>(new Set());

  useEffect(() => {
    seenTicks.current = new Set();
  }, [graphData]);

  const ticks = useMemo(() => {
    const tickSet = new Set<number>();

    graphData.forEach(entry => {
      let date = new Date(entry.time);

      if (safetyState.dateSubdivision === 'monthly') {
        date.setDate(1);
      } else {
        const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
        const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        date = new Date((monthStart.getTime() + monthEnd.getTime()) / 2);
      }
      
      date.setHours(0, 0, 0, 0);

      tickSet.add(date.getTime());
    });

    return Array.from(tickSet);
  }, [graphData, safetyState.dateSubdivision]);

  if (graphData.length === 0) {
    return <div style={{height: "45%"}}/>
  }

  return (
    <ResponsiveContainer width="99%" height="99%">
      <LineChart
        data={graphData}
        margin={{
          top: 5,
          right: 30,
          left: 30
        }}
      >
        <YAxis
          domain={['auto', 'auto']}
          hide
        />
        <XAxis
          dataKey = 'time'
          domain = {['dataMin', 'dataMax']}
          name = 'Time'
          tickFormatter={safetyGraphXAxisTickFormatter}
          type = 'number'
          interval="preserveStartEnd"
          axisLine={false}
          tickLine={false}
          ticks={ticks}
        />
        <Tooltip
          content={props => (
            <SafetyGraphTooltip
              {...props}
            />
          )}
        />        
        <Line
          key={'value'}
          dataKey={'value'}
          stroke={'#14B8A6'}
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}