import styled from "styled-components";
import { useMemo } from "react";
import { useSafetyContext } from "../../../../contexts/safetyContext";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import { DateScrollSelector } from "../../progress/components/DateScrollSelector/DateScrollSelector";
import { safetyMetricMetaData } from "../../../../api/safety";
import Flex from "@react-css/flex";
import { IconViewModeSelector } from "../../../common/IconViewModeSelector/IconViewModeSelector";
import { PATH_STRINGS, useGeneratedPaths } from "../../../../hooks/useGeneratedPaths";
import iconEye from '../../../../assets/images/icon_eye.svg';
import iconEyeWhite from '../../../../assets/images/icon_eye_white.svg';
import iconClipboard from '../../../../assets/images/icon_clipboard.svg';
import iconClipboardWhite from '../../../../assets/images/icon_clipboard_white.svg';
import { SafetyHomePage } from "../SafetyHomePage/SafetyHomePage";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SafetyGallery } from "../SafetyGallery/SafetyGallery";

export const SafetyOverview = () => {
  const {
    generateProjectSafetyPath,
    generateSafetyGalleryPath
  } = useGeneratedPaths();

  const { state: BuildingState } = useBuildingContext();
  const { state: safetyState, updateSafety } = useSafetyContext();

  const safetyGalleryPathMatch = useRouteMatch(PATH_STRINGS.safetyGallery);

  const minDate = useMemo(() => {
    let min = new Date();
    min.setMonth(min.getMonth() - 1);

    Object.entries(safetyMetricMetaData).forEach(([slug, metaData]) => {
      const dateSummary = metaData.getDateSummary(safetyState.metricSummary[slug], BuildingState.projectId);

      if (dateSummary) {
        const minKey = Object.keys(dateSummary).reduce((minKey, key) => {
          return new Date(key) < new Date(minKey) ? new Date(key) : new Date(minKey);
        }, new Date());

        const minKeyDate = new Date(minKey);
        minKeyDate.setHours(0, 0, 0, 0);

        if (minKeyDate < min) {
          min = minKeyDate;
        }
      }
    });

    return min;
  }, [BuildingState.projectId, safetyState.metricSummary]);

  const viewModes = useMemo(() => [
    {
      name: 'Dashboard',
      path: generateProjectSafetyPath(BuildingState.projectId),
      icon: iconEye,
      iconActive: iconEyeWhite,
    },
    {
      name: 'Gallery',
      path: generateSafetyGalleryPath(BuildingState.projectId),
      icon: iconClipboard,
      iconActive: iconClipboardWhite,
    },
  ], [BuildingState.projectId, generateProjectSafetyPath, generateSafetyGalleryPath]);

  return (
    <SafetyOverviewContainer
      height={!!safetyGalleryPathMatch ? -89 : -56}
    >
      <SafetyOverviewHeaderContainer>
        {!safetyGalleryPathMatch && 
          <DateScrollSelector
            justify="flex-start"
            date={safetyState.observationDate}
            minDate={minDate}
            maxDate={new Date()}
            setDate={d => updateSafety({observationDate: d})}
            showSkipWeekButtons
          />
        }
        {!!safetyGalleryPathMatch &&
          <div/>
        }
        <Flex
          style={{
            position: 'relative',
            gap: '8px',
            top: !!safetyGalleryPathMatch ? '35px' : '0px'
          }}
        >
          <IconViewModeSelector
            modes={viewModes}
          />
        </Flex>
      </SafetyOverviewHeaderContainer>
      <SafetyOverviewContentContainer>
        <Switch>
          <Route
            exact
            path={PATH_STRINGS.safetyOverview}
          >
            <SafetyHomePage/>
          </Route>
          <Route
            exact
            path={PATH_STRINGS.safetyGallery}
          >
            <SafetyGallery
              showSafetyCircle
              excludeFilterOptions={["Holes"]}
            />
          </Route>
        </Switch>
      </SafetyOverviewContentContainer>
    </SafetyOverviewContainer>
  );
}

const SafetyOverviewContainer = styled.div<{height: number}>`
  position: relative;
  top: ${props => props.height}px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 44px);
  gap: 20px;
`;

const SafetyOverviewHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`;

const SafetyOverviewContentContainer = styled.div`
  flex: 1;
`;