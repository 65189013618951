import { useState, useMemo } from "react";
import { ConfirmationModal } from "../../common/Confirmation/Confirmation";
import { useListProjectFloorsQuery, useListProjectsQuery } from "../building_page/hooks/buildingQueries";
import { Project, ProjectFloor } from "../../../api/types";
import styled from "styled-components";
import { IGroup } from "../../common/ViewSelector/components/PointGroupSelector/PointGroupSelector";
import { useFetchFloorGroupsQuery } from "../admin_building_page/hooks/adminBuildingQueries";
import { Sitewalk, createSiteWalk } from "../../../api/sitewalk";
import { useNotifications } from "../../../contexts/notificationProvider";

interface CreateNewSiteWalkModalProps {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  onCreateNewSiteWalk: (newSiteWalk: Sitewalk) => void;
}

export const CreateNewSiteWalkModal = ({
  isOpen,
  setIsOpen,
  onCreateNewSiteWalk,
}: CreateNewSiteWalkModalProps) => {
  const {addNotification} = useNotifications();

  const now = new Date();
  const initialTakenOnDateTime = now.getFullYear() + '-' + ('0' + (now.getMonth()+1)).slice(-2) + '-' + ('0' + now.getDate()).slice(-2) + "T12:00:00";

  const [selectedProject, setSelectedProject] = useState<Project>();
  const [selectedFloor, setSelectedFloor] = useState<ProjectFloor>();
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();
  const [takenOnDateTime, setTakenOnDateTime] = useState<string>(initialTakenOnDateTime);

  const {data: projects} = useListProjectsQuery('active');
  const {data: floors} = useListProjectFloorsQuery(selectedProject?.public_id ?? '');
  const {data: groups} = useFetchFloorGroupsQuery(selectedProject?.public_id ?? '', selectedFloor?.floor_code ?? '');

  const canCreateWalk = !!selectedFloor && !!selectedGroup;

  const onCreateSiteWalk = async () => {
    if (canCreateWalk) {
      try {
        const createdSiteWalk = await createSiteWalk({
          project_floor: (selectedFloor as ProjectFloor).id,
          sub_group_id: (selectedGroup as IGroup).group_id,
          duration: 0,
          type: 'video',
          camera: null,
          taken_on: new Date(takenOnDateTime),
          start_point_sub_id: null,
        });

        onCreateNewSiteWalk(createdSiteWalk);
      }  catch {
        addNotification("Error creating new site walk", "error");
      }
    }
  }

  const CreateSiteWalkForm = useMemo(() => {
    return (
      <FormContainer>
        <StyledSelect
          label="Project:"
          options={projects ?? []}
          selectedOption={selectedProject}
          onSelectOption={option => setSelectedProject(option)}
          getOptionValue={option => option.name}
          getOptionLabel={option => option.name}
          disabled={!projects}
        />
        <StyledSelect
          label="Floor:"
          options={floors ?? []}
          selectedOption={selectedFloor}
          onSelectOption={option => setSelectedFloor(option)}
          getOptionValue={option => option.floor_code}
          getOptionLabel={option => option.floor_code}
          disabled={!floors || !selectedProject}
        />
        <StyledSelect
          label="Point Group:"
          options={groups ?? []}
          selectedOption={selectedGroup}
          onSelectOption={option => setSelectedGroup(option)}
          getOptionValue={option => option.name}
          getOptionLabel={option => option.name}
          disabled={!groups || !selectedProject || !selectedFloor}
        />
        <FormControlContainer>
          <label>Walk Date:</label>
          <DateInput
            type="datetime-local"
            value={takenOnDateTime}
            onChange={e => setTakenOnDateTime(e.target.value)}
          />
        </FormControlContainer>
      </FormContainer>
    )
  }, [floors, groups, projects, selectedFloor, selectedGroup, selectedProject, takenOnDateTime]);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      message="Create New Site Walk"
      content={CreateSiteWalkForm}
      confirmButtonAltText="Create"
      onConfirm={onCreateSiteWalk}
      styleOverrides={{
        content: {
          height: 'fit-content',
          maxHeight: 'default'
        },
        confirmationButton: {
          backgroundColor: canCreateWalk ? '#073C7A' : '#073C7A80',
          borderColor: canCreateWalk ? '#073C7A' : '#073C7A80',
          cursor: canCreateWalk ? 'pointer' : 'default',
          color: 'white'
        }
      }}
    />
  );
}

interface StyledSelectProps<T> {
  label: string;
  options: T[];
  selectedOption: T | undefined;
  onSelectOption: (option: T | undefined) => void;
  getOptionValue: (option: T) => string;
  getOptionLabel: (option: T) => string;
  disabled?: boolean;
}

const StyledSelect = <T extends {}>({
  label,
  options,
  selectedOption,
  onSelectOption,
  getOptionValue,
  getOptionLabel,
  disabled,
}: StyledSelectProps<T>) => {
  const selectedOptionValue = selectedOption ? getOptionValue(selectedOption) : '';

  const handleSelectOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;

    const newlySelectedOption = options.find(val => getOptionValue(val) === selectedValue);

    onSelectOption(newlySelectedOption);
  }

  return (
    <FormControlContainer>
      <label>{label}</label>
      <select
        disabled={disabled}
        style={{padding: '14px'}}
        value={selectedOptionValue}
        onChange={e => handleSelectOnChange(e)}
      >
        <option key='empty' selected disabled hidden></option>
        {options.map((option: T) => {
          const value = getOptionValue(option);
          const optionLabel = getOptionLabel(option);

          return (
            <option
              key={value}
              value={value}
            >
              {optionLabel}
            </option>
          )
        })}
      </select>
    </FormControlContainer>
  )
}

const FormControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
`;

const DateInput = styled.input`
  padding: 14px;
`;