import styled from "styled-components";
import iconChecked from '../../../../../assets/images/icon_checked.svg';

export interface CheckmarkPosition {
  top?: React.CSSProperties['top'];
  right?: React.CSSProperties['right'];
  bottom?: React.CSSProperties['bottom'];
  left?: React.CSSProperties['left'];
}

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
`;

export const Checkmark = styled.div<{checked?: boolean; backgroundColor?: string; checkmarkPosition?: CheckmarkPosition;}>`
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid ${props => props.checked ? props.backgroundColor ?? '#2196F3' : 'black'};
  background-color: ${props => props.checked ? props.backgroundColor ?? '#2196F3' : 'white'};
  &::after {
    content: url(${iconChecked});
    position: relative;
    ${props => 
      !props.checkmarkPosition && `
        left: 4px;
        top: 2px;
      `
    }
    ${props => `
      ${props.checkmarkPosition?.top ? `top: ${props.checkmarkPosition.top};` : ''}
      ${props.checkmarkPosition?.right ? `right: ${props.checkmarkPosition.right};` : ''}
      ${props.checkmarkPosition?.bottom ? `bottom: ${props.checkmarkPosition.bottom};` : ''}
      ${props.checkmarkPosition?.left ? `left: ${props.checkmarkPosition.left};` : ''}
    `}
  }
`;

export const NameInput = styled.input`
  border: none;
  font-size: 14px;
  width: 140px;
`;

export const GroupSelector = styled.div`
  padding: 14px 0;
  position: relative;
  display: flex;
  align-items: center;
  &:not(&:last-child) {
    border-bottom: 1px solid #E2E8F0;
  }
`;