import { Point } from '../../../../../../api/types';
import { useBuildingContext } from '../../../../../../contexts/buildingContext';
import { useEffect, useState } from 'react';
import { useNavigation } from '../../../../../../hooks/useNavigation';
import mixpanel from 'mixpanel-browser';
import { useImageViewerContext } from '../../../imageViewerContext';
import { getTourPoints } from './getTourPoints';

const INITIAL_RANGE = 350;

export const TourManager = (props: { pnlm: any, imageData: any }) => {
  const { state: buildingState, updateTour } = useBuildingContext();
  const { navigateToPointFullscreen } = useNavigation();
  const { updateMaster } = useImageViewerContext();
  const [onLoad, setOnLoad] = useState(false);
  const [range, setRange] = useState(INITIAL_RANGE);
  const SCALE = buildingState.floorData.latest_floor_plan.scale || 1;

  const hospotClickHandler = (
    event: any,
    args: { pitch: number; yaw: number; type: string; tagId?: number; extras?: any }
  ) => {
    if (args.extras) {
      let date = new URLSearchParams(window.location.search).get('date');
      if (date) {
        navigateToPointFullscreen(args.extras.pointId, { date: date });
      } else {
        navigateToPointFullscreen(args.extras.pointId);
      }
    }
    updateMaster({ angleDelta: null });
    mixpanel.track('Click Tour Hotspot');
  };

  const populate = () => {
    let tp = getTourPoints(buildingState.floorData.points, buildingState.pointData);
    let currentPointCoords = {x: buildingState.pointData.x, y: buildingState.pointData.y};
    if(props.imageData.true_x){
      currentPointCoords.x = parseFloat(props.imageData.true_x)
      currentPointCoords.y = parseFloat(props.imageData.true_y)
    }

    if (tp.length > 0) {
      tp.forEach((point: Point) => {
        console.log(point.point_id)
        let distance = Math.round(distanceBetween(point, currentPointCoords));
        let relative = relativeCoords(currentPointCoords, point, distance, SCALE, props.imageData.height || 50);
        let scaleY = limitNumberWithinRange(
          SCALE > 1
            ? 1 - (distance * SCALE - INITIAL_RANGE / SCALE) / (INITIAL_RANGE / SCALE)
            : ((distance * SCALE - INITIAL_RANGE / SCALE) * -1) / (INITIAL_RANGE / SCALE),
          0.25,
          1
        );
        props.pnlm.current.addHotSpot(
          relative,
          'TOUR',
          hospotClickHandler,
          () => null,
          point.point_id,
          true,
          {
            pointId: point.point_id,
            distance: distance,
            scaleY: scaleY,
            scaleX: scaleY + 1.4 - (1 - scaleY),
          }
        );
      });
      updateTour(tp);
    }
  };

  useEffect(() => {
    if (props.pnlm) {
      props.pnlm.current.pannellumRef.on('load', () => setOnLoad(true));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (onLoad) populate();
    // eslint-disable-next-line
  }, [onLoad]);

  useEffect(() => {
    if (!onLoad) populate();
    // eslint-disable-next-line
  }, [buildingState.imageData]);

  useEffect(() => {
    setOnLoad(false);
  }, [buildingState]);
  return <></>;
};

// Util functions

const distanceBetween = (p1: any, p2: any) => {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
};

function getPitch(distance: number, scale: number, imageHeight: number) {
  if(distance <= 0){
    return 0
  }
  let ROVER_HEIGHT = imageHeight / scale;
  let c = Math.sqrt(Math.pow(distance, 2) + Math.pow(ROVER_HEIGHT, 2));
  let theta = Math.atan2(c, ROVER_HEIGHT);
  return (theta / Math.PI) * 180 - 90;
}

const relativeCoords = (p1: any, p2: any, distance: number, scale: number, imageHeight: number) => {
  var deltaX = p2.x - p1.x;
  var deltaY = p2.y - p1.y;
  var rad = Math.atan2(deltaY, deltaX);
  var yaw = (rad / Math.PI) * 180 + (rad > 0 ? 0 : 360);
  yaw += 90; // arbitrary correction, but seems to work.
  return [getPitch(distance, scale, imageHeight), yaw];
};

function limitNumberWithinRange(num: number, min: number, max: number) {
  const MIN = min || 1;
  const MAX = max || 20;
  return Math.min(Math.max(num, MIN), MAX);
}
