import { useCallback, useEffect, useMemo, useState } from "react";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { Item } from "../../../../../api/items";
import { LightingMetric, fetchLightingMetrics } from "../../../../../api/safety";
import { CustomTooltipValue } from "../../../progress/components/ProgressCharts/CustomTooltip";
import { Job } from "../../../../../api/jobs";
import { useSafetyContext } from "../../../../../contexts/safetyContext";
import { ViewerPosition } from "../../../image_viewer/types";
import { SafetyFloorPlanView } from "../SafetyFloorPlanView";
import { useProjectFloorProgressRegionsQuery } from "../../../progress/hooks/progressTrackingQueries";

interface SafetyLightingMetricFloorPlanViewProps {
  viewerPosition?: ViewerPosition;
  minimapMode?: boolean;
}

export const SafetyLightingFloorPlanView = ({
  viewerPosition,
  minimapMode=false,
}: SafetyLightingMetricFloorPlanViewProps) => {
  const {state: buildingState} = useBuildingContext();
  const {state: safetyState} = useSafetyContext();

  const {observationDatePointImageMap} = safetyState;

  const {data: progressRegions, isLoading: progressRegionsLoading} = useProjectFloorProgressRegionsQuery();

  const [lightingMetrics, setLightingMetrics] = useState<LightingMetric[]>([]);
  const [lightingMetricsLoaded, setLightingMetricsLoaded] = useState<boolean>(false);
  const [tooltipItem, setTooltipItem] = useState<Item | null>(null);

  const dataLoaded = lightingMetricsLoaded && !!progressRegions && !progressRegionsLoading;

  const handleFetchLightingMetrics = useCallback(async () => {
    if (buildingState.projectId && buildingState.floorId) {
      setLightingMetricsLoaded(false);

      const floorLightingMetrics = await fetchLightingMetrics({project_id: buildingState.projectId, floor_code: buildingState.floorId});
      floorLightingMetrics.sort((a, b) => new Date(b.taken_on).getTime() - new Date(a.taken_on).getTime());

      setLightingMetrics(floorLightingMetrics);
      setLightingMetricsLoaded(true);
    }
  }, [buildingState.floorId, buildingState.projectId]);

  useEffect(() => {
    handleFetchLightingMetrics();
  }, [handleFetchLightingMetrics]);

  const lightingMetricsFilteredForDate = useMemo(() => {
    return lightingMetrics.filter(metric => observationDatePointImageMap.has(metric.viewpoints_image_id))
  }, [lightingMetrics, observationDatePointImageMap]);

  const viewpointMetricMap = useMemo(() => {
    const map = new Map<number, LightingMetric>();

    lightingMetricsFilteredForDate.forEach(metric => {
      const viewpoint = metric.viewpoint;

      if (!map.has(viewpoint)) {
        map.set(viewpoint, metric);
      }
    });

    return map;
  }, [lightingMetricsFilteredForDate]);

  const jobs = useMemo(() => {
    if (progressRegions) {
      return progressRegions.map(region => {
        const itemMetric = viewpointMetricMap.get(region.viewpoint);

        const displayColor = itemMetric ? parseFloat(itemMetric.foot_candles) >= 5 ? "#10B981" : "#FB7185" : "#808080"

        return {
          id: itemMetric ? itemMetric.viewpoints_image_id : -1,
          completed_units: itemMetric ? parseFloat(itemMetric.foot_candles) : 0,
          initial_observation_date: itemMetric ? `${itemMetric.taken_on} 12:00:00` : null,
          item: region as any,
          total_units: 100,
          type: {
            project: 0,
            job_type: {
              display_color: displayColor,
              display_shape: "area",
              display_order: 0,
              id: 1,
              item_type: 1,
              name: "Lighting",
              secondary_display_color: null,
              slug: "lighting",
              tertiary_display_color: null,
              units: "SF"
            }
          },
          registered_on: "",
          last_modified_on: "",
          status: "",
          rate: 0,
        }
      });
    }

    return [];
  }, [progressRegions, viewpointMetricMap]);

  const itemJobMap = useMemo(() => {
    const map = new Map<number, Job>();

    jobs.forEach(job => {
      map.set(job.item.id, job);
    });

    return map;
  }, [jobs]);

  const tooltipJob = useMemo(() => {
    if (tooltipItem) {
      return itemJobMap.get(tooltipItem.id);
    }

    return null;
  }, [itemJobMap, tooltipItem]);

  const TooltipValue = useMemo(() => {
    const color = tooltipJob ? tooltipJob.type.job_type.display_color : "#808080";
    let message = "";

    if (tooltipJob && tooltipJob.id >= 0) {
      message = `${tooltipJob.completed_units} foot candles`
    } else {
      message = "No Measurement"
    }

    return (
      <CustomTooltipValue
        style={{
          color: color,
        }}
      >
        {message}
      </CustomTooltipValue>
    )
  }, [tooltipJob]);

  return (
    <SafetyFloorPlanView
      viewerPosition={viewerPosition}
      jobs={jobs}
      dataLoaded={dataLoaded}
      tooltipItem={tooltipItem}
      setTooltipItem={setTooltipItem}
      selectedJobTypeIds={[1]}
      TooltipValue={TooltipValue}
      minimapMode={minimapMode}
    />
  )
}