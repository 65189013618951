import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Table } from '../../common/Table/Table';
import { SiteWalkVerificationStats, Sitewalk, fetchSiteWalkVerificationStats, fetchSiteWalks } from '../../../api/sitewalk';
import { AddProjectButton } from '../buildings_page/components/AddProjectButton';
import { PATH_STRINGS, useGeneratedPaths } from '../../../hooks/useGeneratedPaths';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { useUserContext } from '../../../contexts/userContext';
import { checkUserPermissions } from '../../common/PermissionWrapper/PermissionWrapper';
import { CheckNameDelete } from '../../common/ViewSelector/components/PointGroupSelector/CheckNameDelete';

export const SiteWalkTable = () => {
  const {state: userState} = useUserContext();
  const userCanReviewSiteWalks = checkUserPermissions(userState.permissions, 'site_walk_tables.review_site_walk');

  const {generateSiteWalkConfirmationPath} = useGeneratedPaths();
  const [siteWalks, setSiteWalks] = useState<Sitewalk[]>([]);
  const [siteWalksLoading, setSiteWalksLoading] = useState<boolean>(true);
  const [siteWalkVerificationStats, setSiteWalkVerificationStats] = useState<SiteWalkVerificationStats>({});
  const [siteWalkVerificationStatsLoading, setSiteWalkVerificationStatsLoading] = useState<boolean>(true);
  const [hideFullyReviewed, setHideFullyReviewed] = useState<boolean>(userCanReviewSiteWalks);

  const history = useHistory();

  useEffect(() => {
    fetchSiteWalks(undefined, ['Uploaded', 'Awaiting Transformation', 'Transformed']).then(returnedWalks => {
      returnedWalks.sort((a,b) => new Date(b.taken_on).getTime() - new Date(a.taken_on).getTime());

      setSiteWalks(returnedWalks);
      setSiteWalksLoading(false);
    });
  }, []);

  useEffect(() => {
    setSiteWalkVerificationStatsLoading(true);

    if (userCanReviewSiteWalks) {
      fetchSiteWalkVerificationStats().then(returnedStats => {
        setSiteWalkVerificationStats(returnedStats);
        setSiteWalkVerificationStatsLoading(false);
      });
    } else {
      setSiteWalkVerificationStatsLoading(false);
    }
  }, [userCanReviewSiteWalks]);

  const formatWalkDate = (walkDate?: string) => {
    if (walkDate) {
      const walkDateObject = new Date(walkDate);
      const dateString = walkDateObject.toLocaleDateString();
      const timeString = walkDateObject.toLocaleTimeString().replace(/:\d\d\s/, ' ');
      
      return `${dateString} ${timeString}`;
    }

    return '';
  }

  const onClickConfirmButton = useCallback((siteWalkId: string | number) => {
    history.push(generateSiteWalkConfirmationPath(siteWalkId));
  }, [history, generateSiteWalkConfirmationPath]);

  const generateConfirmButton = useCallback((walk: Sitewalk) => {
    return (
      <AddProjectButton
        text="Confirm"
        onClick={() => onClickConfirmButton(walk.id)}
      />
    )
  }, [onClickConfirmButton]);

  const formatSite = useCallback((walk: Sitewalk) => {
    return `${walk.project.name} - ${walk.project_floor.floor_code} - ${walk.group_name}`;
  }, []);

  const formatNumImagesReviewed = useCallback((walk: Sitewalk) => {
    const verificationStats = siteWalkVerificationStats[walk.id];

    if (verificationStats) {
      const isReviewed = verificationStats.is_reviewed;
      const total = verificationStats.total;

      return `${isReviewed} / ${total}`; 
    }

    return 'N/A';
  }, [siteWalkVerificationStats]);

  const formatNumImagesApproved = useCallback((walk: Sitewalk) => {
    const verificationStats = siteWalkVerificationStats[walk.id];

    if (verificationStats) {
      const isApproved = verificationStats.is_approved;
      const total = verificationStats.total;

      return `${isApproved} / ${total}`; 
    }

    return 'N/A';
  }, [siteWalkVerificationStats]);

  const siteWalkIsFullyReviewed = useCallback((walk: Sitewalk) => {
    const verificationStats = siteWalkVerificationStats[walk.id];

    if (verificationStats) {
      const isReviewed = verificationStats.is_reviewed;
      const total = verificationStats.total;

      return isReviewed === total;
    }

    return false;
  }, [siteWalkVerificationStats]);

  const data = useMemo(() => {
    if (hideFullyReviewed) {
      return siteWalks.filter(walk => !siteWalkIsFullyReviewed(walk));
    }

    return siteWalks;
  }, [hideFullyReviewed, siteWalkIsFullyReviewed, siteWalks]);

  const columns = useMemo(() => {
    return [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Walk Date', accessor: (walk: Sitewalk) => formatWalkDate(walk.taken_on) },
      { Header: 'Site', accessor: (walk: Sitewalk) => formatSite(walk) },
      { Header: 'Status', accessor: 'status' },
      ...userCanReviewSiteWalks ? [ { Header: 'Reviewed', accessor: (walk: Sitewalk) => formatNumImagesReviewed(walk) }] : [],
      ...userCanReviewSiteWalks ? [ { Header: 'Approved', accessor: (walk: Sitewalk) => formatNumImagesApproved(walk) }] : [],
      ...userCanReviewSiteWalks ? [ { Header: 'Confirm', accessor: (walk: Sitewalk) => generateConfirmButton(walk) }] : []
    ];
  }, [formatNumImagesApproved, formatNumImagesReviewed, formatSite, generateConfirmButton, userCanReviewSiteWalks]);

  const onClickUploadNewWalk = () => {
    history.push(PATH_STRINGS.siteWalkVideoUpload)
  }

  if (siteWalksLoading || siteWalkVerificationStatsLoading) {
    return <LoadingIndicator/>
  }

  return (
    <SiteWalkTableContainer>
      <UploadNewWalkContainer>
        {!userCanReviewSiteWalks &&
          <div/>
        }
        {userCanReviewSiteWalks &&
          <CheckNameDelete
            checked={hideFullyReviewed}
            onChangeChecked={checked => setHideFullyReviewed(checked)}
            checkmarkPosition={{
              left: '4px',
              top: '0px'
            }}
            name="Hide Fully Reviewed"
            containerStyle={{
              border: 'none'
            }}
          />
        }
        <AddProjectButton
          text="Upload New Walk"
          onClick={onClickUploadNewWalk}
          buttonStyle={{
            background: '#073c7a',
            color: 'white'
          }}
        />
      </UploadNewWalkContainer>
      <Table
        data={data}
        columns={columns}
        headerStyles={{
          padding: '5px 40px'
        }}
      />
    </SiteWalkTableContainer>
  )
}

const UploadNewWalkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const SiteWalkTableContainer = styled.div`
  position: relative;
  overflow: auto;
  white-space: nowrap;
  margin-top: 20px;
`;