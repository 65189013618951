import { useEffect, useMemo, useState } from "react";
import { useSafetyContext } from "../../../../contexts/safetyContext";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import { SafetyGallery } from "../SafetyGallery/SafetyGallery";
import { SafetyLightingFloorPlanView } from "../SafetyFloorPlanView/SafetyLightingFloorPlanView/SafetyLightingFloorPlanView";
import styled from "styled-components";
import { ViewSelector } from "../../../common/ViewSelector/ViewSelector";
import { IViewSelectorOption, OptionPicker } from "../../../common/ViewSelector/components/OptionPicker";
import { useNavigation } from "../../../../hooks/useNavigation";
import { PointPreview } from "../../progress/ProgressFloorTracker/PointPreview/PointPreview";
import iconZoomInBlue from '../../../../assets/images/icon_zoom_in_blue.svg';
import iconZoomOutBlue from '../../../../assets/images/icon_zoom_out_blue.svg';
import iconCrosshairs from '../../../../assets/images/icon_crosshairs.svg';
import iconCrosshairsWhite from '../../../../assets/images/icon_crosshairs_white.svg';
import { ProgressDateSelectorContainer, ProgressDateSelectorTitle } from "../../progress/ProgressFloorTracker/ProgressFloorPlanView/styles";
import { IconButton } from "../../../common/IconButton";
import { DateScrollSelector } from "../../progress/components/DateScrollSelector/DateScrollSelector";
import { SafetyClutterFloorPlanView } from "../SafetyFloorPlanView/SafetyClutterFloorPlanView/SafetyClutterFloorPlanView";
import { Item } from "../../../../api/items";
import { ViewerPosition } from "../../image_viewer/types";
import { SafetyFireExtinguisherFloorPlanView } from "../SafetyFloorPlanView/SafetyFireExtinguisherFloorPlanView/SafetyFireExtinguisherFloorPlanView";
import { SafetyGuardrailsFloorPlanView } from "../SafetyFloorPlanView/SafetyGuardrailsFloorPlanView/SafetyGuardrailsFloorPlanView";
import { SafetyMetricSlug, safetyMetricMetaData } from "../../../../api/safety";
import { ObservationView } from "../SafetyOverview/ObservationView/ObservationView";
import { SafetyViewModeMenu } from "../SafetyViewModeMenu/SafetyViewModeMenu";
import { SafetyWetSurfaceFloorPlanView } from "../SafetyFloorPlanView/SafetyWetSurfaceFloorPlanView/SafetyWetSurfaceFloorPlanView";

export interface BaseSafetyFloorPlanViewProps {
  viewerPosition: ViewerPosition;
  tooltipItem: Item | null;
  setTooltipItem: React.Dispatch<React.SetStateAction<Item | null>>;
  hoverCoordinates: {x: number, y: number};
  setHoverCoordinates: React.Dispatch<React.SetStateAction<{x: number; y: number; }>>;
}

export const SafetyMetricDetail = () => {
  const { navigateToSafetyMetricDetailFloor } = useNavigation();

  const { state: buildingState, updateBuilding } = useBuildingContext();
  const { state: safetyState, updateSafety } = useSafetyContext();

  const { selectedMetricCategory, observationDate, showPoints, mapRef, selectedItemId } = safetyState;

  const showPointPreview = buildingState.pointId && buildingState.pointData && buildingState.imageData && Object.entries(buildingState.imageData).length !== 0 && !selectedItemId;
  const showObservationView = !!safetyState.activeObservation && !!selectedItemId;
  
  const selectedMetric = safetyMetricMetaData[selectedMetricCategory as SafetyMetricSlug]
  const selectedDisplayMode = selectedMetric.display_mode ?? 'gallery';

  const [floorExpanded, setFloorExpanded] = useState<boolean>(!buildingState.floorId);
  const [viewerPosition, setViewerPosition] = useState({ yaw: 0, angle: 0, pitch: 0, hfov: 120 });

  useEffect(() => {
    if (showPointPreview) {
      updateSafety({showPoints: true});
    }
  }, [showPointPreview, updateSafety]);

  useEffect(() => {
    if (!buildingState.pointId) {
      updateBuilding({
        imageData: {
          image: null,
          data: null,
        },
        pointData: null,
      });
    }
  }, [buildingState.pointId, updateBuilding]);

  const viewSelectorFloorOptions: IViewSelectorOption[] = useMemo(() => {
    if (buildingState.projectData.floors) {
    return buildingState.projectData.floors
      .map((floor: { name: string; floor_code: string }) => ({
        Id: floor.floor_code,
        Content: floor.name,
        Options: [],
      }))
      .reverse();
    }

    return [];
  }, [buildingState.projectData.floors]);

  const currentFloor = useMemo(() => {
    return viewSelectorFloorOptions.filter(
      option => option.Id === buildingState.floorId
    )[0];
  }, [buildingState.floorId, viewSelectorFloorOptions]);

  const onSelectFloorOption = (selectedOption: IViewSelectorOption) => {
    navigateToSafetyMetricDetailFloor(selectedOption.Id, selectedMetricCategory);
  };

  return (
    <>
      {selectedDisplayMode !== 'gallery' &&
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '25px',
            marginTop: '43.5px',
          }}>
            <SafetyViewModeMenu/>
        </div>
      }

      {selectedMetricCategory === "ppe_compliance" &&
        <SafetyGallery
          initialFilter={{ text: "PPE", field: 'category', value: "PPE", locked: true }}
          excludeFilterOptions={["Holes", "Fall Protection", "Slip", "Rebar", "PPE"]}
          showSafetyViewModeMenu
        />
      }
      {selectedMetricCategory === "holes" &&
        <SafetyGallery
          initialFilter={{ text: "Holes", field: 'type_codes', value: ["FALL_ROTECTION_OPENING_PROTECTED","FALL_ROTECTION_FLR_COVRS"].join(','), locked: true }}
          excludeFilterOptions={["PPE", "Fall Protection", "Slip", "Rebar"]}
          showSafetyViewModeMenu
        />
      }
      {selectedMetricCategory === "lighting" &&
        <SafetyLightingFloorPlanView
          viewerPosition={viewerPosition}
        />
      }
      {selectedMetricCategory === "clutter" &&
        <SafetyClutterFloorPlanView
          viewerPosition={viewerPosition}
        />
      }
      {selectedMetricCategory === "wet_surface" &&
        <SafetyWetSurfaceFloorPlanView
          viewerPosition={viewerPosition}
        />
      }
      {selectedMetricCategory === "fire_extinguishers" &&
        <SafetyFireExtinguisherFloorPlanView
          viewerPosition={viewerPosition}
        />
      }
      {selectedMetricCategory === "guardrails" &&
        <SafetyGuardrailsFloorPlanView
          viewerPosition={viewerPosition}
        />
      }
      {selectedDisplayMode === 'floor_plan' &&
        <>
          <ProgressDateSelectorContainer>
            <ProgressDateSelectorTitle>
              <h1>{selectedMetric.dateSelectorTitle ?? 'Progress As Of'}</h1>
            </ProgressDateSelectorTitle>
            <DateScrollSelector
              justify="center"
              date={observationDate}
              setDate={d => updateSafety({observationDate: d})}
              showSkipWeekButtons
            />
          </ProgressDateSelectorContainer>
          <IconButton
            icon={showPoints ? iconCrosshairsWhite : iconCrosshairs}
            size={40}
            primary={showPoints}
            onClick={() => updateSafety({showPoints: !showPoints})}
            style={{ position: 'fixed', left: '40px', top: 'calc(90% - 66px)', zIndex: 2 }}
          />
          <IconButton
            icon={iconZoomInBlue}
            size={40}
            primary={false}
            onClick={() => mapRef.current?.zoomIn()}
            style={{ position: 'fixed', left: '40px', top: 'calc(90% - 22px)', zIndex: 2 }}
          />
          <IconButton
            icon={iconZoomOutBlue}
            size={40}
            primary={false}
            onClick={() => mapRef.current?.zoomOut()}
            style={{ position: 'fixed', left: '40px', top: 'calc(90% + 22px)', zIndex: 2 }}
          />
          <FloorSelectorContainer>
            <ViewSelector
              title="Floors"
              initialViewSelectorExpanded
              viewingExpanded={floorExpanded}
              setViewingExpanded={setFloorExpanded}
              maxHeight="calc(100vh - 332px)">
              <OptionPicker
                expanded={floorExpanded}
                setExpanded={setFloorExpanded}
                viewingItems={{
                  Id: 'root',
                  Content: 'Floor',
                  OnSelectChild: onSelectFloorOption,
                  Options: viewSelectorFloorOptions,
                }}
                selectedItem={currentFloor}
                hoverItem={null}
                onSelectOption={onSelectFloorOption}
                onHover={() => null}
              />
            </ViewSelector>
          </FloorSelectorContainer>
        </>
      }
      {showPointPreview && (
        <PointPreview
          viewerPosition={viewerPosition}
          onChangeViewerPosition={setViewerPosition}
          onClose={() => {
            navigateToSafetyMetricDetailFloor(buildingState.floorId, selectedMetricCategory);
          }}
          observationDate={observationDate}
          onChangeObservationDate={newDate => updateSafety({observationDate: newDate})}
        />
      )}
      {showObservationView &&
        <ObservationViewContainer>
          <ObservationView
            onClose={() => {
              navigateToSafetyMetricDetailFloor(buildingState.floorId, selectedMetricCategory)
            }}
            viewerPosition={viewerPosition}
            onChangeViewerPosition={setViewerPosition}
            changeSeverityDisabled
          />
        </ObservationViewContainer>
      }
    </>
  );
}

const FloorSelectorContainer = styled.div`
  position: absolute;
  top: 150px;
  left: 0;
`;

const ObservationViewContainer = styled.div`
  right: -60px;
  top: 0;
  position: absolute;
  width: 50%;
  height: 100%;
  filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
  z-index: 20;
`;