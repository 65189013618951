import React from 'react';
import { getFullName } from '../../../../utils';

export const ChatMessage = (props: { message: any; user: string; isLastMessage?: boolean }) => {
  return (
    <div key={Math.random()}>
      <div
        className={`chat-message ${
          props.message.sender.public_id === props.user && 'user-message'
        }`}
        id={props.isLastMessage ? 'last-message' : ''}>
        {props.message.content}
      </div>
      {props.message.sender.public_id !== props.user && (
        <div>
          <em className="chat-message-email">{getFullName(props.message.sender)}</em>
        </div>
      )}
    </div>
  );
};
