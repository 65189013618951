import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Point } from '../../../api/types';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { ImageViewer } from '../image_viewer/ImageViewer';
import { PreviewPopup } from './components/PreviewPopup';
import { BuildingOverview } from './components/BuildingOverview/BuildingOverview';
import { FloorPlanView } from './components/FloorPlanView';
import { PATH_STRINGS, useGeneratedPaths } from '../../../hooks/useGeneratedPaths';
import { useNavigation } from '../../../hooks/useNavigation';
import { useBuildingContext } from '../../../contexts/buildingContext';
import { useBuildingQuery, useFloorImages, useImagesQuery } from './hooks/buildingQueries';
import { useRouteData } from './hooks/useRouteData';
import { TagProvider } from '../../../contexts/tagContext';
import { SafetyPage } from '../safety/SafetyPage';
import { ViewModeMenu } from './components/ViewModeMenu';
import { PermissionRoute } from '../../common/PermissionRoute/PermissionRoute';
import { ViewSelector } from '../../common/ViewSelector/ViewSelector';
import { IViewSelectorOption, OptionPicker } from '../../common/ViewSelector/components/OptionPicker';
import { MapPoint } from '../../common/MapViewer';
import { ProgressPage } from '../progress/ProgressPage';
import { checkUserPermissions } from '../../common/PermissionWrapper/PermissionWrapper';
import { useUserContext } from '../../../contexts/userContext';

export const BuildingPage = () => {
  const [hoverFloor, setHoverFloor] = useState<string | null>(null);
  const { generateProjectSafetyPath, generateProjectPath, generateProjectProgressPath, generateProjectProgressGanttPath, generateProjectProgressChartsPath, generateProjectProgressReportsPath, generateSafetyGalleryPath } = useGeneratedPaths();

  useRouteData();
  const {
    updateBuilding,
    updateFloor,
    updatePoint,
    updateImage,
    state: buildingState,
  } = useBuildingContext();
  const { state: userState } = useUserContext();
  const { navigateToFloor, navigateToCurrentPointFullscreen } = useNavigation();

  const [floorExpanded, setFloorExpanded] = useState<boolean>(!buildingState.floorId);

  const updateFloorDesc = (data: any) => {
    updateFloor(data);
  };

  const inProgressTracking = !!useRouteMatch(PATH_STRINGS.progressOverview);
  const inSafetyTracking = !!useRouteMatch(PATH_STRINGS.safetyOverview);
  const excludeRegionPictures = !inProgressTracking && !inSafetyTracking;

  const { isLoading: floorImagesLoading } = useFloorImages(updateFloorDesc, false, undefined, undefined, excludeRegionPictures);
  const imagesQuery = useImagesQuery(
    buildingState.dateRange,
    buildingState.imageData,
    updateImage,
    updateImage
  );

  const { data: buildingData, isLoading: buildingLoading } = useBuildingQuery(updateBuilding);

  const floorPlanViewLoaded: boolean = buildingState.floorData && buildingState.floorData.imageUrl;

  useEffect(() => {
    updateBuilding({
      floorImagesLoading: floorImagesLoading
    });
  }, [floorImagesLoading, updateBuilding]);

  /*
  if buildingState.pointId changes, update the active point info
   */
  useEffect(() => {
    const point = buildingState.floorData.points?.find(
      (point: Point) => buildingState.pointId === point.point_id.toString()
    );
    if (point && buildingState.imageData) {
      updatePoint({
        ...point,
        panoramaUrl: buildingState.imageData.image,
        panoramaThumbUrl: point.panoramaUrl,
      });
    }
    // eslint-disable-next-line
  }, [buildingState.imageData, buildingState.pointId]);

  /*
  If the date-range changes, re-fetch the 360 image to get one within the range
   */
  useEffect(() => {
    if (imagesQuery.isSuccess) imagesQuery.refetch();
    // eslint-disable-next-line
  }, [buildingState.dateRange]);

  const VIEW_MODE_MAP = useMemo(() => ({
    'Site View': {
      route: buildingState.projectId ? generateProjectPath(buildingState.projectId) : '',
      userCanAccess: checkUserPermissions(
        userState.permissions,
        'project_tables.read_project',
        buildingState.projectId
      ),
    },
    Safety: {
      route: buildingState.projectId ? generateProjectSafetyPath(buildingState.projectId) : '',
      userCanAccess: checkUserPermissions(
        userState.permissions,
        'safety_tables.read_observation',
        buildingState.projectId
      ),
      additionalActivePaths: [buildingState.projectId ? generateSafetyGalleryPath(buildingState.projectId) : ''],
    },
    Progress: {
      route: buildingState.projectId ? generateProjectProgressPath(buildingState.projectId) : '',
      additionalActivePaths: [
        buildingState.projectId ? generateProjectProgressGanttPath(buildingState.projectId) : '',
        buildingState.projectId ?  generateProjectProgressChartsPath(buildingState.projectId) : '',
        buildingState.projectId ?  generateProjectProgressReportsPath(buildingState.projectId) : ''
      ],
      userCanAccess: checkUserPermissions(
        userState.permissions,
        'progress_tables.read_project_tracker',
        buildingState.projectId
      ),
    }
  }), [buildingState.projectId, generateProjectPath, generateProjectProgressChartsPath, generateProjectProgressGanttPath, generateProjectProgressPath, generateProjectProgressReportsPath, generateProjectSafetyPath, generateSafetyGalleryPath, userState.permissions]);

  if (buildingLoading || !buildingData) {
    return <LoadingIndicator />;
  }

  const onHoverFloor = (hoverOption: IViewSelectorOption | null) => {
    setHoverFloor(hoverOption ? hoverOption.Id : null);
  };

  const viewSelectorFloorOptions: IViewSelectorOption[] = buildingData.floors
    .map((floor: { name: string; floor_code: string }) => ({
      Id: floor.floor_code,
      Content: floor.name,
      Options: [],
    }))
    .reverse();

  const currentSelectedFloor = viewSelectorFloorOptions.filter(
    option => option.Id === buildingState.floorId
  )[0];

  const currentHoverFloor = viewSelectorFloorOptions.filter(option => option.Id === hoverFloor)[0];

  const onClickBuildingOverviewFloor = (floor: string) => {
    navigateToFloor(floor);
  };

  const viewingItems = {
    Id: 'root',
    Content: 'Floor',
    Options: viewSelectorFloorOptions,
    OnSelectChild: (selectedChild: IViewSelectorOption) => onClickBuildingOverviewFloor(selectedChild.Id),
    OnHoverChild: onHoverFloor
  }

  const onClickSelectItem = (selectedOption: IViewSelectorOption) => {
    if (viewingItems.OnSelectChild) {
      viewingItems.OnSelectChild(selectedOption);
    }
  };

  const onHover = (hoverOption: IViewSelectorOption | null) => {
    if (viewingItems.OnHoverChild) {
      viewingItems.OnHoverChild(hoverOption)
    }
  }

  let points = <></>;

  if (floorPlanViewLoaded) {
    points = buildingState.floorData.points.map((point: any) => (
      <MapPoint
        key={point.point_id}
        selectedDateRange={buildingState.dateRange}
        point={point}
        highlighted={point.point_id === buildingState.pointData?.point_id}
        x={point.x}
        y={point.y}
      />
    ));
  }

  return (
    <>
      <div style={{ position: 'absolute', zIndex: buildingState.fullscreen ? 1 : 11 }}>
        <Switch>
          <Route path={PATH_STRINGS.safetyOverview} />
          <Route path={PATH_STRINGS.progressOverview} />
          <Route path={PATH_STRINGS.project}>
            <ViewSelector
              title="View Selector"
              initialViewSelectorExpanded
              viewingExpanded={floorExpanded}
              setViewingExpanded={setFloorExpanded}
              maxHeight="calc(100vh - 155px)"
            >
              <OptionPicker
                expanded={floorExpanded}
                setExpanded={setFloorExpanded}
                viewingItems={viewingItems}
                selectedItem={currentSelectedFloor}
                hoverItem={currentHoverFloor}
                onSelectOption={onClickSelectItem}
                onHover={onHover}
              />
            </ViewSelector>
          </Route>
        </Switch>
      </div>
      <Switch>
        <Route
          path={[
            PATH_STRINGS.project,
            PATH_STRINGS.safetyOverview,
            PATH_STRINGS.safetyGallery,
            PATH_STRINGS.progressOverview,
            PATH_STRINGS.progressOverviewGantt,
            PATH_STRINGS.progressOverviewCharts,
            PATH_STRINGS.progressOverviewReports
          ]}
          exact>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '25px',
              marginTop: '38px',
            }}>
            <ViewModeMenu
              items={VIEW_MODE_MAP}
              zIndex={100}
            />
          </div>
        </Route>
      </Switch>
      <Switch>
        <PermissionRoute
          permission="progress_tables.read_project_tracker"
          path={PATH_STRINGS.progressOverview}
          component={ProgressPage}
        />
        <PermissionRoute
          permission="safety_tables.read_observation"
          path={PATH_STRINGS.safetyOverview}
          component={SafetyPage}
        />
        <Route exact path={PATH_STRINGS.project}>
          <BuildingOverview
            hoverFloor={hoverFloor}
            setHoverFloor={setHoverFloor}
            onFloorClicked={onClickBuildingOverviewFloor}
            showLogos={!userState.is_demo_account}
          />
        </Route>
        <Route path={PATH_STRINGS.floor}>
          {floorImagesLoading && <LoadingIndicator />}
          {floorPlanViewLoaded && (
            <FloorPlanView
              floorData={buildingState.floorData}
              mapViewerChildren={points}
              zoomInIconStyle={{ position: 'fixed', right: '40px', top: 'calc(50% - 22px)', zIndex: 2}}
              zoomOutIconStyle={{ position: 'fixed', right: '40px', top: 'calc(50% + 22px)', zIndex: 2}}
            />
          )}
          {(buildingState.imageData && buildingState.pointId) &&
            (
              <PreviewPopup
                imageLoading={imagesQuery.isLoading}
                onFullScreenClick={() => {
                  navigateToCurrentPointFullscreen();
                }}
              />
            )}
          {buildingState.fullscreen &&
            buildingState.floorData &&
            buildingState.pointData &&
            !floorImagesLoading &&
            Object.entries(buildingState.imageData).length !== 0 && (
              <TagProvider>
                <>
                  <ImageViewer />
                </>
              </TagProvider>
            )}
        </Route>
      </Switch>
    </>
  );
};
