import styled from "styled-components";
import React from "react";

export const CustomTooltip = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({children, ...rest}, ref) => {
  return (
    <CustomTooltipContainer
      ref={ref}
      {...rest}
    >
      {children}
    </CustomTooltipContainer>
  )
});

export const CustomTooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  gap: 7px;
  background: #FFFFFF;
  border: 1px solid #E2E8F0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;

export const CustomTooltipLabel = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #5C6F8A;
`;

export const CustomTooltipValue = styled.div`
  font-size: 16px;
  line-height: 20px;
`;
