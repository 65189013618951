import React, { useState } from "react";
import styled from "styled-components";
import { createNoGoZone, updateNoGoZone } from "../../../../../api/adminBuildingFetches";
import { fetchFloors } from "../../../../../api/buildingFetches";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { usePanZoomContext } from "../../../../common/PanZoomContext";
import { IBoundaryPoint, INoGoZone } from "./ManageNoGoZones";
import { NoGoZone } from "./NoGoZone";
import { useNoGoZoneContext } from "./NoGoZoneContext";

export const NoGoZonesSvg = () => {
  const {
    updateBuilding,
    updateFloor,
    state: buildingState
  } = useBuildingContext();

  const {
    svgIsDraggingDown,
    setSVGIsDraggingDown,
    setSVGDraggingOccurred,
    currentDraggingPointId,
    pointDraggingOccurred,
    setPointDraggingOccurred,
    currentDraggingZoneId,
    zoneDraggingOccurred,
    setZoneDraggingOccurred,
    dragStartX,
    setDragStartX,
    dragStartY,
    setDragStartY,
    canCreateNewZones,
    canEditZones,
  } = useNoGoZoneContext();

  const {
    scale,
  } = usePanZoomContext();

  const { noGoZones, visibleNoGoZones } = buildingState.floorData;

  const [lastTouched, setLastTouched] = useState<number>(0);

  const { addNotification } = useNotifications();

  const onUpdatePoint = (id: string, x: number, y: number) => {
    const updatedNoGoZones = new Map<number, INoGoZone>(noGoZones);

    updatedNoGoZones.forEach((zone, zoneId, map) =>{
      zone = {...zone, points: zone.points.map((point: IBoundaryPoint) => {
        if (point.id === id) {
          return {...point, x, y};
        } else {
          return point;
        }
      })}

      map.set(zoneId, zone);
    });

    updateFloor({
      noGoZones: updatedNoGoZones
    });
  }

  const getRectangle = (startX: number, startY: number, endX: number, endY: number) => {
    const topLeft: IBoundaryPoint = {id: '', zone_id: -1, x: Math.min(startX, endX), y: Math.min(startY, endY)};
    const topRight: IBoundaryPoint = {id: '', zone_id: -1, x: Math.min(startX, endX), y: Math.max(startY, endY)};
    const bottomLeft: IBoundaryPoint = {id: '', zone_id: -1, x: Math.max(startX, endX), y: Math.min(startY, endY)};
    const bottomRight: IBoundaryPoint = {id: '', zone_id: -1, x: Math.max(startX, endX), y: Math.max(startY, endY)};

    return [topLeft, topRight, bottomRight, bottomLeft];
  }

  const initiateDrawingNewZone = (x: number, y: number) => {
    if (!canCreateNewZones) {
      return;
    }

    const newZoneId: number = -1;

    setSVGIsDraggingDown(newZoneId);
    setDragStartX(x);
    setDragStartY(y);

    const points = getRectangle(x, y, x, y);

    const newZone: INoGoZone = { id: newZoneId, project_floor: buildingState.floorId, name: `New Zone`, points: points, is_active: true, drawing: true }
    
    const visibleNoGoZones = new Set(buildingState.floorData.visibleNoGoZones);
    visibleNoGoZones.add(newZoneId);

    const updatedNoGoZones = new Map(noGoZones);
    updatedNoGoZones.set(newZoneId, newZone);

    updateFloor({
      noGoZones: updatedNoGoZones,
      visibleNoGoZones,
    });
  }

  const onDoubleClick = (e: React.MouseEvent<SVGElement>) => {
    initiateDrawingNewZone(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  }

  const getTouchXAndY = (e: React.TouchEvent) => {
    const eventTarget = e.target as HTMLElement;
    const svg = eventTarget.tagName.toLowerCase() === 'svg' ? e.target : eventTarget.parentElement;

    const rect = (svg as HTMLElement).getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    const x = (e.changedTouches[0].pageX - (rect.left - bodyRect.left)) / scale;
    const y = (e.changedTouches[0].pageY - (rect.top - bodyRect.top)) / scale;

    return [x,y];
  }

  const onTouchStart = (e: React.TouchEvent<SVGSVGElement>) => {
    e.preventDefault();
    
    const touchTime = new Date().getTime();
    const doubleTap: boolean = touchTime - lastTouched <= 200;

    if (doubleTap && e.touches.length > 0) {
      const [x,y] = getTouchXAndY(e);

      initiateDrawingNewZone(x, y);
    }

    setLastTouched(touchTime);
  }

  const onTouchEnd = (e: React.TouchEvent<SVGSVGElement>) => {
    e.preventDefault();

    endDraggingActions();
  }

  const zoneCreationWithBuildingUpdate = (drawingZone: INoGoZone) => {
    return createNoGoZone(buildingState.projectId, buildingState.floorId, drawingZone).then(async (createdZone: INoGoZone) => {
      const updatedFloors = await fetchFloors(buildingState.projectId);

      updateBuilding({ 
        projectData: {
          ...buildingState.projectData,
          floors: updatedFloors,
        }
      });

      return createdZone;
    });
  }

  const createNewNoGoZone = async () => {
    let updatedNoGoZones = new Map<number, INoGoZone>(noGoZones);
    let updatedVisibleNoGoZones = new Set(visibleNoGoZones);

    const drawingZone = updatedNoGoZones.get(svgIsDraggingDown);

    if (drawingZone) {
      try {
        const createdZone = await zoneCreationWithBuildingUpdate(drawingZone);

        updatedNoGoZones.delete(drawingZone.id);
        updatedVisibleNoGoZones.delete(drawingZone.id);

        updatedNoGoZones.set(createdZone.id, createdZone);
        updatedVisibleNoGoZones.add(createdZone.id);

        updateFloor({
          noGoZones: updatedNoGoZones,
          visibleNoGoZones: updatedVisibleNoGoZones,
        });

        setSVGDraggingOccurred(false);
      } catch (err) {
        console.log('createNewZone==>>', err);
        addNotification('Error Creating Zone', 'error');
      }
    }
  }

  const saveZoneAfterDragging = async () => {
    let updatedNoGoZones = new Map<number, INoGoZone>(noGoZones);

    const draggedZone = updatedNoGoZones.get(currentDraggingZoneId);

    if (draggedZone) {
      try {
        const updatedDraggedZone = await updateNoGoZone(buildingState.projectId, buildingState.floorId, draggedZone);

        updatedNoGoZones.set(currentDraggingZoneId, updatedDraggedZone);
  
        updateFloor({
          noGoZones: updatedNoGoZones
        });
      } catch (err) {
        console.log('saveZoneAfterDragging==>>', err);

        addNotification('Error Saving Zone After Drag', 'error');
      }
    }
  }

  const savePointAfterDragging = async () => {
    let updatedNoGoZones = new Map<number, INoGoZone>(noGoZones);

    let draggedPointZone: INoGoZone | null = null;

    noGoZones.forEach((zone: INoGoZone) => {
      const currentZoneContainsPoint = zone.points.some(point => point.id === currentDraggingPointId);

      if (currentZoneContainsPoint) {
        draggedPointZone = zone;
      };
    });

    if (draggedPointZone !== null) {
      const updatedZone = await updateNoGoZone(buildingState.projectId, buildingState.floorId, draggedPointZone);

      updatedNoGoZones.set((draggedPointZone as INoGoZone).id, updatedZone);

      updateFloor({
        noGoZones: updatedNoGoZones
      });

      setPointDraggingOccurred(false);
    }
  }

  const endDraggingActions = () => {
    if (svgIsDraggingDown !== null) {
      createNewNoGoZone();      
    } else if (zoneDraggingOccurred) {
      saveZoneAfterDragging();
    } else if (pointDraggingOccurred) {
      savePointAfterDragging();
    }

    setZoneDraggingOccurred(false);
    setSVGIsDraggingDown(null);
  }

  const onMouseUp = async (e: React.MouseEvent<SVGElement>) => {
    endDraggingActions();
  }

  const drawShapeIfDragging = (dragEndX: number, dragEndY: number) => {
    if (svgIsDraggingDown) {
      const updatedNoGoZones = new Map<number, INoGoZone>(noGoZones);
      const newZone = updatedNoGoZones.get(svgIsDraggingDown);

      if (newZone) {
        const points = getRectangle(dragStartX, dragStartY, dragEndX, dragEndY);      

        newZone.points = points;
        updatedNoGoZones.set(svgIsDraggingDown, newZone);
      }

      updateFloor({
        noGoZones: updatedNoGoZones,
      });
        
      setSVGDraggingOccurred(true);
    }
  }

  const movePointIfDragging = (dragEndX: number, dragEndY: number) => {
    if (currentDraggingPointId !== null) {
      onUpdatePoint(currentDraggingPointId, dragEndX, dragEndY);
      setPointDraggingOccurred(true);
    }
  }

  const movePolygonIfDragging = (dragEndX: number, dragEndY: number) => {
    if (currentDraggingZoneId !== null) {
      const xDiff = dragEndX - dragStartX;
      const yDiff = dragEndY - dragStartY;

      const updatedZones = new Map<number, INoGoZone>(noGoZones);

      const draggingZone = updatedZones.get(currentDraggingZoneId);

      if (draggingZone) {
        draggingZone.points = draggingZone.points.map((point: IBoundaryPoint) => {
          return {...point, x: point.x + xDiff, y: point.y + yDiff};
        });

        updatedZones.set(currentDraggingZoneId, draggingZone);
      }

      updateFloor({
        noGoZones: updatedZones
      });

      setDragStartX(dragEndX);
      setDragStartY(dragEndY);
      setZoneDraggingOccurred(true);
    }
  }

  const processDraggingOperations = (dragEndX: number, dragEndY: number) => {
    if (!canEditZones) {
      return;
    }

    drawShapeIfDragging(dragEndX, dragEndY);
    movePointIfDragging(dragEndX, dragEndY);
    movePolygonIfDragging(dragEndX, dragEndY);
  }

  const onTouchMove = (e: React.TouchEvent<SVGSVGElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const [dragEndX, dragEndY] = getTouchXAndY(e);

    processDraggingOperations(dragEndX, dragEndY);
  }

  const onMouseMove = (e: React.MouseEvent<SVGElement>) => {
    const dragEndX = e.nativeEvent.offsetX;
    const dragEndY = e.nativeEvent.offsetY;

    processDraggingOperations(dragEndX, dragEndY);
  }

  return (
    <ZoneSVG
      width='100%'
      height='100%'
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onDoubleClick={onDoubleClick}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
    >
      { Array.from(noGoZones as Map<number, INoGoZone>).map(([zoneId, zone]) => {
        if (visibleNoGoZones.has(zone.id)) {
          return (
            <NoGoZone
              key={zone.id}
              zone={zone}
              getTouchXAndY={getTouchXAndY}
            />
          )
        } else {
          return <></>;
        }
      })}
    </ZoneSVG>
  )
}

const ZoneSVG = styled.svg`
  position: absolute;
  left: 0;
  right: 0;
  background-color:transparent;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &::selection 
  {
      background-color:transparent;
  } 
  
  &::-moz-selection
  {
      background-color:transparent;
  }
`;