import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Flex from '@react-css/flex';
import _ from 'lodash';
import axios from 'axios';

import { Building } from '../../../api/types';
import { Icon } from '../../common/Icon';
import { Card, Integrations } from './components/Card';
import { ModeSelector } from './components/ModeSelector';
import { AddProjectButton } from './components/AddProjectButton';
import iconZoom from '../../../assets/images/icon_zoom.svg';
import iconPlus from '../../../assets/images/icon_plus.svg';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { useUserContext } from '../../../contexts/userContext';
import { captureException } from '../../../analytics';
import { useAdminZoneContext } from '../../../contexts/adminZoneContext';
import { useGeneratedPaths } from '../../../hooks/useGeneratedPaths';
import { ProcoreProjectLink } from './components/ProcoreProjectLink';
import { verifyProcoreToken } from '../../../api/procore';

const fetchProjects = async (user_id: string, adminView?: boolean) => {
  return axios
    .get(`https://api.nexterarobotics.com/api/user/${user_id}/projects${adminView ? '/admin' : ''}`)
    .then(r => r.data)
    .then(response => response.data)
    .catch(err => {
      captureException(err);
    });
};

export const BuildingsPage = () => {
  const {
    generateFilterBuildingsPath,
  } = useGeneratedPaths();

  const history = useHistory();
  const { state: userState } = useUserContext();
  const { inAdminZone } = useAdminZoneContext();
  const { search } = useLocation();

  const [buildings, setBuildings] = useState<Building[]>([]);
  const [buildingsLoading, setBuildingsLoading] = useState<boolean>(true);

  const [selectedProject, setSelectedProject] = useState<Building | null>(null);
  const [procoreProjectLinkOpen, setProcoreProjectLinkOpen] = useState<boolean>(false);
  const [userIsProcoreAuthenticated, setUserIsProcoreAuthenticated] = useState<boolean>(false);
  const [procoreAuthCheckComplete, setProcoreAuthCheckComplete] = useState<boolean>(true);

  // const establishProcoreAuthentication = useCallback(async () => {
  //   try {
  //     await verifyProcoreToken();
  //     setUserIsProcoreAuthenticated(true);
  //   } catch {
  //     setUserIsProcoreAuthenticated(false);
  //   } finally {
  //     setProcoreAuthCheckComplete(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   establishProcoreAuthentication();
  // }, [establishProcoreAuthentication]);

  const queryParams = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  useEffect(() => {
    setBuildingsLoading(true);

    fetchProjects(userState.public_id, inAdminZone)
      .then(returnedBuildings => {
        setBuildings(returnedBuildings);
      })
      .finally(() => {
        setBuildingsLoading(false);
      });
  }, [inAdminZone, userState.public_id]);

  const dataLoaded = !buildingsLoading && procoreAuthCheckComplete;

  const searchMode = queryParams.get('mode') ?? 'All';
  const sortMode = queryParams.get('sort') ?? 'Last Updated';

  const setSearchMode = useCallback(
    (mode: string) => history.replace(generateFilterBuildingsPath(inAdminZone, mode, sortMode)),
    [history, generateFilterBuildingsPath, inAdminZone, sortMode]
  );
  const setSortMode = useCallback(
    (mode: string) => history.replace(generateFilterBuildingsPath(inAdminZone, searchMode, mode)),
    [history, searchMode, generateFilterBuildingsPath, inAdminZone]
  );

  const buildingsFiltered = useMemo(() => {
    let filteredBuildings: Building[] = [];

    if (!buildings) return [];

    switch (searchMode) {
      case 'All':
        filteredBuildings = buildings;
        break;
      case 'Active':
        filteredBuildings = buildings.filter((x: any) => x.status === 'active');
        break;
      case 'Hold':
        filteredBuildings = buildings.filter((x: any) => x.status === 'hold');
        break;
      case 'Done':
        filteredBuildings = buildings.filter((x: any) => x.status === 'done');
        break;
    }

    switch (sortMode) {
      case 'Last Updated':
        filteredBuildings = _.orderBy(filteredBuildings, ['latest_data_received_on'], ['desc']);
        break;
      case 'Name':
        filteredBuildings = _.orderBy(filteredBuildings, ['name']);
        break;
      case 'Status':
        filteredBuildings = _.orderBy(filteredBuildings, ['status', 'name']);
        break;
    }

    return filteredBuildings;
  }, [buildings, searchMode, sortMode]);

  const onClickProjectContextMenuItem = (integration: Integrations, item: Building) => {
    if (integration === Integrations.Procore) {
      setSelectedProject(item);
      setProcoreProjectLinkOpen(true);
    }
  }

  const onCloseProcoreLink = () => {
    setProcoreProjectLinkOpen(false);
    setSelectedProject(null);
  }

  const onUpdateProjectAfterProcoreLink = (updatedProject: Building) => {
    setBuildings(prevBuildings => {
      return prevBuildings.map(building => {
        if (building.public_id === updatedProject.public_id) {
          return {...updatedProject};
        } else {
          return building;
        }
      });
    })
  }

  return (
    <>
      <TopMenu>
        <Flex flexDirection={'column'}>
          <PageTitle>Projects</PageTitle>
          <Flex alignItems={'center'}>
            {/* <Icon icon={iconZoom} size={14} /> */}
            <Flex alignItems={'center'}>
              <ModeSelector
                items={['All', 'Active', 'Hold', 'Done']}
                activeItem={searchMode}
                setActiveItem={setSearchMode}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection={'column'} alignItems={'flex-end'}>
          {/*<Flex alignItems={'center'} style={{ marginBottom: '10px' }}>*/}
          {/*  <AddProjectButton icon={iconPlus} text="Add Project" />*/}
          {/*</Flex>*/}
          <Flex alignItems={'center'}>
            <span style={{ marginRight: '16px', color: '#7E858E' }}>Sort by</span>
            <Select value={sortMode} onChange={e => setSortMode(e.target.value)}>
              <option value="Last Updated">Last Updated</option>
              <option value="Name">Name</option>
              {searchMode === 'All' && <option value="Status">Status</option>}
            </Select>
          </Flex>
        </Flex>
      </TopMenu>
      <CardsContainer>
        <CardsContainerInner>
          {!dataLoaded ? (
            <LoadingIndicator />
          ) : (
            buildingsFiltered.map(item => (
              <Card
                key={item.public_id}
                {...item}
                public_id={item.public_id}
                onClickMenuItem={integration => onClickProjectContextMenuItem(integration, item)}
                userHasMenuAccess={userIsProcoreAuthenticated}
              />
            ))
          )}
          {(!buildingsFiltered.length && dataLoaded) && (
            <NoProjectsContainer>
              <h1>No projects matching criteria!</h1>
            </NoProjectsContainer>
          )}
        </CardsContainerInner>
      </CardsContainer>
      {(procoreProjectLinkOpen && !!selectedProject) &&
        <ProcoreProjectLink
          item={selectedProject}
          onClose={onCloseProcoreLink}
          onUpdateProject={onUpdateProjectAfterProcoreLink}
        />
      }
    </>
  );
};

const TopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const PageTitle = styled.div`
  font-size: 24px;
  line-height: 28px;

  text-transform: capitalize;

  color: #202020;

  margin-top: 40px;
  margin-bottom: 32px;
`;

const Select = styled.select`
  background: #ffffff;
  border: 1px solid #a7aabd;
  border-radius: 2px;

  font-size: 12px;
  padding: 0 4px 0 4px;
  min-width: 112px;
  height: 30px;
`;

const CardsContainer = styled.div`
  margin-top: 27px;
`;

const CardsContainerInner = styled.div`
  margin: -18px;

  display: flex;
  flex-wrap: wrap;
`;

const NoProjectsContainer = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
  padding: 2em;
`;
