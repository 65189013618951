import { useRouteMatch } from 'react-router-dom';
import { PATH_STRINGS } from '../../../../hooks/useGeneratedPaths';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { useEffect } from 'react';

/**
 * Parses route parameters into variables
 */
export const useAdminRouteData = () => {
  const { updateBuilding } = useBuildingContext();
  const buildingMatch = useRouteMatch<{ projectId: string }>(PATH_STRINGS.adminProject);
  const floorMatch = useRouteMatch<{ floorId: string }>(PATH_STRINGS.adminFloor);
  const scheduleMatch = useRouteMatch<{ scheduleId: string }>(PATH_STRINGS.adminSchedules);
  const manageUsersMatch = useRouteMatch<{ userId: string }>(PATH_STRINGS.adminManageUsersUser);
  const projectId: string = buildingMatch?.params.projectId || '';
  const floorId: string = floorMatch?.params.floorId || '';
  const scheduleId: string = scheduleMatch?.params.scheduleId || '';
  const projectUserId: string = manageUsersMatch?.params.userId || '';

  useEffect(() => {
    updateBuilding({
      projectId: projectId,
      floorId: floorId,
      scheduleId: scheduleId,
      projectUserId: projectUserId,
    });
    // eslint-disable-next-line
  }, [projectId, floorId, scheduleId, projectUserId]);

  return { projectId, floorId, scheduleId, projectUserId };
};
