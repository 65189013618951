import axios from 'axios';
import { Project } from './types';

const API_BASE = 'https://api.nexterarobotics.com/api';
// const API_BASE = 'http://127.0.0.1:8000/api';

const fetchProject = async (projectId: string) => {
  try {
    const res = await axios.get(`${API_BASE}/project/${projectId}`);
    const response = await res.data;
    return response.data;
  } catch (err) {
    console.log('errrProjects==>>', err);
  }
};

const updateProject = async (projectId: string, projectData: Partial<Project>) => {
  try {
    const res = await axios.patch(`${API_BASE}/project/${projectId}`, projectData);
    const response = await res.data;
    return response.data;
  } catch (err) {
    console.log('errUpdateProject==>>', err);
  }
}

const fetchFloors = async (projectId: string) => {
  try {
    const floorsUrl = `${API_BASE}/project/${projectId}/floors`;
    const res = await axios.get(floorsUrl);
    const response = await res.data;
    return response.data;
  } catch (err) {
    console.log('errrFloors==>>', err);
  }
};

const fetchFloor = async (projectId: string, floorId: string) => {
  try {
    const floorUrl = `${API_BASE}/project/${projectId}/floor/${floorId}`;
    const res = await axios.get(floorUrl);
    const response = await res.data;
    return response.data;
  } catch (err) {
    console.log('errrFloors==>>', err);
  }
};

const fetchImage = async (imageUrl: string) => {
  try {
    const res = await axios.get(imageUrl, {
      responseType: 'blob',
      transformRequest: (data, headers) => {
        delete headers.common['Authorization'];
        return data;
      },
    });
    return res.data;
  } catch (err) {
    console.log('errrImage==>>', err);
  }
};

const fetchPoints = async (projectId: string, floorId: string, activeOnly: boolean = false) => {
  try {
    const res = await axios.get(
      `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/points${activeOnly ? '?is_active=true' : ''}`
    );
    const response: any = await res.data;
    return response.data;
  } catch (err) {
    console.log('errrPoints==>>', err);
  }
};

const fetchPointImages = async (projectId: string, floorId: string, params?: Record<string, any>) => {
  try {
    const res = await axios.get(
      `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/images`,
      {
        params: params,
      }
    );
    const response: any = await res.data;
    let resp = response.data.map((item: any, i: number) => {
      item.taken_on = item.taken_on + `T12:00:00.${i % 1000}`;
      return item;
    });
    return resp;
  } catch (err) {
    console.log('errrPointImages==>>', err);
  }
};

const fetchPointImage = async (projectId: string, floorId: string, viewpointImage: string) => {
  try {
    const res = await axios.get(
      `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/image/${viewpointImage}`
    );
    const response: any = await res.data;
    response.data.taken_on = response.data.taken_on + 'T12:00:00.000';
    return response.data;
  } catch (err) {
    console.log('errrPointImage==>>', err);
  }
};

export {
  fetchProject,
  updateProject,
  fetchFloors,
  fetchImage,
  fetchPointImages,
  fetchPoints,
  fetchFloor,
  fetchPointImage,
};
