import React from "react";
import { updateNoGoZone } from "../../../../../api/adminBuildingFetches";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { blue, gray, pink } from "../../../../../styles/colors";
import { getNewPointId, IBoundaryPoint, INoGoZone } from "./ManageNoGoZones";
import { NoGoZoneCircle } from "./NoGoZoneCircle";
import { useNoGoZoneContext } from "./NoGoZoneContext";

interface IGhostPointProps {
  beforePoint: IBoundaryPoint;
  afterPoint: IBoundaryPoint;
  zone: INoGoZone;
}

export const GhostPoint = ({
  beforePoint,
  afterPoint,
  zone,
}: IGhostPointProps) => {
  const {
    updateFloor,
    state: buildingState
  } = useBuildingContext();

  const {
    setCurrentDraggingPointId,
    hoverNoGoZone,
  } = useNoGoZoneContext();

  const { addNotification } = useNotifications();

  const { noGoZones, selectedNoGoZones } = buildingState.floorData;

  const x = (beforePoint.x + afterPoint.x) / 2;
  const y = (beforePoint.y + afterPoint.y) / 2;

  let newPointIndex: number = 0;

  const onAddPoint = async (prevPointId: string, x: number, y: number) => {
    const updatedNoGoZones = new Map<number, INoGoZone>(noGoZones);

    const originalZone: INoGoZone = {...zone};
    const newZone: INoGoZone = {...zone};

    newZone.points = newZone.points.flatMap((point: IBoundaryPoint, i: number) => {
      if (point.id === prevPointId) {
        newPointIndex = i+1;
        return [point, {id: `${getNewPointId()}`, zone_id: newZone.id, x, y}]
      } else {
        return [point];
      }
    });

    updatedNoGoZones.set(zone.id, newZone);
    setCurrentDraggingPointId(newZone.points[newPointIndex].id);

    updateFloor({
      noGoZones: updatedNoGoZones
    });

    try {
      await updateNoGoZone(buildingState.projectId, buildingState.floorId, newZone);
    } catch (err) {
      console.log('onAddPoint==>>', err);
      addNotification('Error Adding New Point', 'error');
      updatedNoGoZones.set(zone.id, originalZone);

      updateFloor({
        noGoZones: updatedNoGoZones
      });

      setCurrentDraggingPointId(null);
    }
  }

  const onMouseDownGhostPoint = async (prevPointId: string, x: number, y: number) => {
    await onAddPoint(prevPointId, x, y);
  }

  const onCreateGhostPoint = (e: React.FormEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    onMouseDownGhostPoint(beforePoint.id, x, y);
  }

  return (
    <NoGoZoneCircle
      fill='white'
      stroke={(hoverNoGoZone === zone.id || selectedNoGoZones.has(zone.id)) ? blue : zone.is_active ? pink : gray}
      x={x}
      y={y}
      r={18}
      onMouseDown={onCreateGhostPoint}
      onTouchStart={onCreateGhostPoint}
    />
  )
}