import React from 'react';
import { useContextMenu } from '../../../../hooks/useContextMenu';
import { useTagContext } from '../../../../../../../contexts/tagContext';
import { useBuildingContext } from '../../../../../../../contexts/buildingContext';
import { HotspotContextMenu } from './HotspotContextMenu/HotspotContextMenu';
import { ContextMenuContainer, MenuItem } from './styles';
import mixpanel from 'mixpanel-browser';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { PermissionWrappedComponent } from "../../../../../../common/PermissionWrapper/PermissionWrapper";
import { MapPointContainer, MapPointMainCircle } from '../../../../../../common/MapViewer';
import classNames from 'classnames';
import styled from 'styled-components';
import iconAnnotationGray from '../../../../../../../assets/images/icon_annotation_gray.svg';
import iconAnnotationWhite from '../../../../../../../assets/images/icon_annotation_white.svg';
import iconCameraGray from '../../../../../../../assets/images/icon_camera_gray.svg';
import iconCameraWhite from '../../../../../../../assets/images/icon_camera_white.svg';
import iconDangerWhite from '../../../../../../../assets/images/icon_danger.svg';
import iconDangerGray from '../../../../../../../assets/images/icon_danger_gray.svg';
import iconProcoreGray from '../../../../../../../assets/images/icon_procore_gray.svg';
import iconProcoreWhite from '../../../../../../../assets/images/icon_procore_white.svg';
import iconAddChatGray from '../../../../../../../assets/images/icon_add_chat_gray.svg';
import iconAddChatWhite from '../../../../../../../assets/images/icon_add_chat_white.svg';
import { useProcoreAuthContext } from '../../../../../../../contexts/procoreAuthContext';

interface IImageViewerContextMenuProps {
  pannellumRef: any;
  mapPopupRef: any;
}
export const ImageViewerContextMenu = ({
  pannellumRef,
  mapPopupRef,
}: IImageViewerContextMenuProps) => {
  const { state: buildingState } = useBuildingContext();
  const { updateTags, updateChat, findSetCurrentTag } = useTagContext();
  const { xPos, yPos, showMenu, isHotspot, pitch, yaw } = useContextMenu(pannellumRef);
  const { state: procoreAuthState } = useProcoreAuthContext();
  const {isAuthenticated: isProcoreAuthenticated, canCreateRfis, canReadRfis, canUploadImages} = procoreAuthState;

  const projectIsLinkedToProcore = buildingState && buildingState.projectData && buildingState.projectData.procore_company_id && buildingState.projectData.procore_project_id;
  const showProcoreMenuItems = isProcoreAuthenticated && projectIsLinkedToProcore;

  const hospotClickHandler = (
    event: any,
    args: { pitch: number; yaw: number; type: string; tagId?: number }
  ) => {
    if (args.tagId) {
      findSetCurrentTag(args.tagId);
      mixpanel.track('Click Hotspot', {
        type: args.type,
      });
    }
  };

  const hotspotContextMenuHandler = (event: MouseEvent, args: { tagId: number }) => {
    if (args.tagId) findSetCurrentTag(args.tagId, true);
  };

  const addHotspot = (e: any, type: string, text?: string) => {
    if (pannellumRef.current && pitch !== null && yaw !== null) {
      const pannellum = pannellumRef.current;
      let coordinates: any = [pitch, yaw];
      const id = Math.round(Math.random() * -100);
      pannellum.addHotSpot(
        coordinates,
        type,
        hospotClickHandler,
        hotspotContextMenuHandler,
        id,
        false,
        text
      );
      updateTags({
        current: {
          id: id,
          pitch: coordinates[0],
          yaw: coordinates[1],
          project_id: buildingState.projectId,
          point_id: buildingState.pointId,
          type: type,
        },
      });
      if (type === 'CHAT') {
        updateChat({ displayDialogue: true, status: 'create' });
      }
      mixpanel.track('Add Tag', {
        type: type,
      });
    }
  };

  const onDownloadClick = async () => {
    if (mapPopupRef.current && pannellumRef.current) {
      const mapPopup = mapPopupRef.current;
      const pannellum = pannellumRef.current;

      const viewerImageData = pannellum.captureScreenshot();

      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      const context = canvas.getContext('2d')!;

      const viewerImage = await loadImage(viewerImageData);
      canvas.width = viewerImage.width;
      canvas.height = viewerImage.height;

      context.drawImage(viewerImage, 0, 0, canvas.width, canvas.height);

      const canvasPopup = await html2canvas(mapPopup, {
        ignoreElements: el => el.classList.contains('ignore-html2canvas'),
      });

      context.drawImage(canvasPopup, canvas.width - canvasPopup.width - 10, 10);

      canvas.toBlob(blob => {
        saveAs(blob!, 'screen.png');
      });
      mixpanel.track('Download Screenshot');
    }
  };

  if (isHotspot)
    return (
      <HotspotContextMenu pannellumRef={pannellumRef} xPos={xPos} yPos={yPos} showMenu={showMenu} />
    );

  return (
    <div>
      {showMenu &&
        <MapPointContainer
          x={parseInt(xPos)}
          y={parseInt(yPos)}
          isActive={false}
          style={{zIndex: 10000}}
        >
          <MapPointMainCircle
            pointSize={14}
            borderSize={1}
            className={classNames({
              back_blue: true,
              border_white: true,
            })}
          />
        </MapPointContainer>
      }
      
      <ContextMenuContainer
        style={{
          top: yPos,
          left: parseInt(xPos) + 9,
          display: showMenu ? 'block' : 'none',
          padding: '0px'
        }}>
        <ul>
          <MenuItem
            onClick={e => addHotspot(e, 'CHAT')}
            className="oco-ignore context-menu-li"
            icon={iconAddChatGray}
            hoverIcon={iconAddChatWhite}
            style={menuItemStyle}
          >
            Chat
          </MenuItem>
          <MenuItem
            onClick={e => addHotspot(e, 'ANNOTATION')}
            className="oco-ignore context-menu-li"
            icon={iconAnnotationGray}
            hoverIcon={iconAnnotationWhite}
            style={menuItemStyle}
          >
            Annotation
          </MenuItem>
          <MenuItem
            onClick={onDownloadClick}
            className="oco-ignore"
            icon={iconCameraGray}
            hoverIcon={iconCameraWhite}
            style={menuItemStyle}
          >
            Screenshot
          </MenuItem>
          <PermissionWrappedComponent permission="safety_tables.create_observation" project_id={buildingState.projectId}>
            <MenuSectionDivider/>
            <MenuItem
              onClick={e => addHotspot(e, 'SAFETY')}
              className="oco-ignore context-menu-li"
              icon={iconDangerGray}
              hoverIcon={iconDangerWhite}
              style={menuItemStyle}
            >
              Safety Observation
            </MenuItem>
          </PermissionWrappedComponent>
          {showProcoreMenuItems &&
            <>
              {(canCreateRfis || canReadRfis || canUploadImages) &&
                <MenuSectionDivider/>
              }
              {canCreateRfis &&
                <MenuItem
                  onClick={e => addHotspot(e, 'PROCORE-NEW-RFI')}
                  className="oco-ignore context-menu-li"
                  icon={iconProcoreGray}
                  hoverIcon={iconProcoreWhite}
                  style={menuItemStyle}
                >
                  New RFI
                </MenuItem>
              }
              {canReadRfis &&
                <MenuItem
                  onClick={e => addHotspot(e, 'PROCORE-LINK-RFI')}
                  className="oco-ignore context-menu-li"
                  icon={iconProcoreGray}
                  hoverIcon={iconProcoreWhite}
                  style={menuItemStyle}
                >
                  Link Existing RFI
                </MenuItem>
              }
              {canUploadImages &&
                <MenuItem
                  onClick={e => addHotspot(e, 'PROCORE-SAVE-IMAGE')}
                  className="oco-ignore context-menu-li"
                  icon={iconProcoreGray}
                  hoverIcon={iconProcoreWhite}
                  style={menuItemStyle}
                >
                  Save Image to Procore
                </MenuItem>
              }
            </>
          }
        </ul>
      </ContextMenuContainer>
    </div>
  );
};

async function loadImage(image: string) {
  return new Promise<HTMLImageElement>(resolve => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = image;
  });
}

const menuItemStyle = {
  padding: '5px 10px',
}

const MenuSectionDivider = styled.div`
  border-top: 1px solid rgba(107, 114, 128, 0.5);
`
