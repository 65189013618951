import React, { createContext, useContext } from 'react';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { useUserContext } from '../../../../../contexts/userContext';
import { checkUserPermissions } from '../../../../common/PermissionWrapper/PermissionWrapper';

const ManagePointsContext = createContext<any>(null);

export const ManagePointsProvider = ({ children }: any) => {
  const { state: userState } = useUserContext();
  const { state: buildingState } = useBuildingContext();

  const canCreateNewPoints = checkUserPermissions(userState.permissions, 'viewpoints_tables.create_viewpoints_point', buildingState.projectId);
  const canEditPoints = checkUserPermissions(userState.permissions, 'viewpoints_tables.edit_viewpoints_point', buildingState.projectId);
  const canDeletePoints = checkUserPermissions(userState.permissions, 'viewpoints_tables.delete_viewpoints_point', buildingState.projectId);

  const canCreatePointGroups = checkUserPermissions(userState.permissions, 'viewpoints_tables.create_viewpoints_group', buildingState.projectId);

  const canCreatePointMappings = checkUserPermissions(userState.permissions, 'viewpoints_tables.create_viewpoints_point_group', buildingState.projectId);
  const canDeletePointGroups = checkUserPermissions(userState.permissions, 'viewpoints_tables.delete_viewpoints_group', buildingState.projectId);

  const canSelectPoints = canEditPoints || canDeletePoints || canCreatePointMappings;

  const value = {
    canCreateNewPoints,
    canEditPoints,
    canDeletePoints,
    canCreatePointGroups,
    canCreatePointMappings,
    canDeletePointGroups,
    canSelectPoints,
  }

  return <ManagePointsContext.Provider value={value}>{children}</ManagePointsContext.Provider>;
};

export const useManagePointsContext = () => {
  return useContext(ManagePointsContext);
};
