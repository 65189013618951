import styled from 'styled-components';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import iconBack from '../../../../assets/images/icon_back_dark.svg';
import iconForward from '../../../../assets/images/icon_forward.svg';
import { IconButton } from '../../../common/IconButton';
import { Flex } from '@react-css/flex';
import axios from 'axios';
import { useNotifications } from '../../../../contexts/notificationProvider';
import { useViewerPosition } from '../hooks/useViewerPosition';
import { useEffect, useState } from 'react';
import { globalErrorHandler } from '../../../../globalErrorHandler';
import { useImageViewerContext } from '../imageViewerContext';

const DebugCard = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 300px;
  height: 160px;
  background: #000;
  color: #0f0;
  padding: 10px;
  line-height: 1.1em;
  font-size: 0.9em;
`;

export const DebugPanel = ({ viewerPosition, updateViewer }: any) => {
  // const { viewerPosition, updateViewerPosition } = useViewerPosition();
  const { state: buildingState } = useBuildingContext();
  const { state: ImageViewerState, updateMaster } = useImageViewerContext();
  const { addNotification } = useNotifications();
  const [offsetAngle, setOffsetAngle] = useState<number>(0);

  const updateAngle = () => {
    axios
      .patch(
        `https://api.nexterarobotics.com/api/project/${buildingState.projectId}/floor/${buildingState.floorId}/viewpoints/image/${buildingState.imageData.data.id}`,
        { angle: angleLimit(offsetAngle) }
      )
      .then(res => addNotification('Angle Updated', 'success'))
      .catch(err => {
        globalErrorHandler(err);
        addNotification('Error updating point', 'error');
      });
  };

  const incrementAngle = (amount: number) => {
    setOffsetAngle(prevState => prevState + amount);
  };

  useEffect(() => {
    updateMaster({ angleDelta: offsetAngle });
  }, [offsetAngle]);

  useEffect(() => {
    setOffsetAngle(ImageViewerState.master.data.angle);
  }, [ImageViewerState.master.data.angle]);

  return (
    <DebugCard>
      <h4>Viewpoint Image Id: {ImageViewerState.master.data.id}</h4>
      <h4>Original offset: {ImageViewerState.master.data.angle}&#176;</h4>
      <h4>Current Offset: {offsetAngle}&#176;</h4>
      <p>Rotation: {viewerPosition?.yaw}&#176;</p>
      <p>Pitch: {viewerPosition?.pitch}&#176;</p>
      <Flex style={{ margin: '5px 0' }}>
        <div onClick={() => incrementAngle(-15)} style={{ cursor: 'pointer', marginRight: '10px' }}>
          -15
        </div>
        <div onClick={() => incrementAngle(15)} style={{ cursor: 'pointer', marginRight: '10px' }}>
          +15
        </div>
        <div onClick={() => incrementAngle(-90)} style={{ cursor: 'pointer', marginRight: '10px' }}>
          -90
        </div>
        <div onClick={() => incrementAngle(90)} style={{ cursor: 'pointer' }}>
          +90
        </div>
      </Flex>
      <Flex flexDirection="row" style={{ paddingTop: '5px', alignItems: 'center' }}>
        <IconButton
          size={25}
          icon={iconBack}
          onClick={() => incrementAngle(1)}
          style={{ position: 'unset', marginRight: '5px' }}
        />
        <IconButton
          size={25}
          icon={iconForward}
          onClick={() => incrementAngle(-1)}
          style={{ position: 'unset', marginRight: '5px' }}
        />
        <div onClick={updateAngle} style={{ cursor: 'pointer' }}>
          Save
        </div>
      </Flex>
    </DebugCard>
  );
};

const angleLimit = (angle: number): number => {
  if (angle > 360) {
    return angleLimit(angle - 360);
  } else if (angle < 0) {
    return angleLimit(angle + 360);
  } else {
    return angle;
  }
};
