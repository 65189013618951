import React, { useState } from 'react';
import axios from 'axios';
import { useNotifications } from '../../../../../../../../contexts/notificationProvider';
import { ContextMenuContainer, MenuItem } from '../styles';
import { useTagContext } from '../../../../../../../../contexts/tagContext';
import { useUserContext } from '../../../../../../../../contexts/userContext';
import mixpanel from 'mixpanel-browser';
import { ConfirmationModal } from '../../../../../../../common/Confirmation/Confirmation';

interface IHotspotContextMenuProps {
  pannellumRef: any;
  xPos: string;
  yPos: string;
  showMenu: boolean;
}
export const HotspotContextMenu = ({
  pannellumRef,
  xPos,
  yPos,
  showMenu,
}: IHotspotContextMenuProps) => {
  const { addNotification } = useNotifications();
  const { state: tagState } = useTagContext();
  const { state: userState } = useUserContext();
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const removeHotspot = () => {
    axios
      .patch(`https://services.nexterarobotics.com/tags/${tagState.tags.current.id}/`, {
        deleted: true,
      })
      .then(() => {
        addNotification('Tag removed', 'success');
      })
      .catch(e => {
        addNotification('Tag could not be removed', 'error');
      });
    pannellumRef.current?.removeHotspot(tagState.tags.current.id);
    mixpanel.track('Remove Tag');
  };

  if (tagState.tags.current.type === 'CHAT') {
    if (tagState.tags.current.chat_room.admin.public_id !== userState.public_id) {
      return <></>;
    }
  }

  return (
    <>
      <ContextMenuContainer
        style={{
          top: yPos,
          left: xPos,
          display: showMenu ? 'block' : 'none',
        }}>
        <ul>
          <MenuItem
            onClickCapture={() => setConfirmation(true)}
            className="oco-ignore context-menu-li">
            {tagState.tags.current.type === 'CHAT' ? 'Archive' : 'Delete'}
          </MenuItem>
        </ul>
      </ContextMenuContainer>
      <ConfirmationModal
        isOpen={confirmation}
        setIsOpen={setConfirmation}
        message={'Are you sure you want to archive this tag?'}
        onConfirm={removeHotspot}
      />
    </>
  );
};
