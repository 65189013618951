import { Route } from "react-router-dom";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { PATH_STRINGS } from "../../../../../hooks/useGeneratedPaths"
import { LoadingIndicator } from "../../../../common/LoadingIndicator";
import { useSchedulePointsQuery, useScheduleQuery } from "../../hooks/adminBuildingQueries";
import { ManageSchedulesProvider } from "./ManageSchedulesContext";
import { ConvexHullGrahamScan } from "./ScheduleControls/graham_scan";
import { ScheduleFloorPlanView } from "./ScheduleFloorPlanView"
import { SchedulesDashboard } from "./SchedulesDashboard";

interface IBuildingFloor {
  floor_code: string;
  id: number;
  name: string;
}

interface IManageSchedulesProps {
  floorLoading: boolean;
  floors: IBuildingFloor[];
}

export const ManageSchedules = ({
  floorLoading,
  floors,
}: IManageSchedulesProps) => {
  const {
    updateBuilding,
    updateFloor,
  } = useBuildingContext();

  let firstFloorName: string = '';

  if (floors && floors.length) {
    firstFloorName = floors[0].floor_code;
  } 

  const updateScheduleData = (data: any) => {
    updateBuilding({
      scheduleData: data
    });
  }

  const updateSchedulePointsData = (data: any[]) => {
    const scheduleMappings = new Map();
    const selectedPoints = new Set();
    const convexHullCalculatorMappings = new Map<string, ConvexHullGrahamScan>();

    floors.forEach((floor) => {
      scheduleMappings.set(floor.floor_code, new Set());
      convexHullCalculatorMappings.set(floor.floor_code, new ConvexHullGrahamScan(-1, -1))
    });

    data.forEach((point) => {
      scheduleMappings.get(point.floor.floor_code).add(point.point_id);
      convexHullCalculatorMappings.get(point.floor.floor_code)?.addPoint(point.point_id, point.x, point.y);
      selectedPoints.add(point.point_id);
    });

    updateBuilding({
      scheduleMappings,
      convexHullCalculatorMappings,
    });

    updateFloor({
      selectedPoints,
    })
  }

  const { isLoading: scheduleLoading, isIdle: scheduleIdle } = useScheduleQuery(updateScheduleData);
  const { isLoading: pointsLoading, isIdle: pointsIdle } = useSchedulePointsQuery(updateSchedulePointsData); 

  const allDataLoaded: boolean = !floorLoading && !scheduleLoading && !pointsLoading && !scheduleIdle && !pointsIdle;

  return (
    <ManageSchedulesProvider>
      <Route
        path={PATH_STRINGS.adminSchedules}
      >
        { allDataLoaded &&
          <ScheduleFloorPlanView/>
        }
        { !allDataLoaded &&
          <LoadingIndicator/>
        }          
      </Route>
      <Route
        exact
        path={PATH_STRINGS.adminProject}
      >
        <SchedulesDashboard
          firstFloorName={firstFloorName}
        />
      </Route> 
    </ManageSchedulesProvider>
  )
}