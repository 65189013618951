import React, { useEffect, useRef, useState } from 'react';

import { ImageViewerContextMenu } from './components/ContextMenu/ImageViewerContextMenu';
import { TagManager } from './components/TagManager';
import { TourManager } from './components/TourManager';
import { Pannellum } from '../../../../third_party/Pannellum';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { useViewerPosition } from '../../hooks/useViewerPosition';
import { MinimapPopup } from './components/MinimapPopup';
import { MinimapViewer } from '../../../../common/MapViewer';
import { ImageViewerSettings } from './components/ImageViewerSettings/ImageViewerSettings';
import { useImageViewerContext } from '../../imageViewerContext';
import { XRayView } from './components/XRayView/XRayView';
import { ErrorBoundary } from 'react-error-boundary';
import { ComponentErrorFallback } from '../../../../common/Errors/ComponentErrorFallback';
import { fetchImage } from '../../../../../api/buildingFetches';
import { LoadingIndicator } from '../../../../common/LoadingIndicator';
import html2canvas from 'html2canvas';
import mixpanel from 'mixpanel-browser';
import { ProcoreAuthProvider } from '../../../../../contexts/procoreAuthContext';
import { DebugPanel } from '../DebugPannel';

let urlCreator = window.URL || window.webkitURL;

interface IViewerPaneProps {
  type: string;
  masterPannellumRef?: any;
}
export const ViewerPane = ({ type, masterPannellumRef }: IViewerPaneProps) => {
  const { state: buildingState } = useBuildingContext();
  const {
    state: ImageViewerState,
    updateImageViewer,
    updateMaster,
    updatePane2,
  } = useImageViewerContext();
  const [inititalized, setInititalized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const pannellumRef = useRef<Pannellum | null>(null);
  const mapRef = useRef<MinimapViewer | null>(null);
  const mapPopupRef = useRef<HTMLDivElement>(null);
  const { updateViewerPosition, getInitialPosition, viewerPosition } = useViewerPosition();
  const isMaster = type === 'master';

  const onImageLoadError = async (err: any) => {
    if (err.contains('blob:')) {
      setLoading(true);
      let imageBlob = await fetchImage(ImageViewerState[type].data.processed_image_url);
      isMaster
        ? updateMaster({ image: urlCreator.createObjectURL(imageBlob) })
        : updatePane2({ image: urlCreator.createObjectURL(imageBlob) });
      setLoading(false);
    } else {
      throw Error(err);
    }
  };

  const onSyncChange = () => {
    pannellumRef.current?.setSync(ImageViewerState.sync);
  };

  useEffect(() => {
    onSyncChange();
  }, [ImageViewerState.sync]);

  useEffect(() => {
    if (isMaster) {
      masterPannellumRef.current = pannellumRef.current;
      updateMaster({ ...buildingState.imageData });
    }
  }, [pannellumRef.current, buildingState.imageData]);

  useEffect(() => {
    if (inititalized) {
      setLoading(true);
      ValidateBlob(ImageViewerState[type].image, bool => {
        bool ? setLoading(false) : onImageLoadError('Invalid blob url');
      });
    }
  }, [inititalized]);

  useEffect(() => {
    if (isMaster) {
      masterPannellumRef.current = pannellumRef.current;
      updateMaster({ ...buildingState.imageData });
    } else {
      updatePane2({ ...ImageViewerState.master, angleDelta: 0 });
      if (masterPannellumRef.current)
        updateViewerPosition(masterPannellumRef.current.props.viewerPosition);
    }
    setInititalized(true);
  }, []);

  if (!ImageViewerState['master'].data || (!isMaster && !masterPannellumRef.current)) return <></>;

  if (loading)
    return (
      <div style={{ backgroundColor: '#f8f8f8', width: '100%' }}>
        <LoadingIndicator />
      </div>
    );

  return (
    <ErrorBoundary
      FallbackComponent={ComponentErrorFallback}
      onReset={() => {
        window.location.reload();
      }}>
      <div
        style={{ height: '100%', width: '100%' }}
        className={
          ImageViewerState.focused === type && ImageViewerState.splitScreen
            ? 'viewer-focused'
            : 'viewer'
        }
        onClick={() => updateImageViewer({ focused: type })}
        id={'image-viewer-' + type}>
        {isMaster && (
          <ProcoreAuthProvider>
            <DebugPanel viewerPosition={viewerPosition} updateViewer={updateViewerPosition} />
            <ImageViewerContextMenu pannellumRef={pannellumRef} mapPopupRef={mapPopupRef} />
            <TagManager pnlm={pannellumRef} mapPopupRef={mapPopupRef} />
            <TourManager pnlm={pannellumRef} imageData={ImageViewerState[type].data}/>
          </ProcoreAuthProvider>
        )}
        {!isMaster && ImageViewerState.pane2Type === 'forge' ? (
          <XRayView
            masterPannellumRef={masterPannellumRef}
            viewerPosition={viewerPosition}
            onUpdate={(x: { rot: number; fov: number }) => {
              updateViewerPosition({
                yaw: x.rot,
                hfov: x.fov,
                angle: 0,
              });
            }}
          />
        ) : (
          <Pannellum
            image={ImageViewerState[type].image}
            ref={pannellumRef}
            initialPosition={() => getInitialPosition(isMaster)}
            viewerPosition={viewerPosition}
            sync={ImageViewerState.sync}
            onUpdate={x => {
              updateViewerPosition({
                yaw: x.rot,
                hfov: x.fov,
                pitch: x.pitch,
                angle: ImageViewerState[type].data.angle,
              });
            }}
            angleOffset={
              ImageViewerState[type].angleDelta || ImageViewerState[type].data?.angle || 0
            }
            onError={onImageLoadError}
          />
        )}
        <MinimapPopup
          mapPopupRef={mapPopupRef}
          mapRef={mapRef}
          viewerPosition={viewerPosition}
          rightPosition={isMaster ? '40px' : window.innerWidth / 2 + 40}
          type={type}
          angleCorrection={ImageViewerState[type].angleDelta || viewerPosition.angle || 0}
        />
        {isMaster && (
          <ImageViewerSettings
            mapPopupRef={mapPopupRef}
            pannellumRef={pannellumRef}
            viewerPosition={viewerPosition}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

function ValidateBlob(blobUrl: string, callback: (bool: boolean) => void) {
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (this.readyState == 4) {
      if (
        this.status == 200 ||
        (this.response && this.response.type && this.response.type == 'image/jpeg')
      ) {
        callback(true);
      } else {
        callback(false);
      }
    }
  };
  xhr.open('GET', blobUrl);
  xhr.responseType = 'blob';
  xhr.send();
}

const loadImage = async (image: string) => {
  return new Promise<HTMLImageElement>(resolve => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = image;
  });
}

export const takeScreenShot = async (mapPopupRef: React.RefObject<HTMLDivElement>, pannellumRef: React.RefObject<Pannellum | null>, onSave: (blob: Blob | null) => any) => {
  if (mapPopupRef.current && pannellumRef.current) {
    const mapPopup = mapPopupRef.current;
    const pannellum = pannellumRef.current;

    const viewerImageData = pannellum.captureScreenshot();

    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    const context = canvas.getContext('2d')!;

    const viewerImage = await loadImage(viewerImageData);
    canvas.width = viewerImage.width;
    canvas.height = viewerImage.height;

    context.drawImage(viewerImage, 0, 0, canvas.width, canvas.height);

    const canvasPopup = await html2canvas(mapPopup, {
      ignoreElements: el => el.classList.contains('ignore-html2canvas'),
    });

    context.drawImage(canvasPopup, canvas.width - canvasPopup.width - 10, 10);

    canvas.toBlob(blob => {
      onSave(blob);
    });

    mixpanel.track('Download Screenshot');
  }
}
