import { useMemo } from 'react';
import { ColumnWithLooseAccessor } from 'react-table';
import { Floor } from '../api/types';
import { ProgressCell } from '../components/views/progress/ProgressOverview/ProgressCell/ProgressCell';
import { ParentHeader } from '../components/views/progress/ProgressOverview/ProgressOverview';
import { useBuildingContext } from '../contexts/buildingContext';

export type ProgressTableColumn = ColumnWithLooseAccessor;

export const ceiling_cols = [
  {
    Header: () => <ParentHeader>Ceiling Progress</ParentHeader>,
    id: 111,
    disableSortBy: true,
    disableFilters: true,
    border: true,
    columns: [
      {
        Header: 'Top Track',
        accessor: 'ceiling_top_track',
        disableSortBy: true,
        disableFilters: true,
        Cell: (cell: any) => (
          <ProgressCell
            total={100}
            progress={0}
            rate={0}
            units={() => {
              return 'LF';
            }}
          />
        ),
      },
    ],
  },
  {
    Header: () => <ParentHeader>Ceiling Progress</ParentHeader>,
    id: 111,
    disableSortBy: true,
    disableFilters: true,
    border: true,
    columns: [
      {
        Header: 'Framing',
        accessor: 'ceiling_framing',
        disableSortBy: true,
        disableFilters: true,
        Cell: (cell: any) => (
          <ProgressCell
            total={100}
            progress={0}
            rate={0}
            units={() => {
              return 'LF';
            }}
          />
        ),
      },
    ],
  },
  {
    Header: () => <ParentHeader>Ceiling Progress</ParentHeader>,
    id: 111,
    disableSortBy: true,
    disableFilters: true,
    border: true,
    columns: [
      {
        Header: 'Dry Wall - Hung',
        accessor: 'ceiling_dry_wal_hung',
        disableSortBy: true,
        disableFilters: true,
        Cell: (cell: any) => (
          <ProgressCell
            total={100}
            progress={0}
            rate={0}
            units={() => {
              return 'SF';
            }}
          />
        ),
      },
    ],
  },
  {
    Header: () => <ParentHeader>Ceiling Progress</ParentHeader>,
    id: 111,
    disableSortBy: true,
    disableFilters: true,
    border: true,
    columns: [
      {
        Header: 'Dry Wall - Taped',
        accessor: 'ceiling_dry_wall_taped',
        disableSortBy: true,
        disableFilters: true,
        Cell: (cell: any) => (
          <ProgressCell
            total={100}
            progress={0}
            rate={0}
            units={() => {
              return 'SF';
            }}
          />
        ),
      },
    ],
  },
];

export const useOverviewCols = () => {
  const {state: buildingState} = useBuildingContext();

  return useMemo(() => [
    {
      Header: () => <ParentHeader></ParentHeader>,
      id: 432,
      disableSortBy: true,
      disableFilters: true,
      border: true,
      columns: [
        {
          Header: 'Floor',
          accessor: 'floor',
          disableSortBy: true,
          disableFilters: true,
          sticky: true,
          Cell: (cell: any) => {
            const floorName = cell.value;
            const isOverviewCell = floorName === "Overview";
            
            const onClickFloorCell = () => {
              if (!isOverviewCell && buildingState && buildingState.projectId && buildingState.projectData && buildingState.projectData.floors) {
                const floor = buildingState.projectData.floors.find((floor: Floor) => floor.name === floorName);

                if (floor) {
                  window.location.replace(`/project/${buildingState.projectId}/progress/floor/${floor.floor_code}`);
                }
              }
            }
  
            return (
              <span
                style={{ cursor: isOverviewCell ? 'default' : 'pointer' }}
                onClick={onClickFloorCell}>
                {floorName}
              </span>
            )
          },
        },
      ],
    },
  ], [buildingState]);
}
