import { Icon } from "../../../common/Icon";
import { Text } from "../../../common/Text";
import { format } from 'date-fns';
import iconBack from '../../../../assets/images/icon_back_carat.svg';
import iconForward from '../../../../assets/images/icon_forward_carat.svg';
import styled from "styled-components";
import { SiteWalkComparisonImageData } from "./SiteWalkImageComparison";

interface SiteWalkTimeScrollProps {
  disabled?: boolean;
  images: SiteWalkComparisonImageData[];
  selectedIndex: number;
  onChangeSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  onChangeObservationDate: (newDate: Date) => void;
}

export const SiteWalkTimeScroll = ({
  disabled,
  images,
  selectedIndex,
  onChangeSelectedIndex,
  onChangeObservationDate,
}: SiteWalkTimeScrollProps) => {
  const onIncrementSelectedIndex = (delta: number) => {
    const newSelectedIndex = selectedIndex + delta;

    if (newSelectedIndex >= 0 && newSelectedIndex <= images.length - 1) {
      onChangeSelectedIndex(newSelectedIndex);
      onChangeObservationDate(new Date(images[newSelectedIndex].taken_on));
    }
  }

  return (
    <DateRangeSelectorBase>
      <ViewButton
        onClick={() => onIncrementSelectedIndex(1)}
        className={disabled || images.length === selectedIndex + 1 ? 'inactive' : ''}>
        <Icon icon={iconBack} size={8} />
      </ViewButton>
      {images[selectedIndex] &&
        <DateRangeSelectorDisplay className="right">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text size={14} color={'#073c7a'} bold={false} marginRight={5}>
              {format(new Date((images[selectedIndex]).taken_on), 'MMM dd, yyyy')}
            </Text>
          </div>
        </DateRangeSelectorDisplay>
      }
      <ViewButton
        onClick={() => onIncrementSelectedIndex(-1)}
        className={disabled || selectedIndex <= 0 ? 'inactive' : ''}>
        <Icon icon={iconForward} size={8} />
      </ViewButton>
    </DateRangeSelectorBase>
  )
}

const ViewButton = styled.div`
  border: 1px solid #94a3b8;
  border-radius: 4px;
  padding: 6px 10px;
  margin: 4px;
  cursor: pointer;

  &.inactive {
    pointer-events: none;
    border: 1px solid #ccc;
    background: #ccc;
    cursor: default;
  }
`;

const DateRangeSelectorBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 200;
  position: absolute;
  bottom: 8px;
  left: 10px;
  background: #f4f5f9;
  border-radius: 4px;
  opacity: 0.8;
`;

const DateRangeSelectorDisplay = styled.div`
  padding: 10px;
  height: 100%;
  border: 1px solid #f4f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  &.left {
    border: none;
    padding: unset;
    border-radius: 2px 0 0 2px;
  }
  &.right {
    border-radius: 0 2px 2px 0;
  }
`;