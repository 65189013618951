import React, { useState } from "react";
import { DeleteButton } from "./DeleteButton";
import { CheckboxInput, CheckboxLabel, Checkmark, CheckmarkPosition, GroupSelector, NameInput } from "./styles";

interface ICheckNameDeleteProps {
  checked?: boolean;
  onChangeChecked?: (newValue: boolean) => void;
  checkboxColor?: string;
  checkboxStyle?: React.CSSProperties;
  checkmarkPosition?: CheckmarkPosition;
  name: string;
  nameStyle?: React.CSSProperties;
  onChangeName?: (newName: string) => void;
  nameInputRef?: React.RefObject<HTMLInputElement>;
  onClickDelete?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  farText?: string | JSX.Element;
  containerStyle?: React.CSSProperties;
}

export const CheckNameDelete = ({
  checked,
  onChangeChecked,
  name,
  nameStyle,
  onChangeName,
  nameInputRef,
  checkboxColor,
  checkboxStyle,
  checkmarkPosition,
  onClickDelete,
  onMouseEnter,
  onMouseLeave,
  farText,
  containerStyle,
}: ICheckNameDeleteProps) => {
  const [nameValue, setNameValue] = useState<string>(name);

  const nameInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value);
  }
  
  const nameInputOnPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !!onChangeName) {
      onChangeName(nameValue);
    }
  }

  const nameInputOnBlur = () => {
    setNameValue(name);
  }

  return (
    <GroupSelector
      style={containerStyle}
    >
      <CheckboxLabel>
        { !!onChangeChecked &&
          <>
            <CheckboxInput
              checked={checked}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeChecked(e.target.checked)}
            />

            <div>
              <Checkmark
                checked={checked}
                backgroundColor={checkboxColor}
                style={{...checkboxStyle}}
                checkmarkPosition={checkmarkPosition}
              />
            </div>
          </>
        }
        
        { !!onChangeName &&
          <NameInput
            ref={nameInputRef}
            value={nameValue}
            onChange={nameInputOnChange}
            onKeyPress={nameInputOnPressEnter}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onBlur={nameInputOnBlur}
          />
        }

        { !onChangeName &&
          <span
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={nameStyle}
          >
            {name}
          </span>
        }
      </CheckboxLabel>

      { !!onClickDelete &&
        <DeleteButton
          onClickDelete={onClickDelete}
        />
      }

      <>{farText}</>
    </GroupSelector>
  )
}