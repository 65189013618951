import { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useBuildingContext } from '../../../contexts/buildingContext';
import { PATH_STRINGS, useGeneratedPaths } from '../../../hooks/useGeneratedPaths';
import { useNavigation } from '../../../hooks/useNavigation';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { PermissionRoute } from '../../common/PermissionRoute/PermissionRoute';
import { BuildingOverview } from '../building_page/components/BuildingOverview/BuildingOverview';
import { ViewModeMenu } from '../building_page/components/ViewModeMenu';
import {
  useBuildingQuery,
  useFloorImages,
  useImagesQuery,
} from '../building_page/hooks/buildingQueries';
import { useFloorGroupsAndMappingsQuery } from './hooks/adminBuildingQueries';
import { ManagePoints } from './components/ManagePoints/ManagePoints';
import { useAdminRouteData } from './hooks/useAdminRouteData';
import { IMapPoint } from './components/ManagePoints/ManagedMapPoint';
import { ManageNoGoZones } from './components/ManageNoGoZones/ManageNoGoZones';
import { FloorSelector } from './components/FloorSelector';
import { ManageSchedules } from './components/ManageSchedules/ManageSchedules';
import { ManageEquipment } from './components/ManageEquipment/ManageEquipment';
import { ManagePointsProvider } from './components/ManagePoints/ManagePointsContext';
import { ManageUsers } from './components/ManageUsers/ManageUsers';
import { checkUserPermissions } from '../../common/PermissionWrapper/PermissionWrapper';
import { useUserContext } from '../../../contexts/userContext';

export const AdminBuildingPage = () => {
  const { generateManagePointsProjectPath, generateManageNoGoZonesProjectPath, generateAdminProjectPath, generateManageEquipmentProjectPath, generateManageUsersProjectPath } = useGeneratedPaths();
  const { state: userState } = useUserContext();

  useAdminRouteData();

  const { navigateToManagePointsFloor, navigateToManageNoGoZonesFloor } = useNavigation();

  const onClickBuildingOverviewFloorManagePoints = (floor: string) => {
    navigateToManagePointsFloor(floor);
  };

  const onClickBuildingOverviewFloorManageNoGoZones = (floor: string) => {
    navigateToManageNoGoZonesFloor(floor);
  };

  const {
    updateBuilding,
    updateFloor,
    updateImage,
    updatePoint,
    state: buildingState,
  } = useBuildingContext();

  const { data: buildingData, isLoading: buildingLoading } = useBuildingQuery(updateBuilding);

  const [hoverFloor, setHoverFloor] = useState<string | null>(null);

  const matchesManageSchedules: boolean = !!useRouteMatch(PATH_STRINGS.adminSchedules);
  const onlyLoadActivePoints: boolean = matchesManageSchedules;

  const updateFloorDesc = (data: any) => {
    updateFloor(data);
  };

  const { isLoading: floorLoading, isIdle: floorIdle } = useFloorImages(
    updateFloorDesc,
    onlyLoadActivePoints
  );

  const imagesQuery = useImagesQuery(
    buildingState.dateRange,
    buildingState.imageData,
    updateImage,
    updateImage
  );

  const { isLoading: groupsLoading } = useFloorGroupsAndMappingsQuery(updateFloorDesc);

  useEffect(() => {
    const point = buildingState.floorData.points?.find(
      (point: IMapPoint) => buildingState.pointId === point.point_id.toString()
    );
    if (point && buildingState.imageData) {
      updatePoint({
        ...point,
        panoramaUrl: buildingState.imageData.image,
        panoramaThumbUrl: point.panoramaUrl,
      });
    }
    // eslint-disable-next-line
  }, [buildingState.imageData, buildingState.pointId]);

  useEffect(() => {
    if (imagesQuery.isSuccess) imagesQuery.refetch();
    // eslint-disable-next-line
  }, [buildingState.dateRange]);

  const ADMIN_VIEW_MODE_MAP = useMemo(() => ({
    Schedule: {
      route: buildingState.projectId ? generateAdminProjectPath(buildingState.projectId) : '',
      userCanAccess: checkUserPermissions(
        userState.permissions,
        'scheduler_tables.read_scheduler_schedule',
        buildingState.projectId
      ),
    },
    'No-Go Areas': {
      route: buildingState.projectId ? generateManageNoGoZonesProjectPath(buildingState.projectId) : '',
      userCanAccess: checkUserPermissions(
        userState.permissions,
        'project_tables.read_no_go_zone',
        buildingState.projectId
      ),
    },
    'Equipment': {
      route: buildingState.projectId ? generateManageEquipmentProjectPath(buildingState.projectId) : '',
      userCanAccess: checkUserPermissions(
        userState.permissions,
        'project_tables.read_equipment',
        buildingState.projectId
      ),
    },
    'New Points': {
      route: buildingState.projectId ? generateManagePointsProjectPath(buildingState.projectId) : '',
      userCanAccess: checkUserPermissions(
        userState.permissions,
        'viewpoints_tables.read_viewpoints_point',
        buildingState.projectId
      ),
    },
    'Users': {
      route: buildingState.projectId ? generateManageUsersProjectPath(buildingState.projectId) : '',
      userCanAccess: checkUserPermissions(
        userState.permissions,
        'project_tables.read_project',
        buildingState.projectId
      ),
    }
  }), [buildingState.projectId, generateAdminProjectPath, generateManageEquipmentProjectPath, generateManageNoGoZonesProjectPath, generateManagePointsProjectPath, generateManageUsersProjectPath, userState.permissions]);

  if (buildingLoading || !buildingData) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <FloorSelector hoverFloor={hoverFloor} setHoverFloor={setHoverFloor} />

      <Switch>
        <Route
          path={[
            PATH_STRINGS.adminProject,
            PATH_STRINGS.adminManagePoints,
            PATH_STRINGS.adminManageNoGoZones,
            PATH_STRINGS.adminManageEquipment,
            PATH_STRINGS.adminManageUsers,
          ]}
          exact>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '25px',
              marginTop: '38px',
            }}>
            <ViewModeMenu
              items={ADMIN_VIEW_MODE_MAP}
            />
          </div>
        </Route>
      </Switch>
      <Switch>
        <PermissionRoute
          exact
          permission="viewpoints_tables.read_viewpoints_point"
          path={PATH_STRINGS.adminManagePoints}
          component={BuildingOverview}
          hoverFloor={hoverFloor}
          setHoverFloor={setHoverFloor}
          onFloorClicked={onClickBuildingOverviewFloorManagePoints}
        />
        <PermissionRoute
          permission="viewpoints_tables.read_viewpoints_point"
          path={PATH_STRINGS.adminManagePointsFloor}
          component={
            floorLoading || floorIdle || groupsLoading ? LoadingIndicator : WrappedManagePoints
          }
        />
        <PermissionRoute
          exact
          permission="project_tables.read_no_go_zone"
          path={PATH_STRINGS.adminManageNoGoZones}
          component={BuildingOverview}
          hoverFloor={hoverFloor}
          setHoverFloor={setHoverFloor}
          onFloorClicked={onClickBuildingOverviewFloorManageNoGoZones}
        />
        <PermissionRoute
          permission="project_tables.read_no_go_zone"
          path={PATH_STRINGS.adminManageNoGoZonesFloor}
          component={ManageNoGoZones}
          floorLoading={floorLoading || floorIdle}
        />
        <PermissionRoute
          exact
          permission="project_tables.read_equipment"
          path={[PATH_STRINGS.adminManageEquipment, PATH_STRINGS.adminManageEquipmentFloor]}
          component={ManageEquipment}
          hoverFloor={hoverFloor}
          setHoverFloor={setHoverFloor}
          floorLoading={floorLoading}
        />
        <PermissionRoute
          permission="project_tables.read_project"
          path={PATH_STRINGS.adminManageUsers}
          component={ManageUsers}
        />
        <PermissionRoute
          permission="scheduler_tables.read_scheduler_schedule"
          path={PATH_STRINGS.adminProject}
          component={ManageSchedules}
          floorLoading={floorLoading || floorIdle}
          floors={buildingData.floors}
        />
      </Switch>
    </>
  );
};

const WrappedManagePoints = () => {
  return (
    <ManagePointsProvider>
      <ManagePoints />
    </ManagePointsProvider>
  );
};
