import { useCallback, useMemo, useState } from "react";
import { Column } from "react-table";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { LoadingIndicator } from "../../../../common/LoadingIndicator";
import { Table } from "../../../../common/Table/Table"
import { CheckNameDelete } from "../../../../common/ViewSelector/components/PointGroupSelector/CheckNameDelete";
import { useProjectRolesQuery, useProjectUsersQuery } from "../../hooks/adminBuildingQueries";
import iconDown from '../../../../../assets/images/icon_down_gray.svg';
import { addProjectUserRoleMapping, deleteProjectUserRoleMapping } from "../../../../../api/adminBuildingFetches";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import styled from "styled-components";

export interface IAssignedProjectRole {
  assigned_project_role_id: number;
  role_id: number;
  project_id: number;
  role_name: string;
}

export interface IManagedUserEntry {
  user: {
    first_name: string;
    last_name: string;
    public_id: string;
    email: string;
    assigned_project_roles: IAssignedProjectRole[];
    projectRoleIdSet: Set<number>;
  }
}

export interface IProjectRole {
  id: number;
  name: string;
  description: string;
  is_staff_role: boolean;
  is_default_role: boolean;
}

export const ManageUsers = () => {
  const {
    updateBuilding,
    state: buildingState,
  } = useBuildingContext();

  const { addNotification } = useNotifications();

  const [currentlyExpandedRoles, setCurrentlyExpandedRoles] = useState<string | null>(null);

  const updateUsers = (users: IManagedUserEntry[]) => {
    updateBuilding(users);
  };

  const updateRoles = (roles: IProjectRole[]) => {
    updateBuilding(roles);
  };

  const { isLoading: usersLoading } = useProjectUsersQuery(updateUsers);
  const { isLoading: rolesLoading } = useProjectRolesQuery(updateRoles);

  const addRole = useCallback(async (user: IManagedUserEntry, role: IProjectRole) => {
    const updatedUser = {...user};

    try {
      const createdRole = await addProjectUserRoleMapping(buildingState.projectId, user.user.public_id, role.id);
      updatedUser.user.assigned_project_roles.push(createdRole);
      updatedUser.user.projectRoleIdSet.add(createdRole.role_id)

      let users = buildingState.users.map((projectUser: IManagedUserEntry) => {
        return user.user.public_id === projectUser.user.public_id ? updatedUser : projectUser;
      });

      updateBuilding({
        users,
      });

      addNotification('Permission updated successfully', 'success');
    } catch (err) {
      console.log('addProjectUserRoleMapping==>>', err);
      
      addNotification('Error updating user permission', 'error');
    }
  }, [addNotification, buildingState.projectId, buildingState.users, updateBuilding]);

  const deleteRole = useCallback(async (user: IManagedUserEntry, selectedProjectUserRole: IAssignedProjectRole) => {
    const updatedUser = {...user};

    try {
      await deleteProjectUserRoleMapping(buildingState.projectId, user.user.public_id, selectedProjectUserRole.assigned_project_role_id);

      updatedUser.user.assigned_project_roles = updatedUser.user.assigned_project_roles.filter((role: IAssignedProjectRole) => {
        return role.assigned_project_role_id !== selectedProjectUserRole.assigned_project_role_id;
      });

      updatedUser.user.projectRoleIdSet.delete(selectedProjectUserRole.role_id);

      let users = buildingState.users.map((projectUser: IManagedUserEntry) => {
        return user.user.public_id === projectUser.user.public_id ? updatedUser : projectUser;
      });

      updateBuilding({
        users,
      });

      addNotification('Permission updated successfully', 'success');
    } catch (err) {
      console.log('deleteProjectUserRoleMapping==>>', err);
      
      addNotification('Error updating user permission', 'error');
    }
  }, [addNotification, buildingState.projectId, buildingState.users, updateBuilding]);

  const onChangeRoleChecked = useCallback((checked: boolean, user: IManagedUserEntry, role: IProjectRole, selectedProjectUserRole: IAssignedProjectRole) => {
    if (checked) {
      addRole(user, role);
    } else {
      deleteRole(user, selectedProjectUserRole);
    }
  }, [addRole, deleteRole]);

  const data = useMemo(() => {
    return buildingState.users.map((user: IManagedUserEntry) => {
      const userRoleList = user.user.assigned_project_roles
                            .map((role: IAssignedProjectRole) => role.role_name)
                            .join(', ')

      const options = (
        <RoleOptionsContainer 
          onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        >
          { buildingState.projectRoles.map((role: IProjectRole) => {
            const selectedProjectUserRole: IAssignedProjectRole = user.user.assigned_project_roles.filter((projectUserRole: IAssignedProjectRole) => projectUserRole.role_id === role.id)[0];

            return (
              <CheckNameDelete
                key={`${user.user.public_id}_${role.id}`}
                checked={user.user.projectRoleIdSet.has(role.id)}
                onChangeChecked={(checked: boolean) => onChangeRoleChecked(checked, user, role, selectedProjectUserRole)}
                name={role.name}
                checkmarkPosition={{left: '4px'}}
              />
            )
          })}
        </RoleOptionsContainer>
      );

      const expanded = currentlyExpandedRoles === user.user.public_id;

      return {
        ...user,
        roles: (
        <div style={{position: 'relative'}}>
          <RolesDropdown>
            {userRoleList}

            <img
              src={iconDown} 
              alt='expand roles picker'
              style={{position: 'absolute', right: '15px', top: '11px', transform: `rotate(${expanded ? -180 : 0}deg)`, transition: 'transform 0.5s'}}
            />
          </RolesDropdown>

          { expanded && 
            <>{ options }</>
          }
        </div>
        )
      }
    });
  }, [buildingState.users, buildingState.projectRoles, currentlyExpandedRoles, onChangeRoleChecked]);

  const sortFirstName = useCallback((rowA: any, rowB: any) => {
    const userA = rowA.original.user;
    const userB = rowB.original.user;

    if (userA.first_name !== userB.first_name) {
      return userA.first_name.localeCompare(userB.first_name);
    } else {
      return userA.last_name.localeCompare(userB.last_name);
    } 
  }, []);

  const sortLastName = useCallback((rowA: any, rowB: any) => {
    const userA = rowA.original.user;
    const userB = rowB.original.user;

    if (userA.last_name !== userB.last_name) {
      return userA.last_name.localeCompare(userB.last_name);
    } else {
      return userA.first_name.localeCompare(userB.first_name);
    } 
  }, []);

  const columns = useMemo((): Column[] => [
    { Header: 'First Name', accessor: 'user.first_name', sortType: sortFirstName, width: '150px' },
    { Header: 'Last Name', accessor: 'user.last_name', sortType: sortLastName, width: '150px' },
    { Header: 'Email', accessor: 'user.email' },
    { Header: 'Project Roles', accessor: 'roles', width: '250px' }
  ], [sortFirstName, sortLastName]);

  if (usersLoading || rolesLoading) {
    return <LoadingIndicator/>
  }

  return (
    <Table
      data={data}
      columns={columns}
      headerStyles={{textAlign: 'left'}}
      bodyStyles={{textAlign: 'left', paddingLeft: '5px'}}
      onClickRow={(originalRow) => {
        setCurrentlyExpandedRoles((prevUserId) => prevUserId === originalRow.user.public_id ? null : originalRow.user.public_id);
      }}
      preventAutoResetSortBy
      initialSortBy={[
        {
          id: 'user.last_name',
          desc: false
        },
        {
          id: 'user.first_name',
          desc: false
        }
      ]}
    />
  )
}

const RoleOptionsContainer = styled.div`
  z-index: 1;
  position: absolute;
  background-color: white;
  border: 1px solid #D4DBE8;
  border-radius: 2px;
  padding: 8px 10px;
`

const RolesDropdown = styled.div`
  background-color: white;
  border: 1px solid #D4DBE8;
  border-radius: 2px;
  padding: 8px 10px;
  width: 100%;
  font-size: 14px;
`