import React, { useEffect, useState } from 'react';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import Select from 'react-select';

import { useBuildingContext } from '../../../../contexts/buildingContext';
import { useTagContext } from '../../../../contexts/tagContext';
import { useUserContext } from '../../../../contexts/userContext';
import { useNotifications } from '../../../../contexts/notificationProvider';
import {
  ObservationContainer,
  ObservationForm,
  DetailsButton
} from './styles';
import { useImageViewerContext } from '../../../views/image_viewer/imageViewerContext';
import { SegmentedControl } from "../../SegmentedControl/SegmentedControl";
import { listProjectSubcontractors, ProjectSubcontractor } from "../../../../api/projects";
import {
  listObservationTypes,
  Observation,
  ObservationType,
  retrieveObservation,
  updateObservation
} from "../../../../api/observations";

interface IAnnotationInputProps {
  isNew: boolean;
  hydrate: any;
  observation?: Observation|null;
}
const ObservationInput = ({ isNew, hydrate, observation }: IAnnotationInputProps) => {
  const { state: userState } = useUserContext();
  const { state: buildingState } = useBuildingContext();
  const { state: ImageViewerState } = useImageViewerContext();
  const { findSetCurrentTag, state: tagState } = useTagContext();
  const { addNotification } = useNotifications();
  const [observationTypes, setObservationTypes] = useState<ObservationType[]>([])
  const [subcontractors, setSubcontractors] = useState<any[]>([])
  const [currentSubcontractor, setCurrentSubcontractor] = useState<ProjectSubcontractor|null>(null)
  const [selectedType, setSelectedType] = useState<ObservationType|null>(null)
  const [safe, setSafe] = useState<string>('SAFE')
  const [severity, setSeverity] = useState<string>('LOW')
  const [notes, setNotes] = useState('')

  const createNewTag = () => {
    let observation = {
      project: buildingState.projectId,
      type: selectedType?.id,
      unsafe: safe === 'UNSAFE',
      severity: "SEVERITY_"+severity,
      notes: notes,
      subcontractor: currentSubcontractor?.subcontractor.id,
      vp_image: buildingState.imageData.data.id,
      claimed: true,
      verified: true,
      is_manual: true
    }
    axios
      .post('https://services.nexterarobotics.com/tags/', {
        creator: userState.public_id,
        pitch: tagState.tags.current.pitch,
        yaw: tagState.tags.current.yaw + ImageViewerState.master.data.angle,
        project_id: buildingState.projectId,
        point_id: buildingState.pointId,
        floor_id: buildingState.floorId,
        image_id: buildingState.imageData.data._id,
        type: 'SAFETY',
        observation_data: observation
      })
      .then(res => {
        hydrate().then(() => {
          findSetCurrentTag(res.data.id);
        });
        addNotification("Observation saved", "success")
        mixpanel.track('Create Safety Tag');
      });
  };

  const updateTag = () => {
    let observation = {
      type: '',
      unsafe: safe === 'UNSAFE',
      severity: "SEVERITY_"+severity,
      notes: notes,
      subcontractor: currentSubcontractor?.subcontractor.id
    }
    updateObservation(buildingState.projectId, '', observation)
      .then(() => {
        hydrate();
        mixpanel.track('Update Safety Tag');
      });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!selectedType) {
      addNotification('Please Select Observation Type', 'error');
      return;
    }
    if (isNew) {
      createNewTag();
    } else {
      updateTag();
    }
  };

  const groupTypes = (types: ObservationType[]) => {
    let grouped: any = {}
    types.map( (type: ObservationType) => {
      grouped[type.category] = [...grouped[type.category] || [], type]
    })
    let groupArr: any = []
    Object.keys(grouped).map( (group: string) => {
      groupArr.push({label: group, options: grouped[group]})
    })
    return groupArr
  }

  useEffect( () => {
    if(observation){
      setSelectedType(observation.type)
      setNotes(observation.notes)
      setSafe(observation.unsafe ? "UNSAFE" : "SAFE")
      setSeverity(observation.severity.replace("SEVERITY_", ""))
      setCurrentSubcontractor(observation.subcontractor)
    }
  }, [observation])
  useEffect( () => {
    listProjectSubcontractors(buildingState.projectId).then(data => setSubcontractors(data.data))
    listObservationTypes().then( data => setObservationTypes(groupTypes(data.data)))
  }, [])

  return (
    <ObservationForm onSubmit={onSubmit} className="oco-ignore oco-dialogue">
      <label>Observation Type</label>
      <Select
          value={selectedType}
          classNamePrefix="oco-ignore oco-dialogue "
          options={observationTypes}
          //@ts-ignore
          formatOptionLabel={(v) => v.name.replace(v.category, '').replace('_', ' ').capitalize()}
          getOptionValue={(v) => v.name}
          onChange={(v: any) => setSelectedType(v)}
          isClearable={true}
      />
      <SegmentedControl value={safe || "SAFE"} values={['SAFE', 'UNSAFE']} onClick={setSafe} style={{fontSize: '14px', marginBottom: '5px'}}/>
      {safe === "UNSAFE" &&
      <SegmentedControl value={severity || "LOW"} values={['LOW', 'MEDIUM', 'HIGH', 'LIFE_THREAT']} onClick={setSeverity} style={{fontSize: '14px', marginTop: '0'}}/>}
      <label>Subcontractor</label>
      <Select
        value={currentSubcontractor}
        classNamePrefix="oco-ignore oco-dialogue "
        options={subcontractors}
        formatOptionLabel={(v) => v.subcontractor.name}
        getOptionValue={(v) => v.subcontractor.name}
        onChange={(v: any) => setCurrentSubcontractor(v)}
        isClearable={true}
      />
      <label>Notes</label>
      <textarea
        style={{width: '100%', padding: '10px', border: "1px solid #D4DBE8" }}
        rows={5}
        onChange={e => setNotes(e.target.value)}
        value={notes}
      />
      {isNew ? <DetailsButton onClick={onSubmit}>Save</DetailsButton> :
      <DetailsButton className="disabled">Save</DetailsButton>}
    </ObservationForm>
  );
};

interface ISafetyTagProps {
  safetyTag: any;
  transform: string;
  hydrate: any;
}
export const SafetyTag = ({ safetyTag, transform, hydrate }: ISafetyTagProps) => {
  const {state: buildingState} = useBuildingContext()
  const [observation, setObservation] = useState<Observation|null>()

  useEffect(() => {
    if (safetyTag?.observation) {
      retrieveObservation(buildingState.projectId, safetyTag.observation)
        .then( d => setObservation(d.data))
      mixpanel.track('View Safety Tag');
    }
  }, [safetyTag]);

  return (
    <ObservationContainer
      style={{ transform: transform }}
      id="tag-container"
      className="down-arrow oco-ignore oco-dialogue">
      <div style={{padding: "5px", marginBottom: "1em"}}>
        <h2>Safety Observation</h2>
      </div>
        <ObservationInput
          isNew={!safetyTag.observation}
          observation={observation}
          hydrate={hydrate}
        />
    </ObservationContainer>
  );
};

