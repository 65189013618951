import { useEffect, useRef } from 'react';
import axios from 'axios';
import * as THREE from 'three';

//Temporary solution to loading multiple files
const KAYE_FILE_URNS = [
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQTkwJTIwMjIwMCUyMEtheWVfQ0wlMjBTdWJzdHJ1Y3R1cmUlMjBFeGNhdmF0aW9uJlNob3JpbmclMjBTeXN0ZW1fUjIwMjEucnZ0",
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQjEwJTIwMjIwMCUyMEtheWVfQ0wlMjBTdGFpcnNfUjIwMjEucnZ0",
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQjEwJTIwMjIwMCUyMEtheWVfQ0wlMjBTdHJ1Y3R1cmVfUjIwMjEucnZ0",
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQjIwJTIwMjIwMCUyMEtheWVfQ0wlMjBFeHRlcmlvciUyMEVuY2xvc3VyZV9SMjAyMS5ydnQ=",
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQzEwJTIwMjIwMCUyMEtheWVfQ0wlMjBJbnRlcmlvcl9SMjAyMS5ydnQ=",
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQ0wlMjAyMjAwJTIwS2F5ZV9DZW50cmFsLnJ2dA==",
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvRDIwLUQ3MCUyMDIyMDAlMjBLYXllX0NMJTIwQ2VpbGluZyUyMERldmljZXNfUjIwMjEucnZ0",
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvRzIwJTIwMjIwMCUyMEtheWVfQ0wlMjBMYW5kc2NhcGVfUjIwMjEucnZ0",
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvRDMwX0tBWUVfSFZBQ19SMjAyMS5ydnQ=",
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvRDMwX0tBWUVfSFlEUk9OSUNfUjIwMjEucnZ0",
  "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvRDIwX0tBWUVfUGx1bWJpbmdfUjIwMjEucnZ0"
]

const lockSettings = {
  orbit: false,
  pan: false,
  zoom: false,
  roll: false,
  fov: false,
  gotoview: false,
  walk: false,
};

export const ForgeViewer = ({
  position,
  fileUrn,
  sync,
  initialRotation,
  masterPannellumRef,
  onUpdate,
  setLoading,
  projectId
}: any) => {
  const initializer: any = useRef();
  const viewer: any = useRef();
  const syncRef = useRef(sync);
  let dragging = false;
  let vFov = 120;
  const vFovMax = 120;
  const vFovMin = 50;

  const initializeViewer = () => {
    setLoading(true);
    const Autodesk = (window as any).Autodesk;
    //@ts-ignore
    if (!window.Autodesk) return;
    let options = {
      env: 'AutodeskProduction2',
      api: 'streamingV2',
      getAccessToken: function (onTokenReady: any) {
        var token = getForgeToken().then(data => {
          var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
          onTokenReady(data.access_token, timeInSeconds);
        });
      },
    };
    initializer.current = Autodesk.Viewing.Initializer(options, function () {
      var htmlDiv = document.getElementById('forgeViewer');
      const guiOptions = {
        disabledExtensions: {
          measure: false,
          viewcube: false,
          layermanage: true,
          explode: true,
          section: true,
          hyperlink: true,
          fusionOrbit: true,
          fullScreen: true,
          viewerSettings: true,
          propertiesManager: true,
          layerManager: true,
        },
        profileSettings: {
          bimWalkToolPopup: false,
          firstPersonToolPopup: false,
        },
        loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
        extensions: ['Autodesk.Viewing.MemoryLimitedDebug'],
      };
      let v = new Autodesk.Viewing.Viewer3D(htmlDiv, guiOptions);
      var startedCode = v.start();
      viewer.current = v;
      v.addEventListener(
        //@ts-ignore
        Autodesk.Viewing.PROGRESS_UPDATE_EVENT,
        initializePosition
      );
      // For debugging positions, press esc to print position
      v.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, () => {
        console.log(v.navigation.getPosition());
        rotate90();
      });

      if (startedCode > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
      }

      if (projectId === "99Vt3MfWNCN7") {
        KAYE_FILE_URNS.forEach((urn) => {
          Autodesk.Viewing.Document.load(urn, onDocumentLoadSuccess, onDocumentLoadFailure);
        });
      } else {
        Autodesk.Viewing.Document.load(fileUrn, onDocumentLoadSuccess, onDocumentLoadFailure);
      }
    });
  };

  const onDocumentLoadSuccess = (viewerDocument: any) => {
    let defaultModel = viewerDocument.getRoot().getDefaultGeometry();
    if (viewer.current) {
      viewer.current.loadExtension('Autodesk.BimWalk');

      if (projectId === "99Vt3MfWNCN7") {
        viewer.current.loadDocumentNode(viewerDocument, defaultModel, {keepCurrentModels: true, globalOffset: {x:0,y:0,z:0}});
      } else {
        viewer.current.loadDocumentNode(viewerDocument, defaultModel);
      }
    }
  };

  const onDocumentLoadFailure = () => {
    console.error('Failed fetching Forge manifest');
  };

  const initializePosition = (e: { percent: number; state: number; model: any }) => {
    if (e.percent === 1) {
      viewer.current.navigation.setPosition(position);
      viewer.current.setNavigationLockSettings(lockSettings);
      viewer.current.setNavigationLock(true);
      viewer.current.setActiveNavigationTool('bimwalk');
      viewer.current.setBimWalkToolPopup(false);
      setInitialRotation();
      onViewEvent();
      setLoading(false);
    }
  };

  const setInitialRotation = () => {
    const camera = viewer.current.navigation.getCamera();
    let adjusted = position.clone();
    adjusted.y += 500; // make it be true north
    adjusted = rotate(
      position,
      adjusted,
      -masterPannellumRef.current.getYaw() -
        masterPannellumRef.current.getAngleOffset() -
        initialRotation
    );
    camera.fov = masterPannellumRef.current.getHfov();
    camera.aspect = 1;
    // camera.fov = (Math.atan(Math.tan(((90 / 2) * Math.PI) / 180) / camera.aspect) * 2 * 180) / Math.PI;
    viewer.current.navigation.setView(position, adjusted);
  };

  const rotate90 = () => {
    const camera = viewer.current.navigation.getCamera();
    // let adjusted = position.clone();
    const target = viewer.current.navigation.getTarget();
    let adjusted = rotate(position, target, 90);
    camera.fov = masterPannellumRef.current.getHfov();
    viewer.current.navigation.setView(position, adjusted);
  };

  const handleMouseMove = (e: MouseEvent) => {
    let FV = document.getElementById('forgeViewer');
    let ignore = (e.target as HTMLElement).closest('.oco-ignore');
    if (dragging && viewer.current && e.target && FV && !ignore) {
      // @ts-ignore
      if (syncRef.current || FV.contains(e.target)) {
        const target = viewer.current.navigation.getTarget();
        let newTarget = target.clone();
        const pos = viewer.current.navigation.getPosition();
        newTarget.z += e.movementY * 0.6;
        newTarget.z = Math.min(Math.max(newTarget.z, -609), 609);
        newTarget = rotate(pos, newTarget, e.movementX * 0.065);
        viewer.current.navigation.setView(pos, newTarget);
      }
    }
  };

  const handleMouseDown = (e: MouseEvent) => {
    dragging = true;
  };

  const handleMouseUp = (e: MouseEvent) => {
    dragging = false;
    onViewEvent();
  };

  const mouseWheel = (e: any) => {
    let FV = document.getElementById('forgeViewer');
    let ignore = (e.target as HTMLElement).closest('.oco-ignore');
    if (viewer.current && FV && !ignore) {
      if (syncRef.current || FV.contains(e.target)) {
        let camera = viewer.current.navigation.getCamera();
        vFov -= e.wheelDeltaY * 0.05;
        vFov = Math.min(Math.max(vFov, vFovMin), vFovMax);
        camera.fov = vFov;
        // update view
        const target = viewer.current.navigation.getTarget();
        const pos = viewer.current.navigation.getPosition();
        viewer.current.navigation.setView(pos, target);
      }
    }
  };

  const getYaw = () => {
    if (viewer.current) {
      let north = position.clone();
      north.y += 100;
      const target = viewer.current.navigation.getTarget();
      let result =
        Math.atan2(north.y - position.y, north.x - position.x) -
        Math.atan2(target.y - position.y, target.x - position.x);
      return result * (180 / Math.PI);
    }
    return 0;
  };

  const getPitch = () => {
    return;
  };

  const getFov = () => {
    if (viewer.current) {
      return viewer.current.getFOV();
    }
    return 0;
  };

  const onViewEvent = () => {
    onUpdate({
      rot: getYaw() - initialRotation,
      fov: getFov(),
    });
  };

  const destroy = () => {
    const Autodesk = (window as any).Autodesk;
    if (viewer.current) {
      viewer.current.finish();
      viewer.current = null;
    }
    if (initializer.current) {
      initializer.current.destroy();
      initializer.current = null;
    }
    Autodesk.Viewing.shutdown();
  };

  useEffect(() => {
    syncRef.current = sync;
    if (!document.getElementById('autodeskScript')) {
      var css = document.createElement('link');
      css.rel = 'stylesheet';
      css.href = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.51/style.min.css';
      var body = document.getElementsByTagName('body')[0];
      body.appendChild(css);
      var tag = document.createElement('script');
      tag.async = true;
      tag.src =
        'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.51/viewer3D.min.js';
      tag.id = 'autodeskScript';
      body.appendChild(tag);
      tag.addEventListener('load', initializeViewer);
    } else {
      initializeViewer();
    }
    let pnlmMaster = document.getElementById('pnlm-master');

    if (pnlmMaster) {
      pnlmMaster.addEventListener('mousedown', handleMouseDown, true);
      pnlmMaster.addEventListener('mousemove', handleMouseMove, true);
      pnlmMaster.addEventListener('mouseup', handleMouseUp, true);
      pnlmMaster.addEventListener('mousewheel', mouseWheel, true);
    }
    return () => {
      destroy();
      if (pnlmMaster) {
        pnlmMaster.removeEventListener('mousedown', handleMouseDown, true);
        pnlmMaster.removeEventListener('mousemove', handleMouseMove, true);
        pnlmMaster.removeEventListener('mouseup', handleMouseUp, true);
        pnlmMaster.removeEventListener('mousewheel', mouseWheel, true);
      }
    };
  }, []);

  useEffect(() => {
    syncRef.current = sync;
  }, [sync]);

  console.log("Instance Tree: ", viewer.current, viewer.current?.model, viewer.current?.model?.getInstanceTree());

  return <div id="forgeViewer" />;
};

// Utility Functions
function getForgeToken() {
  return axios.get('https://services.nexterarobotics.com/core/adsk-token/').then(r => r.data);
}

function rotate(center: THREE.Vector3, p: THREE.Vector3, angle: number) {
  angle = angle * (Math.PI / 180);
  let s = Math.sin(angle);
  let c = Math.cos(angle);
  // translate point back to origin:
  p.x -= center.x;
  p.y -= center.y;
  // rotate point
  let xnew = p.x * c - p.y * s;
  let ynew = p.x * s + p.y * c;
  // translate point back:
  p.x = xnew + center.x;
  p.y = ynew + center.y;
  return p;
}
