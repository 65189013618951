import { PropsWithChildren, createRef, useCallback, useEffect, useState } from "react";

let dragCounter = 0;

interface IDragAndDropProps {
  onDrop: (files: FileList) => void;
}

export const DragAndDrop = ({
  onDrop,
  children,
}: PropsWithChildren<IDragAndDropProps>) => {
  const [drag, setDrag] = useState<boolean>(false);
  const dropRef = createRef<HTMLDivElement>();

  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }
  
  const handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter++;

    if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDrag(true);
    }
  }

  const handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter--;

    if (dragCounter === 0) {
      setDrag(false);
    }
  }

  const handleDrop = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setDrag(false);

    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      onDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      dragCounter = 0;
    }
  }, [onDrop]);

  useEffect(() => {
    let div = dropRef.current;

    if (div) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', handleDrop);
    }

    return () => {
      if (div) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDrop);
      }
    }
  }, [dropRef, handleDrop]);

  return (
    <div
      style={{height: '100%', width: '100%'}}
      ref={dropRef}
    >
      {drag &&
        <div 
          style={{
            border: 'dashed grey 4px',
            backgroundColor: 'rgba(255,255,255,.8)',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0, 
            right: 0,
            zIndex: 9999
          }}
        >
          <div 
            style={{
              position: 'absolute',
              top: '50%',
              right: 0,
              left: 0,
              textAlign: 'center',
              color: 'grey',
              fontSize: 36
            }}
          >
            <div>Drop Here</div>
          </div>
        </div>
      }
      {children}
    </div>
  )
}