import React, { createContext, useCallback, useContext, useReducer } from 'react';

const initialState = {
  tags: {
    current: {
      pitch: 0,
      yaw: 0,
      id: null,
      project_id: null,
      point_id: null,
    },
    all: [],
  },
  chat: {
    status: 'loading',
    meta: {},
    chatId: null,
  },
  displayDialogue: false,
};

const TagContext = createContext<any>(null);

const UPDATE_CHAT = 'UPDATE_CHAT';
const UPDATE_TAGS = 'UPDATE_TAGS';
const HYDRATE_TAGS = 'HYDRATE_TAGS';
const FIND_SET_TAG = 'FIND_SET_TAG';
const TOGGLE_DISPLAY = 'TOGGLE_DISPLAY';

const tagsReducer = (state: any, action: any) => {
  let showDialogue = true;
  switch (action.type) {
    case UPDATE_CHAT:
      return {
        ...state,
        chat: { ...state.chat, ...action.payload.item },
      };
    case HYDRATE_TAGS:
      return {
        ...state,
        tags: { ...state.tags, ...action.payload.item },
      };
    case FIND_SET_TAG:
      const selectedTag = state.tags.all.find((item: any) => item.id === action.payload.tagId);
      if (action.payload.tagId === state.tags.current?.id && state.displayDialogue) {
        showDialogue = false;
      }
      if (!selectedTag || action.payload.forceHide) {
        showDialogue = false;
      }
      return {
        ...state,
        tags: { ...state.tags, current: selectedTag },
        chat: {
          ...state.chat,
          status: showDialogue && selectedTag.chat_room?.uuid ? 'conversation' : 'create',
        },
        displayDialogue: showDialogue,
      };
    case UPDATE_TAGS:
      if (action.payload.item.current?.id === state.tags.current?.id && state.displayDialogue) {
        showDialogue = false;
      }
      return {
        ...state,
        tags: { ...state.tags, ...action.payload.item },
        chat: { ...state.chat },
        displayDialogue: showDialogue,
      };
    case TOGGLE_DISPLAY:
      return {
        ...state,
        displayDialogue: false,
      };
    default:
      return state;
  }
};

export const TagProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(tagsReducer, initialState);
  return <TagContext.Provider value={[state, dispatch]}>{children}</TagContext.Provider>;
};

export const useTagContext = () => {
  // @ts-ignore
  const [state, dispatch] = useContext(TagContext);

  const updateChat = (item: any) => {
    dispatch({
      type: UPDATE_CHAT,
      payload: {
        item,
      },
    });
  };

  const updateTags = (item: any) => {
    dispatch({
      type: UPDATE_TAGS,
      payload: {
        item,
      },
    });
  };

  const hydrateTags = useCallback((item: any) => {
    dispatch({
      type: HYDRATE_TAGS,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const findSetCurrentTag = useCallback((tagId: number, forceHide = false) => {
    dispatch({
      type: FIND_SET_TAG,
      payload: { tagId: tagId, forceHide: forceHide },
    });
  }, [dispatch]);

  const toggleDisplay = () => {
    dispatch({
      type: TOGGLE_DISPLAY,
    });
  };

  return {
    updateChat,
    updateTags,
    findSetCurrentTag,
    hydrateTags,
    toggleDisplay,
    state,
  };
};
