import { Calendar } from 'react-date-range';
import React, { createRef, useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../../../common/Text';
import { format } from 'date-fns';
import { Icon } from '../../../../common/Icon';

import iconBack from '../../../../../assets/images/icon_back_carat.svg';
import iconForward from '../../../../../assets/images/icon_forward_carat.svg';
import iconDown from '../../../../../assets/images/icon_down_carat.svg';
import { useOnClickOutside } from '../../../../../hooks/useOnClickOutside';

interface DateScrollSelectorProps {
  justify: string;
  date: Date;
  setDate: (d: Date) => void;
  hideViewButtons?: boolean;
  showSkipWeekButtons?: boolean;
  minDate?: Date;
  maxDate?: Date;
}
export const DateScrollSelector = ({
    justify,
    date,
    setDate,
    hideViewButtons,
    showSkipWeekButtons,
    minDate,
    maxDate,
  }: DateScrollSelectorProps) => {
  const [displayed, setDisplayed] = useState<boolean>(false);

  const containerRef = createRef<HTMLDivElement>();
  useOnClickOutside(containerRef, () => setDisplayed(false));

  const onChangeDate = (newDate: Date) => {
    if (minDate && newDate < minDate) {
      return;
    } else if (maxDate && newDate > maxDate) {
      return;
    }

    setDate(newDate);
    setDisplayed(false);
  }

  return (
    <DateRangeSelectorBase
      id="drsb"
      ref={containerRef}
      style={{ justifyContent: justify }}
    >
      {showSkipWeekButtons && 
        <DoubleArrowButton
          onClick={() => onChangeDate(new Date(date.setDate(date.getDate() - 7)))}
          icon={iconBack}
        />
      }
      {!hideViewButtons &&
        <ViewButton
          onClick={() => onChangeDate(new Date(date.setDate(date.getDate() - 1)))}
          style={{marginLeft: showSkipWeekButtons ? '0px' : '4px'}}
        >
          <Icon icon={iconBack} size={8} />
        </ViewButton>
      }
      {displayed && (
        <DateRangeSelectorPopup>
          <Calendar
            date={date}
            onChange={e => onChangeDate(e)}
            minDate={minDate}
            maxDate={maxDate}
          />
        </DateRangeSelectorPopup>
      )}
      <DateRangeSelectorDisplay onClick={() => setDisplayed(!displayed)} className="right">
        {date && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Text size={14} color={'#073c7a'} bold={false} marginRight={5}>
              {format(date!, 'MMM dd yyyy')}
            </Text>
            <Icon icon={iconDown} size={8} />
          </div>
        )}
      </DateRangeSelectorDisplay>
      {!hideViewButtons &&
        <ViewButton
          onClick={() => onChangeDate(new Date(date.setDate(date.getDate() + 1)))}
          style={{marginRight: showSkipWeekButtons ? '0px' : '4px'}}
        >
          <Icon icon={iconForward} size={8} />
        </ViewButton>
      }
      {showSkipWeekButtons && 
        <DoubleArrowButton
          onClick={() => onChangeDate(new Date(date.setDate(date.getDate() + 7)))}
          icon={iconForward}
        />
      }
    </DateRangeSelectorBase>
  );
};

const ViewButton = styled.div`
  border: 1px solid #94a3b8;
  border-radius: 4px;
  padding: 6px 10px;
  margin: 4px;
  cursor: pointer;

  &.active {
    border: 1px solid #073c7a;
    background: #073c7a;
  }
`;

const DateRangeSelectorBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 20;
  position: relative;
`;

const DateRangeSelectorPopup = styled.div`
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  z-index: 20;
  position: absolute;
  top: 50px;
  .rdrDateDisplayWrapper {
    display: none;
  }
`;

const DateRangeSelectorDisplay = styled.div`
  padding: 10px;
  height: 100%;
  width: max-content;
  border: 1px solid #f4f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  &.left {
    border: none;
    padding: unset;
    border-radius: 2px 0 0 2px;
  }
  &.right {
    border-radius: 0 2px 2px 0;
  }
`;

interface IDoubleArrowButtonProps {
  onClick: () => void;
  icon: string;
}

const DoubleArrowButton = ({
  onClick,
  icon,
}: IDoubleArrowButtonProps) => {
  return (
    <ViewButton
      onClick={onClick}
      style={{
        padding: '6px 8px'
      }}
    >
      <div style={{display: 'flex'}}>
        <div>
          <Icon icon={icon} size={8} />
        </div>
        <div style={{marginLeft: '-4px'}}>
          <Icon icon={icon} size={8} />
        </div>
      </div>
    </ViewButton>
  )
}
