import React from "react";
import styled from "styled-components";

interface IUserInformationFieldProps {
  label: string;
  value: string;
  onChange?: (newValue: string) => void;
  disabled?: boolean;
  type?: string;
}

export const UserInformationField = ({
  label,
  value,
  onChange,
  disabled,
  type,
}: IUserInformationFieldProps) => {
  return (
    <UserInformationContainer>
      <UserInformationLabel 
        htmlFor={label}
      >
        {label}
      </UserInformationLabel>
      <UserInformationInput 
        type={type}
        id={label}
        value={value}
        disabled={disabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(e.target.value)
          }
        }}
      />
    </UserInformationContainer>
  )
}

const UserInformationContainer = styled.div`
  margin-bottom: 15px;
`

const UserInformationLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
`

const UserInformationInput = styled.input`
  display: block;
  padding: 5px 12px;
  border-radius: 4px;
  border: 1px solid silver;
  background-color: white;
`