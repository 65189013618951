import React, { CSSProperties, useState } from "react";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { LoadingIndicator } from "../../../../common/LoadingIndicator";
import { ViewSelector } from "../../../../common/ViewSelector/ViewSelector";
import { FloorPlanView } from "../../../building_page/components/FloorPlanView";
import { useFloorNoGoZonesQuery } from "../../hooks/adminBuildingQueries";
import { NoGoZonePointControls } from "./NoGoZonePointControls";
import { NoGoZonesSvg } from "./NoGoZonesSvg";
import { NoGoZoneProvider } from "./NoGoZoneContext";
import { ZoneSelector } from "./ZoneSelector";
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';
import { PermissionWrappedComponent } from "../../../../common/PermissionWrapper/PermissionWrapper";

export interface IBoundaryPoint {
  id: string;
  zone_id: number;
  x: number;
  y: number;
}

export interface INoGoZone {
  id: number;
  name: string;
  points: IBoundaryPoint[];
  project_floor: number;
  is_active: boolean;
  drawing?: boolean;
}

interface IManageNoGoZonesProps {
  floorLoading: boolean;
} 

export const getNewPointId = () => {
  return uuidv4();
}

export const ManageNoGoZones = ({
  floorLoading
}: IManageNoGoZonesProps) => {
  const {
    updateFloor,
    state: buildingState
  } = useBuildingContext();
  
  const [pointControlsExpanded, setPointControlsExpanded] = useState<boolean>(true);

  const updateFloorDesc = (data: any) => {
    updateFloor(data);
  }

  const { isLoading: zonesLoading } = useFloorNoGoZonesQuery(updateFloorDesc);

  const zoomIconStyle: CSSProperties = { position: 'absolute', right: '0px', zIndex: 2};
  const zoomInIconStyle: CSSProperties = {...zoomIconStyle, bottom: '55px'};
  const zoomOutIconStyle: CSSProperties = {...zoomIconStyle, bottom: '10px'};

  const noGoZones = (
    <NoGoZonesSvg/>
  );

  return (
    <NoGoZoneProvider>
      { (floorLoading || zonesLoading) &&
        <LoadingIndicator/>
      }

      { (!floorLoading && !zonesLoading) &&
          <>
            <FloorPlanView
              floorData={buildingState.floorData}
              hideDateRangeSelector
              mapViewerChildren={noGoZones}
              zoomInIconStyle={zoomInIconStyle}
              zoomOutIconStyle={zoomOutIconStyle}
              preventDoubleTouches
              showPoints={false}
            />
            
            <ViewSelectorContainer>
              <ViewSelector
                title='Zones'
                initialViewSelectorExpanded
                viewingExpanded={pointControlsExpanded}
                setViewingExpanded={setPointControlsExpanded}
                maxHeight={275}
              >
                <ZoneSelector/>
              </ViewSelector>
            
              <PermissionWrappedComponent
                permission={['project_tables.edit_no_go_zone', 'project_tables.delete_no_go_zone']}
                project_id={buildingState.projectId}
                useOrInPermissionCheck
              >
                <ViewSelector
                  title='Point Controls'
                  initialViewSelectorExpanded
                  viewingExpanded={pointControlsExpanded}
                  setViewingExpanded={setPointControlsExpanded}
                  maxHeight={275}
                >
                  <NoGoZonePointControls/>
                </ViewSelector>
              </PermissionWrappedComponent>
            </ViewSelectorContainer>
        </>
      }
    </NoGoZoneProvider>
  )
}

const ViewSelectorContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
`