import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Flex } from '@react-css/flex';
import axios from 'axios';
import Moment from 'react-moment';

import { useTagContext } from '../../../../contexts/tagContext';
import { getFullName, truncateMessage } from '../../../../utils';
import { InitialsAvatar } from '../../InitialsAvatar';

const EditRoomNameInput = styled.input`
  background: #073c7a;
  color: #fff;
  border: none;
  border-bottom: 1px solid #e8e8e8;
`;

export const ChatHeader = ({ hydrate }: any) => {
  const { state: tagState } = useTagContext();
  const [editRoomName, setEditRoomName] = useState(false);
  const [tempRoomName, setTempRoomName] = useState('Untitled Chat');

  const renameRoom = (e: any) => {
    e.preventDefault();
    axios
      .patch(
        `https://services.nexterarobotics.com/chat/rooms/${tagState.tags.current.chat_room.id}/`,
        {
          name: tempRoomName,
        }
      )
      .then(() => {
        hydrate();
        setEditRoomName(false);
      });
  };

  useEffect(() => {
    if (tagState.tags.current.chat_room)
      setTempRoomName(tagState.tags.current.chat_room.name || 'Untitled Chat');
    // eslint-disable-next-line
  }, [tagState.tags.current]);

  useEffect(() => {
    if (tagState.tags.current.chat_room)
      setTempRoomName(tagState.tags.current.chat_room.name || 'Untitled Chat');
    // eslint-disable-next-line
  }, []);

  if (!tagState.tags.current.chat_room) {
    return (
      <Flex
        style={{
          backgroundColor: '#073C7A',
          padding: '10px',
          color: '#fff',
          alignItems: 'center',
        }}
      />
    );
  }
  return (
    <Flex
      style={{ backgroundColor: '#073C7A', padding: '10px', color: '#fff', alignItems: 'center' }}>
      <InitialsAvatar user={tagState.tags.current.chat_room?.admin} theme="light" />
      <div style={{ width: '100%' }}>
        <Flex>
          {editRoomName ? (
            <form onSubmit={renameRoom}>
              <EditRoomNameInput
                type="text"
                value={tempRoomName}
                onChange={e => setTempRoomName(e.target.value)}
              />
            </form>
          ) : (
            <h1 style={{ margin: '5px 0' }} onDoubleClick={() => setEditRoomName(true)}>
              {truncateMessage(tempRoomName, 30)}
            </h1>
          )}
        </Flex>
        <Flex justifySpaceBetween={true}>
          <p style={{ fontSize: '0.75em', color: '#e8e8e8' }}>
            {tagState.tags.current.chat_room?.admin
              ? getFullName(tagState.tags.current.chat_room.admin)
              : null}
          </p>
          <div>
            <span style={{ fontSize: '0.75em', color: '#e8e8e8' }}>Created: </span>
            <Moment
              style={{ fontSize: '0.75em', color: '#e8e8e8' }}
              format="MM/DD/YY hh:mm:ss"
              date={new Date(tagState.tags.current.chat_room?.created_at)}
            />
          </div>
        </Flex>
      </div>
    </Flex>
  );
};
