import { useMemo, useRef, useState } from "react";
import { Item } from "../../../../api/items";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import { useSafetyContext } from "../../../../contexts/safetyContext";
import { ViewerPosition } from "../../image_viewer/types";
import { MapViewer } from "../../../common/MapViewer";
import { Point } from "../../../../api/types";
import { Job } from "../../../../api/jobs";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { LayerManager } from "../../progress/ProgressFloorTracker/LayerManager/LayerManager";
import { CustomTooltip } from "../../progress/components/ProgressCharts/CustomTooltip";
import { useNavigation } from "../../../../hooks/useNavigation";

interface SafetyFloorPlanViewProps {
  viewerPosition?: ViewerPosition;
  jobs: Job[];
  dataLoaded: boolean;
  tooltipItem: Item | null;
  setTooltipItem: React.Dispatch<Item | null>;
  selectedJobTypeIds: number[];
  TooltipValue?: JSX.Element;
  minimapMode?: boolean;
  onClickItem?: (pointId: string, observationDate?: Date, activeTracker?: string, itemId?: string | number) => void;
}

export const SafetyFloorPlanView = ({
  viewerPosition,
  jobs,
  dataLoaded,
  tooltipItem,
  setTooltipItem,
  selectedJobTypeIds,
  TooltipValue,
  minimapMode=false,
  onClickItem,
}: SafetyFloorPlanViewProps) => {
  const {navigateToSafetyMetricDetailFloorPoint} = useNavigation();
  const {state: buildingState} = useBuildingContext();
  const {state: safetyState} = useSafetyContext();

  const {
    showPoints,
    mapRef,
    selectedMetricCategory,
    observationDate,
    selectedItemId
  } = safetyState;

  const [loadedImg, setLoadedImg] = useState<string>('');
  const [hoverCoordinates, setHoverCoordinates] = useState<{x: number, y: number}>({x: 0, y: 0});
  const tooltipRef = useRef<HTMLDivElement>(null);

  const tooltipPosition = useMemo(() => {
    let {x, y} = hoverCoordinates;

    if (tooltipRef.current) {
      const yOffset = minimapMode ? 152 : 68;
      const xOffset = minimapMode ? 40 : 30;
      const tooltipHeight = tooltipRef.current.clientHeight;
      const tooltipWidth = tooltipRef.current.clientWidth;

      y = Math.min(y - yOffset - tooltipHeight / 2, window.innerHeight - yOffset - tooltipHeight - 5);

      if (x + xOffset + tooltipWidth + 5 > window.innerWidth) {
        x = x - tooltipWidth - xOffset * 3;
      } else {
        x = x - xOffset;
      }
    }

    return {
      x,
      y,
    }
  }, [hoverCoordinates, minimapMode, tooltipRef.current]);

  const onClickMapPoint = (point: Point) => {
    navigateToSafetyMetricDetailFloorPoint(point.point_id, selectedMetricCategory, observationDate);
  }

  return (
    <>
      {!dataLoaded &&
        <LoadingIndicator
          containerStyle={{
            height: '100%',
            width: !!buildingState.pointId ? 'calc(50% + 60px)' : '100%'
          }}
        />
      }
      <div
        style={{
          position: 'absolute',
          top: '0px',
          bottom: '0px',
          right: '0px',
          left: '0px',
          zIndex: 0,
        }}
      >
        <MapViewer
          ref={mapRef}
          selectedDateRange={buildingState.dateRange}
          image={buildingState.floorData.imageUrl}
          points={buildingState.floorData.points}
          highlightedPointId={buildingState.pointData?.point_id}
          showDirectionArrow
          angleOffset={viewerPosition?.angle || 0}
          rotation={viewerPosition?.yaw}
          setLoadedImg={setLoadedImg}
          imageLoaded={loadedImg === buildingState.floorData.imageUrl}
          showPoints={showPoints}
          onClickMapPoint={onClickMapPoint}
        >
          {jobs.length > 0 &&
            <LayerManager
              jobs={jobs}
              selectedJobTypes={selectedJobTypeIds}
              setTooltipItem={setTooltipItem}
              setHoverCoordinates={setHoverCoordinates}
              activeTracker={selectedMetricCategory}
              displayZeroValues
              selectedItemId={selectedItemId}
              onClickItem={onClickItem}
            />
          }
        </MapViewer>
      </div>
      {tooltipItem &&
        <CustomTooltip
          id="tooltip"
          ref={tooltipRef}
          style={{
            position: 'absolute',
            top: `${tooltipPosition.y}px`,
            left: `${tooltipPosition.x}px`,
            padding: '5px',
            width: 'max-content',
          }}
        >
          {TooltipValue}
        </CustomTooltip>
      }
    </>
  );
}