import axios from 'axios';
import { globalErrorHandler } from '../globalErrorHandler';

export const getChatNotifications = (userId: string, pageNumber = 1) => {
  return axios
    .get(`https://services.nexterarobotics.com/chat/user-rooms/${userId}/?page=${pageNumber}`)
    .then(res => {
      return res.data;
    })
    .catch(err => globalErrorHandler(err));
};
