import { SplitScreenContainer } from '../styles';
import { ViewerPane } from './ViewerPane/ViewerPane';
import { SplitScreenGutter } from './SplitScreenGutter';
import { useImageViewerContext } from '../imageViewerContext';
import React, { useEffect, useRef } from 'react';
import { Pannellum } from '../../../third_party/Pannellum';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import mixpanel from 'mixpanel-browser';

export const SplitScreenManager = () => {
  const { state: buildingState } = useBuildingContext();
  const { state: ImageViewerState, updateImageViewer } = useImageViewerContext();
  const masterPannellumRef = useRef<Pannellum | null>(null);

  /* Hide Tour hotspots while in split screen, but don't delete them */
  useEffect(() => {
    if (ImageViewerState.splitScreen) {
      let hss = Array.from(document.getElementsByClassName('pnlm-TOUR'));
      hss.forEach(hs => ((hs as HTMLElement).style.display = 'none'));
      mixpanel.track('View Split Screen');
    } else {
      let hss = Array.from(document.getElementsByClassName('pnlm-TOUR'));
      hss.forEach(hs => ((hs as HTMLElement).style.display = 'block'));
      updateImageViewer({ pane2Type: 'image' });
    }
  }, [buildingState.tourPoints, ImageViewerState.splitScreen]);

  return (
    <SplitScreenContainer id="pnlm-master" style={{ height: '100%' }}>
      {ImageViewerState.splitScreen && (
        <>
          <ViewerPane type={'subordinate'} masterPannellumRef={masterPannellumRef} />
          <SplitScreenGutter />
        </>
      )}
      <ViewerPane type={'master'} masterPannellumRef={masterPannellumRef} />
    </SplitScreenContainer>
  );
};
