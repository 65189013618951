import React from "react";
import { useNoGoZoneContext } from "./NoGoZoneContext";

interface INoGoZoneCircleProps {
  x: number;
  y: number;
  r?: number;
  stroke?: string;
  fill?: string;
  onClick?: (e: React.MouseEvent<SVGCircleElement>) => void;
  onMouseDown?: (e: React.MouseEvent<SVGCircleElement>) => void;
  onMouseUp?: (e: React.MouseEvent<SVGCircleElement>) => void;
  onTouchStart?: (e: React.TouchEvent<SVGCircleElement>) => void;
  onTouchEnd?: (e: React.TouchEvent<SVGCircleElement>) => void;
  style?: React.CSSProperties;
}

export const NoGoZoneCircle = ({
  x,
  y,
  r,
  stroke,
  fill,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  style,
}: INoGoZoneCircleProps) => {
  const {
    canEditZones,
  } = useNoGoZoneContext();

  const onDoubleClick = (e: React.MouseEvent<SVGCircleElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  return (
    <circle
      cx={x}
      cy={y}
      r={r ?? 25}
      stroke={stroke}
      fill={fill}
      onDoubleClick={onDoubleClick}
      style={{cursor: canEditZones ? 'pointer' : '', ...style}}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    />
  )
}