import axios from 'axios';
import { ProjectJobType } from './jobs';

const BASE_URL = 'https://api.nexterarobotics.com';
// const BASE_URL = 'http://localhost:8000';

export const listProjectTrackers = (projectId: string): Promise<{data: ProjectTracker[]}> => {
  return axios.get(
    `${BASE_URL}/api/project/${projectId}/trackers`,
    {
      params: {
        category: 'pt'
      }
    }
  ).then(
    r => r.data
  );
};

export const fetchTracker = (projectId: string, tracker: string, observationDate?: Date) => {
  return axios.get(
    `${BASE_URL}/api/project/${projectId}/tracker/${tracker}`,
    {
      params: {
        observation_date: observationDate ? observationDate.toISOString() : undefined
      }
    }
  ).then(r => r.data);
};

type ProjectTrackerObservationParamNames = "floor";
type ProjectTrackerObservationParams = Partial<Record<ProjectTrackerObservationParamNames, string>>;

export const fetchProjectTrackerObservations = async (projectId: string, trackerName: string, params?: ProjectTrackerObservationParams): Promise<ProjectTrackerObservation[]> => {
  const res = await axios.get(
    `${BASE_URL}/api/project/${projectId}/tracker/${trackerName}/observations`,
    {
      params: params
    }
  );
  const response = await res.data;
  return response.data;
}

export interface TrackerType {
  name: string;
  is_sequential: boolean;
  display_order: number;
}
export interface ProjectTracker {
  id: number;
  type: TrackerType;
  registered_on: string;
  last_modified_on: string;
  project: number;
  types?: ProjectJobType[];
}

type ProjectTrackerDataFloor = {floor: string;} & Record<string, any>;

export interface ProjectTrackerData extends ProjectTracker {
  floors: ProjectTrackerDataFloor[];
}

export interface ProjectTrackerObservation {
  id: number;
  registered_on: string;
  last_modified_on: string;
  job_type_id: number;
  observed_quantity: number;
  project_job_type_id: number;
  signal: number;
  is_adjusted: boolean;
  approved: boolean;
  reviewed: boolean;
  is_revised: boolean;
  job: number;
  image: number;
  revised_by_user: number | null;
  item: number;
}
