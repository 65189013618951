import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PATH_STRINGS } from '../../../hooks/useGeneratedPaths';
import { ProgressContextProvider } from '../../../contexts/progressContext';
import { ProgressOverview } from './ProgressOverview/ProgressOverview';
import { ProgressFloorTracker } from './ProgressFloorTracker/ProgressFloorTracker';

export const ProgressPage = () => (
  <ProgressContextProvider>
    <Switch>
      <Route
        exact
        path={[
          PATH_STRINGS.progressOverview,
          PATH_STRINGS.progressOverviewGantt,
          PATH_STRINGS.progressOverviewCharts,
          PATH_STRINGS.progressOverviewReports
        ]}
      >
        <ProgressOverview />
      </Route>
      <Route exact path={[PATH_STRINGS.progressFloor, PATH_STRINGS.progressFloorPoint]}>
        <ProgressFloorTracker />
      </Route>
    </Switch>
  </ProgressContextProvider>
);
