import styled from 'styled-components';
import { getUserInitials } from '../../utils';

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  background: ${props => (props.theme === 'dark' ? '#073c7a' : '#fff')};
  color: ${props => (props.theme === 'dark' ? '#fff' : '#073c7a')};
  display: table-cell;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const InitialsAvatar = ({ user, theme }: any) => (
  <Avatar theme={theme}>{getUserInitials(user)}</Avatar>
);
