import { UserMenu } from './UserMenu';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUserContext } from '../../contexts/userContext';
import { NotificationHub } from './Notifications/NotificationHub';
import { BuildingProvider } from '../../contexts/buildingContext';

const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  background: #073c7a;
  color: #fff;
  display: table-cell;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
`;

export const UserBlock = () => {
  const [userInitials, setUserInitials] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useUserContext().state;

  const getUserInitials = () => {
    if (user.first_name && user.last_name) {
      return (
        user.first_name.substring(0, 1).toUpperCase() + user.last_name.substring(0, 1).toUpperCase()
      );
    } else {
      return user.email.substring(0, 2);
    }
  };

  useEffect(() => {
    if (user.isAuthenticated) setUserInitials(getUserInitials());
    // eslint-disable-next-line
  }, [user]);

  if (!user.isAuthenticated) return <></>;

  return (
    <>
      <NotificationHub />
      <Avatar onClick={() => setMenuOpen(!menuOpen)}>{userInitials}</Avatar>
      <BuildingProvider><UserMenu display={menuOpen} setDisplay={setMenuOpen} /></BuildingProvider>
    </>
  );
};
