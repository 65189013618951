import styled from "styled-components";
import { CompositeSafetyIndexGraph } from "./CompositeSafetyIndexGraph";
import { CompositeSafetyIndexPrintout } from "./CompositeSafetyIndexPrintout";
import { useMemo } from "react";
import { useSafetyContext } from "../../../../../contexts/safetyContext";
import { LoadingIndicator } from "../../../../common/LoadingIndicator";

const containerPaddingAmount = 15;

export const CompositeSafetyIndex = () => {
  const {state: safetyState} = useSafetyContext();
  const {metricSummaryLoadingStatus} = safetyState;

  const metricsLoaded = useMemo(() => {
    return Object.values(metricSummaryLoadingStatus).every(status => !status);
  }, [metricSummaryLoadingStatus]);

  return (
    <CompositeSafetyIndexContainer>
      {!metricsLoaded &&
        <LoadingIndicator/>
      }
      {metricsLoaded &&
        <>
          <CompositeSafetyIndexPrintout/>
          <CompositeSafetyIndexGraph/>
        </>
      }
    </CompositeSafetyIndexContainer>
  );
}

const CompositeSafetyIndexContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: ${containerPaddingAmount}px;
`;