import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { PATH_STRINGS, useGeneratedPaths } from './useGeneratedPaths';
import { useBuildingContext } from '../contexts/buildingContext';

export const useNavigation = () => {
  const { projectId, floorId, pointId, scheduleId } = useBuildingContext().state;
  const {
    generateFloorPath,
    generatePointPath,
    generatePointFullPath,
    generateProjectSafetyPath,
    generateProjectSafetyMetricDetailPath,
    generateProjectSafetyMetricDetailFloorPath,
    generateProjectSafetyMetricDetailFloorPointPath,
    generateSafetyGalleryPath,
    generateManagePointsFloorPath,
    generateManageNoGoZonesFloorPath,
    generateProjectProgressPath,
    generateProjectProgressFloorPath,
    generateProjectProgressFloorPointPath,
    generateManageSchedulesPath,
    generateManageEquipmentFloorPath,
    generateManageUsersProjectUserPath,
    generateProjectProgressChartsPath,
    generateProjectProgressReportsPath
  } = useGeneratedPaths();

  const history = useHistory();

  const pushWithQueryParams = useCallback((path: string, queryDict?: Record<string, string>) => {
    let pathWithParams = path;

    if (queryDict) {
      pathWithParams += '?' + new URLSearchParams(queryDict)
    }

    return history.push(pathWithParams);
  }, [history]);

  const navigateToFloor = useCallback(
    (floorId: string) => history.replace(generateFloorPath(projectId, floorId)),
    // eslint-disable-next-line
    [history, projectId]
  );
  const navigateToPoint = useCallback(
    (pointId: string) => history.replace(generatePointPath(projectId, floorId, pointId)),
    // eslint-disable-next-line
    [history, floorId]
  );
  const navigateToPointFullscreen = useCallback(
    (pointId: string, queryDict?: any) =>
      history.replace(
        generatePointFullPath(projectId, floorId, pointId) + '?' + new URLSearchParams(queryDict)
      ), // eslint-disable-next-line
    [history, floorId]
  );
  const navigateToCurrentPointFullscreen = useCallback(
    () => history.push(generatePointFullPath(projectId, floorId, pointId)),
    // eslint-disable-next-line
    [history, floorId, pointId]
  );
  const navigateToCurrentPoint = useCallback(
    () => history.push(generatePointPath(projectId, floorId, pointId)),
    // eslint-disable-next-line
    [history, floorId, pointId]
  );

  const navigateToSafety = useCallback(
    (categorySlug?: string, floorCode?: string) => {
      const paramsDict: Record<string, string> = {
        ...!!categorySlug ? {category: categorySlug} : {},
        ...!!floorCode ? {floor: floorCode} : {}
      }

      return pushWithQueryParams(generateProjectSafetyPath(projectId), paramsDict)
    },
    // eslint-disable-next-line
    [history, projectId]
  );

  const navigateToSafetyMetricDetail = useCallback(
    (categorySlug?: string) => history.push(generateProjectSafetyMetricDetailPath(projectId, categorySlug)),
    // eslint-disable-next-line
    [history, projectId]
  );

  const navigateToSafetyMetricDetailFloor = useCallback(
    (floorId: string, categorySlug?: string) => history.push(generateProjectSafetyMetricDetailFloorPath(projectId, floorId, categorySlug)),
    // eslint-disable-next-line
    [history, projectId]
  );

  const navigateToSafetyMetricDetailFloorPoint = useCallback(
    (pointId: string, categorySlug?: string, observationDate?: Date, itemId?: string | number) => {
      const queryDict = {
        ...observationDate ? {date: observationDate.toISOString()} : {},
        ...categorySlug ? {category: categorySlug} : {},
        ...itemId ? {itemId: itemId.toString()} : {}
      };
      
      return pushWithQueryParams(generateProjectSafetyMetricDetailFloorPointPath(projectId, floorId, pointId), queryDict);
    },
    // eslint-disable-next-line
    [pushWithQueryParams, projectId, floorId, pointId]
  );

  const navigateToSafetyGallery = useCallback(
    () => history.push(generateSafetyGalleryPath(projectId)),
    // eslint-disable-next-line
    [history, projectId]
  );

  const navigateToProgressOverview = useCallback(
    () => history.push(generateProjectProgressPath(projectId)),
    // eslint-disable-next-line
    [history, projectId]
  );

  const navigateToProgressFloor = useCallback(
    (floorId: string, observationDate?: Date, activeTracker?: string) => {
      const queryDict = {
        ...observationDate ? {date: observationDate.toISOString()} : {},
        ...activeTracker ? {tracker: activeTracker} : {},
      };
      return pushWithQueryParams(generateProjectProgressFloorPath(projectId, floorId), queryDict)
    },
    // eslint-disable-next-line
    [history, projectId, floorId]
  );

  const navigateToProgressFloorPoint = useCallback(
    (pointId: string, observationDate?: Date, activeTracker?: string, itemId?: string | number) => {
      const queryDict = {
        ...observationDate ? {date: observationDate.toISOString()} : {},
        ...activeTracker ? {tracker: activeTracker} : {},
        ...itemId ? {itemId: itemId.toString()} : {}
      };
      
      return pushWithQueryParams(generateProjectProgressFloorPointPath(projectId, floorId, pointId), queryDict)
    },
    // eslint-disable-next-line
    [pushWithQueryParams, projectId, floorId, pointId]
  );

  const navigateToAdminZone = useCallback(
    () => history.push(PATH_STRINGS.adminZone),
    [history]
  );

  const navigateToManagePointsFloor = useCallback(
    (floorId) => history.push(generateManagePointsFloorPath(projectId, floorId)),
    [history, projectId, generateManagePointsFloorPath]
  );

  const navigateToManageNoGoZonesFloor = useCallback(
    (floorId) => history.push(generateManageNoGoZonesFloorPath(projectId, floorId)),
    [history, projectId, generateManageNoGoZonesFloorPath]
  );

  const navigateToManageSchedules = useCallback(
    (floorId, scheduleId) => history.push(generateManageSchedulesPath(projectId, floorId, scheduleId)),
    [history, projectId, generateManageSchedulesPath]
  );

  const navigateToManageSchedulesFloor = useCallback(
    (floorId) => history.push(generateManageSchedulesPath(projectId, floorId, scheduleId)),
    [history, projectId, scheduleId, generateManageSchedulesPath]
  );

  const navigateToManageEquipmentFloor = useCallback(
    (floorId) => history.push(generateManageEquipmentFloorPath(projectId, floorId)),
    [history, projectId, generateManageEquipmentFloorPath]
  );

  const navigateToUserProfile = useCallback(
    () => history.push(PATH_STRINGS.userProfile),
    [history]
  );

  const navigateToUserProfilePassword = useCallback(
    () => history.push(PATH_STRINGS.userProfilePassword),
    [history]
  );

  const navigateToUserProfileNotificationPreferences = useCallback(
    () => history.push(PATH_STRINGS.userProfileNotificationPreferences),
    [history]
  );

  const navigateToUserProfileIntegrations = useCallback(
    () => history.push(PATH_STRINGS.userProfileIntegrations),
    [history]
  );

  const navigateToManageUsersUser = useCallback(
    (userId: string) => history.push(generateManageUsersProjectUserPath(projectId, userId)),
    [history, generateManageUsersProjectUserPath, projectId]
  );

  const navigateToProjectProgressCharts = useCallback(
    (floor?: string, tracker?: string) => {
      const paramsDict: Record<string, string> = {
        ...!!floor ? {floor: floor} : {},
        ...!!tracker ? {tracker: tracker} : {}
      }

      return pushWithQueryParams(generateProjectProgressChartsPath(projectId), paramsDict);
    },
    [pushWithQueryParams, generateProjectProgressChartsPath, projectId]
  );

  const navigateToProjectReports = useCallback(
    (documentId?: number) => {
      const paramsDict: Record<string, string> = {...!!documentId ? {document: documentId.toString()}: {}};

      return pushWithQueryParams(generateProjectProgressReportsPath(projectId), paramsDict);
    },
    [pushWithQueryParams, generateProjectProgressReportsPath, projectId]
  );

  return {
    navigateToFloor,
    navigateToCurrentPointFullscreen,
    navigateToCurrentPoint,
    navigateToPoint,
    navigateToPointFullscreen,
    navigateToSafety,
    navigateToSafetyMetricDetail,
    navigateToSafetyMetricDetailFloor,
    navigateToSafetyMetricDetailFloorPoint,
    navigateToSafetyGallery,
    navigateToAdminZone,
    navigateToManagePointsFloor,
    navigateToManageNoGoZonesFloor,
    navigateToProgressOverview,
    navigateToProgressFloor,
    navigateToProgressFloorPoint,
    navigateToManageSchedules,
    navigateToManageSchedulesFloor,
    navigateToManageEquipmentFloor,
    navigateToUserProfile,
    navigateToUserProfilePassword,
    navigateToUserProfileNotificationPreferences,
    navigateToUserProfileIntegrations,
    navigateToManageUsersUser,
    navigateToProjectProgressCharts,
    navigateToProjectReports,
  };
};
