import styled from 'styled-components';
import imgBell from '../../../assets/images/icon_bell.svg';

export const Ring = styled.img.attrs(() => ({
  src: imgBell,
}))`
  height: 18px;

  margin-right: 25px;
`;

export const Mail = styled.img`
  width: 18px;
  cursor: pointer;
  margin-right: 25px;
`;
