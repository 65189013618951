export function getTourPoints(point_ids, view_point) {
  point_ids = point_ids.filter(point => {
    return point.images?.length
  })

  // Specify some collection parameters
  var collection_radius_num = 3;
  var collection_quadrant_num = 2;  // this is a multiple x3 this number
  var collection_row_num = 4;
  var row_col_pixel_threshold = 100;

  // Run the radius_point_algorithm
  var radiusResult = radius_point_algorithm(point_ids, view_point, collection_radius_num);
  var return_list_radius = radiusResult[0];
  var furthest_distance = radiusResult[1];

  // Run the quadrant_point_algorithm
  var return_list_quadrant = quadrant_algorithm(point_ids, view_point, collection_quadrant_num, return_list_radius);

  // Run the find_points_col_row algorithm
  var return_list_row_col = find_points_col_row(point_ids, view_point, collection_row_num, row_col_pixel_threshold, furthest_distance, return_list_radius);

  var result_ids = [...return_list_radius, ...return_list_quadrant, ...return_list_row_col];
  return point_ids.filter( point => {
    return result_ids.includes(point.point_id) && point.point_id !== view_point.point_id
  })

}

function find_points_col_row(point_ids, view_point, collection_row_num, pixel_threshold, furthest_distance, return_list_radius) {
  // Get the view_point coordinates
  var view_point_x = view_point.x;
  var view_point_y = view_point.y;

  // Instantiate a list of point_ids
  var point_ids_list = Object.keys(point_ids);

  // Instantiate a list of point_ids
  var return_list = [];

  // Instantiate a heap
  var heap = [];

  // Iterate through all the point_ids
  for (var i = 0; i < point_ids_list.length; i++) {
    var point_id = point_ids_list[i];

    // If the point_id is in the return_list_radius, skip it
    if (return_list_radius.includes(point_id)) {
      continue;
    }

    if (point_id === view_point) {
      continue;
    }

    // Get the point coordinates
    var point_x = point_ids[point_id]['x'];
    var point_y = point_ids[point_id]['y'];

    // Calculate the distance between the view_point and the point
    var distance = Math.sqrt(Math.pow((view_point_x - point_x), 2) + Math.pow((view_point_y - point_y), 2));

    // See if the point is within the pixel threshold in x and y
    if (Math.abs(view_point_x - point_x) < pixel_threshold || Math.abs(view_point_y - point_y) < pixel_threshold) {
      heap.push([distance, point_ids[point_id].point_id]);
    }
  }

  // Sort the heap by distance in ascending order
  heap.sort(function(a, b) {
    return a[0] - b[0];
  });

  // Get the collection_row_num closest points
  for (var j = 0; j < Math.min(collection_row_num, heap.length); j++) {
    var heap_distance = heap[j][0];
    var heap_point_id = heap[j][1];

    // If the distance is less than the 2*furthest distance, add it to the return_list
    if (heap_distance < 5 * furthest_distance) {
      return_list.push(heap_point_id);
    }
  }

  return return_list;
}

function quadrant_algorithm(point_ids, view_point, collection_quadrant_num, return_list_radius) {
  // Get the view_point coordinates
  var view_point_x = view_point.x;
  var view_point_y = view_point.y;

  // Instantiate a list of point_ids
  var point_ids_list = Object.keys(point_ids);

  // Instantiate a list of point_ids
  var return_list = [];

  // Instantiate quadrant tracker
  var quadrant_tracker_heap = [[], [], [], []];

  // Find the collection_radius_num closest points
  for (var i = 0; i < point_ids_list.length; i++) {
    var point_id = point_ids_list[i];

    // if the distance isnt 0, add the point_id and distance to the heap
    if (view_point === point_id) {
      continue;
    }

    // Get the point coordinates
    var point_x = point_ids[point_id]['x'];
    var point_y = point_ids[point_id]['y'];

    // Calculate the distance between the view_point and the point
    var distance = Math.sqrt(Math.pow((view_point_x - point_x), 2) + Math.pow((view_point_y - point_y), 2));
    // Find the quadrant relative to the view_point
    var quadrant;
    if (point_x > view_point_x && point_y > view_point_y) {
      quadrant = 0;
    } else if (point_x < view_point_x && point_y > view_point_y) {
      quadrant = 1;
    } else if (point_x < view_point_x && point_y < view_point_y) {
      quadrant = 2;
    } else if (point_x > view_point_x && point_y < view_point_y) {
      quadrant = 3;
    } else{
      continue
    }

    // Add the point_id and distance to the heap
    quadrant_tracker_heap[quadrant].push([distance, point_ids[point_id].point_id, quadrant]);
  }

  // Iterate through all the quadrants
  for (var k = 0; k < quadrant_tracker_heap.length; k++) {
    var quadrant_heap = quadrant_tracker_heap[k];

    // Sort the quadrant heap by distance in ascending order
    quadrant_heap.sort(function(a, b) {
      return a[0] - b[0];
    });

    // Get the collection_quadrant_num closest points
    for (var l = 0; l < Math.min(collection_quadrant_num, quadrant_heap.length); l++) {
      var heap_distance = quadrant_heap[l][0];
      var heap_point_id = quadrant_heap[l][1];

      // If the point_id is in the return_list_radius, skip it
      if (return_list_radius.includes(heap_point_id)) {
        continue;
      }
      if (heap_distance > 1500) {
        continue;
      }
      return_list.push(heap_point_id);
    }
  }

  // Return the list of point_ids
  return return_list;
}

function radius_point_algorithm(point_ids, view_point, collection_radius_num) {
  // Get the view_point coordinates
  var view_point_x = view_point.x;
  var view_point_y = view_point.y;

  // Instantiate a list of point_ids
  var point_ids_list = Object.keys(point_ids);

  // Instantiate a list of point_ids
  var return_list = [];

  // Instantiate a heap
  var heap = [];

  // Find the collection_radius_num closest points
  for (var i = 0; i < point_ids_list.length; i++) {
    var point_id = point_ids_list[i];

    // Get the point coordinates
    var point_x = point_ids[point_id]['x'];
    var point_y = point_ids[point_id]['y'];

    // Calculate the distance between the view_point and the point
    var distance = Math.sqrt(Math.pow((view_point_x - point_x), 2) + Math.pow((view_point_y - point_y), 2));

    // if the distance isnt 0, add the point_id and distance to the heap
    if (view_point === point_id) {
      continue;
    }

    // Add the point_id and distance to the heap
    heap.push([distance, point_ids[point_id].point_id]);
  }

  // Sort the heap by distance in ascending order
  heap.sort(function(a, b) {
    return a[0] - b[0];
  });

  const num_tour_points = Math.min(collection_radius_num, heap.length);

  // Get the collection_radius_num closest points
  for (var j = 0; j < num_tour_points; j++) {
    var heap_distance = heap[j][0];
    var heap_point_id = heap[j][1];
    return_list.push(heap_point_id);

    // if j == num_tour_points - 1, get the furthest distance
    if (j === num_tour_points - 1) {
      var furthest_distance = heap_distance;
    }
  }

  // Return the list of point_ids and furthest distance
  return [return_list, furthest_distance];
}
