import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';

const DEV_MODE = '_self' in React.createElement('div');
export const identifyUser = (user: any) => {
  if (user.public_id) {
    mixpanel.identify(user.public_id);
    mixpanel.people.set({
      $email: user.email,
      $name: user.first_name + ' ' + user.last_name,
    });
    mixpanel.track('Login');
    LogRocket.identify(user.public_id, {
      name: user.first_name + ' ' + user.last_name,
      email: user.email,
    });
  }
};

export const initAnalytics = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
    debug: false,
  });

  if (!DEV_MODE) {
    Sentry.init({
      dsn: 'https://75fc98cabd0e43f086d675fc1d1f5d17@o921770.ingest.sentry.io/5868484',
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.2,
    });
    LogRocket.init('gizj0w/digital-bridge', {
      network: {
        requestSanitizer: request => {
          if (request.headers['Authorization']) {
            request.headers['Authorization'] = '';
          }
          return request;
        },
      },
      console: {
        isEnabled: {
          info: false,
        },
        shouldAggregateConsoleErrors: true,
      },
    });
  }
};

export const captureException = (e: Error) => {
  if (!DEV_MODE) {
    LogRocket.captureException(e);
    Sentry.captureException(e);
  } else {
    console.log('Exception caught: ', e);
  }
};

export const trackEvent = (name: string, properties?: any) => {
  mixpanel.track(name, properties);
};

export const resetMixpanel = () => {
  mixpanel.reset();
};
