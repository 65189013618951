import React, { createRef, useMemo, useState } from "react";
import styled from "styled-components";
import { updateProject } from "../../../../api/buildingFetches";
import { Building } from "../../../../api/types";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { useGetProcoreCompanyProjects, useGetUserProcoreCompaniesQuery } from "../../../common/Procore/hooks/procoreQueries"
import { StyledSelect } from "../../admin_building_page/components/ManageSchedules/ScheduleControls/ScheduleControls";
import { AddProjectButton } from "./AddProjectButton";

const emptyOption = <option key='empty' selected disabled hidden></option>;

interface IProcoreProjectLinkProps {
  item: Building;
  onClose: () => void;
  onUpdateProject: (updatedProject: Building) => void;
}

export const ProcoreProjectLink = ({
  item,
  onClose,
  onUpdateProject,
}: IProcoreProjectLinkProps) => {
  const { addNotification } = useNotifications();

  const {data: companies, isLoading: companiesLoading} = useGetUserProcoreCompaniesQuery();
  const [selectedProcoreCompanyId, setSelectedProcoreCompanyId] = useState<number | null>(item.procore_company_id);

  const {data: projects, isLoading: projectsLoading} = useGetProcoreCompanyProjects(selectedProcoreCompanyId);
  const [selectedProcoreProjectId, setSelectedProcoreProjectId] = useState<number | null>(item.procore_project_id);

  const validForSubmit = !!selectedProcoreCompanyId && !!selectedProcoreProjectId;
  const projectAlreadyLinked = !!item.procore_company_id && !!item.procore_project_id;
  const showLoadingIndicator = projectAlreadyLinked && (companiesLoading || projectsLoading);

  const modalContentRef = createRef<HTMLDivElement>();

  useOnClickOutside(modalContentRef, onClose);

  const companyOptions = useMemo(() => {
    if (!companies || companiesLoading) {
      return <></>;
    }

    return companies.map(company => {
      return (
        <option
          key={company.id}
          value={company.id}
        >
          {company.name}
        </option>
      );
    });
  }, [companies, companiesLoading]);

  const projectOptions = useMemo(() => {
    if (!projects || projectsLoading) {
      return <></>
    }

    return projects.map(project => {
      return (
        <option
          key={project.id}
          value={project.id}
        >
          {project.name}
        </option>
      );
    });
  }, [projects, projectsLoading]);

  const onChangeCompany = (newCompanyId: string) => {
    setSelectedProcoreCompanyId(parseInt(newCompanyId));
  }

  const onChangeProject = (newProjectId: string) => {
    setSelectedProcoreProjectId(parseInt(newProjectId));
  }

  const onClickLinkProject = async () => {
    if (validForSubmit) {
      const procoreIds = {
        procore_company_id: selectedProcoreCompanyId,
        procore_project_id: selectedProcoreProjectId,
      };

      try {
        await updateProject(item.public_id, procoreIds);
        addNotification('Project linked successfully', 'success');

        onUpdateProject({...item, ...procoreIds});
        onClose();
      } catch {
        addNotification('Error linking project', 'error');
      }
    }
  }

  const modalHeading = projectAlreadyLinked ? 'Project Linked Successfully' : `Procore Project Link - ${item.name}`;

  return (
    <ModalContainer>
      <ModalContent
        ref={modalContentRef}
      >
        <h1
          style={{
            marginBottom: '20px',
            fontSize: '20px',
            textAlign: 'center'
          }}
        >
          {modalHeading}
        </h1>
        {showLoadingIndicator &&
          <LoadingIndicator/>
        }
        {!showLoadingIndicator &&
          <>
            <ProjectLinkSelect
              label="Company"
              disabled={projectAlreadyLinked}
              options={companyOptions}
              onChange={onChangeCompany}
              value={selectedProcoreCompanyId}
            />
            <ProjectLinkSelect
              label="Project"
              disabled={!selectedProcoreCompanyId || projectAlreadyLinked}
              options={projectOptions}
              onChange={onChangeProject}
              value={selectedProcoreProjectId}
            />
          </>
        }
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px'
          }}
        >
          <AddProjectButton
            text={projectAlreadyLinked ? "Close" : "Cancel"}
            onClick={onClose}
          />
          {!projectAlreadyLinked &&
            <AddProjectButton
              text="Link"
              buttonStyle={{
                backgroundColor: validForSubmit ? '#073C7A' : '#073C7A80',
                borderColor: validForSubmit ? '#073C7A' : '#073C7A80',
                cursor: validForSubmit ? 'pointer' : 'default',
                color: 'white'
              }}
              onClick={onClickLinkProject}
            />
          }
        </div>
      </ModalContent>
    </ModalContainer>
  )
}

interface IProjectLinkSelectProps {
  options: JSX.Element | JSX.Element[];
  onChange: (newVal: string) => void;
  value?: string | number | readonly string[] | null;
  label: string;
  disabled?: boolean;
}

const ProjectLinkSelect = ({
  options,
  onChange,
  value,
  label,
  disabled,
}: IProjectLinkSelectProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <label>
        {label}
      </label>
      <StyledSelect
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        value={value ?? undefined}
        style={{
          marginBottom: '20px',
        }}
      >
        {emptyOption}
        {options}
      </StyledSelect>
    </div>
  );
}

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.4);
`;

const ModalContent = styled.div`
  background-color: #fff;
  margin: auto;
  width: 500px;
  position: relative;
  top: 50%;
  transform: translate(0%, -50%);
  padding: 20px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.5);
`;