import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths"
import { SiteWalkVideoUpload } from "./SiteWalkVideoUpload"
import { SiteWalkTable } from "./SiteWalkTable";
import { SiteWalkConfirmation } from './SiteWalkConfirmation/SiteWalkConfirmation';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { BuildingProvider } from '../../../contexts/buildingContext';
import { PermissionRoute } from '../../common/PermissionRoute/PermissionRoute';
import { ImageViewerProvider } from '../image_viewer/imageViewerContext';

export const SiteWalkPage = () => {
  const siteWalkMatch = useRouteMatch<{ siteWalkId: string, pointId: string }>([
    PATH_STRINGS.siteWalkConfirmPoint,
    PATH_STRINGS.siteWalkConfirmation,
  ]);

  const siteWalkId: string = siteWalkMatch?.params.siteWalkId || '';
  const pointId: string = siteWalkMatch?.params.pointId || '';
  
  return (
    <Switch>
      <Route exact path={PATH_STRINGS.siteWalkVideoUpload}>
        <SiteWalkVideoUpload />
      </Route>
      <PermissionRoute
        path={PATH_STRINGS.siteWalkConfirmation}
        permission="site_walk_tables.review_site_walk"
        siteWalkId={siteWalkId}
        pointId={pointId}
        component={WrappedSiteWalkConfirmation}
      />
      <Route path={PATH_STRINGS.siteWalk}>
        <SiteWalkTable/>
      </Route>
    </Switch>
  )
}

interface WrappedSiteWalkConfirmationProps {
  siteWalkId: string;
  pointId: string;
}

const WrappedSiteWalkConfirmation = ({
  siteWalkId,
  pointId,
}: WrappedSiteWalkConfirmationProps) => {
  return (
    <>
      {!siteWalkId &&
        <LoadingIndicator/>
      }
      {!!siteWalkId &&
        <BuildingProvider>
          <ImageViewerProvider>
            <SiteWalkConfirmation
              siteWalkId={siteWalkId}
              pointId={pointId}
            />
          </ImageViewerProvider>
        </BuildingProvider>
      }
    </>
  )
}