import React, { useEffect } from 'react';

import iconBack from '../../../assets/images/icon_back.svg';
import { useBuildingContext } from '../../../contexts/buildingContext';
import { TimeScroll } from './components/TimeScroll';
// import { DebugPanel } from './components/DebugPannel';
import { useNavigation } from '../../../hooks/useNavigation';
import { ImageViewerProvider } from './imageViewerContext';
import { SplitScreenManager } from './components/SplitScreenManager';
import { FullscreenPanoramaBackButton, FullscreenPanoramaBase } from './styles';

export const ImageViewer = () => {
  const { navigateToCurrentPoint } = useNavigation();
  const { state: buildingState } = useBuildingContext();

  return (
    <FullscreenPanoramaBase style={{ display: 'flex', flexDirection: 'column' }}>
      {/*<DebugPanel />*/}
      <ImageViewerProvider>
        <SplitScreenManager />
        <FullscreenPanoramaBackButton onClick={() => navigateToCurrentPoint()}>
          <img src={iconBack} alt="Back" />
          <span>Back</span>
        </FullscreenPanoramaBackButton>
        <TimeScroll point={buildingState.pointData} />
      </ImageViewerProvider>
    </FullscreenPanoramaBase>
  );
};
