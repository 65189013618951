import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useWebSocket from 'react-use-websocket';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import mixpanel from 'mixpanel-browser';

import { ChatHeader } from './components/ChatHeader';
import { ChatHistory } from './components/ChatHistory';
import { ChatInput } from './components/ChatInput';
import { useTagContext } from '../../../contexts/tagContext';
import { ChatUserSelect } from './components/ChatUserSelect';
import { useBuildingContext } from '../../../contexts/buildingContext';
import { useUserContext } from '../../../contexts/userContext';
import { useNotifications } from '../../../contexts/notificationProvider';
import { useImageViewerContext } from '../../views/image_viewer/imageViewerContext';

const ChatDialogue = styled.div`
  position: fixed;
  z-index: 100;
  width: 350px;
  height: 300px;
  background: #fff;
  border-radius: 2px;
`;

export const ChatManager = (props: { pnlm: any; hydrate: any }) => {
  const buildingState = useBuildingContext().state;
  const { state: ImageViewerState } = useImageViewerContext();
  const { findSetCurrentTag, state: tagState } = useTagContext();
  const { state: userState } = useUserContext();
  const { addNotification } = useNotifications();
  const [transform, setTransform] = useState<string>('');
  const [messages, setMessages] = useState<any>([]);
  const [chatRoomUUID, setChatRoomUUID] = useState<string | null>(null);
  const currentTag = useTagContext().state.tags.current;
  const chatState = useTagContext().state.chat;

  /*
  Copied from Pannellum to keep the dialogue in correct place in "3D" space
   */
  const renderDialogue = () => {
    let chatDom = document.getElementById('chat-dialogue');
    if (chatDom && props.pnlm) {
      let ctya =
          currentTag.yaw -
          (chatState.status === 'conversation' ? ImageViewerState.master.data.angle : 0),
        canvasPitch = props.pnlm.getPitch(),
        canvasYaw = props.pnlm.getYaw(),
        canvasHfov = props.pnlm.getHfov(),
        hsPitchSin = Math.sin((currentTag.pitch * Math.PI) / 180),
        hsPitchCos = Math.cos((currentTag.pitch * Math.PI) / 180),
        configPitchSin = Math.sin((canvasPitch * Math.PI) / 180),
        configPitchCos = Math.cos((canvasPitch * Math.PI) / 180),
        yawCos = Math.cos(((-ctya + canvasYaw) * Math.PI) / 180);
      var z = hsPitchSin * configPitchSin + hsPitchCos * yawCos * configPitchCos;
      if ((ctya <= 90 && ctya > -90 && z <= 0) || ((ctya > 90 || ctya <= -90) && z <= 0)) {
        console.log('no visible');
      } else {
        var yawSin = Math.sin(((-ctya + canvasYaw) * Math.PI) / 180),
          hfovTan = Math.tan((canvasHfov * Math.PI) / 360);
        // Subpixel rendering doesn't work in Firefox
        // https://bugzilla.mozilla.org/show_bug.cgi?id=739176
        var canvas = props.pnlm.getCanvas(),
          canvasWidth = canvas.clientWidth,
          canvasHeight = canvas.clientHeight;
        var coord = [
          ((-canvasWidth / hfovTan) * yawSin * hsPitchCos) / z / 2,
          ((-canvasWidth / hfovTan) *
            (hsPitchSin * configPitchCos - hsPitchCos * yawCos * configPitchSin)) /
            z /
            2,
        ];
        // Apply transform
        coord[0] += (canvasWidth - chatDom.offsetWidth + 300) / 2;
        coord[1] += (canvasHeight - chatDom.offsetHeight - 360) / 2;
        var t = 'translate(' + coord[0] + 'px, ' + coord[1] + 'px) translateZ(9999px)';
        setTransform(t);
      }
    }
  };

  const onCreateConversation = (users: string[]) => {
    let room_uuid = uuidv4();
    axios
      .post('https://services.nexterarobotics.com/tags/', {
        creator: userState.public_id,
        pitch: currentTag.pitch,
        yaw: currentTag.yaw + ImageViewerState.master.data.angle,
        project_id: buildingState.projectId,
        point_id: buildingState.pointId,
        floor_id: buildingState.floorId,
        image_id: buildingState.imageData.data._id,
        type: 'CHAT',
        room_uuid: room_uuid,
        users: users.join(),
      })
      .then(res => {
        props.hydrate().then(() => {
          findSetCurrentTag(res.data.id);
          setChatRoomUUID(room_uuid);
        });
        mixpanel.track('Create Chat', { ...res.data });
      })
      .catch(e => console.log);
  };

  const onMessage = (e: any) => {
    const data = JSON.parse(e.data);
    if (data.event_type === 'hydrate') {
      var sortedMessages = data.messages
        .sort(
          (a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        .reverse();
      setMessages(sortedMessages);
      let messageData = {
        sender: userState.public_id,
        event_type: 'reception',
      };
      sendMessage(JSON.stringify(messageData));
    } else if (data.event_type === 'chat_message') {
      setMessages((prevState: any) => {
        return prevState.concat(data.message);
      });
      let messageData = {
        sender: userState.public_id,
        event_type: 'reception',
      };
      sendMessage(JSON.stringify(messageData));
    }
  };

  const { sendMessage } = useWebSocket(
    `wss://services.nexterarobotics.com/ws/chat/${chatRoomUUID}/`,
    {
      onOpen: () => {
        console.log(chatRoomUUID);
        mixpanel.track('Join Chat', {
          room: chatRoomUUID,
        });
      },
      onMessage: onMessage,
      onError: err => addNotification('Error connecting to chat server', 'error'),
      onReconnectStop: () => addNotification('Could not connect to chat server', 'error'),
      shouldReconnect: closeEvent => true,
      reconnectAttempts: 3,
      reconnectInterval: 1000,
      retryOnError: true,
    },
    !!chatRoomUUID
  );

  useEffect(() => {
    if (currentTag) {
      if (currentTag.type === 'CHAT') {
        if (currentTag.chat_room) setChatRoomUUID(currentTag.chat_room.uuid);
      }
    }
  }, [currentTag]);

  useEffect(() => {
    renderDialogue();
    // eslint-disable-next-line
  }, [props, currentTag]);

  return (
    <>
      {tagState.displayDialogue && currentTag.type === 'CHAT' && (
        <ChatDialogue
          style={{ transform: transform }}
          id="chat-dialogue"
          className="down-arrow oco-ignore oco-ignore oco-dialogue">
          {chatState.status === 'create' && <ChatUserSelect onCreate={onCreateConversation} />}
          {chatState.status === 'conversation' && (
            <>
              <ChatHeader hydrate={props.hydrate} />
              <ChatHistory messages={messages} />
              <ChatInput sendMessage={sendMessage} pnlm={props.pnlm} />
            </>
          )}
        </ChatDialogue>
      )}
    </>
  );
};
