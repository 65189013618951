import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import iconDown from '../../../../assets/images/icon_down_black.svg';
import { CheckNameDelete } from '../../ViewSelector/components/PointGroupSelector/CheckNameDelete';

export const ProcoreManagerContainer = styled.div`
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  z-index: 100;
  position: fixed;
  padding: 10px;
`;

export const RFIFormContainer = styled.div`
  height: 344px;
  width: 346px;
  padding-bottom: 5px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const RFIFormTitle = styled.span`
  color: #073C7A;
  font-size: 20px;
  text-align: center;
`;

export const RFIFormDivider = styled.div`
  border-top: 1px solid #5C6F8A;
`;

export const RFIFormRow = styled.div`
  display: flex;
  gap: 10px;
`;

export const RFIFormLabel = styled.label<{required?: boolean, bold?: boolean}>`
  display: block;
  font-size: 12px;
  line-height: 28px;

  ${props => (props.required || props.bold) && `
    font-weight: 700;
  `}

  ${props => props.required && `
    &:after {
      content: "*";
    }
  `}
`;

const commonInputStyles = `
  padding: 7px 8px 9px 10px;
  background: rgba(92, 111, 138, 0.05);
  border: 1px solid #5C6F8A;
  border-radius: 2px;
  width: 168px;
`;

export const RFIFormInputControl = styled.input`
  ${commonInputStyles}
`;

const RFIFormSelectControl = styled.select`
  ${commonInputStyles}
`;

export const RFIFormTextArea = styled.textarea`
  ${commonInputStyles}
  width: 345px;
`;

export const RFIFormButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const RFIFormButton = styled.button<{primary?: boolean}>`
  width: 100px;
  border: none;
  border-radius: 2px;
  padding: 10px;
  background-color: transparent;
  color: #073C7A;
  cursor: pointer;

  ${props => props.primary && `
    background-color: #073C7A;
    color: white;
  `}

  &:disabled {
    ${props => props.primary && `
      background-color: rgba(7,60,122,0.5);
    `}
  }
`;

interface IRFIFormInputProps {
  label?: string;
  required?: boolean;
  value: string | number | readonly string[] | undefined;
  onChange: (newVal: string) => void;
  inputStyle?: React.CSSProperties;
}

export const RFIFormInput = ({
  label,
  required,
  value,
  onChange,
  inputStyle
}: IRFIFormInputProps) => {
  return (
    <div>
      <RFIFormLabel
        required={required}
      >
        {label}
      </RFIFormLabel>
      <RFIFormInputControl
        value={value}
        onChange={e => onChange(e.target.value)}
        style={inputStyle}
      />
    </div>
  )
}

interface IBaseRFIFormSelectProps<T> {
  required?: boolean;
  label: string;
  value?: string | number | readonly string[] | null;
  onChange: ((val: string | undefined) => void) | ((val: string) => void);
  options: T[];
  mapOptionValue: (option: T) => string;
  mapOptionLabel: (option: T) => string;
  style?: React.CSSProperties;
  hideEmptyOption?: boolean;
}

interface IRFIFormSelectPropsWithEmpty<T> extends IBaseRFIFormSelectProps<T> {
  hideEmptyOption: true;
  onChange: (val: string) => void;
}

interface IRFIFormSelectPropsWithoutEmpty<T> extends IBaseRFIFormSelectProps<T> {
  hideEmptyOption?: false;
  onChange: (val: string | undefined) => void;
}

type IRFIFormSelectProps<T> = IRFIFormSelectPropsWithEmpty<T> | IRFIFormSelectPropsWithoutEmpty<T>;

export const RFIFormSelect = <T extends {}>({
  required,
  label,
  value,
  onChange,
  options,
  hideEmptyOption,
  mapOptionValue,
  mapOptionLabel,
  style,
}: IRFIFormSelectProps<T>) => {
  return (
    <div>
      <RFIFormLabel required={required}>{label}</RFIFormLabel>
      <RFIFormSelectControl
        value={value ? value : undefined}
        onChange={e => {
          if (e.target.value === '' && !hideEmptyOption) {
            onChange(undefined as any);
          } else {
            onChange(e.target.value);
          }
        }}
        style={style}
      >

      <option
        key='empty'
        selected
        disabled={hideEmptyOption}
        hidden={hideEmptyOption}
      />
        
        {options.map(option => (
          <option
            key={mapOptionValue(option)}
            value={mapOptionValue(option)}
          >
            {mapOptionLabel(option)}
          </option>
        ))}
      </RFIFormSelectControl>
    </div>
  )
}

interface IRFIFormMultiSelectProps<T, V> {
  label: string;
  required?: boolean;
  options: T[];
  selectedValues: V[];
  mapOptionValue: (option: T) => V;
  mapOptionLabel: (option: T) => string;
  onChangeChecked: (checked: boolean, value: V) => void;
}

export const RFIFormMultiSelect = <T extends {}, V extends {}>({
  label,
  required,
  options,
  selectedValues,
  mapOptionValue,
  mapOptionLabel,
  onChangeChecked,
}: IRFIFormMultiSelectProps<T, V>) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const onClickOutsideDropdown = useCallback(() => {
    setExpanded(false);
  }, [])

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickOutsideDropdown);

  const selectedOptionsList = useMemo(() => {
    return options.filter(option => selectedValues.includes(mapOptionValue(option)))
                  .map(option => mapOptionLabel(option))
                  .join(', ')
  }, [mapOptionValue, mapOptionLabel, options, selectedValues]);

  return (
    <MultiSelectContainer ref={wrapperRef}>
      <RFIFormLabel required={required}>{label}</RFIFormLabel>
      <MultiSelectInputContainer onClick={() => setExpanded(prevValue => !prevValue)}>
        <SelectedOptionsListContainer>
          {selectedOptionsList}
        </SelectedOptionsListContainer>
        <MultiSelectArrowImage
          src={iconDown} 
          alt='expand roles picker'
        />
      </MultiSelectInputContainer>
      {expanded &&
        <MultiSelectOptionsContainer>
          {options.map(option => {
            const value = mapOptionValue(option);
            const checked = selectedValues.includes(value);

            return (
              <CheckNameDelete
                key={mapOptionLabel(option)}
                checked={checked}
                onChangeChecked={checked => onChangeChecked(checked, value)}
                name={mapOptionLabel(option)}
                containerStyle={{padding: '1px 0px', border: 'none'}}
                nameStyle={{fontSize: '13px'}}
                checkboxStyle={{width: '17px', height: '17px'}}
                checkmarkPosition={{bottom: '1px', left: '3px'}}
              />
            )
          })}
        </MultiSelectOptionsContainer>
      }
    </MultiSelectContainer>
  )
}

const useOutsideAlerter = (ref: React.MutableRefObject<any>, onClickOutside: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

const MultiSelectArrowImage = styled.img`
  position: absolute;
  right: 3px;
  top: 41px;
  width: 10px;
  height: 6px;
`

const MultiSelectContainer = styled.div`
  position: relative;
  width: 168px;
`

const MultiSelectInputContainer = styled.div`
  width: 100%;
  background: rgba(92, 111, 138, 0.05);
  border: 1px solid #5C6F8A;
  border-radius: 2px;
  height: 32px;
  padding: 0px 15px;
`

const MultiSelectOptionsContainer = styled.div`
  position: absolute;
  background-color: white;
  padding: 2px 5px;
  border-radius: 10px;
  z-index: 1;
  max-height: 100px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const SelectedOptionsListContainer = styled.div`
  cursor: default;
  font-size: 14px;
  height: 13.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: 9px;
  white-space: nowrap;
`;