import { useQuery } from "react-query";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import {
  fetchFloorGroups,
  fetchFloorMappings,
  fetchFloorNoGoZones,
  fetchSchedules,
  fetchSchedule,
  fetchSchedulePoints,
  fetchFloorEquipment,
  fetchProjectUsers,
  fetchProjectRoles,
  fetchFloorSections,
} from '../../../../api/adminBuildingFetches';
import { IMapping } from "../../../common/ViewSelector/components/PointGroupSelector/PointGroupSelector";
import { INoGoZone } from "../components/ManageNoGoZones/ManageNoGoZones";
import { Frequency } from "../components/ManageSchedules/ScheduleControls/ScheduleControls";
import { IAssignedProjectRole, IManagedUserEntry } from "../components/ManageUsers/ManageUsers";
import { ProjectFloorSection } from "../../../../api/types";

export const useFetchFloorGroupsQuery = (projectId: string, floorCode: string) => {
  return useQuery(
    ['groups', projectId, floorCode],
    async () => {
      return await fetchFloorGroups(projectId, floorCode);
    },
    { enabled: !!projectId && !!floorCode }
  );
}

export const useFloorGroupsAndMappingsQuery = (onSuccess: (data: any) => void) => {
  const buildingState = useBuildingContext().state;

  return useQuery(
    `floor groups: ${buildingState.projectId} ${buildingState.floorId}`,
    async () => {
      const groups = await fetchFloorGroups(buildingState.projectId, buildingState.floorId);

      const pointMappingsList = await fetchFloorMappings(buildingState.projectId, buildingState.floorId);
      const pointMappings = new Map();

      pointMappingsList.forEach((mapping: IMapping) => {
        const currentPointId: number = mapping.point_id;
        const pointGroups = pointMappings.has(currentPointId) ? pointMappings.get(currentPointId) : new Set();
        pointGroups.add(mapping.group_id);

        pointMappings.set(currentPointId, pointGroups);
      });

      return {
        groups,
        pointMappings,
      }
    },
    { enabled: !!buildingState.projectId && !!buildingState.floorId, onSuccess: data => onSuccess(data) }
  );
};

export const useFetchProjectFloorSectionsQuery = (projectId: string, floorCode: string, onSuccess?: (data: ProjectFloorSection[]) => void) => {
  return useQuery(
    ['sections', projectId, floorCode],
    async () => {
      return await fetchFloorSections(projectId, floorCode);
    },
    {
      enabled: !!projectId && !!floorCode,
      onSuccess: onSuccess
    }
  );
}

export const useSchedulesQuery = (onSuccess: (data: any) => void) => {
  const buildingState = useBuildingContext().state;

  return useQuery(
    ['schedules', buildingState.projectId],
    async () => {
      const schedules = await fetchSchedules(buildingState.projectId);

      return schedules;
    },
    { enabled: !!buildingState.projectId, onSuccess: data => onSuccess(data) }
  );
}

export const useScheduleQuery = (onSuccess: (data: any) => void) => {
  const buildingState = useBuildingContext().state;

  const tomorrow = new Date(new Date().setHours(0,0,0,0));
  tomorrow.setDate(tomorrow.getDate() + 1);

  const initialScheduleData = {
    frequency: Frequency.never,
    id: 'new',
    name: '',
    start_date:  tomorrow,
    till_date: tomorrow,
    time: '',
  }

  return useQuery(
    ['schedule', buildingState.projectId, buildingState.scheduleId],
    async () => {
      if (buildingState.scheduleId === 'new') {
        return {...initialScheduleData};
      } else {
        return await fetchSchedule(buildingState.projectId, buildingState.scheduleId);
      }
    },
    { enabled: !!buildingState.projectId && !!buildingState.scheduleId, onSuccess: data => onSuccess(data) }
  );
}

export const useSchedulePointsQuery = (onSuccess: (data: any) => void) => {
  const buildingState = useBuildingContext().state;

  return useQuery(
    ['schedule points', buildingState.projectId, buildingState.scheduleId],
    async () => {
      if (buildingState.scheduleId === 'new') {
        return [];
      } else {
        return await fetchSchedulePoints(buildingState.projectId, buildingState.scheduleId);
      }
    },
    { enabled: !!buildingState.projectId && !!buildingState.scheduleId, onSuccess: data => onSuccess(data) }
  );
}

export const useFloorNoGoZonesQuery = (onSuccess: (data: any) => void) => {
  const buildingState = useBuildingContext().state;

  return useQuery(
    `no go zones ${buildingState.projectId} ${buildingState.floorId}`,
    async () => {
      const noGoZonesMap = new Map();
      const noGoZones = await fetchFloorNoGoZones(buildingState.projectId, buildingState.floorId);

      noGoZones.forEach((zone: INoGoZone) => {
        noGoZonesMap.set(zone.id, zone);
      });      

      return {
        noGoZones: noGoZonesMap,
        visibleNoGoZones: new Set(noGoZones.map((zone: INoGoZone) => zone.id))
      }
    },
    { enabled: !!buildingState.projectId && !!buildingState.floorId, onSuccess: data => onSuccess(data) }
  );
};

export const useFloorEquipmentQuery = (onSuccess: (data: any) => void) => {
  const buildingState = useBuildingContext().state;

  return useQuery(
    ['equipment', buildingState.projectId, buildingState.floorId],
    async () => {
      const equipment = await fetchFloorEquipment(buildingState.projectId, buildingState.floorId);
      const equipmentIds = equipment.map((item: any) => item.id);

      const visibleEquipment = new Set(equipmentIds);

      return {
        equipment,
        visibleEquipment,
      }
    },
    { enabled: !!buildingState.projectId && !!buildingState.floorId, onSuccess: data => onSuccess(data) }
  );
}

export const useProjectUsersQuery = (onSuccess: (data: any) => void) => {
  const buildingState = useBuildingContext().state;

  return useQuery(
    ['users', buildingState.projectId],
    async () => {
      const users = await fetchProjectUsers(buildingState.projectId);

      users.forEach((user: IManagedUserEntry) => {
        const roleIdSet = new Set<number>();

        user.user.assigned_project_roles.forEach((role: IAssignedProjectRole) => {
          roleIdSet.add(role.role_id);
        });

        user.user.projectRoleIdSet = roleIdSet;
      });

      return {
        users
      }
    },
    { enabled: !!buildingState.projectId, onSuccess: data => onSuccess(data) }
  );
}

export const useProjectRolesQuery = (onSuccess: (data: any) => void) => {
  const buildingState = useBuildingContext().state;

  return useQuery(
    ['roles', buildingState.projectId],
    async () => {
      const projectRoles = await fetchProjectRoles(buildingState.projectId);

      return {
        projectRoles
      }
    },
    { enabled: !!buildingState.projectId, onSuccess: data => onSuccess(data) } 
  );
}
