import { useLocation } from "react-router-dom";
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths";
import { useNavigation } from "../../../hooks/useNavigation";
import { SelectItem } from "../../common/ViewSelector/components/SelectItem"
import { ContentContainer } from "../../common/ViewSelector/ViewSelector"

const selectItemStyle = {
  border: '1px solid #E8E8E8',
  borderRadius: '4px'
}

export const UserSettingsOptions = () => {
  const {
    navigateToUserProfile,
    navigateToUserProfilePassword,
    navigateToUserProfileNotificationPreferences,
    navigateToUserProfileIntegrations,
  } = useNavigation();

  const location = useLocation();

  return (
    <ContentContainer aria-expanded={true}>
      <SelectItem
        key='My Information'
        content='My Information'
        onClick={navigateToUserProfile}
        selected={location.pathname === PATH_STRINGS.userProfile}
        style={selectItemStyle}
      />
      <SelectItem
        key='Password'
        content='Password'
        onClick={navigateToUserProfilePassword}
        selected={location.pathname === PATH_STRINGS.userProfilePassword}
        style={selectItemStyle}
      />
      {/* <SelectItem
        key='Notification Preferences'
        content='Notification Preferences'
        onClick={navigateToUserProfileNotificationPreferences}
        selected={location.pathname === PATH_STRINGS.userProfileNotificationPreferences}
        style={selectItemStyle}
      /> */}
      <SelectItem
        key='Integrations'
        content='Integrations'
        onClick={navigateToUserProfileIntegrations}
        selected={location.pathname === PATH_STRINGS.userProfileIntegrations}
        style={selectItemStyle}
      />
    </ContentContainer>
  )
}