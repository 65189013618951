import { ErrorPageContainer, ErrorPageMessageContainer, ErrorResetButton } from './styles';

interface IErrorProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const AppErrorFallback = ({ error, resetErrorBoundary }: IErrorProps) => {
  return (
    <ErrorPageContainer>
      <ErrorPageMessageContainer>
        <div>
          <h1 style={{ fontSize: '1.3em' }}>Something went wrong</h1>
          <ErrorResetButton onClick={resetErrorBoundary}>Reload the page</ErrorResetButton>
        </div>
      </ErrorPageMessageContainer>
    </ErrorPageContainer>
  );
};
