import React, { useCallback, useEffect, useState } from 'react';
import { Flex } from '@react-css/flex';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { Content, LoginButton, LoginCard, LoginCardBody, LoginError, LoginLabel, LoginWithMicrosoftButton, ThirdPartySeparator } from './styles';
import { TEMP_LOG_FUNCTION, formatTokenResponseData, login, msTokenGenerate } from '../../../api/auth';
import { useMsal } from '@azure/msal-react';
import { loginRequest, silentRequest } from '../../../msAuthConfig';
import axios from 'axios';
import { useAcquireMsalToken } from '../../../hooks/useAcquireMsalToken';
import { useHandleDbTokenResponse } from '../../../hooks/useHandleDbTokenResponse';
import { useUserContext } from '../../../contexts/userContext';
import { useNotifications } from '../../../contexts/notificationProvider';
import { useHistory, useLocation } from 'react-router-dom';

export const LoginPage = () => {
  const { addNotification } = useNotifications();
  const [localLoad, setLocalLoad] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { instance } = useMsal();
  const acquireMsalToken = useAcquireMsalToken();
  const handleTokenResponse = useHandleDbTokenResponse();
  const { resetUser } = useUserContext();
  const location = useLocation();
  const history = useHistory();
  const redirectError = (location.state as any)?.errorMessage;

  useEffect(() => {
    if (redirectError) {
      addNotification(redirectError, "error");
      history.replace({ state: {} });
    };
  }, [addNotification, history, redirectError]);

  useEffect(() => {
    if (location.hash) {
      console.log("Location hash: ", location.hash);
      TEMP_LOG_FUNCTION(`Location hash: ${location.hash}`);
    }
  }, [location.hash]);

  const logOutUser = useCallback(() => {
    localStorage.clear();
    resetUser();
  }, [resetUser]);

  const generateTokenFromMsToken = useCallback(async (token: string) => {
    msTokenGenerate(token)
    .then(res => formatTokenResponseData(res))
    .then(tokenResponse => handleTokenResponse(tokenResponse))
    .catch(e => {
      console.error("Generate token from ms token error: ", e);
      logOutUser();
    });
  }, [handleTokenResponse, logOutUser]);

  const handleMsalLogin = async () => {
    const dbUsr = localStorage.getItem('db_usr');
    const parsedDbUser = dbUsr ? JSON.parse(dbUsr) : undefined;
    const userEmail = parsedDbUser ? parsedDbUser.email : undefined;

    const silentLoginRequest = {
      ...silentRequest,
      loginHint: userEmail
    }

    setLocalLoad(true);

    try {
      console.log("Attempting silent login");

      const ssoSilentResponse = await instance.ssoSilent(silentLoginRequest);
      await generateTokenFromMsToken(ssoSilentResponse.accessToken);
    } catch (err) {
      console.error("Generate silent token error: ", err);
      TEMP_LOG_FUNCTION(`Generate silent token error: ${JSON.stringify(err)}`);

      try {
        console.log("Attempting redirect login");
        await instance.loginRedirect(loginRequest);
      } catch (err) {
        console.error("Redirect login error: ", err);
        TEMP_LOG_FUNCTION(`Redirect login error: ${JSON.stringify(err)}`);
      }
    } finally {
      setLocalLoad(false);
    }
  }

  const _login = (e: any) => {
    e.preventDefault();
    delete axios.defaults.headers.common['Authorization'];
    setLocalLoad(true);
    if (email && password) {
      login(email, password)
        .then(user => handleTokenResponse(user))
        .catch(e => {
          setError(e.message);
          setLocalLoad(false);
        });
    }
  };

  useEffect(() => {
    acquireMsalToken();
  }, [acquireMsalToken]);

  if (localLoad) return <LoadingIndicator />;

  return (
    <>
      <Content>
        <Flex justifyContent="center">
          <LoginCard>
            <LoginCardBody>
              <form onSubmit={_login}>
                <Flex flexDirection="column">
                  {error && <LoginError id="error">{error}</LoginError>}
                  <LoginLabel htmlFor="email">Email</LoginLabel>
                  <input
                    name="email"
                    type="email"
                    className="login-input"
                    onChange={e => setEmail(e.target.value)}
                  />
                  <LoginLabel htmlFor="password">Password</LoginLabel>
                  <input
                    name="password"
                    type="password"
                    className="login-input"
                    onChange={e => setPassword(e.target.value)}
                  />
                  <LoginButton type="submit" value="Login" />
                </Flex>
              </form>
              <ThirdPartySeparator>or</ThirdPartySeparator>
              <LoginWithMicrosoftButton
                onClick={handleMsalLogin}
              />
            </LoginCardBody>
          </LoginCard>
        </Flex>
      </Content>
    </>
  );
};
