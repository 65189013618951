import React from 'react';
import ReactDOM from 'react-dom';
import './api/axiosConfig';
import './styles/index.scss';
import App from './components/views/App';
import { initAnalytics } from './analytics';
import { PublicClientApplication, EventType } from "@azure/msal-browser";

import { msalConfig } from "./msAuthConfig";
import { TEMP_LOG_FUNCTION } from './api/auth';

const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    console.log('MSAL LOGIN SUCCESS: ', event);
    TEMP_LOG_FUNCTION(`MSAL LOGIN SUCCESS: ${JSON.stringify(event)}`);
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    console.error('MSAL LOGIN FAILURE: ', event);
    TEMP_LOG_FUNCTION(`MSAL LOGIN FAILURE: ${JSON.stringify(event)}`);
    msalInstance.setActiveAccount(null);
    window.location.replace('/')
  }
});

initAnalytics();
ReactDOM.render(
  <React.StrictMode>
    <App msalInstance={msalInstance}/>
  </React.StrictMode>,
  document.getElementById('root')
);
