import { useEffect, useState } from 'react';
import { ViewerPosition } from '../types';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { useImageViewerContext } from '../imageViewerContext';

const initialPosition = {
  yaw: 0,
  pitch: 0,
  hfov: 120,
  angle: 0,
};

export const useViewerPosition = () => {
  const { state: buildingState } = useBuildingContext();
  const [viewerPosition, setViewerPosition] = useState<ViewerPosition>(initialPosition);
  const { state: ImageViewerState } = useImageViewerContext();

  const getInitialPosition = (isMaster: boolean) => {
    let initialPosition = { ...viewerPosition };
    if (new URLSearchParams(window.location.search).get('pitch')) {
      initialPosition.pitch = parseFloat(
        String(new URLSearchParams(window.location.search).get('pitch'))
      );
      initialPosition.yaw =
        parseFloat(String(new URLSearchParams(window.location.search).get('yaw'))) -
        viewerPosition.angle;
      if (new URLSearchParams(window.location.search).get('hfov')) {
        initialPosition.hfov = parseFloat(
          String(new URLSearchParams(window.location.search).get('hfov'))
        );
      }
    } else {
      initialPosition.yaw =
        viewerPosition.yaw -
        (isMaster
          ? ImageViewerState.master.angleDelta ||
            (buildingState.imageData?.data?.angle ?? 0) - viewerPosition.angle
          : ImageViewerState.subordinate.angleDelta || 0);
    }
    return initialPosition;
  };

  const updateViewerPosition = (newValues: any) => {
    let newVal = { ...viewerPosition, ...newValues };
    setViewerPosition(newVal);
  };

  useEffect(() => {
    setViewerPosition(getInitialPosition(true));
  }, []);

  return { viewerPosition, updateViewerPosition, getInitialPosition };
};
