import React, { useRef, useState } from "react";
import { createGroup } from "../../../../../api/adminBuildingFetches";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { InputCreateNew } from "../InputCreateNew";

export const CreateNewGroup = () => {
  const {
    updateFloor,
    state: buildingState,
  } = useBuildingContext();
  const { addNotification } = useNotifications();

  const [newGroupName, setNewGroupName] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const onCreateNewGroup = async () => {
    try {
      const createdGroup = await createGroup(buildingState.projectId, buildingState.floorId, newGroupName);

      updateFloor({
        groups: [...buildingState.floorData.groups, createdGroup]
      });

      setNewGroupName('');
      inputRef.current?.blur();
      addNotification('Group Created Successfully', 'success');
    } catch (err) {
      console.log('createNewGroup==>>', err);
      addNotification('Group Could Not Be Created', 'error');
    }
  }

  return (
    <InputCreateNew
      inputRef={inputRef}
      onSubmit={onCreateNewGroup}
      initialPlaceholder='Create New Group'
      inputValue={newGroupName}
      setInputValue={setNewGroupName}
    />
  )
}