export const removeTemporaryPanellumTags = (panellum: any, ignore: string[] = []) => {
  let tmps = document.getElementsByClassName('pnlm-tmp');

  if (tmps) {
    Array.from(tmps).forEach(element => {
      let tagIdClass = Array.from(element.classList).find((el: any) => {
        return /pnlm-id_-[0-9]/.test(el);
      });

      if (tagIdClass && panellum) {
        if (!ignore.includes(tagIdClass)) {
          let id = tagIdClass.split('_')[1];
          panellum.removeHotspot(parseInt(id));
        }
      }
    });
  }
}