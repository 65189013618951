import { User } from './api/types';

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getUserInitials = (user: User) => {
  if (user.first_name && user.last_name) {
    return (
      user.first_name.substring(0, 1).toUpperCase() + user.last_name.substring(0, 1).toUpperCase()
    );
  } else {
    return user.email.substring(0, 2).toUpperCase();
  }
};

export const getFullName = <T extends {first_name: string, last_name: string}>(user: T) => {
  return user.first_name + ' ' + user.last_name;
};

export const truncateMessage = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
};

const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

export const rgbToHex = (r: number, g: number, b: number) => {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
}

const interpolate = (start: number, end: number, factor: number) => {
  return Math.round(start + (end - start) * factor);
};

export const interpolateColor = (startColor: string, endColor: string, factor: number): string => {
  if (factor < 0 || factor > 1) {
    throw new Error("Factor must be between 0 and 1");
  }

  const [startR, startG, startB] = hexToRgb(startColor);
  const [endR, endG, endB] = hexToRgb(endColor);

  const r = interpolate(startR, endR, factor);
  const g = interpolate(startG, endG, factor);
  const b = interpolate(startB, endB, factor);

  return rgbToHex(r, g, b);
}

export const generateGradientFunction = (minColor: string, minValue: number, maxColor: string, maxValue: number) => (value: number) => {
  value = Math.max(minValue, Math.min(maxValue, value));
  const minColorComponents = hexToRgb(minColor);
  const maxColorComponents = hexToRgb(maxColor);

  const gradient = minColorComponents.map((channel, index) => {
    const difference = maxColorComponents[index] - channel;
    return channel + ((difference / (maxValue - minValue)) * (value - minValue));
  });

  const hex = gradient.map(channel => {
      const hexChannel = Math.round(channel).toString(16);
      return hexChannel.length === 1 ? '0' + hexChannel : hexChannel;
  });

  return '#' + hex.join('');
};
