import { generatePath } from 'react-router';

export const PATH_STRINGS = {
  project: '/project/:projectId',
  floor: '/project/:projectId/floor/:floorId',
  point: '/project/:projectId/floor/:floorId/point/:pointId',
  full: '/project/:projectId/floor/:floorId/point/:pointId/full',
  safetyOverview: '/project/:projectId/safety', //use ?category=<category>
  safetyMetricDetail: '/project/:projectId/safety/detail', //use ?category=<category>
  safetyMetricDetailFloor: '/project/:projectId/safety/floor/:floorId/detail', //use ?category=<category>
  safetyMetricDetailFloorPoint: '/project/:projectId/safety/floor/:floorId/point/:pointId/detail', //use ?category=<category>
  safetyGallery: '/project/:projectId/safety/gallery',
  progressOverview: '/project/:projectId/progress',
  progressOverviewGantt: '/project/:projectId/progress/gantt',
  progressOverviewCharts: '/project/:projectId/progress/charts',
  progressOverviewReports: '/project/:projectId/progress/reports',
  progressFloor: '/project/:projectId/progress/floor/:floorId', // use ?tracker=<tracker>
  progressFloorPoint: '/project/:projectId/progress/floor/:floorId/point/:pointId',
  adminZone: '/admin',
  adminProject: '/admin/project/:projectId',
  adminFloor: '/admin/project/:projectId/floor/:floorId',
  adminManagePoints: '/admin/project/:projectId/managepoints',
  adminManagePointsFloor: '/admin/project/:projectId/floor/:floorId/managepoints',
  adminManageNoGoZones: '/admin/project/:projectId/managenogozones',
  adminManageNoGoZonesFloor: '/admin/project/:projectId/floor/:floorId/managenogozones',
  adminSchedules: '/admin/project/:projectId/floor/:floorId/schedule/:scheduleId/manageschedules',
  adminManageEquipment: '/admin/project/:projectId/manageequipment',
  adminManageEquipmentFloor: '/admin/project/:projectId/floor/:floorId/manageequipment',
  adminManageUsers: '/admin/project/:projectId/manageusers',
  adminManageUsersUser: '/admin/project/:projectId/manageusers/:userId',

  siteWalk: '/site-walk',
  siteWalkVideoUpload: '/site-walk/upload',
  siteWalkConfirmation: '/site-walk/:siteWalkId/confirm',
  siteWalkConfirmPoint: '/site-walk/:siteWalkId/confirm/:pointId',

  userProfile: '/profile',
  userProfilePassword: '/profile/password',
  userProfileNotificationPreferences: '/profile/notificationpreferences',
  userProfileIntegrations: '/profile/integrations'
};

/**
 * Generates path strings following a defined pattern with passed in args
 */
export const useGeneratedPaths = () => {
  const generateProjectPath = (projectId: string) =>
    generatePath(PATH_STRINGS.project, {
      projectId: projectId,
    });

  const generateFloorPath = (projectId: string, floorId: string) =>
    generatePath(PATH_STRINGS.floor, {
      projectId: projectId,
      floorId: floorId,
    });

  const generatePointPath = (projectId: string, floorId: string, pointId: string) =>
    generatePath(PATH_STRINGS.point, {
      projectId: projectId,
      floorId: floorId,
      pointId: pointId,
    });

  const generatePointFullPath = (
    projectId: string,
    floorId: string,
    pointId: string,
    params?: any
  ) =>
    generatePath(PATH_STRINGS.full, {
      projectId: projectId,
      floorId: floorId,
      pointId: pointId,
    });

  const generateProjectSafetyPath = (projectId: string) =>
    generatePath(PATH_STRINGS.safetyOverview, {
      projectId: projectId,
    });

  const generateProjectSafetyMetricDetailPath = (projectId: string, categorySlug?: string) => {
    let path = generatePath(PATH_STRINGS.safetyMetricDetail, {
      projectId: projectId,
    });

    if (categorySlug) {
      path += `?category=${categorySlug}`
    }

    return path;
  }

  const generateProjectSafetyMetricDetailFloorPath = (projectId: string, floorId: string, categorySlug?: string) => {
    let path = generatePath(PATH_STRINGS.safetyMetricDetailFloor, {
      projectId: projectId,
      floorId: floorId,
    });

    if (categorySlug) {
      path += `?category=${categorySlug}`
    }

    return path;
  }

  const generateProjectSafetyMetricDetailFloorPointPath = (
    projectId: string,
    floorId: string,
    pointId: string,
  ) => {
    return generatePath(PATH_STRINGS.safetyMetricDetailFloorPoint, {
      projectId: projectId,
      floorId: floorId,
      pointId: pointId,
    });
  }

  const generateSafetyGalleryPath = (projectId: string) =>
    generatePath(PATH_STRINGS.safetyGallery, {
      projectId: projectId,
    });

  const generateProjectProgressPath = (projectId: string) =>
    generatePath(PATH_STRINGS.progressOverview, {
      projectId: projectId,
    });

  const generateProjectProgressGanttPath = (projectId: string) =>
    generatePath(PATH_STRINGS.progressOverviewGantt, {
      projectId: projectId,
    });

  const generateProjectProgressChartsPath = (projectId: string) =>
    generatePath(PATH_STRINGS.progressOverviewCharts, {
      projectId: projectId,
    });

  const generateProjectProgressReportsPath = (projectId: string) =>
    generatePath(PATH_STRINGS.progressOverviewReports, {
      projectId: projectId,
    });

  const generateProjectProgressFloorPath = (projectId: string, floorId: string) =>
    generatePath(PATH_STRINGS.progressFloor, {
      projectId: projectId,
      floorId: floorId,
    });

  const generateProjectProgressFloorPointPath = (
    projectId: string,
    floorId: string,
    pointId: string,
  ) =>
    generatePath(PATH_STRINGS.progressFloorPoint, {
      projectId: projectId,
      floorId: floorId,
      pointId: pointId,
    });

  const generateManagePointsProjectPath = (projectId: string) =>
    generatePath(PATH_STRINGS.adminManagePoints, {
      projectId: projectId,
    });

  const generateManagePointsFloorPath = (projectId: string, floorId: string) =>
    generatePath(PATH_STRINGS.adminManagePointsFloor, {
      projectId: projectId,
      floorId: floorId
    });

  const generateManageNoGoZonesProjectPath = (projectId: string) =>
    generatePath(PATH_STRINGS.adminManageNoGoZones, {
      projectId: projectId,
    });

  const generateManageNoGoZonesFloorPath = (projectId: string, floorId: string) =>
    generatePath(PATH_STRINGS.adminManageNoGoZonesFloor, {
      projectId: projectId,
      floorId: floorId
    });

  const generateAdminProjectPath = (projectId: string) =>
    generatePath(PATH_STRINGS.adminProject, {
      projectId: projectId,
    });

  const generateManageSchedulesPath = (projectId: string, floorId: string, scheduleId: string) =>
    generatePath(PATH_STRINGS.adminSchedules, {
      projectId: projectId,
      floorId: floorId,
      scheduleId: scheduleId
    });

  const generateManageEquipmentProjectPath = (projectId: string) =>
    generatePath(PATH_STRINGS.adminManageEquipment, {
      projectId: projectId,
    });

  const generateManageEquipmentFloorPath = (projectId: string, floorId: string) =>
    generatePath(PATH_STRINGS.adminManageEquipmentFloor, {
      projectId: projectId,
      floorId: floorId
    });

  const generateManageUsersProjectPath = (projectId: string) =>
    generatePath(PATH_STRINGS.adminManageUsers, {
      projectId: projectId,
    });
  
  const generateManageUsersProjectUserPath = (projectId: string, userId: string) => 
    generatePath(PATH_STRINGS.adminManageUsersUser, {
      projectId,
      userId,
    });

  const generateFilterBuildingsPath = (inAdminZone: boolean, mode: string, sort: string) => {
    const path = inAdminZone ? PATH_STRINGS.adminZone : '/';
    
    return `${path}?mode=${mode}&sort=${sort}`;
  }

  const generateSiteWalkConfirmationPath = (siteWalkId: string | number) => {
    return generatePath(PATH_STRINGS.siteWalkConfirmation, {
      siteWalkId: siteWalkId
    });
  }

  const generateSiteWalkConfirmPointPath = (siteWalkId: string | number, pointId: string | number) => {
    return generatePath(PATH_STRINGS.siteWalkConfirmPoint, {
      siteWalkId: siteWalkId,
      pointId: pointId,
    });
  }

  return {
    generateProjectPath,
    generateFloorPath,
    generatePointPath,
    generatePointFullPath,
    generateProjectSafetyPath,
    generateProjectSafetyMetricDetailPath,
    generateProjectSafetyMetricDetailFloorPath,
    generateProjectSafetyMetricDetailFloorPointPath,
    generateSafetyGalleryPath,
    generateManagePointsProjectPath,
    generateManagePointsFloorPath,
    generateManageNoGoZonesFloorPath,
    generateManageNoGoZonesProjectPath,
    generateProjectProgressPath,
    generateProjectProgressGanttPath,
    generateProjectProgressChartsPath,
    generateProjectProgressReportsPath,
    generateProjectProgressFloorPath,
    generateProjectProgressFloorPointPath,
    generateAdminProjectPath,
    generateManageSchedulesPath,
    generateManageEquipmentProjectPath,
    generateManageEquipmentFloorPath,
    generateManageUsersProjectPath,
    generateManageUsersProjectUserPath,
    generateFilterBuildingsPath,
    generateSiteWalkConfirmationPath,
    generateSiteWalkConfirmPointPath,
  };
};
