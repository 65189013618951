import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import axios from 'axios';

import { useNotifications } from '../../../../../../contexts/notificationProvider';
import { useBuildingContext } from '../../../../../../contexts/buildingContext';
import { useProgressContext } from '../../../../../../contexts/progressContext';
import { Job, getJobsByViewpoint } from '../../../../../../api/jobs';
import { CheckboxInput, CheckboxLabel, Checkmark } from '../../../../../common/ViewSelector/components/PointGroupSelector/styles';
import { useNavigation } from '../../../../../../hooks/useNavigation';
import { useUserContext } from '../../../../../../contexts/userContext';

const allItemsKey = "All Items";

interface ObservationPanelProps {
  viewpointJobs: Job[];
  setViewpointJobs: React.Dispatch<React.SetStateAction<Job[]>>;
  onUpdateJobs?: (updatedJob: Job | Job[]) => void;
  items: any;
  setShow: (show: boolean) => void;
  point?: number;
}
export const ObservationPanel = ({
  viewpointJobs,
  setViewpointJobs,
  items,
  setShow,
  onUpdateJobs
}: ObservationPanelProps) => {
  const {
    navigateToProgressFloorPoint,
  } = useNavigation();

  const { addNotification } = useNotifications();
  const { state: buildingState } = useBuildingContext();
  const { state: progressState } = useProgressContext();
  const { state: userState} = useUserContext();
  const [selectedJob, setSelectedJob] = useState<any>();
  const [obsVal, setObsVal] = useState<string>();
  const [panelJobs, setPanelJobs] = useState<Job[]>([...viewpointJobs]);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const panelRef = useRef<any>();

  const {
    activeTracker,
    observationDate,
    selectedItemId
  } = progressState;

  useEffect(() => {
    setPanelJobs([...viewpointJobs]);
  }, [viewpointJobs]);

  const filteredPanelJobs = useMemo(() => {
    if (selectedItemId) {
      return panelJobs.filter(job => job.item.id === selectedItemId)
    }

    return panelJobs
  }, [panelJobs, selectedItemId]);

  const onChangeJobChecked = useCallback((job: Job, checked: boolean) => {
    const updatedJob = {
      ...job,
      completed_units: checked ? 1 : 0,
    };

    setPanelJobs(prevJobs => prevJobs.map(oldJob => {
      if (oldJob.id === job.id) {
        return updatedJob;
      } else {
        return oldJob;
      }
    }));
  }, []);

  const onSelectItem = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    
    if (value !== allItemsKey) {
      navigateToProgressFloorPoint(buildingState.pointId, observationDate, activeTracker, value);
    } else {
      navigateToProgressFloorPoint(buildingState.pointId, observationDate, activeTracker);
    }
  }

  const onFetchViewpointImageJobs = useCallback(async () => {
    if (buildingState?.imageData?.data?.taken_on) {
      const imageDate = new Date(buildingState.imageData.data.taken_on);
      const returnedJobs = await getJobsByViewpoint(buildingState.projectId, activeTracker, buildingState.pointId, imageDate);

      return returnedJobs.data;
    }

    return []
  }, [buildingState?.imageData?.data?.taken_on, buildingState.projectId, buildingState.pointId, activeTracker]);

  const saveObservation = useCallback(async () => {
    const viewpointJobsMap = new Map<number, Job>();

    viewpointJobs.forEach(job => {
      viewpointJobsMap.set(job.id, job);
    });

    const updatedPanelJobs = filteredPanelJobs.filter(job => {
      const viewpointJob = viewpointJobsMap.get(job.id);

      if (viewpointJob) {
        return job.completed_units !== viewpointJob.completed_units;
      }

      return true;
    });

    const data = updatedPanelJobs.map(job => {
      return {
        job: job.id,
        observed_quantity: job.completed_units,
        image: buildingState.imageData.data.id,
        signal: 1,
        is_revised: true,
        revised_by_user: userState.email,
      }
    });

    const observationChangePromises = data.map(entry => {
      return axios
        .post(
          `https://api.nexterarobotics.com/api/project/${buildingState.projectId}/tracker/${activeTracker}/job/${entry.job}/observation`,
          entry
        );
    });

    try {
      setSaveInProgress(true);

      await Promise.all(observationChangePromises);
      
      if (onUpdateJobs) {
        const updatedViewpointJobs = await onFetchViewpointImageJobs();
        onUpdateJobs([...updatedViewpointJobs]);
        setViewpointJobs([...updatedViewpointJobs]);
        setPanelJobs([...updatedViewpointJobs]);
      }

      addNotification(`Observation${observationChangePromises.length === 1 ? '' : 's'} Saved`, 'success');
    } catch {
      addNotification(`Observation${observationChangePromises.length === 1 ? '' : 's'} Not Saved!`, 'error');
    } finally {
      setSaveInProgress(false);
    }
  }, [activeTracker, addNotification, buildingState.imageData?.data?.id, buildingState.projectId, filteredPanelJobs, onFetchViewpointImageJobs, onUpdateJobs, setViewpointJobs, userState.email, viewpointJobs]);

  return (
    <ObservationPanelContainer
      ref={panelRef}
    >
      {/*<ObservationPanelHeader>Observation Panel</ObservationPanelHeader>*/}
      <ObservationPanelBody>
        <ObservationDetails>
          <p>
            Observation date:{' '}
            {buildingState?.imageData?.data?.taken_on && format(new Date(buildingState?.imageData?.data?.taken_on), 'MMM dd, yyy')}
          </p>
        </ObservationDetails>

        <div
          style={{
            marginBottom: '20px'
          }}
        >
          <h2>Item:</h2>
          <select
            value={selectedItemId}
            onChange={onSelectItem}
            style={{
              padding: "10px 5px"
            }}
          >
            <option>{allItemsKey}</option>
            {items.map((item: any) => (
              <option
                key={item.id}
                value={item.id}
              >
                Item: {item.id}
              </option>
            ))}
          </select>
        </div>

        <CheckboxContainer>
          {filteredPanelJobs.map((job: Job) => {
            const checked = job.completed_units > 0;

            return (
              <CheckboxLabel
                key={job.id}
                style={{
                  marginBottom: '5px'
                }}
              >
                <CheckboxInput
                  checked={checked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeJobChecked(job, e.target.checked)}
                />
    
                <div>
                  <Checkmark
                    checked={checked}
                    checkmarkPosition={{
                      bottom: '1px',
                      left: '4px',
                      top: '0px'
                    }}
                  />
                </div>
                <span
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  {job.item.id}: {job.type.job_type.slug}
                </span>
              </CheckboxLabel>
            )
          })}
        </CheckboxContainer>

        {/* <div>
          <h2>Observation value</h2>
          <input type="text" onChange={e => setObsVal(e.target.value)} />
        </div> */}
        <SaveButton
          onClick={saveObservation}
          disabled={saveInProgress}
        >
          Save
        </SaveButton>
      </ObservationPanelBody>
    </ObservationPanelContainer>
  );
};

const ObservationPanelContainer = styled.div`
  position: absolute;
  bottom: 50px;
  right: 20px;
  z-index: 100;
`;

const ObservationPanelHeader = styled.div`
  background: #073c7a;
  color: #fff;
  font-size: 1.1em;
  padding: 10px;
  border-radius: 4px 4px 0 0;
`;

const ObservationPanelBody = styled.div`
  padding: 1em;
  background: #ffffff;
  border-radius: 0 0 4px 4px;
`;

const ObservationDetails = styled.div`
  font-size: 0.8em;
  color: #666;
  margin-bottom: 10px;
`;

const CheckboxContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding-right: 10px;
`;

const SaveButton = styled.div<{disabled?: boolean}>`
  cursor: pointer;
  background: #073c7a;
  color: #fff;
  border-radius: 2px;
  padding: 5px 10px;
  text-align: center;
  margin: 10px 0;
  opacity: ${props => props.disabled ? 0.5 : 1};
`;
