import styled from 'styled-components';

export const BuildingOverviewContainer = styled.div`
  flex: 1;
  margin-top: 38px;
`;

export const ProjectLogoContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  width: 156px;
`;

export const ViewModeMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 38px;
`;

export const ProjectLogo = styled.img`
  max-width: 100%;
  margin: 0.5em 0;
`;

export const BuildingPreview = styled.div`
  position: relative;
  text-align: center;
  margin: auto;
  max-width: 70%;

  object {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const FloorVector = styled.object`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 10;
`;
