import React, { useMemo, useState } from 'react';
import { changePassword } from '../../../api/auth';
import { useNotifications } from '../../../contexts/notificationProvider';
import { useUserContext } from '../../../contexts/userContext';
import { AddProjectButton } from '../buildings_page/components/AddProjectButton';
import { UserInformationField } from './UserInformationField';
import styled from 'styled-components';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>/?[\]'"\\|]).+$/;

export const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPasswordFirst, setNewPasswordFirst] = useState<string>('');
  const [newPasswordSecond, setNewPasswordSecond] = useState<string>('');

  const { addNotification } = useNotifications();

  const { 
    updateUser,
    state: userState,
   } = useUserContext();

   const newPasswordValid = useMemo(() => {
    return passwordRegex.test(newPasswordFirst) && newPasswordFirst.length >= 15;
   }, [newPasswordFirst]);

  const onChangePassword = (e: React.MouseEvent) => {
    if (!oldPassword.trim() || !newPasswordSecond.trim() || !newPasswordFirst.trim()) {
      addNotification('Please complete all fields', 'warning');
    } else if (newPasswordFirst !== newPasswordSecond) {
      addNotification('"New Password" and "Confirm New Password" must match', 'warning');
    } else if (!newPasswordValid) {
      addNotification('New password not valid, please try again', 'warning');
    } else {
      changePassword(userState.email, oldPassword, newPasswordFirst).then(user => {
        localStorage.setItem('db_usr', JSON.stringify(user));

        updateUser(user);

        setOldPassword('');
        setNewPasswordFirst('');
        setNewPasswordSecond('');

        addNotification('Password Changed Successfully', 'success');
      })
      .catch((err) => {
        addNotification('Error Changing Password', 'error');
      });
    }
  }

  return (
    <>
      <h1 style={{marginBottom: '20px'}}>Change Password</h1>

      <UserInformationField
        label='Old Password'
        type='password'
        value={oldPassword}
        onChange={(newValue: string) => setOldPassword(newValue)}
      />
      <UserInformationField
        label='New Password'
        type='password'
        value={newPasswordFirst}
        onChange={(newValue: string) => setNewPasswordFirst(newValue)}
      />
      <UserInformationField
        label='Confirm New Password'
        type='password'
        value={newPasswordSecond}
        onChange={(newValue: string) => setNewPasswordSecond(newValue)}
      />

      <PasswordSpecification>
        <div>Password must include:</div>
        <div>- Lowercase letters</div>
        <div>- Uppercase letters</div>
        <div>- At least one number</div>
        <div>- At least one special character</div>
        <br/>
        <div>Minimum length: 15 characters</div>
        <div>You cannot reuse any of your previous passwords</div>
        <div>Can not be a dictionary word, a proper name, or include your account name</div>
      </PasswordSpecification>


      <AddProjectButton
        text='Update password'
        onClick={(e) => onChangePassword(e)}
        buttonStyle={{
          fontSize: '14px',
          width: '150px',
          marginTop: '25px',
        }}
      />
    </>
  )
}

const PasswordSpecification = styled.div`
  margin-top: 25px;
`