import React, { useEffect } from 'react';

import { IChatNotification } from '../types';
import { Dropdown } from '../../Dropdown/Dropdown';
import { LoadMore, NoChats } from './styles';
import { ChatNotificationItem } from './ChatNotificationItem';
import mixpanel from 'mixpanel-browser';

interface IChatnotificationsProps {
  display: boolean;
  notifications: any;
  menuToggle: (v: boolean) => void;
  loadMore: (pageNumber: number) => void;
}

export const ChatNotifications = ({
  display,
  notifications,
  menuToggle,
  loadMore,
}: IChatnotificationsProps) => {
  useEffect(() => {
    if (display) {
      mixpanel.track('Show All Chats');
    }
  }, [display]);
  if (!notifications.data) {
    return <></>;
  }

  return (
    <Dropdown display={display} setDisplay={menuToggle}>
      {notifications.data.map((notification: IChatNotification, index: number) => (
        <ChatNotificationItem
          notification={notification}
          lastItem={index === notifications.data.length - 1}
          key={Math.random()}
        />
      ))}
      {notifications.has_next && (
        <LoadMore>
          <span style={{ cursor: 'pointer' }} onClick={() => loadMore(notifications.next_page)}>
            Load More
          </span>
        </LoadMore>
      )}
      {!notifications.data.length && <NoChats>No chats!</NoChats>}
    </Dropdown>
  );
};
