import styled from "styled-components";

export const QuickFilter = ({item, active, onClick, styles}: any) => {
  return(
    <QuickFilterDiv className={active ? 'active' :''} onClick={() => onClick(item)} style={styles}>
      <p className={active ? 'active' :''}>{item.text}</p>
    </QuickFilterDiv>
  )
}

const QuickFilterDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 8px 13px;
  height: 32px;
  width: fit-content;
  background: #F0F4F8;
  border-radius: 6px;
  margin: 10px 0 10px auto;
  cursor: pointer;
  p {
    text-align: center;
    color: #202020;
  }
  &.active {
    background: #073C7A;
  }
  &.active p {
    color: #fff;
  }
`;