import styled from 'styled-components';

export const ErrorPageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
`;

export const ErrorPageMessageContainer = styled.div`
  width: 500px;
  height: 300px;
  margin: auto;
  background: #ddd;
  border-radius: 5px;
  justify-content: center;
  line-height: 2em;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const ErrorResetButton = styled.button`
  background: #073c7a;
  color: #fff;
  border: none;
  padding: 10px;
`;

export const ErrorComponentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: #f8f8f8;
`;
