import { useRef } from "react";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { CheckNameDelete } from "../../../../common/ViewSelector/components/PointGroupSelector/CheckNameDelete"
import { GroupSelectorContainer } from "../../../../common/ViewSelector/components/PointGroupSelector/PointGroupSelector"
import { equipmentColors } from "./EquipmentIcon"
import { IEquipment } from "./ManageEquipmentFloorPlanView";

interface IDeviceSelectorProps {
  hideCheckbox?: boolean;
  equipment: IEquipment[];
  generateFarText?: (item: IEquipment) => JSX.Element | string;
}

export const DeviceSelector = ({
  hideCheckbox,
  equipment,
  generateFarText,
}: IDeviceSelectorProps) => {
  const {
    updateFloor,
    state: buildingState,
  } = useBuildingContext();

  const nameInputRef = useRef<HTMLInputElement>(null);

  const { visibleEquipment } = buildingState.floorData;

  const onToggleEquipmentChecked = (equipmentId: number, checked: boolean) => {
    const updatedVisibleEquipment = new Set(visibleEquipment);

    checked ? updatedVisibleEquipment.add(equipmentId) : updatedVisibleEquipment.delete(equipmentId);

    updateFloor({
      visibleEquipment: updatedVisibleEquipment,
    });
  }

  equipment.sort((a: IEquipment, b: IEquipment) => {
    if (a.equipment_type_name !== b.equipment_type_name) {
      return a.equipment_type_name.localeCompare(b.equipment_type_name);
    } else {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }
  })

  const onMouseEnter = (equipmentId: number) => {
    updateFloor({
      hoverEquipment: equipmentId
    });
  }

  const onMouseLeave = () => {
    updateFloor({
      hoverEquipment: null
    });
  }

  return (
    <GroupSelectorContainer>
      { equipment.length === 0 && 
        <>No Equipment on this Floor</>
      }

      { equipment.map((item: IEquipment, i: number, arr: IEquipment[]) => {
        const farText = generateFarText ? generateFarText(item) : '';

        let containerStyle: React.CSSProperties = {};

        if (i < arr.length - 1 && item.equipment_type_name !== arr[i+1].equipment_type_name) {
          containerStyle.borderBottomWidth = '4px';
        }

        return (
          <CheckNameDelete
            key={item.id}
            nameInputRef={nameInputRef}
            checked={visibleEquipment.has(item.id)}
            checkboxColor={equipmentColors[item.equipment_type_name]}
            onChangeChecked={hideCheckbox ? undefined : (checked: boolean) => onToggleEquipmentChecked(item.id, checked)}
            onMouseEnter={() => onMouseEnter(item.id)}
            onMouseLeave={onMouseLeave}
            name={item.name}
            farText={farText}
            containerStyle={containerStyle}
          />
        )
      })}
    </GroupSelectorContainer>
  )
}