import { ForgeViewer } from '../../../../../../third_party/ForgeViewer';
import * as THREE from 'three';
import { useBuildingContext } from '../../../../../../../contexts/buildingContext';
import { useImageViewerContext } from '../../../../imageViewerContext';
import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { LoadingIndicator } from '../../../../../../common/LoadingIndicator';

const CAMERA_HEIGHT = 4;

interface Transformation {
  linear_transformation: [number, number][];
  translation: [number, number];
  scaling: [number, number];
  rotation: number;
}


interface FloorTranslation {
  height: number;
  primary_to_secondary: string;
  secondary_to_primary: string;
}

export const XRayView = ({ masterPannellumRef, viewerPosition, onUpdate }: any) => {
  const { state: buildingState } = useBuildingContext();
  const { state: imageViewerContext } = useImageViewerContext();
  const [position, setPosition] = useState<THREE.Vector3>();
  const [loading, setLoading] = useState<boolean>(true);
  const [rotation, setRotation] = useState(0);

  const getPosition = () => {
    const forgeJSON: Record<string, FloorTranslation> = buildingState.projectData.forge_translations_json;
    const floorTransformation = forgeJSON[buildingState.floorData.floor_code];
    const transformations: Transformation = JSON.parse(floorTransformation.primary_to_secondary);
    const height = floorTransformation.height;

    let { rx: x, ry: y } = rotatePoint(
      parseInt(buildingState.pointData.x),
      parseInt(buildingState.pointData.y),
      transformations.rotation
    );
    setRotation(transformations.rotation || 0);
    
    const [[a,b],[c,d]] = transformations.linear_transformation;

    const forgeX = a*x + b*y + transformations.translation[0];
    const forgeY = c*x + d*y + transformations.translation[1];

    return new THREE.Vector3(forgeX, forgeY, height + CAMERA_HEIGHT);
  };

  useEffect(() => {
    setPosition(getPosition());
    mixpanel.track('Show 3D Viewer');
  }, []);

  if (!position) return <></>;

  return (
    <>
      {loading && (
        <div style={{ background: '#f8f8f8', height: '100%', width: '100%' }}>
          <LoadingIndicator />
        </div>
      )}
      <ForgeViewer
        position={position}
        fileUrn={buildingState.projectData.forge_model_urn}
        sync={imageViewerContext.sync}
        initialRotation={rotation}
        masterPannellumRef={masterPannellumRef}
        onUpdate={onUpdate}
        setLoading={setLoading}
        projectId={buildingState.projectId}
      />
    </>
  );
};

const rotatePoint = (x: number, y: number, rotation: number) => {
  let rx = x;
  let ry = y;
  switch (rotation) {
    case 90:
      rx = y;
      ry = -1 * x;
      break;
    case 180:
      rx = x * -1;
      ry = y * -1;
      break;
    case 270:
      rx = -1 * y;
      ry = x;
      break;
    default:
      break;
  }
  return { rx, ry };
};
