import { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import { PATH_STRINGS } from "../../../../hooks/useGeneratedPaths";
import { useNavigation } from "../../../../hooks/useNavigation";
import { IViewSelectorOption, OptionPicker } from "../../../common/ViewSelector/components/OptionPicker";
import { ViewSelector } from "../../../common/ViewSelector/ViewSelector";
import { INoGoZone } from "./ManageNoGoZones/ManageNoGoZones";

interface IFloorSelectorProps {
  hoverFloor: string | null;
  setHoverFloor: (newFloor: string | null) => void;
}

export const FloorSelector = ({
  hoverFloor,
  setHoverFloor,
}: IFloorSelectorProps) => {
  const {
    updateFloor,
    state: buildingState,
  } = useBuildingContext();

  const { 
    navigateToManagePointsFloor,
    navigateToManageNoGoZonesFloor,
    navigateToManageSchedulesFloor,
    navigateToManageEquipmentFloor,
   } = useNavigation();

  const matchesManagePoints: boolean = !!useRouteMatch(PATH_STRINGS.adminManagePoints);
  const matchesManagePointsFloor: boolean = !!useRouteMatch(PATH_STRINGS.adminManagePointsFloor);
  const matchesManageNoGoZones: boolean = !!useRouteMatch(PATH_STRINGS.adminManageNoGoZones);
  const matchesManageNoGoZonesFloor: boolean = !!useRouteMatch(PATH_STRINGS.adminManageNoGoZonesFloor);
  const matchesManageSchedules: boolean = !!useRouteMatch(PATH_STRINGS.adminSchedules);
  const matchesManageEquipment: boolean = !!useRouteMatch(PATH_STRINGS.adminManageEquipment);
  const matchesManageEquipmentFloor: boolean = !!useRouteMatch(PATH_STRINGS.adminManageEquipmentFloor);

  const [floorExpanded, setFloorExpanded] = useState<boolean>(true);
  const [viewSelectorFloorOptions, setViewSelectorFloorOptions] = useState<IViewSelectorOption[]>([]);
  
  const onSelectNoGoZoneFloor = (selectedChild: IViewSelectorOption) => {
    navigateToManageNoGoZonesFloor(selectedChild.Id);
  }

  //On floor change, unselect all points except for manage schedules where points can be selected on multiple floors
  useEffect(() => {
    if (!matchesManageSchedules) {
      updateFloor({
        selectedPoints: new Set(),
        selectedGroups: new Set(),
      });
    }
  }, [updateFloor, matchesManageSchedules, buildingState.floorId]);

  useEffect(() => {
    const generateDisclaimer = (floor: { name: string; floor_code: string, no_go_zones: INoGoZone[]}) => {
      if (matchesManageNoGoZones || matchesManageNoGoZonesFloor) {
        const numZones = floor.no_go_zones.length;
  
        if (numZones && numZones > 0) {
          return `${numZones} Zone${numZones !== 1 ? 's' : ''}`;
        }
      } else if (matchesManageSchedules) {
        const floorSet = buildingState.scheduleMappings.get(floor.floor_code);
        const numFloorPoints = !!floorSet ? floorSet.size : 0;
  
        if (numFloorPoints > 0) {
          return `${floorSet.size} Point${numFloorPoints !== 1 ? 's' : ''}`;
        }
      }
    }

    let floorOptions = [];
    
    if (buildingState.projectData.floors) {
      floorOptions = buildingState.projectData.floors
      .map((floor: { name: string; floor_code: string, no_go_zones: INoGoZone[] }) => ({
        Id: floor.floor_code,
        Content: floor.name,
        Options: [],
        Disclaimer: generateDisclaimer(floor)
      }))
      .reverse();
    }

    setViewSelectorFloorOptions(floorOptions);
  }, [buildingState.projectData.floors, matchesManageNoGoZones, matchesManageNoGoZonesFloor, buildingState.scheduleMappings, matchesManageSchedules]);

  const currentSelectedFloor = viewSelectorFloorOptions.filter(
    option => option.Id === buildingState.floorId
  )[0];

  const currentHoverFloor = viewSelectorFloorOptions.filter(
    option => option.Id === hoverFloor
  )[0];

  const onHoverFloor = (hoverOption: IViewSelectorOption | null) => {
    setHoverFloor(hoverOption ? hoverOption.Id : null);
  }

  let onSelectChild = (selectedChild: IViewSelectorOption) => {};

  if (matchesManagePoints || matchesManagePointsFloor) {
    onSelectChild = (selectedChild: IViewSelectorOption) => navigateToManagePointsFloor(selectedChild.Id);
  } else if (matchesManageNoGoZones || matchesManageNoGoZonesFloor) {
    onSelectChild = onSelectNoGoZoneFloor;
  } else if (matchesManageSchedules) {
    onSelectChild = (selectedChild: IViewSelectorOption) => navigateToManageSchedulesFloor(selectedChild.Id); 
  } else if (matchesManageEquipment || matchesManageEquipmentFloor) {
    onSelectChild = (selectedChild: IViewSelectorOption) => navigateToManageEquipmentFloor(selectedChild.Id);
  }

  const viewingItems = { 
    Id: 'root',
    Content: 'Floor',
    Options: viewSelectorFloorOptions,
    OnSelectChild: onSelectChild,
    OnHoverChild: onHoverFloor
  }

  const onClickSelectItem = (selectedOption: IViewSelectorOption) => {
    if (viewingItems.OnSelectChild) {
      viewingItems.OnSelectChild(selectedOption);
    }
  };

  const onHover = (hoverOption: IViewSelectorOption | null) => {
    if (viewingItems.OnHoverChild) {
      viewingItems.OnHoverChild(hoverOption)
    }
  }

  return (
    <div style={{ position: 'absolute', zIndex: buildingState.fullscreen ? 1 : 10 }}>
      <Switch>
        <Route path={[PATH_STRINGS.adminManagePoints, PATH_STRINGS.adminManagePointsFloor, PATH_STRINGS.adminManageNoGoZones, PATH_STRINGS.adminManageNoGoZonesFloor, PATH_STRINGS.adminSchedules, PATH_STRINGS.adminManageEquipment, PATH_STRINGS.adminManageEquipmentFloor]}>
          <ViewSelector
            title="View Selector"
            initialViewSelectorExpanded
            viewingExpanded={floorExpanded}
            setViewingExpanded={setFloorExpanded}
            maxHeight="calc(100vh - 155px)"
          >
            <OptionPicker
              expanded={floorExpanded}
              setExpanded={setFloorExpanded}
              viewingItems={viewingItems}
              selectedItem={currentSelectedFloor}
              hoverItem={currentHoverFloor}
              onSelectOption={onClickSelectItem}
              onHover={onHover}
            />
          </ViewSelector>
        </Route>
      </Switch>
    </div>
  )
}