import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { GroupSelectorContainer } from "../../../../common/ViewSelector/components/PointGroupSelector/PointGroupSelector";
import { INoGoZone } from "./ManageNoGoZones";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { CheckNameDelete } from "../../../../common/ViewSelector/components/PointGroupSelector/CheckNameDelete";
import { useNoGoZoneContext } from "./NoGoZoneContext";
import { gray, pink } from "../../../../../styles/colors";
import { deleteNoGoZone, updateNoGoZoneName } from "../../../../../api/adminBuildingFetches";
import { ConfirmationModal } from "../../../../common/Confirmation/Confirmation";
import { useRef, useState } from "react";
import { fetchFloors } from "../../../../../api/buildingFetches";

export const ZoneSelector = () => {
  const {
    updateBuilding,
    updateFloor,
    state: buildingState,
  } = useBuildingContext();

  const {
    setHoverNoGoZone,
    canEditZones,
    canDeleteZones,
  } = useNoGoZoneContext();

  const { addNotification } = useNotifications();

  const { noGoZones, visibleNoGoZones } = buildingState.floorData;

  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [zoneToDeleteId, setZoneToDeleteId] = useState<number | null>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  
  const onToggleCheckbox = (zone: INoGoZone, checked: boolean) => {
    const selectedZoneId: number = zone.id;
    const updatedVisibleNoZones = new Set(visibleNoGoZones);

    if (checked) {
      updatedVisibleNoZones.add(selectedZoneId);
    } else {
      updatedVisibleNoZones.delete(selectedZoneId);
    }

    updateFloor({
      visibleNoGoZones: updatedVisibleNoZones
    });
  }

  const onClickDeleteZone = async () => {
    const updatedVisibleNoZones = new Set(visibleNoGoZones);
    const updatedNoGoZones = new Map(noGoZones);

    if (zoneToDeleteId !== null) {
      deleteNoGoZone(buildingState.projectId, buildingState.floorId, zoneToDeleteId).then(async (deletedZone: INoGoZone) => {
        const updatedFloors = await fetchFloors(buildingState.projectId);

        updatedVisibleNoZones.delete(zoneToDeleteId);
        updatedNoGoZones.delete(zoneToDeleteId);
    
        updateFloor({
          noGoZones: updatedNoGoZones,
          visibleNoGoZones: updatedVisibleNoZones
        });

        updateBuilding({ 
          projectData: {
            ...buildingState.projectData,
            floors: updatedFloors,
          }
        });
    
        addNotification('Zone Deleted Successfully', 'success');
        setZoneToDeleteId(null);
      }).catch((err) => {
        console.log('deleteNoGoZone==>>', err);
        addNotification('Error Deleting Zone', 'error');
      });
    }
  }

  const onEnterZoneSelectorContainer = (zoneId: number) => {
    setHoverNoGoZone(zoneId);
  }

  const onLeaveZoneSelectorContainer = () => {
    setHoverNoGoZone(null);
  }

  const displayConfirmation = (zoneId: number) => {
    setConfirmation(true);
    setZoneToDeleteId(zoneId);
  }

  const onChangeZoneName = async (zoneId: number, newName: string) => {
    const updatedNoGoZones = new Map(noGoZones);

    try {
      const updatedZone: INoGoZone = await updateNoGoZoneName(buildingState.projectId, buildingState.floorId, zoneId, newName);
      updatedNoGoZones.set(zoneId, updatedZone);

      updateFloor({
        noGoZones: updatedNoGoZones,
      });

      nameInputRef.current?.blur();

      addNotification('Zone renamed successfully', 'success');
    } catch (err) {
      console.log('onChangeZoneName==>>', err);

      addNotification('Error changing zone name', 'error');
    }
  }

  return (
    <>
      <GroupSelectorContainer>
        <div>
          { noGoZones.size === 0 &&
            <>No Zones on this Floor</>
          }

          { Array.from(noGoZones as Map<number, INoGoZone>).map(([zoneId, zone]) => {
            return (
              <CheckNameDelete
                key={zone.id}
                checked={visibleNoGoZones.has(zone.id)}
                checkboxColor={zone.is_active ? pink : gray}
                onChangeChecked={(checked: boolean) => onToggleCheckbox(zone, checked)}
                onMouseEnter={() => onEnterZoneSelectorContainer(zone.id)}
                onMouseLeave={onLeaveZoneSelectorContainer}
                name={zone.name}
                onChangeName={canEditZones ? (newName: string) => onChangeZoneName(zone.id, newName) : undefined}
                nameInputRef={nameInputRef}
                onClickDelete={canDeleteZones ? () => displayConfirmation(zone.id) : undefined}
              />
            )
          })}
        </div> 
      </GroupSelectorContainer>
      <ConfirmationModal
        isOpen={confirmation}
        setIsOpen={setConfirmation}
        message='Are you sure you would like to delete this zone?'
        onConfirm={onClickDeleteZone}
      />
    </>
  )
}