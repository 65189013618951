import React from 'react';
import styled from 'styled-components';

import { Icon } from '../../../common/Icon';

import iconCalendar from '../../../../assets/images/icon_calendar.svg';
import iconExpand from '../../../../assets/images/icon_expand.svg';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { LoadingIndicator } from '../../../common/LoadingIndicator';

const PreviewPopupBase = styled.div`
  right: 0;
  top: 24px;
  position: absolute;

  width: 400px;
  filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
`;

const PreviewPopupHeader = styled.div`
  height: 40px;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  font-size: 16px;

  text-align: right;

  color: #7e858e;

  padding: 0 16px;
  border-radius: 2px 2px 0 0;
`;

const imageStyles = `
  height: 220px;
  max-width: 100%;
  border-radius: 0 0 2px 2px;
  background-color: white;
`

const PreviewPopupImage = styled.img`
  ${imageStyles}
  object-fit: cover;
  display: block;
`;

const EmptyPreviewPopupImage = styled.div`
  ${imageStyles}
  display: flex;
  justify-content: center;
  align-items: center;
`

const PreviewPopupIconButton = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;

  background-color: white;
  padding: 8px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const PreviewPopup = (props: {
  onFullScreenClick: () => void;
  imageLoading: boolean;
}) => {
  const {
    state: buildingState
  } = useBuildingContext();

  let takenOn;
  let imageUrl;
  let day;
  let months;
  let month;
  let year;

  if (!props.imageLoading && buildingState.imageData && buildingState.imageData.data) {
    takenOn = buildingState.imageData.data.taken_on
    imageUrl = buildingState.imageData.image
  
    day = new Date(takenOn).getDate();
    months = new Date(takenOn);
    month = monthNames[months.getMonth()];
    year = new Date(takenOn).getFullYear();
  }

  return (
    <PreviewPopupBase>
      <PreviewPopupHeader>
        <Icon icon={iconCalendar} size={14} style={{ marginRight: '9px' }} />
        { (month && day && year) && (
          <span>
            {month} {day}, {year}
          </span>
        )}
      </PreviewPopupHeader>
      {!props.imageLoading && 
        <>
          {imageUrl ? (
            <PreviewPopupImage src={imageUrl} />
          ) : (
            <EmptyPreviewPopupImage>
              No Image
            </EmptyPreviewPopupImage>
          )}
        </>
      }
      {props.imageLoading &&
        <EmptyPreviewPopupImage>
          <LoadingIndicator/>
        </EmptyPreviewPopupImage>
      }
      <PreviewPopupIconButton onClick={props.onFullScreenClick}>
        <img src={iconExpand} alt="" />
      </PreviewPopupIconButton>
    </PreviewPopupBase>
  );
};
