import { useMemo, useState } from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { JobType } from "../../../../../api/jobs";
import { CustomTooltip, CustomTooltipLabel, CustomTooltipValue } from "./CustomTooltip";
import { NoChartData, ProgressSummaryEntry, xAxisTickFormatter } from "./ProgressCharts";

interface IRenderCustomTooltipProps {
  active?: boolean;
  payload?: any[];
  activeBarKey: string | null;
  activeTrackerJobTypes: JobType[];
}

const RenderCustomTooltip = ({
  active,
  payload,
  activeBarKey,
  activeTrackerJobTypes,
}: IRenderCustomTooltipProps) => {
  const formatCompletedUnits = (completedUnits: number) => {
    const roundedCompletedUnits = Math.round(completedUnits);

    return `+${roundedCompletedUnits.toLocaleString()}%`;
  }

  if (active && payload && payload.length && !!activeBarKey) {
    const activeItem = payload.find(entry => entry.dataKey === activeBarKey);
    const activeItemJobType = activeTrackerJobTypes.find(type => type.slug === activeBarKey);

    return (
      <CustomTooltip>
        <CustomTooltipLabel>{activeItemJobType?.name}</CustomTooltipLabel>
        <CustomTooltipValue
          style={{
            color: activeItem.fill,
          }}
        >
          {formatCompletedUnits(activeItem.value)}
        </CustomTooltipValue>
      </CustomTooltip>
    );
  }

  return <></>;
};

interface IProgressBarChartProps {
  progressSummaryByDate: ProgressSummaryEntry[];
  activeTrackerJobTypes: JobType[];
}

export const ProgressBarChart = ({
  progressSummaryByDate,
  activeTrackerJobTypes,
}: IProgressBarChartProps) => {

  const [activeBarKey, setActiveBarKey] = useState<string | null>(null);

  const data = useMemo(() => {
    const workCompleted: any[] = [];

    progressSummaryByDate.forEach((entry, i, arr) => {
      if (i > 0) {
        const workCompletedEntry: any = {};
        const prevEntry = arr[i-1];
        let greaterThanZeroFound = false;

        workCompletedEntry.date = entry.date;

        Object.getOwnPropertyNames(entry).forEach(slug => {
          if (slug !== "date") {
            const currentProgress = Math.round(entry[slug].completed_units / entry[slug].total_units * 100);
            const prevProgress = Math.round(prevEntry[slug].completed_units / prevEntry[slug].total_units * 100);

            workCompletedEntry[slug] = currentProgress - prevProgress;
            greaterThanZeroFound = greaterThanZeroFound || workCompletedEntry[slug] > 0;
          }
        });

        if (greaterThanZeroFound) {
          workCompleted.push(workCompletedEntry);
        }
      }
    }, []);

    return workCompleted;
  }, [progressSummaryByDate]);

  if (data.length === 0) {
    return (
      <NoChartData/>
    )
  }

  return (
    <ResponsiveContainer width="99%" height="99%">
      <BarChart
        data={data}
      >
        <YAxis/>
        <XAxis
          dataKey="date"
          tickFormatter={xAxisTickFormatter}
        />
        <Tooltip
          cursor={{fill: 'transparent'}}
          content={props => (
            <RenderCustomTooltip
              {...props}
              activeBarKey={activeBarKey}
              activeTrackerJobTypes={activeTrackerJobTypes}
            />
          )}
        />
        {activeTrackerJobTypes.map(type => {
          return (
            <Bar
              key={type.slug}
              dataKey={type.slug}
              fill={type.display_color}
              onMouseEnter={() => setActiveBarKey(type.slug)}
              onMouseLeave={() => setActiveBarKey(null)}
            />
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}