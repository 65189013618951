import { useEffect, useRef } from 'react';
import ReactNotificationSystem from 'react-notification-system';
import { INotification, useNotifications } from './notificationProvider';

const style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      padding: '20px',
      fontSize: '1em',
    },
  },
};

export const NotificationSystem = () => {
  const ref = useRef<any>(null);
  const { notifications, setNotifications } = useNotifications();

  useEffect(() => {
    notifications.forEach(newNotification => {
      const onRemoveNotification = () => {
        setNotifications((prevNotifications: INotification[]) => {
          return prevNotifications.filter(oldNotification => oldNotification.uid !== newNotification.uid)
        })
      }
  
      if (ref.current && !newNotification.sent) {
        ref.current.addNotification({
          uid: newNotification.uid,
          message: newNotification.message,
          level: newNotification.level,
          position: 'br',
          onRemove: onRemoveNotification
        });

        newNotification.sent = true;
      }
    });
  }, [notifications, setNotifications]);

  return (
    <ReactNotificationSystem 
      ref={ref}
      style={style} 
    />
  )
}