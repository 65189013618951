import { Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { SafetyGraphTooltip } from "../SafetyGraphTooltip";

interface SafetyMetricGraphPoint {
  date: Date;
  value: number;
  time: number;
}

interface SafetyMetricTileGraphProps {
  displayColor: string;
  data: SafetyMetricGraphPoint[];
  height?: string | number;
  tooltipProps?: Partial<TooltipProps<any, any>>;
}

export const SafetyMetricTileGraph = ({
  displayColor,
  data,
  height="99%",
  tooltipProps,
}: SafetyMetricTileGraphProps) => {
  return (
    <ResponsiveContainer
        width='99%'
        height={height}
    >
      <LineChart
        data={data}
        margin={{
          top: 2,
          right: 25,
          bottom: 2,
          left: 25
        }}
      >
        <YAxis
          domain={['auto', 'auto']}
          hide
        />
        <XAxis
          hide
          dataKey = 'time'
          domain = {['dataMin', 'dataMax']}
          name = 'Time'
          type = 'number'
          interval="preserveStartEnd"
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          content={props => (
            <SafetyGraphTooltip
              {...props}
            />
          )}
          {...tooltipProps}
        />
        <Line
          dataKey={"value"}
          stroke={displayColor}
          strokeWidth={4}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}