import { useMemo } from "react";
import { Table } from "../../common/Table/Table";
import { SiteWalkUploadData } from "./SiteWalkVideoUpload";
import { Column } from "react-table";
import { ProgressCell } from "../progress/ProgressOverview/ProgressCell/ProgressCell";
import styled from "styled-components";
import { LoadingIndicator } from "../../common/LoadingIndicator";

interface ISiteWalkVideoUploadTableProps {
  walks: SiteWalkUploadData[];
}

export const SiteWalkVideoUploadTable = ({
  walks
}: ISiteWalkVideoUploadTableProps) => {
  const data = useMemo(() => {
    return walks;
  }, [walks]);
 
  const formatWalkDate = (walkDate?: string) => {
    if (walkDate) {
      const walkDateObject = new Date(walkDate);
      const dateString = walkDateObject.toLocaleDateString();
      const timeString = walkDateObject.toLocaleTimeString().replace(/:\d\d\s/, ' ');
      
      return dateString + ' ' + timeString;
    }

    return '';
  }

  const formatWalker = (user?: {first_name: string, last_name: string}) => {
    if (user && user.first_name && user.last_name) {
      return user.first_name + ' ' + user.last_name
    }

    return '';
  }

  const formatWalkProgress = (walk: SiteWalkUploadData) => {
    const progress = Math.floor(walk.progress * 100);

    return (
      <div style={{width: '300px'}}>
        {!walk.initiated &&
          <>Pending...</>
        }

        {walk.initiated &&
          <>
            {walk.error &&
              <>Error Uploading Video</>
            }

            {!walk.error &&
              <ProgressContainer>
                <div style={{flex: 1}}>
                  <ProgressCell
                    total={100}
                    progress={progress}
                    showRate={false}
                    rate={0}
                    units={() => ''}
                  />
                </div>
                {progress < 100 &&
                  <div style={{flexBasis: '20px', marginTop: '3px'}}>
                    <LoadingIndicator fb />
                  </div>
                }
              </ProgressContainer>
            }
          </>
        }
      </div>
    )
  }

  const columns = useMemo((): Column<SiteWalkUploadData>[] => [
    { Header: 'File Name', accessor: walk => walk.videoFile.name },
    { Header: 'Site', accessor: walk => walk.siteWalk?.project.name },
    { Header: 'Floor', accessor: walk => walk.siteWalk?.project_floor?.floor_code },
    { Header: 'Point Group', accessor: walk => walk.siteWalk?.group_name },
    { Header: 'Walk Date', accessor: walk => formatWalkDate(walk.siteWalk?.taken_on) },
    { Header: 'Walker', accessor: walk => formatWalker(walk.siteWalk?.user) },
    { Header: 'Progress', accessor: walk => formatWalkProgress(walk)},
  ], []);

  return (
    <SiteWalkVideoUploadTableContainer>
      <Table
        data={data}
        columns={columns}
        headerStyles={{
          padding: '5px 40px'
        }}
      />
    </SiteWalkVideoUploadTableContainer>
  )
}

const SiteWalkVideoUploadTableContainer = styled.div`
  position: relative;
  overflow: auto;
  white-space: nowrap;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;