import React, { createContext, useContext, useState } from 'react';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { useUserContext } from '../../../../../contexts/userContext';
import { checkUserPermissions } from '../../../../common/PermissionWrapper/PermissionWrapper';

const NoGoZoneContext = createContext<any>(null);

export const NoGoZoneProvider = ({ children }: any) => {
  const { state: userState } = useUserContext();
  const { state: buildingState } = useBuildingContext();

  const [svgIsDraggingDown, setSVGIsDraggingDown] = useState<number | null>(null);
  const [svgDraggingOccurred, setSVGDraggingOccurred] = useState<boolean>(false);
  const [currentDraggingPointId, setCurrentDraggingPointId] = useState<string | null>(null);
  const [pointDraggingOccurred, setPointDraggingOccurred] = useState<boolean>(false);
  const [currentDraggingZoneId, setCurrentDraggingZoneId] = useState<number | null>(null);
  const [zoneDraggingOccurred, setZoneDraggingOccurred] = useState<boolean>(false);
  const [dragStartX, setDragStartX] = useState<number>(0);
  const [dragStartY, setDragStartY] = useState<number>(0);
  const [hoverNoGoZone, setHoverNoGoZone] = useState<number | null>(null);

  const canCreateNewZones = checkUserPermissions(userState.permissions, 'project_tables.create_no_go_zone', buildingState.projectId);
  const canEditZones = checkUserPermissions(userState.permissions, 'project_tables.edit_no_go_zone', buildingState.projectId);
  const canDeleteZones = checkUserPermissions(userState.permissions, 'project_tables.delete_no_go_zone', buildingState.projectId);

  const value = {
    svgIsDraggingDown,
    setSVGIsDraggingDown,
    svgDraggingOccurred,
    setSVGDraggingOccurred,
    currentDraggingPointId,
    setCurrentDraggingPointId,
    pointDraggingOccurred,
    setPointDraggingOccurred,
    currentDraggingZoneId,
    setCurrentDraggingZoneId,
    zoneDraggingOccurred,
    setZoneDraggingOccurred,
    dragStartX,
    setDragStartX,
    dragStartY,
    setDragStartY,
    hoverNoGoZone,
    setHoverNoGoZone,
    canCreateNewZones,
    canEditZones,
    canDeleteZones,
  }

  return <NoGoZoneContext.Provider value={value}>{children}</NoGoZoneContext.Provider>;
};

export const useNoGoZoneContext = () => {
  return useContext(NoGoZoneContext);
};
