import { useTagContext } from "../../../contexts/tagContext";
import { NewRFIForm } from "./components/NewRFIForm";
import { Pannellum } from '../../third_party/Pannellum';
import { useCallback, useEffect, useState } from "react";
import { LinkExistingRFIForm } from "./components/LinkExistingRFIForm";
import { SaveImageForm } from "./components/SaveImageForm";
import { ProcoreManagerContainer } from "./components/styles";
import { CreatedRFI } from "./components/CreatedRFI";
import { useImageViewerContext } from "../../views/image_viewer/imageViewerContext";

interface IProcoreManagerProps {
  pannellumRef: React.RefObject<Pannellum | null>;
  hydrate: (tagsToUpdate?: number[]) => Promise<any>;
  mapPopupRef: React.RefObject<HTMLDivElement>;
}

export const ProcoreManager = (props: IProcoreManagerProps) => {
  const pnlm = props.pannellumRef.current;

  const [transform, setTransform] = useState<string>('');

  const { state: tagState, toggleDisplay } = useTagContext();
  const { state: ImageViewerState } = useImageViewerContext();
  const currentTag = tagState.tags.current;

  const renderDialogue = useCallback(() => {
    let dom = document.getElementById('rfi-container');
    if (dom && pnlm && currentTag) {
      let ctya = currentTag.yaw - (currentTag.rfi ? ImageViewerState.master.data.angle : 0),
        canvasPitch = pnlm.getPitch(),
        canvasYaw = pnlm.getYaw(),
        canvasHfov = pnlm.getHfov(),
        hsPitchSin = Math.sin((currentTag.pitch * Math.PI) / 180),
        hsPitchCos = Math.cos((currentTag.pitch * Math.PI) / 180),
        configPitchSin = Math.sin((canvasPitch * Math.PI) / 180),
        configPitchCos = Math.cos((canvasPitch * Math.PI) / 180),
        yawCos = Math.cos(((-ctya + canvasYaw) * Math.PI) / 180);
      var z = hsPitchSin * configPitchSin + hsPitchCos * yawCos * configPitchCos;
      if ((ctya <= 90 && ctya > -90 && z <= 0) || ((ctya > 90 || ctya <= -90) && z <= 0)) {
        console.log('no visible');
      } else {
        var yawSin = Math.sin(((-ctya + canvasYaw) * Math.PI) / 180),
          hfovTan = Math.tan((canvasHfov * Math.PI) / 360);
        // Subpixel rendering doesn't work in Firefox
        // https://bugzilla.mozilla.org/show_bug.cgi?id=739176
        var canvas = pnlm.getCanvas(),
          canvasWidth = canvas.clientWidth,
          canvasHeight = canvas.clientHeight;
        var coord = [
          ((-canvasWidth / hfovTan) * yawSin * hsPitchCos) / z / 2,
          ((-canvasWidth / hfovTan) *
            (hsPitchSin * configPitchCos - hsPitchCos * yawCos * configPitchSin)) /
            z /
            2,
        ];
        // Apply transform
        coord[0] += (canvasWidth - dom.offsetWidth + 410) / 2;
        coord[1] += (canvasHeight - dom.offsetHeight - 165) / 2;

        const margin = 25;
        const translateX = Math.min(Math.max(coord[0], margin), canvasWidth - dom.offsetWidth - margin);
        const translateY = Math.min(Math.max(coord[1], margin), canvasHeight - dom.offsetHeight - margin);
        
        const t = `translate(${translateX}px, ${translateY}px) translateZ(9999px)`;
        setTransform(t);
      }
    }
  }, [currentTag, pnlm, ImageViewerState.master.data.angle]);

  useEffect(() => {
    renderDialogue();
  }, [props, renderDialogue]);

  const onCloseForm = () => {
    toggleDisplay();

    if (pnlm) {
      pnlm.removeHotspot(currentTag.id);
    }
  }

  return (
    <>
      {tagState.displayDialogue && 
        <ProcoreManagerContainer
          id='rfi-container'
          className="oco-ignore oco-dialogue"
          style={{transform: transform}}
        >
          {currentTag.type === 'PROCORE-NEW-RFI' && (
            <NewRFIForm
              onCloseForm={onCloseForm}
              hydrate={props.hydrate}
            />
          )}
          {currentTag.type === 'PROCORE-LINK-RFI' && (
            <LinkExistingRFIForm
              onCloseForm={onCloseForm}
              hydrate={props.hydrate}
            />
          )}
          {currentTag.type === 'PROCORE-SAVE-IMAGE' && (
            <SaveImageForm
              onCloseForm={onCloseForm}
              pannellumRef={props.pannellumRef}
              mapPopupRef={props.mapPopupRef}
            />
          )}
          {currentTag.type === 'RFI' && (
            <CreatedRFI
              hydrate={props.hydrate}
            />
          )}
        </ProcoreManagerContainer>
      }
    </>
  );
}