import React, { CSSProperties, useState } from "react";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { IGroup, PointGroupSelector } from "../../../../common/ViewSelector/components/PointGroupSelector/PointGroupSelector";
import { ViewSelector } from "../../../../common/ViewSelector/ViewSelector";
import { FloorPlanView } from "../../../building_page/components/FloorPlanView";
import { IMapPoint } from "../ManagePoints/ManagedMapPoint";
import { ScheduleControls } from "./ScheduleControls/ScheduleControls";
import styled from "styled-components";
import { ConvexHullGrahamScan } from "./ScheduleControls/graham_scan";
import { createSchedulePointMapping, deleteSchedulePointMapping } from "../../../../../api/adminBuildingFetches";
import { LoadingIndicator } from "../../../../common/LoadingIndicator";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { SchedulePoint } from "./SchedulePoint";
import { getNextScan } from "./ScheduleHelperFunctions";
import { ConfirmationModal } from "../../../../common/Confirmation/Confirmation";
import { useManageSchedulesContext } from "./ManageSchedulesContext";

export const ScheduleFloorPlanView = () => {
  const {
    updateBuilding,
    updateFloor,
    state: buildingState
  } = useBuildingContext();

  const {
    canCreateSchedulePointMapping,
    canDeleteSchedulePointMapping,
  } = useManageSchedulesContext();

  const { scheduleData } = buildingState;
  const { start_date, till_date, time, frequency } = scheduleData;
  const { lessThan24HoursAway } = getNextScan(frequency, start_date, till_date, time);

  const { addNotification } = useNotifications();

  const isNewSchedule = buildingState.scheduleId === 'new';
  
  const [pointControlsExpanded, setPointControlsExpanded] = useState<boolean>(true);
  const [scheduleSaving, setScheduleSaving] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const zoomIconStyle: CSSProperties = { position: 'absolute', right: '0px', zIndex: 2};
  const zoomInIconStyle: CSSProperties = {...zoomIconStyle, bottom: '55px'};
  const zoomOutIconStyle: CSSProperties = {...zoomIconStyle, bottom: '10px'};

  const performMapPointStateUpdates = (point: IMapPoint, addPoint: boolean) => {
    if ((addPoint && !canCreateSchedulePointMapping) || (!addPoint && !canDeleteSchedulePointMapping)) {
      return;
    }

    const selectedPoints = buildingState.floorData.selectedPoints
    const scheduleMappings = new Map<string, Set<number>>(buildingState.scheduleMappings);
    const convexHullCalculatorMappings = new Map<string, ConvexHullGrahamScan>(buildingState.convexHullCalculatorMappings);
    const floorSet = scheduleMappings.get(buildingState.floorId);
    const floorConvexHullCalculator = convexHullCalculatorMappings.get(buildingState.floorId);
    
    if (addPoint) {
      selectedPoints.add(point.point_id);
      floorSet?.add(point.point_id);
      floorConvexHullCalculator?.addPoint(point.point_id, point.x, point.y);
    } else {
      selectedPoints.delete(point.point_id);
      floorSet?.delete(point.point_id);
      floorConvexHullCalculator?.removePoint(point.point_id);
    }

    updateBuilding({
      scheduleMappings,
      convexHullCalculatorMappings,
    });

    updateFloor({
      selectedPoints: new Set(selectedPoints),
    });
  }

  const onAddPoint = async (point: IMapPoint, addPoint: boolean) => {
    if (!canCreateSchedulePointMapping) {
      return;
    }

    try {
      const newMapping = await createSchedulePointMapping(buildingState.projectId, buildingState.scheduleId, point.point_id);
      performMapPointStateUpdates(newMapping, addPoint);
    } catch (err) {
      console.log('createSchedulePointMapping==>>', err);
      addNotification('Error adding point to schedule', 'error');
    }
  }

  const onDeletePoint = async (point: IMapPoint, addPoint: boolean) => {
    if (!canDeleteSchedulePointMapping) {
      return;
    }

    try {
      const deletedMapping = await deleteSchedulePointMapping(buildingState.projectId, buildingState.scheduleId, point.point_id);
      performMapPointStateUpdates(deletedMapping, addPoint);
    } catch (err) {
      console.log('deleteSchedulePointMapping==>>', err);
      addNotification('Error removing point from schedule', 'error');
    }
  }

  const onClickMapPoint = async (point: IMapPoint, addPoint: boolean) => {
    if (!lessThan24HoursAway) {
      if (!isNewSchedule) {
        if (addPoint) {
          onAddPoint(point, addPoint);
        } else {
          onDeletePoint(point, addPoint);
        }
      } else {
        performMapPointStateUpdates(point, addPoint);
      }
    } else {
      setConfirmation(true);
    }
  }

  const points = buildingState.floorData.points.map((point: any) => (
    <SchedulePoint
      key={point.point_id}
      point={point}
      x={point.x}
      y={point.y}
      onClick={() => onClickMapPoint(point, !buildingState.floorData.selectedPoints.has(point.point_id))}
      disabled={lessThan24HoursAway}
    />
  ));

  const onToggleGroupSelectorCheckbox = (group: IGroup, checked: boolean, groupPoints: number[]) => {
    groupPoints.forEach((pointId: number) => {
      const selectedPoint = buildingState.floorData.points.filter((point: any) => point.point_id === pointId)[0];
      const actionRequired = buildingState.floorData.selectedPoints.has(pointId) !== checked;

      if (selectedPoint && actionRequired) {
        onClickMapPoint(selectedPoint, checked);
      }      
    });
  }

  const disabledOnToggleCheckbox = () => {
    setConfirmation(true);
  }

  return (
    <>
      { scheduleSaving &&
        <LoadingIndicator/>
      }
      { !scheduleSaving &&
        <FloorPlanView
          floorData={buildingState.floorData}
          hideDateRangeSelector
          mapViewerChildren={points}
          zoomInIconStyle={zoomInIconStyle}
          zoomOutIconStyle={zoomOutIconStyle}
          showPoints={false}
        />
      }

      <ViewSelectorContainer>      
        <ViewSelector
          title='Schedule Controls'
          initialViewSelectorExpanded
          viewingExpanded={pointControlsExpanded}
          setViewingExpanded={setPointControlsExpanded}
          maxHeight={470}
        >
          <ScheduleControls
            setScheduleSaving={setScheduleSaving}
          />
        </ViewSelector>
        { (canCreateSchedulePointMapping || canDeleteSchedulePointMapping) &&
          <ViewSelector
            title='Groups'
            initialViewSelectorExpanded
            viewingExpanded={pointControlsExpanded}
            setViewingExpanded={setPointControlsExpanded}
            maxHeight={160}
          >
            <PointGroupSelector
              hideDelete
              hideCreateNewGroup
              parentOnToggleCheckbox={onToggleGroupSelectorCheckbox}
              disabledOnToggleCheckbox={disabledOnToggleCheckbox}
              disabled={lessThan24HoursAway}
            />
          </ViewSelector>
        }
      </ViewSelectorContainer>

      <ConfirmationModal
        isOpen={confirmation}
        setIsOpen={setConfirmation}
        message='Points cannot be edited less than 24 hours away from the next scan'
        hideCancelButton
        confirmButtonAltText="Ok"
      />
    </>
  )
}

const ViewSelectorContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;