import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { useAcquireMsalToken } from "../../../hooks/useAcquireMsalToken";
import { useUserContext } from "../../../contexts/userContext";
import { TEMP_LOG_FUNCTION } from "../../../api/auth";

export const LoginRedirect = () => {
  const history = useHistory();
  const location = useLocation();
  const { resetUser } = useUserContext();
  const acquireMsalToken = useAcquireMsalToken();

  useEffect(() => {
    if (location.hash) {
      console.log("Location hash: ", location.hash);
      TEMP_LOG_FUNCTION(`Location hash: ${location.hash}`);
    }
  }, [location.hash]);

  useEffect(() => {
    try {
      acquireMsalToken(true);
    } catch (e) {
      localStorage.clear();
      resetUser();
      history.push('/login');
    }
    
  }, [acquireMsalToken, history, resetUser]);

  return (
    <LoadingIndicator/>
  );
}