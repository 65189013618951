import { Annotation } from './components/Annotation';
import { useTagContext } from '../../../contexts/tagContext';
import { useEffect, useState } from 'react';
import { useImageViewerContext } from '../../views/image_viewer/imageViewerContext';

export const AnnotationManager = (props: { tag: any; pnlm: any; hydrate: any }) => {
  const { state: ImageViewerState } = useImageViewerContext();
  const [transform, setTransform] = useState<string>('');
  const { state: tagState } = useTagContext();
  const currentTag = useTagContext().state.tags.current;

  const renderDialogue = () => {
    let dom = document.getElementById('annotation-container');
    if (dom && props.pnlm) {
      let ctya = currentTag.yaw - (currentTag.annotation ? ImageViewerState.master.data.angle : 0),
        canvasPitch = props.pnlm.getPitch(),
        canvasYaw = props.pnlm.getYaw(),
        canvasHfov = props.pnlm.getHfov(),
        hsPitchSin = Math.sin((currentTag.pitch * Math.PI) / 180),
        hsPitchCos = Math.cos((currentTag.pitch * Math.PI) / 180),
        configPitchSin = Math.sin((canvasPitch * Math.PI) / 180),
        configPitchCos = Math.cos((canvasPitch * Math.PI) / 180),
        yawCos = Math.cos(((-ctya + canvasYaw) * Math.PI) / 180);
      var z = hsPitchSin * configPitchSin + hsPitchCos * yawCos * configPitchCos;
      if ((ctya <= 90 && ctya > -90 && z <= 0) || ((ctya > 90 || ctya <= -90) && z <= 0)) {
        console.log('no visible');
      } else {
        var yawSin = Math.sin(((-ctya + canvasYaw) * Math.PI) / 180),
          hfovTan = Math.tan((canvasHfov * Math.PI) / 360);
        // Subpixel rendering doesn't work in Firefox
        // https://bugzilla.mozilla.org/show_bug.cgi?id=739176
        var canvas = props.pnlm.getCanvas(),
          canvasWidth = canvas.clientWidth,
          canvasHeight = canvas.clientHeight;
        var coord = [
          ((-canvasWidth / hfovTan) * yawSin * hsPitchCos) / z / 2,
          ((-canvasWidth / hfovTan) *
            (hsPitchSin * configPitchCos - hsPitchCos * yawCos * configPitchSin)) /
            z /
            2,
        ];
        // Apply transform
        coord[0] += (canvasWidth - dom.offsetWidth + 275) / 2;
        coord[1] += (canvasHeight - dom.offsetHeight - 165) / 2;
        var t = 'translate(' + coord[0] + 'px, ' + coord[1] + 'px) translateZ(9999px)';
        setTransform(t);
      }
    }
  };

  useEffect(() => {
    renderDialogue();
    // eslint-disable-next-line
  }, [props, currentTag]);

  return (
    <>
      {tagState.displayDialogue && currentTag.type === 'ANNOTATION' && (
        <Annotation
          annotation={currentTag.annotation}
          transform={transform}
          hydrate={props.hydrate}
        />
      )}
    </>
  );
};
