import React from 'react';
import { Icon } from '../../../common/Icon';
import icon360Light from '../../../../assets/images/icon_360_light.svg';
import icon360Dark from '../../../../assets/images/icon_360_dark.svg';
import { useImageViewerContext } from '../imageViewerContext';
import mixpanel from 'mixpanel-browser';

export const SplitScreenGutter = () => {
  const { state: ImageViewerState, updateImageViewer } = useImageViewerContext();

  return (
    <div
      style={{
        width: '5px',
        backgroundColor: '#f8f8f8',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px',
          position: 'relative',
          backgroundColor: ImageViewerState.sync ? '#073c7a' : '#fff',
          zIndex: 100,
          borderRadius: '5px',
          cursor: 'pointer',
          boxShadow: '0px 0px 10px 0px rgba(255, 255, 255, 0.35)',
        }}
        onClick={() => {
          // if (ImageViewerState.pane2Type === 'forge') return;
          updateImageViewer({ sync: !ImageViewerState.sync });
          mixpanel.track('Toggle Split Screen Sync');
        }}>
        <Icon icon={ImageViewerState.sync ? icon360Light : icon360Dark} size={20} />
      </div>
    </div>
  );
};
