//@ts-ignore
import Modal from 'react-modal';
import { CancelButton, ConfirmationButton, ConfirmationContent, ConfirmationMessage } from './styles';
import { Flex } from '@react-css/flex';
import { useCallback, useEffect } from 'react';

const styles = {
  overlay: {
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  content: {
    position: 'absolute',
    top: '40px',
    left: '40px',
    right: '40px',
    bottom: '40px',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
    width: '300px',
    maxHeight: '130px',
    margin: 'auto',
  },
};

interface IConfirmationProps {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  message: string;
  content?: JSX.Element;
  onConfirm?: () => void;
  onCancel?: () => void;
  confirmButtonAltText?: string;
  cancelButtonAltText?: string;
  hideCancelButton?: boolean;
  styleOverrides?: {
    overlay?: React.CSSProperties;
    content?: React.CSSProperties;
    confirmationButton?: React.CSSProperties;
    cancelButton?: React.CSSProperties;
  };
  useKeyboardShortcuts?: boolean
}
export const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  message,
  content,
  onConfirm,
  onCancel,
  confirmButtonAltText="Confirm",
  cancelButtonAltText="Cancel",
  hideCancelButton,
  styleOverrides,
  useKeyboardShortcuts=false,
}: IConfirmationProps) => {
  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const confirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    closeModal();
  }, [closeModal, onConfirm]);

  const cancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
    closeModal();
  }, [closeModal, onCancel]);

  const handleKeyPress = useCallback((e: KeyboardEvent) => {
    if (useKeyboardShortcuts) {
      const key = e.key;

      if (key === "y") {
        confirm();
      } else if (key === "n") {
        cancel();
      }
    }
  }, [cancel, confirm, useKeyboardShortcuts]);

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    }
  }, [handleKeyPress, useKeyboardShortcuts]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Confirmation Modal"
      style={{
        overlay: {...styles.overlay, ...styleOverrides?.overlay},
        content: {...styles.content, ...styleOverrides?.content}
      }}>
      <ConfirmationMessage>{message}</ConfirmationMessage>
      {!!content &&
        <ConfirmationContent>{content}</ConfirmationContent>
      }
      <Flex justifyCenter>
        <ConfirmationButton
          onClick={confirm}
          style={styleOverrides?.confirmationButton}
        >
          {`${confirmButtonAltText} ${useKeyboardShortcuts ? "(Y)" : ""}`}
        </ConfirmationButton>
        { !hideCancelButton &&
          <CancelButton
            onClick={cancel}
            style={styleOverrides?.cancelButton}
          >
            {`${cancelButtonAltText} ${useKeyboardShortcuts ? "(N)" : ""}`}
          </CancelButton>
        }
      </Flex>
    </Modal>
  );
};
