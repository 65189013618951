import React, { useEffect, useState } from 'react';
import { fetchImage, fetchPointImage } from '../../../../api/buildingFetches';
import { useNavigation } from '../../../../hooks/useNavigation';
import Slider from 'rc-slider/lib/Slider';
import styled from 'styled-components';
import { useImageViewerContext } from '../imageViewerContext';
import mixpanel from 'mixpanel-browser';
import { useBuildingContext } from '../../../../contexts/buildingContext';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

let urlCreator = window.URL || window.webkitURL;

const ScrollContainer = styled.div`
  position: relative;
  width: 100%;
  background: white;
  border-radius: 2px;
  padding: 1em 2em;
  z-index: 100;
  height: 80px;
`;

const fakeDates = [
  { date: 'June 4, 2021' },
  { date: 'June 11, 2021' },
  { date: 'June 18, 2021' },
  { date: 'June 25, 2021' },
  { date: 'July 2, 2021' },
  { date: 'July 9, 2021' },
  { date: 'July 16, 2021' },
  { date: 'July 23, 2021' },
  { date: 'July 30, 2021' },
];

const TimeScrollMarker = (props: { date: Date; image?: any; neighboring?: boolean }) => {
  const getDateString = (d: Date) => {
    if (d.getDate() === 1) {
      const day = d.getDate();
      const month = d.getMonth();
      const year = d.getFullYear();
      return <span className="rc-slider-month-label">{`${monthNames[month]} ${year}`}</span>;
    } else if (d.getDate() < 3 || props.neighboring) {
      return <></>;
    }
    return d.getDate();
  };
  return <>{getDateString(props.date)}</>;
};

export const TimeScroll = (props: { point: any }) => {
  const { state: buildingState } = useBuildingContext();
  const { state: ImageViewerState, updatePane2, updateMaster } = useImageViewerContext();
  let updateMethod: (item: any) => void = (item: any) => null;
  const { navigateToPointFullscreen } = useNavigation();
  const [sliderMarks, setSliderMarks] = useState<any>();
  const [defaultPosition, setDefaultPosition] = useState<number>(0);

  const getSetCurrentImageData = async (image: any) => {
    if (ImageViewerState[ImageViewerState.focused].data.image_id === image.image_id) return;
    let oldAngle = ImageViewerState[ImageViewerState.focused].data.angle;
    let newAngle = image.angle;
    const viewpointsImage = await fetchPointImage(
      buildingState.projectId,
      buildingState.floorData.floor_code,
      image.id
    );
    const imageBlob = await fetchImage(viewpointsImage.processed_image_url);
    const currentImageData = {
      image: urlCreator.createObjectURL(imageBlob),
      data: image,
      angleDelta: newAngle - oldAngle,
      observationDate: image.taken_on,
      isMostRecent: true,
    };
    navigateToPointFullscreen(image.point_id, { date: image.taken_on });

    ImageViewerState.focused === 'master'
      ? updateMaster(currentImageData)
      : updatePane2(currentImageData);
  };

  const sortImages = (images: any) => {
    return images.sort(
      (a: any, b: any) => new Date(b.taken_on).getTime() - new Date(a.taken_on).getTime()
    );
  };

  const getStartOfMonths = (startDate: Date): Date[] => {
    let diff = new Date().getMonth() - startDate.getMonth();
    let result = [];
    if (diff > 0) {
      for (let i = 0; i < diff + 1; i++) {
        let newD = new Date(startDate);
        newD.setMonth(startDate.getMonth() + i);
        result.push(newD);
      }
    }
    return result;
  };

  const generateMarks = () => {
    let sorted = sortImages(props.point.images);
    let startDate = new Date(sorted[sorted.length-1].taken_on)
    let marks: any = {
      0: <TimeScrollMarker date={startDate} />,
      100: <TimeScrollMarker date={new Date()} />,
    };

    let difference = new Date().getTime() - new Date(startDate).getTime();

    getStartOfMonths(new Date(startDate)).forEach((date: any) => {
      let startDiff = date.getTime() - new Date(startDate).getTime();
      let percentOff = Math.round((startDiff / difference) * 100);
      marks[percentOff] = <TimeScrollMarker date={date} />;
    });

    sorted.forEach((image: any, index: any) => {
      if (index === sorted.length) return;
      let startDiff = new Date(image.taken_on).getTime() - new Date(startDate).getTime();
      let percentOff = Math.round((startDiff / difference) * 100);
      marks[percentOff] = <TimeScrollMarker date={new Date(image.taken_on)} image={image} />;
      if (marks[percentOff + 1] && !marks[percentOff + 1].props.neighboring) {
        marks[percentOff + 1] = React.createElement('TimeScrollMarker', {
          ...marks[percentOff + 1].props,
          neighboring: true,
        });
      }
    });

    // fakeDates.forEach((item: any, index: any) => {
    //   if (index === fakeDates.length) return;
    //   let startDiff = new Date(item.date).getTime() - new Date('May 01, 2021').getTime();
    //   let percentOff = Math.round((startDiff / difference) * 100);
    //   marks[percentOff] = (
    //     <TimeScrollMarker date={new Date(item.date)} image={sorted[sorted.length - 1]} />
    //   );
    //   if (marks[percentOff + 1] && !marks[percentOff + 1].props.neighboring) {
    //     marks[percentOff + 1] = React.createElement('TimeScrollMarker', {
    //       ...marks[percentOff + 1].props,
    //       neighboring: true,
    //     });
    //   }
    // });

    setSliderMarks(marks);
  };

  const onChange = (value: number) => {
    if (sliderMarks[value].props.image) {
      setDefaultPosition(value);
      mixpanel.track('Use Time Scroll');
    }
  };

  const onAfterChange = (value: number) => {
    if (sliderMarks) {
      if (sliderMarks[value].props.image) {
        getSetCurrentImageData(sliderMarks[value].props.image);
      }
    }
  };

  useEffect(() => {
    if (props.point.images) generateMarks();
  }, [props.point]);

  useEffect(() => {
    updateMethod = ImageViewerState.focused === 'master' ? updateMaster : updatePane2;
  }, [ImageViewerState.focused]);

  useEffect(() => {
    onAfterChange(defaultPosition);
  }, [defaultPosition]);

  useEffect(() => {
    if (sliderMarks) {
      let currentKey: string | undefined = Object.keys(sliderMarks).find((key: any) => {
        return (
          sliderMarks[key].props.image?._id === ImageViewerState[ImageViewerState.focused].data._id
        );
      });
      if (currentKey) setDefaultPosition(parseInt(currentKey));
    }
  }, [sliderMarks, ImageViewerState, ImageViewerState.focused]);

  if (false)
    return (
      <ScrollContainer>
        <Slider
          min={0}
          marks={sliderMarks}
          step={null}
          included={false}
          onChange={onChange}
          value={defaultPosition}
        />
      </ScrollContainer>
    );
  return <></>;
};
