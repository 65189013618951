import { useSafetyContext } from "../../../../../contexts/safetyContext";
import { DetailPane, DetailPaneProps } from "../../SafetyGallery/DetailPane/DetailPane";

type ObservationViewProps = Omit<DetailPaneProps, 'observation' | 'initialShowMap' | 'splitCoordinates'>

export const ObservationView = (props: ObservationViewProps) => {
  const { state: SafetyState } = useSafetyContext();

  return (
    <DetailPane
      observation={SafetyState.activeObservation}
      initialShowMap={false}
      splitCoordinates={SafetyState.selectedMetricCategory ? (coordinates) => coordinates.split(', ') : undefined}
      {...props}
    />
  );
}