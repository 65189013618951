import React, { createContext, useContext } from 'react';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { useUserContext } from '../../../../../contexts/userContext';
import { checkUserPermissions } from '../../../../common/PermissionWrapper/PermissionWrapper';

const ManageSchedulesContext = createContext<any>(null);

export const ManageSchedulesProvider = ({ children }: any) => {
  const { state: userState } = useUserContext();
  const { state: buildingState } = useBuildingContext();

  const canCreateSchedules = checkUserPermissions(userState.permissions, 'scheduler_tables.create_scheduler_schedule', buildingState.projectId);
  const canEditSchedules = checkUserPermissions(userState.permissions, 'scheduler_tables.edit_scheduler_schedul', buildingState.projectId);
  const canDeleteSchedules = checkUserPermissions(userState.permissions, 'scheduler_tables.delete_scheduler_schedule', buildingState.projectId);

  const canCreateSchedulePointMapping = checkUserPermissions(userState.permissions, 'scheduler_tables.create_scheduler_schedule_point', buildingState.projectId);
  const canDeleteSchedulePointMapping = checkUserPermissions(userState.permissions, 'scheduler_tables.delete_scheduler_schedule_point', buildingState.projectId);

  const value = {
    canCreateSchedules,
    canEditSchedules,
    canDeleteSchedules,
    canCreateSchedulePointMapping,
    canDeleteSchedulePointMapping,
  }

  return <ManageSchedulesContext.Provider value={value}>{children}</ManageSchedulesContext.Provider>;
};

export const useManageSchedulesContext = () => {
  return useContext(ManageSchedulesContext);
};
