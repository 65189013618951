import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { useSafetyContext } from "../../../../../contexts/safetyContext";
import { useNavigation } from "../../../../../hooks/useNavigation";
import { Icon } from "../../../../common/Icon";
import { ObservationTableData, ObservationTableRow, ObservationType, ProjectFloor, ProjectFloorCode, ProjectFloorName, ScrollableObservationTableContainer, Status } from "./styles";
import iconMap from "../../../../../assets/images/icon_map_gray.svg";
import iconHouse from "../../../../../assets/images/icon_house.svg";
import { GuardrailObservationSummary } from "../../../../../api/safety";
import { useMemo } from "react";
import { getRedToGreenGradientColor } from "../../SafetyFloorPlanView/SafetyClutterFloorPlanView/SafetyClutterFloorPlanView";

interface GuardrailObservationTableData {
  floorName: string;
  floorCode: string;
  safe: number;
  unsafe: number;
  total: number;
  items: number;
}

interface GuardrailsObservationTableProps {
  data: GuardrailObservationSummary;
}

export const GuardrailsObservationTable = ({
  data,
}: GuardrailsObservationTableProps) => {
  const {navigateToSafety} = useNavigation();
  const {state: BuildingState} = useBuildingContext();
  const {state: safetyState} = useSafetyContext();

  const guardrailMetricSummaryData = useMemo(() => {
    let summaryData: GuardrailObservationTableData[] = [];
    const buildingFloors = BuildingState.projectData?.floors;
    const projectId = BuildingState.projectId;

    if (buildingFloors && buildingFloors.length > 0 && data && projectId) {
      const projectGuardrailMetricSummary = data.floor_summary[projectId];
  
      if (projectGuardrailMetricSummary) {
        buildingFloors.forEach((floor: any) => {
          const floorCode = floor.floor_code;

          const floorGuardrailMetricSummary = projectGuardrailMetricSummary[floorCode];

          if (floorGuardrailMetricSummary) {
            summaryData.push({
              floorCode: floorCode,
              floorName: floor.name,
              safe: floorGuardrailMetricSummary.safe,
              unsafe: floorGuardrailMetricSummary.unsafe,
              total: floorGuardrailMetricSummary._total,
              items: floorGuardrailMetricSummary.items
            })
          }
        })
      }
    }

    return summaryData;
  }, [BuildingState.projectData?.floors, BuildingState.projectId, data]);

  return (
    <ScrollableObservationTableContainer>
      <table>
        {guardrailMetricSummaryData.map(entry => {
          const activeEntry = entry.floorCode === BuildingState.floorId;
          const guardrailScore = (entry.items - entry.unsafe) / entry.items;
          const color = getRedToGreenGradientColor(guardrailScore);

          return (
            <ObservationTableRow
              key={entry.floorCode}
              active={activeEntry}
              onClick={() => navigateToSafety(safetyState.selectedMetricCategory, entry.floorCode)}
            >
              <ObservationTableData>
                <ProjectFloorName>
                  <Icon
                    icon={iconMap}
                    size={18}
                  />
                  <ObservationType>{entry.floorName}</ObservationType>
                </ProjectFloorName>
              </ObservationTableData>
              <ObservationTableData>
                <ProjectFloor>
                  <Icon
                    icon={iconHouse}
                    size={12}
                  />
                  <ProjectFloorCode>
                    {entry.floorCode}
                  </ProjectFloorCode>
                </ProjectFloor>
              </ObservationTableData>
              <ObservationTableData
                align="right"
              >
                <Status
                  statusColorHex={color}
                >
                  {`${(guardrailScore * 100).toFixed(1)}%`}
                </Status>
              </ObservationTableData>
            </ObservationTableRow>
          );
        })}
      </table>
    </ScrollableObservationTableContainer>
  );
}