import axios from "axios";

const BASE_URL = 'https://api.nexterarobotics.com/api';
// const BASE_URL = 'http://localhost:8000/api';

type ProgressRegionParamNames = "floor_code";
export type ProgressRegionParams = Partial<Record<ProgressRegionParamNames, string>>;

export const fetchProgressRegions = async (projectId: string, params?: ProgressRegionParams): Promise<ProgressRegion[]> => {
  const res = await axios.get(
    `${BASE_URL}/project/${projectId}/regions`,
    {
      params: params
    }
  );
  const response = await res.data;
  return response.data;
}

interface ProgressRegionPosition {
  x: number;
  y: number;
  order: number;
  id: number;
}

export interface ProgressRegion {
  id: number;
  position: ProgressRegionPosition[];
  viewpoint: number;
  viewpoint_sub_id: number;
}