import { useCallback, useMemo, useState } from "react";
import { ViewerPosition } from "../../../image_viewer/types";
import { SafetyFloorPlanView } from "../SafetyFloorPlanView";
import { Item } from "../../../../../api/items";
import { FireExtinguisherViewpointsDistanceMetric } from "../../../../../api/safety";
import { useProjectFloorProgressRegionsQuery } from "../../../progress/hooks/progressTrackingQueries";
import { useSafetyContext } from "../../../../../contexts/safetyContext";
import { CustomTooltipValue } from "../../../progress/components/ProgressCharts/CustomTooltip";
import { Job } from "../../../../../api/jobs";
import { useFetchFireExtinguisherViewpointDistanceMetrics } from "../../hooks/safetyQueries";

interface SafetyFireExtinguisherFloorPlanViewProps {
  viewerPosition?: ViewerPosition;
  minimapMode?: boolean;
}

export const SafetyFireExtinguisherFloorPlanView = ({
  viewerPosition,
  minimapMode=false,
}: SafetyFireExtinguisherFloorPlanViewProps) => {
  const {state: safetyState} = useSafetyContext();

  const {observationDatePointImageMap} = safetyState;

  const {data: progressRegions, isLoading: progressRegionsLoading} = useProjectFloorProgressRegionsQuery();

  const [tooltipItem, setTooltipItem] = useState<Item | null>(null);
  const {data: viewpointDistanceMetrics, isLoading: viewpointDistanceMetricsLoading} = useFetchFireExtinguisherViewpointDistanceMetrics();

  const dataLoaded = !!viewpointDistanceMetrics && !viewpointDistanceMetricsLoading && !!progressRegions && !progressRegionsLoading;

  const viewpointDistanceMetricsFilteredForDate = useMemo(() => {
    if (viewpointDistanceMetrics) {
      return viewpointDistanceMetrics.filter(metric => observationDatePointImageMap.has(metric.viewpoints_image_id))
    }
    
    return [];
  }, [observationDatePointImageMap, viewpointDistanceMetrics]);

  console.log(viewpointDistanceMetricsFilteredForDate);

  const viewpointMetricMap = useMemo(() => {
    const map = new Map<number, FireExtinguisherViewpointsDistanceMetric>();

    viewpointDistanceMetricsFilteredForDate.forEach(metric => {
      const viewpoint = metric.viewpoint_id;

      if (!map.has(viewpoint)) {
        map.set(viewpoint, metric);
      }
    });

    return map;
  }, [viewpointDistanceMetricsFilteredForDate]);

  const hexToRgb = (hex: string) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [0, 0, 0];
  }

  const generateGradientFunction = useCallback((minColor: string, minValue: number, maxColor: string, maxValue: number) => (value: number) => {
    value = Math.max(minValue, Math.min(maxValue, value));
    const minColorComponents = hexToRgb(minColor);
    const maxColorComponents = hexToRgb(maxColor);


    const gradient = minColorComponents.map((channel, index) => {
      const difference = maxColorComponents[index] - channel;
      return channel + ((difference / (maxValue - minValue)) * (value - minValue));
    });

    const hex = gradient.map(channel => {
        const hexChannel = Math.round(channel).toString(16);
        return hexChannel.length === 1 ? '0' + hexChannel : hexChannel;
    });

    return '#' + hex.join('');
  }, []);

  const getColor = useCallback((feet: number) => {
    const compliantGradient = generateGradientFunction("#006400", 0, "#90ee90", 75);
    const nonCompliantGradient = generateGradientFunction("#ffff00", 76, "#ff0000", 250);

    if (feet <= 75) {
      return compliantGradient(feet);
    } else {
      return nonCompliantGradient(feet);
    }
  }, [generateGradientFunction]);

  const jobs = useMemo(() => {
    if (progressRegions) {
      return progressRegions.map(region => {
        const itemMetric = viewpointMetricMap.get(region.viewpoint);

        const displayColor = itemMetric ? getColor(itemMetric.distance) : "#808080"

        return {
          id: itemMetric ? itemMetric.viewpoints_image_id : -1,
          completed_units: itemMetric ? itemMetric.distance : 0,
          initial_observation_date: itemMetric ? `${itemMetric.taken_on} 12:00:00` : null,
          item: region as any,
          total_units: 100,
          type: {
            project: 0,
            job_type: {
              display_color: displayColor,
              display_shape: "area",
              display_order: 0,
              id: 1,
              item_type: 1,
              name: "fireExtinguisher",
              secondary_display_color: null,
              slug: "fire_extinguisher",
              tertiary_display_color: null,
              units: "SF"
            }
          },
          registered_on: "",
          last_modified_on: "",
          status: "",
          rate: 0,
        }
      });
    }

    return [];
  }, [getColor, progressRegions, viewpointMetricMap]);

  const itemJobMap = useMemo(() => {
    const map = new Map<number, Job>();

    jobs.forEach(job => {
      map.set(job.item.id, job);
    });

    return map;
  }, [jobs]);

  const tooltipJob = useMemo(() => {
    if (tooltipItem) {
      return itemJobMap.get(tooltipItem.id);
    }

    return null;
  }, [itemJobMap, tooltipItem]);

  const TooltipValue = useMemo(() => {
    const color = tooltipJob ? tooltipJob.type.job_type.display_color : "#808080";
    let message = "";

    if (tooltipJob && tooltipJob.id >= 0) {
      message = `${Math.round(tooltipJob.completed_units)} ft.`
    } else {
      message = "No Measurement"
    }

    return (
      <CustomTooltipValue
        style={{
          color: color,
        }}
      >
        {message}
      </CustomTooltipValue>
    )
  }, [tooltipJob]);

  return (
    <SafetyFloorPlanView
      viewerPosition={viewerPosition}
      jobs={jobs}
      dataLoaded={dataLoaded}
      tooltipItem={tooltipItem}
      setTooltipItem={setTooltipItem}
      selectedJobTypeIds={[1]}
      minimapMode={minimapMode}
      TooltipValue={TooltipValue}
    />
  );
}