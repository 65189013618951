import { useMemo } from "react";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import { useUserContext } from "../../../../contexts/userContext";
import { ViewModeMenu, ViewModeMenuProps } from "../../building_page/components/ViewModeMenu";
import { useGeneratedPaths } from "../../../../hooks/useGeneratedPaths";
import { safetyMetricMetaData } from "../../../../api/safety";
import { checkUserPermissions } from "../../../common/PermissionWrapper/PermissionWrapper";

export const SafetyViewModeMenu = (props: Omit<ViewModeMenuProps, 'items'>) => {
  const {
    generateProjectSafetyPath,
    generateProjectSafetyMetricDetailPath,
    generateProjectSafetyMetricDetailFloorPath
  } = useGeneratedPaths();

  const { state: buildingState } = useBuildingContext();
  const { state: userState } = useUserContext();

  const viewModeMenuItems = useMemo(() => {
    let menuItems: Record<string, any> = {
      "Safety": {
        route: generateProjectSafetyPath(buildingState.projectId),
        userCanAccess: checkUserPermissions(
          userState.permissions,
          'safety_tables.read_observation',
          buildingState.projectId
        ),
      }
    };

    Object.entries(safetyMetricMetaData).forEach(([slug, metadata]) => {
      let route = '';
      const displayMode = metadata.display_mode;

      if (buildingState.projectId) {
        if (displayMode === 'gallery') {
          route = generateProjectSafetyMetricDetailPath(buildingState.projectId, slug);
        } else {
          const initialFloorCode = buildingState.projectData.floors[0].floor_code;
          const l1InFloors = !!buildingState.projectData.floors.find((floor: any) => floor.floor_code === "L1");
          const fallbackFloorCode = l1InFloors ? "L1" : initialFloorCode;
          const floorCode = buildingState.floorId || fallbackFloorCode;
          route = generateProjectSafetyMetricDetailFloorPath(buildingState.projectId, floorCode, slug)
        }
      }

      menuItems[metadata.view_mode_menu_name] = {
        route: route,
        userCanAccess: checkUserPermissions(
          userState.permissions,
          'safety_tables.read_observation',
          buildingState.projectId
        ),
      }
    })

    return menuItems;
  }, [buildingState.floorId, buildingState.projectData.floors, buildingState.projectId, generateProjectSafetyMetricDetailFloorPath, generateProjectSafetyMetricDetailPath, generateProjectSafetyPath, userState.permissions]);

  return (
    <ViewModeMenu
      matchQueryParams
      items={viewModeMenuItems}
      {...props}
    />
  );
}