import { useEffect, useMemo } from 'react';
import { usePagination, useTable, useSortBy, useRowSelect } from 'react-table';
import { Flex } from '@react-css/flex';

import { RowCheck } from './components/RowCheck';
import { Icon } from '../Icon';
import { DefaultColumnFilter } from './components/TableFilters';

import iconUp from '../../../assets/images/icon_up.svg';
import iconDown from '../../../assets/images/icon_down.svg';
import { AddProjectButton } from '../../views/buildings_page/components/AddProjectButton';

interface ITableProps {
  data: any;
  columns: any;
  rowSelect?: boolean;
  rowSelectHeader?: string;
  onRowSelected?: (rows: any) => void;
  pageSize?: number;
  filters?: boolean;
  onClickRow?: (originalRow: any) => void;
  headerStyles?: React.CSSProperties;
  bodyStyles?: React.CSSProperties;
  headerText?: JSX.Element;
  initialSortBy?: { id: any, desc: boolean}[];
  preventAutoResetSortBy?: boolean;
}
export const Table = ({
  data,
  columns,
  rowSelect = false,
  pageSize = 10,
  rowSelectHeader,
  onRowSelected,
  filters = false,
  onClickRow,
  headerStyles,
  bodyStyles,
  headerText,
  initialSortBy,
  preventAutoResetSortBy,
}: ITableProps) => {
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // @ts-ignore
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    setPageSize,
    state: { 
      selectedRowIds, 
      pageSize: currentPageSize 
    },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { 
        pageSize: pageSize,
        sortBy: initialSortBy ?? []
      },
      disableSortRemove: true,
      autoResetSortBy: !preventAutoResetSortBy,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: any) => {
      rowSelect &&
        hooks.visibleColumns.push((columns: any) => [
          // Let's make a column for selection
          {
            id: 'selection',
            Header: rowSelectHeader,
            Cell: ({ row }: any) => (
              <div>
                <RowCheck {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  const loadMore = () => {
    setPageSize(Math.min(currentPageSize + 10, rows.length));
  }

  useEffect(() => {
    if (onRowSelected) onRowSelected(selectedRowIds);
  }, [selectedRowIds, onRowSelected]);

  return (
    <>
      <table {...getTableProps()} style={{ width: '90%', margin: 'auto', marginBottom: '25px' }}>
        <thead>
          { headerText }
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    background: 'none',
                    color: '#7E858E',
                    fontWeight: 'bold',
                    padding: '5px',
                    textAlign: 'center',
                    width: column.width,
                    ...headerStyles
                  }}>
                  {column.render('Header')}
                  {column.canSort && (
                    <span>
                      {' '}
                      <Icon
                        icon={iconDown}
                        size={10}
                        style={{ cursor: 'pointer' }}
                        onClick={() => column.toggleSortBy(true, false)}
                      />{' '}
                      <Icon
                        icon={iconUp}
                        size={10}
                        style={{ cursor: 'pointer' }}
                        onClick={() => column.toggleSortBy(false, false)}
                      />
                    </span>
                  )}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, rowIndex: number) => {
            prepareRow(row);
            return (
              <tr 
                {...row.getRowProps()} 
                onClick={() => {
                  if (onClickRow) {
                    onClickRow(row.original);
                  }
                }}
                style={{cursor: !!onClickRow ? 'pointer' : 'auto'}}
              >
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '15px',
                        textAlign: 'center',
                        background: rowIndex % 2 ? '#F8F8F8' : 'white',
                        ...bodyStyles
                      }}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Flex justifyCenter>
        {/*{canPreviousPage && (*/}
        {/*  <Icon icon={iconBack} size={16} onClick={previousPage} style={{ cursor: 'pointer' }} />*/}
        {/*)}*/}
        {/*<p style={{ padding: '0 5px', color: '#073C7A', fontWeight: 'bold' }}>*/}
        {/*  {pageIndex * pageSize + 1} - {pageIndex * pageSize + page.length}*/}
        {/*</p>*/}
        { (currentPageSize < rows.length) && (
            <AddProjectButton
              text='Load More'
              onClick={loadMore}
              buttonStyle={{marginBottom: '25px'}}
            />
          // <Icon icon={iconForward} size={16} onClick={nextPage} style={{ cursor: 'pointer' }} />
        )}
      </Flex>
    </>
  );
};
