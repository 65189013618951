import React, { useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import iconDirection from '../../assets/images/icon_direction.svg';
import { Point } from '../../api/types';
import { useNavigation } from '../../hooks/useNavigation';
import { useBuildingContext } from '../../contexts/buildingContext';
import { blue, gray, pink } from '../../styles/colors';
import { PanZoomContext, PanZoomProvider } from './PanZoomContext';

const ZoomStep = 0.2;

const PointSize = 30;
const PointBorder = PointSize / 6;
const PointHighlightStart = PointSize + PointBorder * 2;
const PointHighlightRadius = PointBorder * 4;

const FOVLength = 1500;

export interface MapPointMainCircleProps {
  pointSize?: number,
  borderSize?: number,
  backgroundColors?: string[],
  borderColors?: string[],
}

export const MapPointMainCircle = styled.div<MapPointMainCircleProps>`
  position: absolute;
  width: ${props => props.pointSize ?? PointSize}px;
  height: ${props => props.pointSize ?? PointSize}px;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 50%;
  border-width: ${props => props.borderSize ? props.borderSize : props.pointSize ? props.pointSize / 6 : PointBorder}px;
  border-style: solid;
  border-color: #dbdbdb;
  box-sizing: content-box;
  &.back_pink {
    background-color: ${pink};
    border-color: ${pink};
  }
  &.back_pink_empty {
    background-color: white;
    border-color: ${pink};
  }
  &.back_gray {
    background-color: ${gray};
    border-color: ${gray};
  }
  &.back_gray_empty {
    background-color: white;
    border-color: ${gray};
  }
  &.back_blue {
    background-color: ${blue};
  }
  &.back_blue_empty {
    background-color: white;
    border-color: ${blue};
  }
  &.back_red {
    background-color: red;
  }
  &.back_red_empty {
    background-color: white;
    border-color: red;
  }
  &.back_green {
    background-color: green;
  }
  &.back_green_empty {
    background-color: white;
    border-color: green;
  }
  &.back_yellow {
    background-color: yellow;
  }
  &.back_yellow_empty {
    background-color: white;
    border-color: yellow;
  }
  &.border_white {
    border-color: white;
  }
  &.shadow {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
  ${props => props.backgroundColors && `
    background-image: conic-gradient(${props.backgroundColors.map((color, i, arr) => `${color} ${(360 / arr.length) * i}deg ${(360 / arr.length) * (i+1)}deg`).join(', ')});
  `};
  ${props => props.borderColors && `
    background: 
      linear-gradient(#fff 0 0) padding-box,
      conic-gradient(${props.borderColors.map((color, i, arr) => `${color} ${(360 / arr.length) * i}deg ${(360 / arr.length) * (i+1)}deg`).join(', ')}) border-box;
    border: ${props.borderSize ? props.borderSize : props.pointSize ? props.pointSize / 6 : PointBorder}px solid transparent;
  `}
`;

export const MapPointMainHighlighted = styled.div`
  position: absolute;
  width: ${PointHighlightStart}px;
  height: ${PointHighlightStart}px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: ${PointHighlightRadius}px solid rgba(7, 60, 122, 0.1);
  box-sizing: content-box;
`;

const MapPointDirectionArrow = styled.img`
  position: absolute;
  width: ${PointSize + PointBorder * 2}px;
  height: ${PointSize + PointBorder * 2}px;
  transform: translate(-50%, -50%) translateY(-${PointSize / 2}px) rotate(45deg);
`;

const MapPointDirectionCone = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%) translateY(-${FOVLength / 2}px) rotate(180deg);
  border-style: solid;
  border-color: transparent transparent rgba(7, 60, 122, 0.1) transparent;
`;

interface IMapPointContainerProps {
  x: number;
  y: number;
  isActive: boolean;
}

function calcBorder(fov: number) {
  const H = FOVLength;
  const fovRad = (fov / 180) * Math.PI;
  const halfWidth = H * Math.tan(fovRad / 2);
  return `0 ${halfWidth}px ${H}px ${halfWidth}px`;
}

export const MapPointContainer = styled.div<IMapPointContainerProps>`
  position: absolute;
  left: ${props => props.x}px;
  top: ${props => props.y}px;
  cursor: ${props => (props.isActive ? 'pointer' : 'default')};
`;

export const MapPoint = (props: {
  x: number;
  y: number;
  point: Point;
  active?: boolean;
  selectedDateRange: any;
  highlighted?: boolean;
  showDirectionArrow?: boolean;
  fov?: number;
  rotation?: number;
  onClick?: () => void;
}) => {
  const { state: buildingState } = useBuildingContext();
  const { navigateToPointFullscreen } =
    useNavigation();

  /*
  a point is active if: it has an image during the selected window
   */
  const isPointActive = useMemo(() => {
    if (!buildingState.floorData.images) return false;
    const found = buildingState.floorData.images
      // eslint-disable-next-line
      .filter((image: any) => props.point.point_id == image.sub_point_id)
      .sort((a: any, b: any) => new Date(b.taken_on).getTime() - new Date(a.taken_on).getTime());
    if (!found.length) return false;
    let anyFound = false;
    for (let point in found) {
      const pointDate = new Date(found[point].taken_on);
      const isInDateRange =
        !props.selectedDateRange ||
        (pointDate >= props.selectedDateRange?.startDate &&
          pointDate <= props.selectedDateRange?.endDate);
      // console.log('Active point: ', isInDateRange, props.point.images?.length);
      if (!!(isInDateRange && props.point.images?.length)) anyFound = true;
    }
    return anyFound;
  }, [props.point, props.selectedDateRange, buildingState.floorData.images]);

  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <MapPointContainer
      className={`map-point mp-${props.point.point_id}`}
      x={props.x}
      y={props.y}
      isActive={isPointActive}>
      {props.showDirectionArrow && (
        <MapPointArrow
          rotation={props.rotation}
          fov={props.fov}
        />
      )}
      <MapPointMainCircle
        className={classNames({
          back_blue: isPointActive || props.active,
          border_white: props.highlighted || props.showDirectionArrow,
          shadow: props.showDirectionArrow,
        })}
        onClick={isPointActive ? () => onClick() : () => null}
        onDoubleClick={
          isPointActive ? () => navigateToPointFullscreen(props.point.point_id) : () => null
        }
      />
      {props.highlighted && (
        <MapPointMainHighlighted
          onClick={() => onClick()}
          onDoubleClick={() => navigateToPointFullscreen(props.point.point_id)}
        />
      )}
    </MapPointContainer>
  );
};

type PanZoomProps = {
  initialX?: number;
  initialY?: number;
  minScale?: number;
  maxScale?: number;
  interactive?: boolean;
  preventDoubleTouches?: boolean;
};

class PanZoom extends React.Component<PanZoomProps> {
  static contextType = PanZoomContext;
  context!: React.ContextType<typeof PanZoomContext>;

  private mainRef = React.createRef<HTMLDivElement>();
  private innerRef = React.createRef<HTMLDivElement>();

  private x = 0;
  private y = 0;
  private isDraggingDown = false;
  private isScaling = false;
  private dragStartX = 0;
  private dragStartY = 0;
  private dragStartScale = 0;
  private interactive = false;
  private lastTouch = 0;

  constructor(props: Readonly<PanZoomProps> | PanZoomProps) {
    super(props);

    this.x = props.initialX ?? 0;
    this.y = props.initialY ?? 0;
    this.interactive = this.props.interactive ?? true;

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchDown = this.onTouchDown.bind(this);
    this.onTouchUp = this.onTouchUp.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onWheel = this.onWheel.bind(this);

    this.onDragStart = this.onDragStart.bind(this);

    this.onGestureStart = this.onGestureStart.bind(this);
    this.onGestureEnd = this.onGestureEnd.bind(this);
    this.onGestureChange = this.onGestureChange.bind(this);
  }

  public getZoom(): number {
    return this.context.scale;
  }

  public setZoom(newZoom: number) {
    const mainDiv = this.mainRef.current!;
    const rect = mainDiv.getBoundingClientRect();

    this.setZoomRelativeTo(newZoom, rect.width / 2, rect.height / 2);
  }

  public setPosition(newZoom: number, newX: number, newY: number) {
    this.x = newX;
    this.y = newY;

    this.updatePosition(newZoom);
  }

  public setZoomRelativeTo(newZoom: number, x: number, y: number) {
    if (this.props.maxScale != null && newZoom > this.props.maxScale) newZoom = this.props.maxScale;
    if (this.props.minScale != null && newZoom < this.props.minScale) newZoom = this.props.minScale;

    const scaleMult = newZoom / this.context.scale;

    this.x = x - (x - this.x) * scaleMult;
    this.y = y - (y - this.y) * scaleMult;

    this.updatePosition(newZoom);
  }

  private updatePosition(newZoom?: number) {
    const scale = newZoom ?? this.context.scale;
    
    const divOverlay2 = this.innerRef.current!;
    divOverlay2.style.transform = `translate(${this.x}px, ${this.y}px) translate(-50%,-50%) scale(${scale}) translate(50%,50%)`;
    
    this.context.setScale(scale);
  }

  public componentDidMount() {
    const mainDiv = this.mainRef.current!;

    if (this.interactive) {
      document.addEventListener('mouseup', this.onMouseUp, true);
      document.addEventListener('mousemove', this.onMouseMove, true);
      document.addEventListener('touchend', this.onTouchUp, true);
      document.addEventListener('touchmove', this.onTouchMove, true);
      mainDiv.addEventListener('wheel', this.onWheel, false);
      mainDiv.addEventListener('dragstart', this.onDragStart, false);
      mainDiv.addEventListener('gesturestart', this.onGestureStart, false);
      mainDiv.addEventListener('gestureend', this.onGestureEnd, false);
      mainDiv.addEventListener('gesturechange', this.onGestureChange, false);
    }
    this.updatePosition();
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.initialX !== this.props.initialX || prevProps.initialY !== this.props.initialY) {
      this.x = this.props.initialX ?? 0;
      this.y = this.props.initialY ?? 0;
      this.setZoomRelativeTo(0.3, this.x, this.y);
    }
  }

  public componentWillUnmount() {
    const mainDiv = this.mainRef.current!;

    if (this.interactive) {
      document.removeEventListener('mouseup', this.onMouseUp, false);
      document.removeEventListener('mousemove', this.onMouseMove, false);
      document.removeEventListener('touchend', this.onTouchUp, false);
      document.removeEventListener('touchmove', this.onTouchMove, false);
      mainDiv.removeEventListener('wheel', this.onWheel, false);
      mainDiv.removeEventListener('dragstart', this.onDragStart, false);
      mainDiv.removeEventListener('gesturestart', this.onGestureStart, false);
      mainDiv.removeEventListener('gestureend', this.onGestureEnd, false);
      mainDiv.removeEventListener('gesturechange', this.onGestureChange, false);
    }
  }

  private onMouseDown(event: React.MouseEvent<HTMLDivElement>) {
    this.isDraggingDown = true;
    this.dragStartX = event.clientX;
    this.dragStartY = event.clientY;
  }

  private onTouchDown(event: React.TouchEvent) {
    const currentTouch = new Date().getTime();
    const isDoubleTouch: boolean = currentTouch - this.lastTouch <= 200;
    const eligibleToProceed = !isDoubleTouch || !this.props.preventDoubleTouches;

    if (eligibleToProceed && event.touches.length === 1) {
      this.isDraggingDown = true;
      this.dragStartX = event.touches[0].clientX;
      this.dragStartY = event.touches[0].clientY;
    }

    this.lastTouch = currentTouch;
  }

  private onMouseUp() {
    this.isDraggingDown = false;
  }

  private onTouchUp() {
    this.isDraggingDown = false;
  }

  private onMouseMove(event: MouseEvent) {
    event.preventDefault();
    if (this.isDraggingDown) {
      this.x += event.clientX - this.dragStartX;
      this.y += event.clientY - this.dragStartY;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      this.updatePosition();
    }
  }

  private onTouchMove(event: TouchEvent) {
    event.preventDefault();
    if (this.isDraggingDown) {
      // eslint-disable-next-line
      if (event.touches.length == 1) {
        this.x += event.touches[0].clientX - this.dragStartX;
        this.y += event.touches[0].clientY - this.dragStartY;
        this.dragStartX = event.touches[0].clientX;
        this.dragStartY = event.touches[0].clientY;
        this.updatePosition();
      }
    }
  }

  private onWheel(e: WheelEvent) {
    e.preventDefault();
    const mainDiv = this.mainRef.current!;

    const dir = e.deltaY > 0 ? 1 : -1;

    if (e.target) {
      const rect = mainDiv.getBoundingClientRect();

      const delta = (e as any).wheelDeltaY;
      const zoomStep = 1 + ZoomStep * Math.max(0, Math.min(1.5, Math.abs(delta) / 120));

      const mouseX = e.clientX - rect.x;
      const mouseY = e.clientY - rect.y;
      const scaleMult = dir === 1 ? 1 / zoomStep : zoomStep;

      this.setZoomRelativeTo(this.context.scale * scaleMult, mouseX, mouseY);
    }
  }

  private onDragStart(e: Event) {
    e.preventDefault();
  }

  private onGestureStart(e: any) {
    e.preventDefault();
    this.dragStartScale = e.scale;
    this.isScaling = true;
  }

  private onGestureEnd(e: any) {
    e.preventDefault();
    this.isScaling = false;
  }

  private onGestureChange(e: any) {
    e.preventDefault();
    if (this.isScaling) {
      const diff = e.scale / this.dragStartScale;
      this.dragStartScale = e.scale;

      const mainDiv = this.mainRef.current!;

      if (e.target) {
        const rect = mainDiv.getBoundingClientRect();

        const mouseX = e.clientX - rect.x;
        const mouseY = e.clientY - rect.y;

        this.setZoomRelativeTo(this.context.scale * diff, mouseX, mouseY);
      }
    }
  }

  render() {
    return (
      <div
        ref={this.mainRef}
        style={{ width: '100%', height: '100%', overflow: 'hidden' }}
        onMouseDown={this.onMouseDown}
        onTouchStart={this.onTouchDown}>
        <div style={{ position: 'relative', display: 'inline-block' }} ref={this.innerRef}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

interface IMapViewerProps extends React.PropsWithChildren<{}> {
  image: any;
  setLoadedImg: (value: string) => void;
  imageLoaded: boolean;
  selectedDateRange: any;
  points: Point[];
  highlightedPointId?: string | null;
  showDirectionArrow?: boolean;
  angleOffset?: number;
  rotation?: number;
  onDoubleClickMap?: (e: React.MouseEvent) => void;
  onMouseMoveMap?: (e: React.MouseEvent) => void;
  preventDoubleTouches?: boolean;
  showPoints?: boolean;
  onClickMapPoint?: (point: Point) => void;
}

interface IMapViewerState {
  initialPositionSet: boolean;
}

export class MapViewer extends React.Component<IMapViewerProps, IMapViewerState> {
  constructor(props: Readonly<IMapViewerProps> | IMapViewerProps) {
    super(props);

    this.state = {
      initialPositionSet: false,
    }

    this.onImageLoad = this.onImageLoad.bind(this);
  }

  private mapRef = React.createRef<PanZoom>();
  public static defaultProps = {
    showPoints: true,
  };
  public zoomIn() {
    const map = this.mapRef.current;
    if (map) map.setZoom(map.getZoom() * (1 + ZoomStep));
  }

  public zoomOut() {
    const map = this.mapRef.current;
    if (map) map.setZoom(map.getZoom() / (1 + ZoomStep));
  }

  // shouldComponentUpdate(
  //   nextProps: Readonly<{
  //     image: any;
  //     selectedDateRange: any;
  //     points: Point[];
  //     isDateSelected: boolean;
  //     imageLoaded: boolean;
  //     setLoadedImg: (value: string) => void;
  //     highlightedPointId?: string | null;
  //   }>,
  //   nextState: Readonly<{}>
  // ) {
  //   return nextProps.image !== this.props.image;
  // }

  private getRotation () {
    if (this.props.rotation !== undefined && this.props.angleOffset !== undefined) {
      return this.props.rotation + this.props.angleOffset;
    }

    return undefined;
  }

  private onImageLoad (e: React.SyntheticEvent<HTMLImageElement, Event>) {
    if (!this.state.initialPositionSet) {
      const map = this.mapRef.current;
      const target = (e.target) as HTMLImageElement;
      const {clientWidth, clientHeight} = target;

      const parentElement = target.parentElement;
      const screenContainer = parentElement?.parentElement;

      if (!!screenContainer && !!map) {
        const screenContainerDimensions = screenContainer.getBoundingClientRect();
        const screenContainerWidth = screenContainerDimensions.width;
        const screenContainerHeight = screenContainerDimensions.height;

        const heightRatio = screenContainerHeight / clientHeight;

        const initialScale = heightRatio - 0.005;
        const newImageWidth = clientWidth * initialScale;
        const newImageHeight = clientHeight * initialScale;

        const newX = (screenContainerWidth / 2) - (newImageWidth / 2);
        const newY = (screenContainerHeight / 2) - (newImageHeight / 2)

        map.setPosition(initialScale, newX, newY);
        this.setState({
          initialPositionSet: true
        });
      }
    }

    this.props.setLoadedImg(this.props.image);
  }

  render() {
    return (
      <PanZoomProvider initialScale={0.3}>
        <PanZoom
          ref={this.mapRef}
          minScale={0.1}
          maxScale={1}
          preventDoubleTouches={this.props.preventDoubleTouches}>
          <img
            src={this.props.image}
            alt=""
            id="floor-plan"
            onLoad={this.onImageLoad}
            className={`smooth-image image-${this.props.imageLoaded ? 'visible' : 'hidden'}`}
            key={this.props.image}
            onDoubleClick={this.props.onDoubleClickMap}
            onMouseMove={this.props.onMouseMoveMap}
          />
          {this.props.imageLoaded &&
            <>
              {this.props.children}
              {this.props.showPoints &&
                this.props.points.map(point => (
                  <MapPoint
                    key={point.point_id}
                    selectedDateRange={this.props.selectedDateRange}
                    point={point}
                    highlighted={point.point_id === this.props.highlightedPointId}
                    showDirectionArrow={point.point_id === this.props.highlightedPointId && this.props.showDirectionArrow}
                    rotation={this.getRotation()}
                    x={point.x}
                    y={point.y}
                    onClick={() => {
                      if (this.props.onClickMapPoint) {
                        this.props.onClickMapPoint(point);
                      }
                    }}
                  />
                ))}
            </>
          }
        </PanZoom>
      </PanZoomProvider>
    );
  }
}

interface IMinimapViewerProps {
  image: string;
  point: Point;
  fov: number;
  selectedDateRange: any;
  rotation: number;
  angleOffset: any;
  tourPoints?: Point[];
  onClickTourPoint?: (point: Point) => void;
  boundingBox?: string;
}

export class MinimapViewer extends React.Component<IMinimapViewerProps> {
  private mapRef = React.createRef<PanZoom>();

  public zoomIn() {
    const map = this.mapRef.current;
    if (map) map.setZoom(map.getZoom() * (ZoomStep + 1));
  }

  public zoomOut() {
    const map = this.mapRef.current;
    if (map) map.setZoom(map.getZoom() / (ZoomStep + 1));
  }

  getRotation() {
    return this.props.rotation + this.props.angleOffset;
  }

  render() {
    if (!this.props.point.x) return <></>;

    const boundingBoxComponents = this.props.boundingBox?.split(',').map(Number) || [0, 0, 0, 0];
    const boundingBoxX = boundingBoxComponents[0];
    const boundingBoxY = boundingBoxComponents[1];
    const boundingBoxWidth = boundingBoxComponents[2];
    const boundingBoxHeight = boundingBoxComponents[3];

    return (
      <PanZoomProvider initialScale={0.3}>
        <PanZoom
          ref={this.mapRef}
          initialX={-this.props.point.x * 0.3 + 200}
          initialY={-this.props.point.y * 0.3 + 110}
          minScale={0.1}
          maxScale={1}
          interactive={true}>
          <img src={this.props.image} alt="" />
          <MapPoint
            active={true}
            selectedDateRange={this.props.selectedDateRange}
            point={this.props.point}
            highlighted={false}
            showDirectionArrow={true}
            fov={this.props.fov}
            rotation={this.getRotation()}
            x={this.props.point.x}
            y={this.props.point.y}
          />
          {this.props.tourPoints &&
            this.props.tourPoints.map(point => (
              <MapPoint
                key={point.point_id}
                selectedDateRange={this.props.selectedDateRange}
                point={point}
                highlighted={false}
                x={point.x}
                y={point.y}
                onClick={() => {
                  if (this.props.onClickTourPoint) {
                    this.props.onClickTourPoint(point);
                  }
                }}
              />
            ))}
          {boundingBoxComponents.length > 0 &&
            <div
              style={{
                position: 'absolute',
                left: boundingBoxX - (boundingBoxWidth / 2),
                top: boundingBoxY - (boundingBoxHeight / 2),
                width: boundingBoxComponents[2],
                height: boundingBoxComponents[3],
                border: '2px solid red',
              }}
            />
          }
        </PanZoom>
      </PanZoomProvider>
    );
  }
}

interface MapPointArrowProps {
  rotation?: number;
  fov?: number;
}

export const MapPointArrow = ({
  rotation,
  fov,
}: MapPointArrowProps) => {
  return (
    <div style={{ transform: `rotate(${rotation}deg)` }}>
      <MapPointDirectionArrow src={iconDirection} alt="" />
      {fov && fov !== -1 && (
        <MapPointDirectionCone
          style={{
            borderWidth: calcBorder(fov),
          }}
        />
      )}
    </div>
  )
}
