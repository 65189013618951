import React, { createContext, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

const AdminZoneContext = createContext<boolean>(false);
const adminPrefix = '/admin';

export const AdminZoneProvider = ({ children }: any) => {
  const adminRoute = `${adminPrefix}`;

  const adminZoneMatch = useRouteMatch(adminRoute);

  return (
    <AdminZoneContext.Provider value={adminZoneMatch !== null}>
      {children}
    </AdminZoneContext.Provider>
  );
};

export const useAdminZoneContext = () => {
  const inAdminZone = useContext(AdminZoneContext);

  return {
    inAdminZone,
    adminPrefix: inAdminZone ? adminPrefix : '',
  };
};
