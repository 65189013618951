import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  BuildingOverviewContainer,
  BuildingPreview,
  ProjectLogoContainer,
  ProjectLogo,
  FloorVector,
} from './styles';
import { useBuildingContext } from '../../../../../contexts/buildingContext';

interface IBuildingOverviewProps {
  hoverFloor: string | null;
  setHoverFloor: (floor: string | null) => void;
  onFloorClicked: (floor: string) => void;
  showLogos?: boolean;
}

export const BuildingOverview = ({
  hoverFloor,
  setHoverFloor,
  onFloorClicked,
  showLogos,
}: IBuildingOverviewProps) => {
  const { state: BuildingState } = useBuildingContext();
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [svgString, setSvgString] = useState<string | null>(null);

  const handleMouseInOut = useCallback(
    (e: MouseEvent) => {
      let polygon = e.target as SVGPolygonElement;
      if (!polygon.id || e.type === 'mouseout') {
        setHoverFloor(null);
      } else if (polygon.id !== hoverFloor) {
        setHoverFloor(polygon.id);
      }
    },
    [hoverFloor, setHoverFloor]
  );

  const handleVectorClick = useCallback(
    (e: MouseEvent) => {
      let polygon = e.target as SVGPolygonElement;
      if (polygon) if (polygon.id) onFloorClicked(polygon.id);
    },
    [onFloorClicked]
  );

  const addListeners = useCallback(
    (elm: SVGGElement) => {
      elm.addEventListener('mouseover', handleMouseInOut);
      elm.addEventListener('mouseout', handleMouseInOut);
      elm.addEventListener('click', handleVectorClick);
    },
    [handleMouseInOut, handleVectorClick]
  );

  const fetchSvg = useCallback(() => {
    return fetch(BuildingState.projectData.rendering_vector_url).then(r => r.text());
  }, [BuildingState.projectData.rendering_vector_url]);

  /*
  Toggle polygon color on floor change
   */
  useEffect(() => {
    let floorVec = document.getElementById('floor-vector') as HTMLObjectElement;
    if (floorVec) {
      let svg = floorVec.contentDocument;

      // reset all floors to clear
      let groups = svg?.querySelectorAll('g');
      groups?.forEach((item: SVGGElement) => {
        item.style.fillOpacity = '0';
      });
      if (hoverFloor) {
        let group = svg?.getElementById(`g-${hoverFloor}`);
        if (group) group.style.fillOpacity = FILL_OPACITY;
      }
    }
  }, [hoverFloor]);

  /*
  Add event listeners to all polygons
   */
  useEffect(() => {
    if (svgString) {
      setTimeout(() => {
        let floorVec = document.getElementById('floor-vector') as HTMLObjectElement;
        if (floorVec) {
          let svg = floorVec.contentDocument || floorVec.getSVGDocument();
          let path = svg?.querySelectorAll('polygon');
          path?.forEach((item: SVGGElement) => {
            addListeners(item);
          });
        }
      }, 300);
    }
  }, [svgString, addListeners]);

  /*
  Fetch SVG from url and create a local blob url

  solves XSS issues with JS so we can add event listeners
   */
  useEffect(() => {
    if (BuildingState.projectData.rendering_vector_url) {
      fetchSvg().then(data => {
        let blob = new Blob([data], { type: 'image/svg+xml' });
        let url = URL.createObjectURL(blob);
        setSvgString(url);
      });
    }
  }, [BuildingState.projectData.rendering_vector_url, fetchSvg]);

  return (
    <BuildingOverviewContainer>
      <BuildingPreview>
        <FloorVector id="floor-vector" data={svgString || ''} />
        {BuildingState.projectData.rendering_url && (
          <img
            src={BuildingState.projectData.rendering_url}
            alt=""
            className="fade-in-image"
            ref={imgRef}
            style={{ width: '100%' }}
          />
        )}
      </BuildingPreview>
      {showLogos && (
        <ProjectLogoContainer>
          {BuildingState.projectData.company &&
            <ProjectLogo src={BuildingState.projectData.company.logo_url} alt="" />
          }
          {BuildingState.projectData.client_logo_url && (
            <ProjectLogo src={BuildingState.projectData.client_logo_url} alt="" />
          )}
        </ProjectLogoContainer>
      )}
    </BuildingOverviewContainer>
  );
};

const FILL_OPACITY = '0.5';
// const ViewModeItems = ['Site view', 'Safety', 'Progress', 'Clean Site'];
