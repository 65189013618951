import { useCallback, useEffect, useMemo } from 'react';
import { usePagination, useTable, useSortBy } from 'react-table';

import { Icon } from '../Icon';
import { DefaultColumnFilter } from './components/TableFilters';

import iconUp from '../../../assets/images/icon_up.svg';
import iconDown from '../../../assets/images/icon_down.svg';
import styled from 'styled-components';
import { ProgressCell } from '../../views/progress/ProgressOverview/ProgressCell/ProgressCell';
import { generateSlugLabel, getPercentOverTime, ParentHeader } from '../../views/progress/ProgressOverview/ProgressOverview';
import { useOverviewCols } from '../../../data/pt_cols';

interface IProgressTableProps {
  trackerData: any;
  rowSelect?: boolean;
  rowSelectHeader?: string;
  onRowSelected?: (rows: any) => void;
  pageSize?: number;
  filters?: boolean;
}
export const ProgressTable = ({
  trackerData,
  rowSelect = false,
  pageSize = 10,
  rowSelectHeader,
  onRowSelected,
  filters = false,
}: IProgressTableProps) => {
  const overview_cols: any = useOverviewCols();

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const renderProgressCell = useCallback((cell: any) => {
    let units = '% / Day';
    let rate = 0;

    if (cell.value && cell.value.initial_observation_date) {
      const cellValue = cell.value;
      const initialObservationDate = new Date(cellValue.initial_observation_date);
      const latestObservationDate = new Date(cellValue.latest_observation_date);
      
      rate = getPercentOverTime(cellValue.total_units, cellValue.completed_units, initialObservationDate, latestObservationDate);
    }

    return (
      <ProgressCell
        total={cell.value?.total_units}
        progress={cell.value?.completed_units}
        rate={rate}
        units={() => units}
      />
    )
  }, []);

  const mapJobTypeToColumn = useCallback((job_type, tracker) => {
    return {
      Header: generateSlugLabel(job_type, tracker.type.name),
      accessor: job_type,
      disableSortBy: true,
      disableFilters: true,
      Cell: renderProgressCell,
    }
  }, [renderProgressCell]);

  const wallsOverviewColumn: any = useMemo(() => {
    return {
      Header: 'Overview',
      accessor: 'Overview',
      disableSortBy: true,
      disableFilters: true,
      Cell: renderProgressCell,
    }
  }, [renderProgressCell]);

  const data = useMemo(() => {
    const floorData: any[] = [];

    trackerData[0].floors.forEach((floor: any, i: number) => {
      let floorEntry: Record<any, any> = {};  

      trackerData.forEach((tracker: any) => {
        const trackerFloor = {...tracker.floors[i]}
        
        const grandOverview = {...trackerFloor.Overview}
        delete trackerFloor.Overview;

        if (floorEntry.Overview) {
          floorEntry.Overview = {
            ...floorEntry.Overview,
            total_units: floorEntry.Overview.total_units + grandOverview.total_units,
            completed_units: floorEntry.Overview.completed_units + grandOverview.completed_units,
          }

          if (grandOverview.initial_observation_date) {
            if (!floorEntry.Overview.initial_observation_date || (new Date(grandOverview.initial_observation_date) <= new Date(floorEntry.Overview.initial_observation_date))) {
              floorEntry.Overview.initial_observation_date = grandOverview.initial_observation_date;
            }
          }

          if (grandOverview.latest_observation_date) {
            if (!floorEntry.Overview.latest_observation_date || new Date(grandOverview.latest_observation_date) >= new Date(floorEntry.Overview.latest_observation_date)) {
              floorEntry.Overview.latest_observation_date = grandOverview.latest_observation_date; 
            }
          }
        } else {
          floorEntry.Overview = {...grandOverview};
        }

        floorEntry = {...floorEntry, ...trackerFloor}
      });

      floorData.push(floorEntry);
    });

    return floorData;
  }, [trackerData]);

  const columns: any = useMemo(() => {
    overview_cols[0].columns[1] = {...wallsOverviewColumn};
    
    return overview_cols.concat(
      trackerData
        .map((tracker: any) => ({
          Header: () => <ParentHeader>{tracker.type.name}</ParentHeader>,
          id: tracker.id,
          disableSortBy: true,
          disableFilters: true,
          border: true,
          columns: Object.keys(tracker.floors[0])
            .filter( key => key !== "floor" && key !== 'Overview')
            .map(job_type => mapJobTypeToColumn(job_type, tracker)
          ),
        }))
    );
  }, [wallsOverviewColumn, trackerData, overview_cols, mapJobTypeToColumn]);

  // @ts-ignore
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data: data,
      defaultColumn,
      initialState: { pageSize: pageSize },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (onRowSelected) onRowSelected(selectedRowIds);
  }, [selectedRowIds]);

  return (
    <Styles>
      <table {...getTableProps()}>
        <thead style={{ borderBottom: '1px solid #E2E8F0' }}>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={column.sticky ? 'sticky-col table-header' : 'table-header'}
                  style={{
                    color: '#212121',
                    padding: '5px 15px',
                    textAlign: 'left',
                    maxWidth: '75px',
                    textTransform: 'capitalize',
                    borderRight: column.border ? '1px solid #E2E8F0' : 'none',
                  }}>
                  {column.render('Header')}
                  {column.canSort && (
                    <span>
                      {' '}
                      <Icon
                        icon={iconDown}
                        size={10}
                        style={{ cursor: 'pointer' }}
                        onClick={() => column.toggleSortBy(true, false)}
                      />{' '}
                      <Icon
                        icon={iconUp}
                        size={10}
                        style={{ cursor: 'pointer' }}
                        onClick={() => column.toggleSortBy(false, false)}
                      />
                    </span>
                  )}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, rowIndex: number) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ borderBottom: '1px solid #E2E8F0' }}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={cell.column.sticky ? 'sticky-col' : ''}
                      style={{
                        padding: '15px',
                        background: 'white',
                        color: '#5C6F8A',
                        borderRight: cell.column.border ? '1px solid #E2E8F0' : 'none',
                      }}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styles>
  );
};

const Styles = styled.div`
  position: relative;
  overflow: auto;
  white-space: nowrap;

  .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background: #fff;
    z-index: 10;
  }
`;
