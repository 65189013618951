import { Dropdown } from '../../Dropdown/Dropdown';
import React, { useState } from 'react';
import { Icon } from '../../Icon';
import imgIconVerticalDotMenu from '../../../../assets/images/icon_vertical_dot_menu.svg';
import { ChatMenuItem } from './styles';
import { useNotifications } from '../../../../contexts/notificationProvider';
import { useTagContext } from '../../../../contexts/tagContext';
import { useUserContext } from '../../../../contexts/userContext';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { ConfirmationModal } from '../../Confirmation/Confirmation';

export const ChatMenu = ({ pnlm }: any) => {
  const [display, setDisplay] = useState(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const { addNotification } = useNotifications();
  const { state: tagState, toggleDisplay } = useTagContext();
  const { state: userState } = useUserContext();

  const removeHotspot = () => {
    axios
      .patch(`https://services.nexterarobotics.com/tags/${tagState.tags.current.id}/`, {
        deleted: true,
      })
      .then(() => {
        addNotification('Chat Archived', 'success');
      })
      .catch(e => {
        addNotification('Chat could not be archived', 'error');
      });
    pnlm?.removeHotspot(tagState.tags.current.id);
    toggleDisplay();
    mixpanel.track('Archive Chat');
  };

  if (tagState.tags.current.type === 'CHAT') {
    if (tagState.tags.current.chat_room?.admin.public_id !== userState.public_id) {
      return <></>;
    }
  }
  return (
    <>
      <Icon icon={imgIconVerticalDotMenu} size={18} onClick={() => setDisplay(!display)} />
      <Dropdown
        display={display}
        setDisplay={setDisplay}
        style={{
          minWidth: '50px',
          overflowY: 'unset',
          maxHeight: '50px',
          right: 0,
          top: '-50px',
        }}>
        <ul>
          <ChatMenuItem onClick={() => setConfirmation(true)}>Archive</ChatMenuItem>
        </ul>
      </Dropdown>
      <ConfirmationModal
        isOpen={confirmation}
        setIsOpen={setConfirmation}
        message={'Are you sure you want to archive this chat?'}
        onConfirm={removeHotspot}
      />
    </>
  );
};
