import styled from "styled-components";
import { Pannellum } from "../../../third_party/Pannellum";
import { useCallback, useEffect, useRef, useState } from "react";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { fetchImage, fetchPointImage } from "../../../../api/buildingFetches";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import iconClose from '../../../../assets/images/icon_close.svg';
import { SiteWalkTimeScroll } from "./SiteWalkTimeScroll";
import { ViewpointsImage } from "../../../../api/types";
import { useImageViewerContext } from "../../image_viewer/imageViewerContext";
import { useViewerPosition } from "../../image_viewer/hooks/useViewerPosition";
import { findClosestDate } from "../../building_page/hooks/buildingQueries";
import { AddProjectButton } from "../../buildings_page/components/AddProjectButton";
import { SiteWalkReviewFlags } from "../../../../api/sitewalk";

const urlCreator = window.URL || window.webkitURL;

export interface SiteWalkComparisonImageData extends Partial<SiteWalkReviewFlags> {
  id: number;
  angle: number;
  taken_on: string;
}

interface BaseSiteWalkImageComparisonProps {
  type: 'master' | 'subordinate';
  label?: string;
  images: SiteWalkComparisonImageData[];
  onClose?: () => void;
  getImageUrl: (image: ViewpointsImage) => string;
  onApproveImage?: (viewpointsImageId: number) => void;
  onRejectImage?: (viewpointsImageId: number) => void;
  setRotation?: (newValue: number) => void;
}

interface MasterSiteWalkImageComparisonProps extends BaseSiteWalkImageComparisonProps {
  type: 'master';
  onApproveImage: (viewpointsImageId: number) => void;
  onRejectImage: (viewpointsImageId: number) => void;
}

interface SubordinateSiteWalkImageComparisonProps extends BaseSiteWalkImageComparisonProps {
  type: 'subordinate'
}

type SiteWalkImageComparisonProps = MasterSiteWalkImageComparisonProps | SubordinateSiteWalkImageComparisonProps;

export const SiteWalkImageComparison = ({
  type,
  label,
  images,
  onClose,
  getImageUrl,
  onApproveImage,
  onRejectImage,
  setRotation,
}: SiteWalkImageComparisonProps) => {
  const {
    state: buildingState,
  } = useBuildingContext();
  const {
    state: ImageViewerState,
    updateImageViewer,
    updateMaster,
    updatePane2,
  } = useImageViewerContext();

  const { updateViewerPosition, getInitialPosition, viewerPosition } = useViewerPosition();

  let initialSelectedIndex = 0;
  let initialObservationDate = new Date();

  if (type === 'master') {
    const imageNotReviewedIndex = images.findIndex((image: any) => !image.is_reviewed);
    const imageApprovedIndex = images.findIndex((image: any) => image.is_approved);
    
    if (imageNotReviewedIndex !== -1) {
      initialSelectedIndex = imageNotReviewedIndex;
    } else if (imageApprovedIndex !== -1) {
      initialSelectedIndex = imageApprovedIndex;
    }

    initialObservationDate = new Date(images[initialSelectedIndex].taken_on)
  }

  const [selectedIndex, setSelectedIndex] = useState<number>(initialSelectedIndex);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [observationDate, setObservationDate] = useState<Date>(initialObservationDate);
  const pannellumRef = useRef<Pannellum | null>(null);

  const dataLoaded = images.length === 0 || (!!ImageViewerState[type].image && !imageLoading);
  const isMaster = type === 'master';

  const selectedImage = images[selectedIndex];
  const issueDetected = selectedImage?.blurriness_issue || selectedImage?.brightness_issue || selectedImage?.wrong_location;

  const fetchViewpointImage = useCallback(async (viewpointId: string | number) => {
    if (buildingState.projectId && buildingState.floorData.floor_code) {
      const viewpointsImage = await fetchPointImage(
        buildingState.projectId,
        buildingState.floorData.floor_code,
        viewpointId.toString(),
      );

      if (viewpointsImage) {
        const oldImage = ImageViewerState[type];
        let oldAngle = oldImage?.data?.angle ?? 0;
        let newAngle = viewpointsImage.angle;
        
        const imageUrl = getImageUrl(viewpointsImage);
        const imageBlob = await fetchImage(imageUrl);

        if (oldImage && oldImage.image) {
          urlCreator.revokeObjectURL(oldImage.image);
        }

        const currentImageData = {
          image: urlCreator.createObjectURL(imageBlob),
          data: viewpointsImage,
          angleDelta: newAngle - oldAngle,
          observationDate: viewpointsImage.taken_on,
          isMostRecent: true,
        };

        if (type === 'master') {
          updateMaster(currentImageData);
        } else {
          updatePane2(currentImageData);
        }
      } else {
        throw new Error("Error fetching viewpoints image")
      }
    }
  }, [buildingState.projectId, buildingState.floorData.floor_code, ImageViewerState, type, getImageUrl, updateMaster, updatePane2]);

  useEffect(() => {
    if (images.length) {
      setImageLoading(true);

      const sortedWithIndex = images.map((sortedImage: any, i: number) => ({...sortedImage, originalIndex: i}));
      let dateSorted = [...sortedWithIndex].sort((a: any, b: any) => findClosestDate(observationDate, a.taken_on, b.taken_on));

      const newIndex = dateSorted[0].originalIndex;

      setSelectedIndex(newIndex);
      fetchViewpointImage(images[newIndex].id)
        .then(() => {
          setImageLoading(false);
        })
        .catch(() => {
          console.log("Error fetching viewpoints image")
        })
    }
  }, [images, observationDate]);

  return (
    <ImageComparisonBase
      style={{
        top: isMaster ? 0 : '50%',
      }}
      onClick={() => updateImageViewer({ focused: type })}
      id={'image-viewer-' + type}
    >
      {dataLoaded && images.length > 0 && (
        <Pannellum
          image={ImageViewerState[type].image}
          ref={pannellumRef}
          initialPosition={() => getInitialPosition(isMaster)}
          viewerPosition={viewerPosition}
          sync={true}
          onUpdate={x => {
            updateViewerPosition({
              yaw: x.rot,
              hfov: x.fov,
              pitch: x.pitch,
            });

            if (setRotation) {
              setRotation((ImageViewerState[type].data?.angle || 0) + x.rot);
            }
          }}
          angleOffset={ImageViewerState[type].data?.angle || 0}
          onError={() => {}}
        />
      )}
      {dataLoaded && images.length === 0 && (
        <NoPointsMessage>
          No previously approved images for this point
        </NoPointsMessage>
      )}
      {!dataLoaded &&
        <LoadingIndicator/>
      }
      {label &&
        <Label>{label}</Label>
      }
      {!!onClose &&
        <CloseIconButton onClick={onClose}>
          <img src={iconClose} alt="" />
        </CloseIconButton>
      }
      {images.length > 1 &&
        <SiteWalkTimeScroll
          disabled={imageLoading}
          images={images}
          selectedIndex={selectedIndex}
          onChangeSelectedIndex={setSelectedIndex}
          onChangeObservationDate={setObservationDate}
        />
      }
      {(isMaster && images.length > 0 && !imageLoading && issueDetected) &&
        <FlagContainer
          left={images.length > 1 ? 'calc(50% - 80px)' : '8px'}
        >
          {selectedImage.blurriness_issue &&
            <div>Flagged for blurriness</div>
          }
          {selectedImage.brightness_issue &&
            <div>Flagged for brightness</div>
          }
          {selectedImage.wrong_location &&
            <div>Flagged for location</div>
          }
        </FlagContainer>
      }
      {(isMaster && images.length > 0 && !imageLoading) &&
        <IndividualReviewButtons>
          <AddProjectButton
            text="Approve"
            buttonStyle={{background: '#073c7a', color: 'white'}}
            onClick={() => {
              if (!!onApproveImage) {
                onApproveImage(selectedImage.id)
              }
            }}
          />
          <AddProjectButton
            text="Reject"
            buttonStyle={{color: '#ff5252', border: '1px solid #ff5252'}}
            onClick={() => {
              if (!!onRejectImage) {
                onRejectImage(selectedImage.id)
              }
            }}
          />
        </IndividualReviewButtons>
      }
    </ImageComparisonBase>
  )
}

const ImageComparisonBase = styled.div`
  right: -60px;
  position: absolute;
  width: calc(50% + 60px);
  height: 50%;
  filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
`;

const Label = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 10px;
  background-color: #FFFFFF;
  color: #073C7A;
`;

const CloseIconButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #00000077;
  padding: 10px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
`;

const NoPointsMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

const IndividualReviewButtons = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  gap: 5px;
`;

const FlagContainer = styled.div<{left?: string}>`
  position: absolute;
  bottom: 8px;
  left: ${props => props.left ?? '8px'};
  padding: 10px;
  background-color: #FFFFFF;
  color: #073C7A;
`;