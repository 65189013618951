import React from "react";
import styled from "styled-components";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { CreateNewGroup } from "./CreateNewGroup";
import { deleteGroup } from "../../../../../api/adminBuildingFetches";
import { IMapPoint } from "../../../../views/admin_building_page/components/ManagePoints/ManagedMapPoint";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { CheckNameDelete } from "./CheckNameDelete";

export interface IGroup {
  group_id: number;
  name: string;
}

export interface IMapping {
  point_id: number;
  group_id: number;
}

interface IPointGroupSelectorProps {
  hideDelete?: boolean;
  hideCreateNewGroup?: boolean;
  parentOnToggleCheckbox?: (group: IGroup, checked: boolean, groupPoints: number[]) => void;
  disabledOnToggleCheckbox?: () => void;
  disabled?: boolean;
}

export const PointGroupSelector = ({
  hideDelete,
  hideCreateNewGroup,
  parentOnToggleCheckbox,
  disabledOnToggleCheckbox,
  disabled,
}: IPointGroupSelectorProps) => {
  const { addNotification } = useNotifications();
  const {
    updateFloor,
    state: buildingState,
  } = useBuildingContext();

  const onEnterPointSelectorContainer = (group: IGroup) => {    
    const groupPoints = buildingState.floorData.points.filter((point: IMapPoint) => buildingState.floorData.pointMappings.get(point.point_id)?.has(group.group_id))
                                                      .map((point: IMapPoint) => point.point_id);

    updateFloor({
      groupPoints: new Set(groupPoints)
    });
  }

  const onLeavePointSelectorContainer = () => {
    updateFloor({
      groupPoints: new Set()
    });
  }

  const onToggleCheckbox = (group: IGroup, checked: boolean) => {
    if (!disabled) {
      const selectedGroups = new Set(buildingState.floorData.selectedGroups);

      checked ? selectedGroups.add(group.group_id) : selectedGroups.delete(group.group_id);

      const groupPoints: number[] = [];

      buildingState.floorData.pointMappings.forEach((value: Set<number>, key: number) => {
        if (value.has(group.group_id)) {
          groupPoints.push(key);
        }
      });

      updateFloor({
        selectedGroups
      });

      if (parentOnToggleCheckbox) {
        parentOnToggleCheckbox(group, checked, groupPoints);
      }
    } else {
      if (disabledOnToggleCheckbox) {
        disabledOnToggleCheckbox();
      }
    }
  }

  const onClickDeleteGroup = async (groupId: number) => {
    try {
      const deletedGroup: IGroup = await deleteGroup(buildingState.projectId, buildingState.floorId, groupId);

      const updatedGroups = buildingState.floorData.groups.filter((group: IGroup) => group.group_id !== deletedGroup.group_id);
      const updatedPointMappings: Map<number, Set<number>> = new Map(buildingState.floorData.pointMappings);
      const selectedPoints: Set<number> = new Set<number>(buildingState.floorData.selectedPoints);

      updatedPointMappings.forEach((value: Set<number>, key: number) => {
        if (value.has(deletedGroup.group_id)) {
          selectedPoints.delete(key);
          value.delete(deletedGroup.group_id);
        }
      });

      updateFloor({
        groups: updatedGroups,
        groupPoints: new Set(),
        pointMappings: updatedPointMappings,
        selectedPoints
      });

      addNotification('Group Deleted Successfully', 'success');
    } catch (err) {
      addNotification('Group Could Not Be Deleted', 'error');
    }
  }

  return (
    <GroupSelectorContainer>
      <div>
        {buildingState.floorData.groups.map((group: IGroup) => (
          <CheckNameDelete
            key={group.group_id}
            checked={buildingState.floorData.selectedGroups.has(group.group_id)}
            onChangeChecked={(checked: boolean) => onToggleCheckbox(group, checked)}
            onMouseEnter={() => onEnterPointSelectorContainer(group)}
            onMouseLeave={onLeavePointSelectorContainer}
            name={group.name}
            onClickDelete={!hideDelete ? () => onClickDeleteGroup(group.group_id) : undefined}
          />
        ))}
      </div>

      { !hideCreateNewGroup &&
        <CreateNewGroup/>
      }
    </GroupSelectorContainer>
  )
}

export const GroupSelectorContainer = styled.div` 
  color: #212121;
  font-size: 14px;
  padding: 18px;
`;