import React, { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { useBuildingContext } from './buildingContext';
import { listProjectTrackers, ProjectTracker } from '../api/trackers';
import { listTrackerJobTypes } from '../api/jobs';
import { useQueryParams } from '../hooks/useQueryParams';

let initialObservationDate = new Date();
const searchParams = new URLSearchParams(window.location.search);
const searchParamsDateString = searchParams.get('date');
const searchParamsTracker = searchParams.get('tracker') ?? 'Walls';
const searchParamsItemId = searchParams.get("itemId");

if (!!searchParamsDateString) {
  initialObservationDate = new Date(searchParamsDateString);

  if (isNaN(initialObservationDate.getTime())) {
    initialObservationDate = new Date();
  }
}

const inDebugMode = true//window.location.hostname.includes("debug");

const initialState = {
  trackers: [],
  observationDate: initialObservationDate,
  showPoints: inDebugMode,
  inDebugMode: inDebugMode,
  activeTracker: searchParamsTracker,
  selectedItemId: (searchParamsItemId && !isNaN(parseInt(searchParamsItemId))) ? parseInt(searchParamsItemId) : "",
};

const ProgressContext = createContext<any>(null);

const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
const UPDATE_ACTIVE_TRACKER = 'UPDATE_ACTIVE_TRACKER';

const progressReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return {
        ...state,
        ...action.payload.item,
      };
    case UPDATE_ACTIVE_TRACKER:
      return {
        ...state,
        activeTracker: action.payload.item,
      };
    default:
      return state;
  }
};

export const ProgressContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(progressReducer, initialState);
  const { state: BuildingState } = useBuildingContext();
  const queryParams = useQueryParams();

  useEffect(() => {
    const itemIdParam = queryParams.get("itemId");
    const selectedItemId = (itemIdParam && !isNaN(parseInt(itemIdParam))) ? parseInt(itemIdParam) : "";

    dispatch({
      type: UPDATE_PROGRESS,
      payload: {
        item: {
          selectedItemId: selectedItemId,
        },
      },
    });
  }, [queryParams]);

  const addTypes = useCallback(async (tracker: ProjectTracker) => {
    let types = await listTrackerJobTypes(BuildingState.projectId, tracker.type.name.toLowerCase());
    return { ...tracker, types: types.data };
  }, [BuildingState.projectId]);

  const fetchTrackers = useCallback(async () => {
    let data = await listProjectTrackers(BuildingState.projectId);
    let trackers = await Promise.all(data.data.map((tracker: ProjectTracker) => addTypes(tracker)));
    dispatch({
      type: 'UPDATE_PROGRESS',
      payload: { item: { trackers: trackers } },
    });
  }, [BuildingState.projectId, addTypes]);

  useEffect(() => {
    fetchTrackers();
  }, [fetchTrackers]);

  return <ProgressContext.Provider value={[state, dispatch]}>{children}</ProgressContext.Provider>;
};

export const useProgressContext = () => {
  const [state, dispatch] = useContext(ProgressContext);

  const updateProgress = useCallback((item: any) => {
    dispatch({
      type: UPDATE_PROGRESS,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const updateActiveTracker = useCallback((item: any) => {
    dispatch({
      type: UPDATE_ACTIVE_TRACKER,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  return {
    updateProgress,
    updateActiveTracker,
    state,
  };
};
