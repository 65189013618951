import { useCallback, useMemo } from "react";
import { SafetyMetricSlug, safetyMetricMetaData } from "../../../../api/safety";
import { useSafetyContext } from "../../../../contexts/safetyContext";
import { useBuildingContext } from "../../../../contexts/buildingContext";

interface AggregationResult {
  date: Date;
  total: number;
  count: number;
}

export const useGenerateMetricGraph = (slug: SafetyMetricSlug) => {
  const {state: safetyState} = useSafetyContext();
  const {state: buildingState} = useBuildingContext();

  const metricSummaryLoading = safetyState.metricSummaryLoadingStatus[slug];
  const metricSummary = safetyState.metricSummary[slug];
  const metric = safetyMetricMetaData[slug];
  const metricDateSummary = metric.getFilteredDateSummary(metricSummary, buildingState.projectId);

  const mergeAdd = useCallback((obj1: any, obj2: any) => {
    const result: Record<string, any> = {};

    Object.keys(obj1).forEach(key => {
      const val1 = obj1[key] || 0;
      const val2 = obj2[key] || 0;

      if (typeof val1 === 'object' && typeof val2 === 'object') {
        result[key] = mergeAdd(val1, val2);
      } else {
        result[key] = val1 + val2;
      }
    });

    return result;
  }, []);

  const aggregateData = useCallback((obtainDateKey: (date: Date) => number) => {
    let aggregatedData: Record<number, any> = {};

    if (metricDateSummary) {
      const metricDateSummaryEntries: [string, Record<string, any>][] = Object.entries(metricDateSummary);

      metricDateSummaryEntries.forEach(([date, value]) => {
        const year = parseInt(date.split('-')[0]);
        const month = parseInt(date.split('-')[1]);
        const day = parseInt(date.split('-')[2]);

        const dateKey = obtainDateKey(new Date(year, month - 1, day));

        if (!aggregatedData[dateKey]) {
          aggregatedData[dateKey] = {...value};
        } else {
          aggregatedData[dateKey] = mergeAdd(aggregatedData[dateKey], value);
        }
      });
    }

    return aggregatedData;
  }, [mergeAdd, metricDateSummary]);

  return useMemo(() => {
    if (metricDateSummary && !metricSummaryLoading) {
      let aggregatedData: Record<number, AggregationResult> = {};

      if (safetyState.dateSubdivision === 'monthly') {
        aggregatedData = aggregateData(date => new Date(date.getFullYear(), date.getMonth(), 1).getTime());
      } else if (safetyState.dateSubdivision === 'weekly') {
        aggregatedData = aggregateData(date => new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay()).getTime());
      }

      return Object.entries(aggregatedData).map(([date, aggregatedDateSummary]) => ({
        date: new Date(parseInt(date)),
        time: new Date(parseInt(date)).getTime(),
        value: metric.calulate_metric_summary_value(aggregatedDateSummary)
      })).sort((a, b) => a.time - b.time);
    }

    return null;
  }, [aggregateData, metric, metricDateSummary, metricSummaryLoading, safetyState.dateSubdivision]);
}