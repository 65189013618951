import { useCallback, useEffect, useMemo, useState } from "react";
import { useFetchPointCloudsQuery, useFetchSiteWalkPointsQuery, useFetchSiteWalkQuery } from "../hooks/siteWalkQueries";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import { useFloorImages } from "../../building_page/hooks/buildingQueries";
import { SiteWalkComparisonImageData, SiteWalkImageComparison } from "./SiteWalkImageComparison";
import { SiteWalkFloorPlanView } from "./SiteWalkFloorPlanView";
import { SiteWalkPoint, SiteWalkPointBulkUpdateData, bulkUpdateSiteWalkPoints } from "../../../../api/sitewalk";
import { useSiteWalkNavigation } from "../hooks/useSiteWalkNavigation";
import { ConfirmationModal } from "../../../common/Confirmation/Confirmation";
import { ViewSelector } from "../../../common/ViewSelector/ViewSelector";
import { CheckNameDelete } from "../../../common/ViewSelector/components/PointGroupSelector/CheckNameDelete";
import { AddProjectButton } from "../../buildings_page/components/AddProjectButton";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { ViewpointsImage } from "../../../../api/types";
import { useImageViewerContext } from "../../image_viewer/imageViewerContext";
import { RejectSiteWalkImageReasons, RejectSiteWalkReasonData } from "./RejectSiteWalkImageReasons";
import styled from "styled-components";

const initialSiteWalkRejectionData: RejectSiteWalkReasonData[] = [
  {property: 'wrong_location', name: "Wrong Location", checked: false},
  {property: 'brightness_issue', name: "Brightness", checked: false},
  {property: 'blurriness_issue', name: "Blurriness", checked: false}
]

interface SiteWalkConfirmationProps {
  siteWalkId: string;
  pointId: string;
}

enum SiteWalkConfirmationModalType {
  bulk_approve,
  individual_approve,
  bulk_reject,
  individual_reject,
}

export const SiteWalkConfirmation = ({
  siteWalkId,
  pointId,
}: SiteWalkConfirmationProps) => {
  const {addNotification} = useNotifications();
  const {navigateToSiteWalkConfirmation, navigateToSiteWalkConfirmPoint} = useSiteWalkNavigation();

  const [pointCloudSelectorExpanded, setPointCloudSelectorExpanded] = useState<boolean>(true);
  const [selectedPoints, setSelectedPoints] = useState<SiteWalkPoint[]>([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState<string>('');
  const [confirmationModalType, setConfirmationModalType] = useState<SiteWalkConfirmationModalType | null>(null);
  const [confirmationSiteWalkViewpointsImageId, setConfirmationSiteWalkViewpointsImageId] = useState<number | null>(null);
  const [siteWalkPoints, setSiteWalkPoints] = useState<SiteWalkPoint[]>([]);
  const [pointReviewInProgress, setPointReviewInProgress] = useState<boolean>(false);
  const [siteWalkRejectionData, setSiteWalkRejectionData] = useState<RejectSiteWalkReasonData[]>([...initialSiteWalkRejectionData]);
  const [rotation, setRotation] = useState<number | undefined>();

  const isApproveAction = confirmationModalType === SiteWalkConfirmationModalType.bulk_approve || confirmationModalType === SiteWalkConfirmationModalType.individual_approve;
  const isIndividualAction = confirmationModalType === SiteWalkConfirmationModalType.individual_approve || confirmationModalType === SiteWalkConfirmationModalType.individual_reject;

  const {
    state: buildingState,
    updateBuilding,
    updateFloor,
  } = useBuildingContext();

  const {
    resetImageViewer,
  } = useImageViewerContext();

  const onInitiateSiteWalkPoints = (returnedPoints: SiteWalkPoint[]) => {
    setSiteWalkPoints([...returnedPoints]);
    setSelectedPoints([...returnedPoints]);
  }

  const {floorData: projectFloor} = buildingState;
  const {data: siteWalk} = useFetchSiteWalkQuery(siteWalkId);
  const {data: pointClouds, isLoading: pointCloudsLoading} = useFetchPointCloudsQuery(siteWalkId);
  const {isLoading: siteWalkPointsLoading} = useFetchSiteWalkPointsQuery(siteWalkId, onInitiateSiteWalkPoints);

  const updateFloorDesc = (data: any) => {
    updateFloor(data);
  };

  const { isLoading: floorLoading, refetch: refetchFloorImages } = useFloorImages(updateFloorDesc, false, true, true);

  const siteWalkImagesMap = useMemo(() => {
    const imagesMap = new Map<number, SiteWalkComparisonImageData[]>();

    siteWalkPoints.forEach(point => {
      const viewpointSubId = point.viewpoints_image.sub_point_id;
      const currentViewpointImages = imagesMap.get(viewpointSubId) ?? [];
      currentViewpointImages.push({
        ...point.viewpoints_image,
        blurriness_issue: !!point.blurriness_issue,
        brightness_issue: !!point.brightness_issue,
        wrong_location: !!point.wrong_location,
      });

      imagesMap.set(viewpointSubId, currentViewpointImages);
    });

    return imagesMap;
  }, [siteWalkPoints]);

  const floorImagesMap = useMemo(() => {
    const imagesMap = new Map<number, any[]>();
    const projectFloorImages = projectFloor?.images ?? [];
    
    projectFloorImages.forEach((image: any) => {
      const viewpointSubId = image.sub_point_id;
      const currentViewpointImages = imagesMap.get(viewpointSubId) ?? [];
      currentViewpointImages.push(image);

      imagesMap.set(viewpointSubId, currentViewpointImages);
    });

    return imagesMap;
  }, [projectFloor?.images]);

  const allPointClouds = useMemo(() => {
    const pointCloudSet = new Set<number | null>();

    siteWalkPoints.forEach(point => pointCloudSet.add(point.pcd_sub_id));

    const pointCloudArr = Array.from(pointCloudSet);
    pointCloudArr.sort((a,b) => {
      if (a === null) {
        return -1;
      } else if (b === null) {
        return 1;
      } else {
        return a-b;
      }
    });

    return pointCloudArr;
  }, [siteWalkPoints]);

  useEffect(() => {
    if (siteWalk) {
      updateBuilding({
        projectId: siteWalk.project.public_id,
        floorId: siteWalk.project_floor.floor_code,
        pointId: pointId,
      });
    }
  }, [siteWalk, updateBuilding, pointId]);

  useEffect(() => {
    const activePoint = selectedPoints.find(point => point.viewpoints_image.sub_point_id === parseInt(pointId));

    if (!!pointId && !activePoint) {
      navigateToSiteWalkConfirmation(siteWalkId);
    }
  }, [navigateToSiteWalkConfirmation, pointId, selectedPoints, siteWalkId]);

  const selectedSiteWalkPointsSet = useMemo(() => {
    return new Set(selectedPoints.map(point => point.id));
  }, [selectedPoints]);

  const selectedPointClouds = useMemo(() => {
    const pointCloudSet = new Set<number | null>();

    selectedPoints.forEach(point => pointCloudSet.add(point.pcd_sub_id));

    return pointCloudSet;
  }, [selectedPoints]);

  const onCloseImageComparison = () => {
    navigateToSiteWalkConfirmation(siteWalkId);
    resetImageViewer();
  }

  const manageConfirmationModal = (isOpen: boolean, message: string, confirmationModalType: SiteWalkConfirmationModalType | null, imageId: number| null) => {
    setConfirmationModalOpen(isOpen);
    setConfirmationModalMessage(message);
    setConfirmationModalType(confirmationModalType);
    setConfirmationSiteWalkViewpointsImageId(imageId)
  }

  const manageSiteWalkPointApproval = async () => {
    setPointReviewInProgress(true);

    const activeSiteWalkPoint = siteWalkPoints.find(point => point.viewpoints_image.id === confirmationSiteWalkViewpointsImageId)

    const rejectionReasons: Partial<Record<RejectSiteWalkReasonData["property"], boolean>> = {};
    siteWalkRejectionData.forEach(dataEntry => rejectionReasons[dataEntry.property] = dataEntry.checked);

    let updateData: SiteWalkPointBulkUpdateData[] = [];

    if (isIndividualAction && activeSiteWalkPoint) {
      updateData = [
        {
          id: activeSiteWalkPoint.id,
          is_reviewed: true,
          is_approved: isApproveAction,
          ...rejectionReasons
        }
      ]
    } else if (!isIndividualAction) {
      updateData = selectedPoints.map(point => {
        return {
          id: point.id,
          is_reviewed: true,
          is_approved: isApproveAction,
          ...rejectionReasons
        }
      });
    }

    try {
      const updatedPoints = await bulkUpdateSiteWalkPoints(siteWalkId, updateData);
      const updatedPointsMap = new Map<number, SiteWalkPoint>(updatedPoints.map(point => [point.id, point]));

      setSiteWalkPoints(prevSiteWalkPoints => {
        return prevSiteWalkPoints.map(point => {
          const updatedPoint = updatedPointsMap.get(point.id);
          
          return updatedPoint ? updatedPoint : point;
        })
      });

      refetchFloorImages();
      addNotification(`Site walk points ${isApproveAction ? "approved" : "rejected"} successfully`, "success");
    } catch {
      addNotification(`Error ${isApproveAction ? "approving" : "rejecting"} site walk points`, "error");
    } finally {
      setPointReviewInProgress(false);
    }
  }

  const onConfirmModalMessage = async () => {
    if (confirmationModalType !== null) {
      await manageSiteWalkPointApproval();
    }

    manageConfirmationModal(false, '', null, null);
    setSiteWalkRejectionData([...initialSiteWalkRejectionData]);
  }

  const onCheckPointCloud = (checked: boolean, pcdSubId: number | null) => {
    siteWalkPoints.forEach(point => {
      if (point.pcd_sub_id === pcdSubId) {
        if (checked) {
          selectedSiteWalkPointsSet.add(point.id);
        } else {
          selectedSiteWalkPointsSet.delete(point.id);
        }
      }
    });

    setSelectedPoints(siteWalkPoints.filter(point => selectedSiteWalkPointsSet.has(point.id)));
  }

  const onClickPointCloudSelectionButton = () => {
    if (selectedPointClouds.size < allPointClouds.length) {
      setSelectedPoints([...siteWalkPoints]);
    } else {
      setSelectedPoints([]);
    }
  }

  const onGetSitewalkImageUrl = useCallback((image: ViewpointsImage) => {
    return image.stitched_org_image_url;
  }, []);

  const onGetApprovedImageUrl = useCallback((image: ViewpointsImage) => {
    return image.processed_image_url;
  }, []);

  const onChangeSiteWalkRejectionReasonChecked = useCallback((propertyName: string, checked: boolean) => {
    setSiteWalkRejectionData(prevSiteWalkRejectionData => {
      return prevSiteWalkRejectionData.map(dataEntry => {
        return {
          ...dataEntry,
          checked: dataEntry.property === propertyName ? checked : dataEntry.checked
        };
      })
    })
  }, []);

  const onClickPoint = useCallback((points: SiteWalkPoint[]) => {
    const updatedSelectedPoints = new Set(selectedSiteWalkPointsSet);
    const point = points[0];

    if (point.viewpoints_image.sub_point_id.toString() !== pointId) {
      points.forEach(point => {
        if (!updatedSelectedPoints.has(point.id)) {
          updatedSelectedPoints.add(point.id);
        }
      });

      setSelectedPoints(siteWalkPoints.filter(point => updatedSelectedPoints.has(point.id)));

      navigateToSiteWalkConfirmPoint(siteWalkId, point.viewpoints_image.sub_point_id);
    } else {
      navigateToSiteWalkConfirmation(siteWalkId);
    }
  }, [selectedSiteWalkPointsSet, pointId, siteWalkPoints, navigateToSiteWalkConfirmPoint, siteWalkId, navigateToSiteWalkConfirmation]);

  const onDoubleClickPoint = useCallback((points: SiteWalkPoint[]) => {
    const updatedSelectedPoints = new Set(selectedSiteWalkPointsSet);

    points.forEach(point => {
      if (updatedSelectedPoints.has(point.id)) {
        updatedSelectedPoints.delete(point.id);

        if (point.viewpoints_image.sub_point_id === parseInt(pointId)) {
          navigateToSiteWalkConfirmation(siteWalkId);
        }
      } else {
        updatedSelectedPoints.add(point.id);
      }
    });

    setSelectedPoints(siteWalkPoints.filter(point => updatedSelectedPoints.has(point.id)));
  }, [navigateToSiteWalkConfirmation, pointId, selectedSiteWalkPointsSet, siteWalkId, siteWalkPoints]);

  const generatePCDLabel = useCallback((pcdSubId: number | null) => {
    if (pcdSubId !== null ) {
      let label = "";

      if (pointClouds) {
        const pointCloud = pointClouds.find(pcd => pcd.sub_id === pcdSubId);

        if (pointCloud) {
          const partNumbers = pointCloud.part_numbers;

          label += `Part${partNumbers.length !== 1 ? 's' : ''}: ${partNumbers.join(', ')}`
        }
      }

      label += ` (PCD ${pcdSubId})`;

      return label;
    }

    return "PCD Not Specified";
  }, [pointClouds]);

  const SiteWalkRejectionCheckboxes = useMemo(() => {
    return (
      <RejectSiteWalkImageReasons
        checkboxes={siteWalkRejectionData}
        onChangeChecked={onChangeSiteWalkRejectionReasonChecked}
      />
    )
  }, [siteWalkRejectionData, onChangeSiteWalkRejectionReasonChecked]);

  if (!siteWalk || siteWalkPointsLoading || floorLoading || pointCloudsLoading) {
    return <LoadingIndicator/>
  }

  return (
    <>
      <SiteWalkFloorPlanView
        pointId={pointId}
        projectFloor={projectFloor}
        siteWalkPoints={siteWalkPoints}
        selectedPoints={selectedPoints}
        onClickApproveAllPoints={() => manageConfirmationModal(true, `Approve all selected site walk points`, SiteWalkConfirmationModalType.bulk_approve, null)}
        onClickRejectAllPoints={() => manageConfirmationModal(true, `Reject all selected site walk points`, SiteWalkConfirmationModalType.bulk_reject, null)}
        onClickPoint={onClickPoint}
        onDoubleClickPoint={onDoubleClickPoint}
        pointReviewInProgress={pointReviewInProgress}
        rotation={rotation}
      />
      {allPointClouds.length > 0 &&
        <ViewSelectorContainer>
          <ViewSelector
            title="Point Clouds"
            initialViewSelectorExpanded
            viewingExpanded={pointCloudSelectorExpanded}
            setViewingExpanded={setPointCloudSelectorExpanded}
            maxHeight={250}
          >
            <div>
              <PointCloudCheckboxContainer>
                {allPointClouds.map(pcdSubId => {
                  const name = generatePCDLabel(pcdSubId);

                  return (
                    <CheckNameDelete
                      key={name}
                      name={name}
                      checked={selectedPointClouds.has(pcdSubId)}
                      checkmarkPosition={{bottom: '0px', left: '4px'}}
                      onChangeChecked={checked => onCheckPointCloud(checked, pcdSubId)}
                    />
                  )
                })}
              </PointCloudCheckboxContainer>
              <div>
                <AddProjectButton
                  text={selectedPointClouds.size < allPointClouds.length ? "Select All PCDs" : "Deselect All PCDs"}
                  onClick={onClickPointCloudSelectionButton}
                />
              </div>
            </div>
          </ViewSelector>
        </ViewSelectorContainer>
      }
      {!!pointId &&
        <div id="pnlm-master">
          <SiteWalkImageComparison
            type="master"
            label={`Sitewalk ${siteWalkId}`}
            images={siteWalkImagesMap.get(parseInt(pointId)) ?? []}
            onClose={onCloseImageComparison}
            getImageUrl={onGetSitewalkImageUrl}
            onApproveImage={viewpointsImageId => manageConfirmationModal(true, `Approve this image`, SiteWalkConfirmationModalType.individual_approve, viewpointsImageId)}
            onRejectImage={viewpointsImageId => manageConfirmationModal(true, `Reject this image`, SiteWalkConfirmationModalType.individual_reject, viewpointsImageId)}
            setRotation={rotation => setRotation(rotation)}
          />
          <SiteWalkImageComparison
            type="subordinate"
            label="Previous Images"
            images={floorImagesMap.get(parseInt(pointId)) ?? []}
            getImageUrl={onGetApprovedImageUrl}
          />
        </div>
      }      
      <ConfirmationModal
        useKeyboardShortcuts
        isOpen={confirmationModalOpen}
        setIsOpen={setConfirmationModalOpen}
        message={confirmationModalMessage}
        onConfirm={onConfirmModalMessage}
        styleOverrides={{content: {height: 'fit-content', maxHeight: 'default'}}}
        content={!isApproveAction ? SiteWalkRejectionCheckboxes : <></>}
      />
    </>
  )
}

const ViewSelectorContainer = styled.div`
  position: absolute;
  left: 25px;
`;

const PointCloudCheckboxContainer = styled.div`
  max-height: 200px;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
