import { MapViewer } from '../../../common/MapViewer';
import { IconButton } from '../../../common/IconButton';
import iconZoomInBlue from '../../../../assets/images/icon_zoom_in_blue.svg';
import iconZoomOutBlue from '../../../../assets/images/icon_zoom_out_blue.svg';
import { DateRangeSelector } from './DateRangeSelector';
import React, { CSSProperties, useRef, useState } from 'react';
import { LoadingIndicator } from '../../../common/LoadingIndicator';
import { SelectAllPointsButton } from './SelectAllPointsButton';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { useNavigation } from '../../../../hooks/useNavigation';
import { Point } from '../../../../api/types';

interface IFloorPlanViewProps {
  floorData: any;
  hideDateRangeSelector?: boolean;
  onDoubleClickMap?: (e: React.MouseEvent) => void;
  onMouseMoveMap?: (e: React.MouseEvent) => void;
  mapViewerChildren?: JSX.Element | JSX.Element[];
  zoomInIconStyle?: CSSProperties;
  zoomOutIconStyle?: CSSProperties;
  selectAllPointsStyle?: CSSProperties;
  showSelectAllPoints?: boolean;
  preventDoubleTouches?: boolean;
  showPoints?: boolean;
}
export const FloorPlanView = ({
  floorData,
  hideDateRangeSelector,
  onDoubleClickMap,
  onMouseMoveMap,
  mapViewerChildren,
  zoomInIconStyle,
  zoomOutIconStyle,
  selectAllPointsStyle,
  showSelectAllPoints,
  preventDoubleTouches,
  showPoints = true,
}: IFloorPlanViewProps) => {
  const { navigateToPoint, navigateToPointFullscreen } = useNavigation();
  const { state: buildingState } = useBuildingContext();
  const mapRef = useRef<MapViewer | null>(null);
  const [loadedImg, setLoadedImg] = useState('');

  const onClickMapPoint = (point: Point) => {
    if (buildingState.fullscreen) {
      navigateToPointFullscreen(point.point_id, window.location.search);
    } else {
      navigateToPoint(point.point_id);
    }
  }

  if (Object.entries(floorData).length === 0) return <></>;

  return (
    <>
      {loadedImg === floorData.imageUrl ? null : <LoadingIndicator />}
      <div
        style={{
          position: 'absolute',
          top: '0px',
          bottom: '0px',
          right: '0px',
          left: '0px',
          zIndex: 0,
        }}>
        <MapViewer
          ref={mapRef}
          image={floorData.imageUrl}
          setLoadedImg={setLoadedImg}
          selectedDateRange={buildingState.dateRange}
          imageLoaded={loadedImg === floorData.imageUrl}
          onDoubleClickMap={onDoubleClickMap}
          onMouseMoveMap={onMouseMoveMap}
          points={showPoints ? floorData.points : []}
          highlightedPointId={buildingState.pointData?.sub_id}
          preventDoubleTouches={preventDoubleTouches}
          onClickMapPoint={onClickMapPoint}>
          <>{mapViewerChildren}</>
        </MapViewer>
        {showSelectAllPoints && <SelectAllPointsButton size={40} style={selectAllPointsStyle} />}
        <IconButton
          icon={iconZoomInBlue}
          size={40}
          primary={false}
          onClick={() => mapRef.current?.zoomIn()}
          style={zoomInIconStyle}
        />
        <IconButton
          icon={iconZoomOutBlue}
          size={40}
          primary={false}
          onClick={() => mapRef.current?.zoomOut()}
          style={zoomOutIconStyle}
        />
        {!hideDateRangeSelector && <DateRangeSelector />}
      </div>
    </>
  );
};
