import { useState } from 'react';
import styled from 'styled-components';
import { IconButtonExpand } from './components/IconButtonExpand';

export const ViewSelector = (props: React.PropsWithChildren<{
  title: string;
  initialViewSelectorExpanded?: boolean;
  viewingExpanded: boolean;
  setViewingExpanded: (newValue: boolean) => void;
  maxHeight?: React.CSSProperties["maxHeight"];
}>) => {
  const [viewSelectorExpanded, setViewSelectorExpanded] = useState<boolean>(
    !!props.initialViewSelectorExpanded
  );

  const onClickViewSelectorExpanded = (newValue: boolean) => {
    setViewSelectorExpanded(newValue);
    props.setViewingExpanded(false);
  };

  return (
    <ViewSelectorContainer>
      <HeaderContainer>
        <IconButtonExpand
          expanded={viewSelectorExpanded}
          onClick={onClickViewSelectorExpanded}
          style={{ margin: '0px 5px', display: 'inline-flex' }}
          size={20}
        />

        <SectionHeader style={{ display: 'inline', fontSize: '8px' }}>{`${
          viewSelectorExpanded ? 'Hide' : 'Show'
        } ${props.title}`}</SectionHeader>
      </HeaderContainer>

      <ContentContainer aria-expanded={viewSelectorExpanded} maxHeight={props.maxHeight}>
        {props.children}
      </ContentContainer>
    </ViewSelectorContainer>
  );
};

export const HeaderContainer = styled.div`
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
`;

export const SectionHeader = styled.h2`
  color: #686868;
  opacity: 0.64;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 16px;
`;

const ViewSelectorContainer = styled.div`
  margin: 20px 0px 0px 0px;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(106, 106, 106, 0.1);
  border-radius: 4px;
  width: 250px;
  padding: 8px;
  height: 100%;
`;

export const ContentContainer = styled.div<{maxHeight?: React.CSSProperties["maxHeight"]}>`
  max-height: 0;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  transition: max-height 0.5s;
  &[aria-expanded='true'] {
    max-height: ${props => !!props.maxHeight ? typeof props.maxHeight === 'number' ? `${props.maxHeight}px` : props.maxHeight : '750px'};
  }
`;
