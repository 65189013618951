import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from "react";
import { useBuildingContext } from "./buildingContext";
import { useLocation } from "react-router-dom";
import { MapViewer } from "../components/common/MapViewer";
import { ViewpointsImage } from "../api/types";
import { findClosestDate } from "../components/views/building_page/hooks/buildingQueries";
import { Observation } from "../api/observations";

const initialQueryParams = new URLSearchParams(window.location.search);
let initialObservationDate = new Date();
const searchParamsDateString = initialQueryParams.get('date');
const searchParamsItemId = initialQueryParams.get("itemId");

if (!!searchParamsDateString) {
  initialObservationDate = new Date(searchParamsDateString);

  if (isNaN(initialObservationDate.getTime())) {
    initialObservationDate = new Date();
  }
}

const initialState = {
  observations: [],
  observationDate: initialObservationDate,
  dateSubdivision: 'monthly',
  selectedMetricCategory: initialQueryParams.get("category") ?? 'ppe_compliance',
  showPoints: false,
  metricSummary: {
    lighting: null,
    ppe_compliance: null,
    guardrails: null,
    holes: null,
    clutter: null,
    fire_extinguishers: null,
    wet_surface: null,
  },
  metricSummaryLoadingStatus: {
    lighting: true,
    ppe_compliance: true,
    guardrails: true,
    holes: true,
    clutter: true,
    fire_extinguishers: true,
    wet_surface: true,
  },
  metricGraphs: {
    lighting: null,
    ppe_compliance: null,
    guardrails: null,
    holes: null,
    clutter: null,
    fire_extinguishers: null,
    wet_surface: null,
  },
  observationDatePointImageMap: new Map(),
  selectedItemId: (searchParamsItemId && !isNaN(parseInt(searchParamsItemId))) ? parseInt(searchParamsItemId) : "",
};

const SafetyContext = createContext<any>(null);

const UPDATE_SAFETY = 'UPDATE_SAFETY';
const UPDATE_SAFETY_METRIC_GRAPHS = 'UPDATE_SAFETY_METRIC_GRAPHS';
const ADD_OBSERVATIONS = 'ADD_OBSERVATIONS';

const safetyReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_SAFETY:
      return {
        ...state,
        ...action.payload.item,
      };
    case UPDATE_SAFETY_METRIC_GRAPHS:
      return {
        ...state,
        metricGraphs: {
          ...state.metricGraphs,
          ...action.payload.metricGraphs,
        },
      };
    case ADD_OBSERVATIONS:
      return {
        ...state,
        observations: [...state.observations, ...action.payload.observations],
      }
    default:
      return state;
  }
};

export const SafetyContextProvider = ({ children }: any) => {
  const {state: buildingState, updateBuilding} = useBuildingContext();
  const mapRef = useRef<MapViewer | null>(null);
  const [state, dispatch] = useReducer(safetyReducer, {...initialState, mapRef: mapRef});
  
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    let selectedMetricCategory = "ppe_compliance";

    const categoryParam = queryParams.get("category");
    const itemIdParam = queryParams.get("itemId");
    const selectedItemId = (itemIdParam && !isNaN(parseInt(itemIdParam))) ? parseInt(itemIdParam) : "";
    
    if (categoryParam !== null) {
      selectedMetricCategory = categoryParam;
    }

    dispatch({
      type: UPDATE_SAFETY,
      payload: {
        item: {
          selectedMetricCategory: selectedMetricCategory,
          selectedItemId: selectedItemId,
        },
      },
    });
  }, [queryParams]);

  useEffect(() => {
    const floorCodeParam = queryParams.get("floor");

    if (floorCodeParam) {
      updateBuilding({
        floorId: floorCodeParam,
      });
    }
  }, [queryParams, updateBuilding]);

  useEffect(() => {
    const map = new Map<number, ViewpointsImage>();
    const seenSubPointIds = new Set<number>();
    const floorImages = buildingState.floorData?.images;
    
    if (floorImages && floorImages.length > 0) {
      let searchDate = new Date(state.observationDate);
      
      const searchDateMax = new Date(searchDate);
      searchDateMax.setHours(23, 59, 59, 999);

      const beforeSearchDate = floorImages.filter((point: ViewpointsImage) => new Date(point.taken_on) <= searchDateMax);

      beforeSearchDate.sort((pointA: ViewpointsImage, pointB: ViewpointsImage) => findClosestDate(searchDate, pointA.taken_on, pointB.taken_on));

      beforeSearchDate.forEach((image: ViewpointsImage) => {
        if (!seenSubPointIds.has(image.sub_point_id)) {
          map.set(image.id, image);
          seenSubPointIds.add(image.sub_point_id);
        }
      });
    }
    
    dispatch({
      type: UPDATE_SAFETY,
      payload: {
        item: {
          observationDatePointImageMap: map,
        },
      },
    });
  }, [buildingState.floorData?.images, state.observationDate]);

  return <SafetyContext.Provider value={[state, dispatch]}>{children}</SafetyContext.Provider>;
};

export const useSafetyContext = () => {
  const [state, dispatch] = useContext(SafetyContext);

  const updateSafety = useCallback((item: any) => {
    dispatch({
      type: UPDATE_SAFETY,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const updateSafetyMetricGraphs = useCallback((metricGraphs: any) => {
    dispatch({
      type: UPDATE_SAFETY_METRIC_GRAPHS,
      payload: {
        metricGraphs,
      },
    });
  }, [dispatch]);

  const addSafetyObservations = useCallback((observations: Observation[]) => {
    dispatch({
      type: ADD_OBSERVATIONS,
      payload: {
        observations,
      },
    });
  }, [dispatch]);

  return {
    updateSafety,
    updateSafetyMetricGraphs,
    addSafetyObservations,
    state,
  };
};
