import Flex from "@react-css/flex";
import { useState } from "react"
import { Route } from "react-router-dom";
import { BuildingProvider } from "../../../contexts/buildingContext";
import { PATH_STRINGS } from "../../../hooks/useGeneratedPaths";
import { ViewSelector } from "../../common/ViewSelector/ViewSelector"
import { ChangePassword } from "./ChangePassword";
import { Integrations } from "./Integrations";
import { UserInformation } from "./UserInformation";
import { UserSettingsOptions } from "./UserSettingsOptions";

export const UserProfile = () => {
  const [settingsMenuExpanded, setSettingsMenuExpanded] = useState<boolean>(true);

  return (
    <Flex>
      <ViewSelector
        title='Settings'
        initialViewSelectorExpanded
        viewingExpanded={settingsMenuExpanded}
        setViewingExpanded={setSettingsMenuExpanded}
      >
        <BuildingProvider>
          <UserSettingsOptions/>
        </BuildingProvider>
      </ViewSelector>

      <div style={{margin: '20px 0 0 120px'}}>
        <Route
          exact
          path={PATH_STRINGS.userProfile}
        >
          <UserInformation/>
        </Route>
        <Route
          exact
          path={PATH_STRINGS.userProfilePassword}
        >
          <ChangePassword/>
        </Route>
        <Route
          exact
          path={PATH_STRINGS.userProfileNotificationPreferences}
        >
          <></>
        </Route>
        <Route
          exact
          path={PATH_STRINGS.userProfileIntegrations}
        >
          <Integrations/>
        </Route>
      </div>
    </Flex>
  )
}