import React, { useCallback, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import axios from 'axios';

import { ChatManager } from '../../../../../common/Chat/ChatManager';
import { AnnotationManager } from '../../../../../common/Annotation/AnnotationManager';
import { useTagContext } from '../../../../../../contexts/tagContext';
import { Tag } from '../../../../../../api/types';
import { useBuildingContext } from '../../../../../../contexts/buildingContext';
import { SafetyTagManager } from "../../../../../common/SafetyTag/SafetyTagManager";
import { useUserContext } from "../../../../../../contexts/userContext";
import { checkUserPermissions } from "../../../../../common/PermissionWrapper/PermissionWrapper";
import { ProcoreManager } from '../../../../../common/Procore/ProcoreManager';
import { useProcoreAuthContext } from '../../../../../../contexts/procoreAuthContext';
import { removeTemporaryPanellumTags } from '../utils';

interface ITagManagerProps {
  pnlm: any;
  mapPopupRef: React.RefObject<HTMLDivElement>;
}

export const TagManager = (props: ITagManagerProps) => {
  const { state: buildingState } = useBuildingContext();
  const { state: userState } = useUserContext();
  const { hydrateTags, findSetCurrentTag, toggleDisplay, state: tagState } = useTagContext();
  const [readyToHydrate, setReadyToHydrate] = useState(false);
  
  const { state: procoreAuthState } = useProcoreAuthContext();
  const {authenticationCheckComplete, isAuthenticated: isProcoreAuthenticated} = procoreAuthState;

  const projectIsLinkedToProcore = buildingState && buildingState.projectData && buildingState.projectData.procore_company_id && buildingState.projectData.procore_project_id;
  const procoreTagSelected = tagState.tags.current?.type === 'RFI' || tagState.tags.current?.type === 'PROCORE-NEW-RFI' || tagState.tags.current?.type === 'PROCORE-LINK-RFI' || tagState.tags.current?.type === 'PROCORE-SAVE-IMAGE';
  const showProcoreManager = isProcoreAuthenticated && projectIsLinkedToProcore && procoreTagSelected;

  /*
  Callback for when user clicks on a hotspot.

  Don't rely on dynamic info here, only the args passed on creation essentially.
   */
  const hospotClickHandler = useCallback((
    event: any,
    args: { pitch: number; yaw: number; type: string; tagId?: number }
  ) => {
    if (args.tagId) {
      findSetCurrentTag(args.tagId);
      mixpanel.track('Click Hotspot', {
        type: args.type,
      });
    }
  }, [findSetCurrentTag]);

  const hotspotContextMenuHandler = useCallback((event: MouseEvent, args: { tagId: number }) => {
    if (args.tagId) findSetCurrentTag(args.tagId, true);
  }, [findSetCurrentTag]);

  const hydrate = useCallback((tagsToUpdate?: number[]) => {
    return axios
      .get('https://services.nexterarobotics.com/tags/', {
        params: {
          isProcoreAuthenticated: isProcoreAuthenticated,
          project_id: buildingState.projectId,
          point_id: buildingState.pointId,
          image_id: buildingState.imageData.data.id,
          safety: checkUserPermissions(userState.permissions, "safety_tables.read_observation", buildingState.projectId)
        },
      })
      .then(res => {
        hydrateTags({ all: res.data });
        res.data.forEach((tag: Tag) => {
          const useDisabledStyle = tag.rfi && tag.rfi.status === 'closed';

          // add hotspot
          props.pnlm.current?.addHotSpot(
            [tag.pitch, tag.yaw],
            tag.type,
            hospotClickHandler,
            hotspotContextMenuHandler,
            tag.id,
            true,
            {
              disabled: useDisabledStyle,
              tagsToUpdate: tagsToUpdate,
            }
          );
        });
        return res.data;
      });
  }, [buildingState.imageData.data.id, buildingState.pointId, buildingState.projectId, hospotClickHandler, hotspotContextMenuHandler, hydrateTags, props.pnlm, isProcoreAuthenticated, userState.permissions]);

  useEffect(() => {
    if (props.pnlm)
      props.pnlm.current.pannellumRef.on('load', () => {
        setReadyToHydrate(true);
      });
    return;
  }, [props.pnlm]);

  useEffect(() => {
    const onClickOutside = (event: any) => {
      if (!event.target.closest('.oco-ignore')) {
        toggleDisplay();
      }
      if (
        !event.target.closest('.oco-dialogue') &&
        !event.target.closest('.context-menu-li')
      ) {
        removeTemporaryPanellumTags(props.pnlm.current);
      }
    };
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (readyToHydrate && authenticationCheckComplete) {
      hydrate().then(() => {
        let urlTag = new URLSearchParams(window.location.search).get('tag');
        if (urlTag) findSetCurrentTag(parseInt(urlTag));
      });
    }
    return;
  }, [readyToHydrate, authenticationCheckComplete, findSetCurrentTag, hydrate]);

  return (
    <div style={{ position: 'relative' }}>
      {tagState.tags.current?.type === 'CHAT' && (
        <ChatManager pnlm={props.pnlm.current} hydrate={hydrate} />
      )}
      {tagState.tags.current?.type === 'ANNOTATION' && (
        <AnnotationManager tag={tagState.current} pnlm={props.pnlm.current} hydrate={hydrate} />
      )}
      {tagState.tags.current?.type === 'SAFETY' && (
        <SafetyTagManager tag={tagState.current} pnlm={props.pnlm.current} hydrate={hydrate} />
      )}
      {showProcoreManager && (
        <ProcoreManager pannellumRef={props.pnlm} mapPopupRef={props.mapPopupRef} hydrate={hydrate}/>
      )}
    </div>
  );
};
