import React, { createContext, useContext, useState } from 'react';

export const PanZoomContext = createContext<any>(null);

export const PanZoomProvider = ({ children, initialScale }: any) => {
  const [scale, setScale] = useState<number>(initialScale);

  const value = {
    scale,
    setScale,
  }

  return <PanZoomContext.Provider value={value}>{children}</PanZoomContext.Provider>;
};

export const usePanZoomContext = () => {
  return useContext(PanZoomContext);
};
