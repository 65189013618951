import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DateRange, Range } from 'react-date-range';
import classNames from 'classnames';
import { format } from 'date-fns';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Text } from '../../../common/Text';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import mixpanel from 'mixpanel-browser';

const DateRangeSelectorBase = styled.div`
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const DateRangeSelectorPopup = styled.div`
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`;

const DateRangeSelectorDisplay = styled.div`
  width: 246px;
  height: 60px;

  background: #ffffff;

  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;

  &.inverted {
    background: #073c7a;
  }
`;

const DRClear = styled.div`
  position: absolute;
  top: -30px;
  right: 0;
  font-size: 0.8em;
  background: #fff;
  border-radius: 10px;
  color: #073c7a;
  padding: 5px;
`;

export const DateRangeSelector = (props: any) => {
  const { state: buildingState, updateBuilding } = useBuildingContext();
  const selectorRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const selectionRange: Range = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };

  const [tempRange, setTempRange] = useState<Range>(selectionRange);
  const [selectedRange, setSelectedRange] = useState<Range | null>(null);
  const [displayed, setDisplayed] = useState(false);

  const onClick = (e: any) => {
    if (displayed) {
      setDisplayed(false);
      setSelectedRange(tempRange);
      updateBuilding({ dateRange: tempRange });
      mixpanel.track('Set DateRange', {
        range: tempRange,
      });
    } else if (e.target.id !== 'clear') {
      if (selectedRange != null) setTempRange(selectedRange);
      setDisplayed(true);
    }
  };

  const clearRange = () => {
    setTempRange(selectionRange);
    setSelectedRange(null);
    updateBuilding({ dateRange: null });
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setDisplayed(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectorRef]);

  return (
    <DateRangeSelectorBase ref={selectorRef} id="drsb">
      {displayed && (
        <DateRangeSelectorPopup>
          <DateRange
            rangeColors={['#073c7a']}
            color="#073c7a"
            ranges={[tempRange]}
            maxDate={new Date()}
            showDateDisplay={false}
            onChange={(item: any) => {
              // if (item.selection.startDate.valueOf() === item.selection.endDate.valueOf()) {
              // @ts-ignore
              item.selection.endDate.setHours(23, 59, 59, 999);
              // }
              setTempRange(item.selection);
            }}
          />
        </DateRangeSelectorPopup>
      )}
      <DateRangeSelectorDisplay
        className={classNames({
          inverted: displayed,
        })}
        onClick={onClick}>
        {buildingState.dateRange && !displayed && (
          <DRClear onClick={clearRange} id="clear">
            Clear
          </DRClear>
        )}
        {displayed ? (
          <Text size={16} color={'white'}>
            Apply
          </Text>
        ) : buildingState.dateRange ? (
          <Text size={14} color={'#073c7a'} bold={false}>
            {format(buildingState.dateRange.startDate!, 'MMM dd, yyyy')}
            <span
              style={{
                display: 'inline-block',
                width: '16px',
                height: '1px',
                backgroundColor: '#000',
                margin: '4px 3px',
              }}
            />
            {format(buildingState.dateRange.endDate!, 'MMM dd, yyyy')}
          </Text>
        ) : (
          <Text size={16} color={'#073C7A'}>
            Select Range
          </Text>
        )}
      </DateRangeSelectorDisplay>
    </DateRangeSelectorBase>
  );
};
