import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Flex } from '@react-css/flex';
import { useTagContext } from '../../../../contexts/tagContext';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { useUserContext } from '../../../../contexts/userContext';
import { useNotifications } from '../../../../contexts/notificationProvider';
import { globalErrorHandler } from '../../../../globalErrorHandler';
import { InitialsAvatar } from '../../InitialsAvatar';
import {
  CreateButtonContainer,
  UserEmail,
  UserListContainer,
  UserListScrollContainer,
  SearchBar,
  CreateButton,
} from './styles';
import { getFullName } from '../../../../utils';
import { User } from '../../../../api/types';

interface IUserListListProps {
  users: any;
  toggleSelectedUser: any;
  selectedUsers: any;
}
const UserListList = ({ users, toggleSelectedUser, selectedUsers }: IUserListListProps) => {
  const { state: userState } = useUserContext();
  return users.map((user: any) => {
    if (user.public_id !== userState.public_id) {
      return (
        <UserListItem
          toggleSelectedUser={toggleSelectedUser}
          user={user}
          selectedUsers={selectedUsers}
          key={Math.random()}
        />
      );
    }
    return <></>;
  });
};

interface IUserListItemProps {
  toggleSelectedUser: any;
  user: any;
  selectedUsers: any;
}
const UserListItem = ({ toggleSelectedUser, user, selectedUsers }: IUserListItemProps) => {
  const [selected, setSelected] = useState<boolean>(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      toggleSelectedUser(user.public_id);
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (selectedUsers.includes(user.public_id)) {
      setSelected(true);
      firstUpdate.current = true;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Flex
      flexDirection="row"
      onClickCapture={() => setSelected(!selected)}
      // onMouseDown={() => setSelected(!selected)}
      key={Math.random()}
      className={
        selected ? 'selected-chat-user oco-ignore oco-dialogue' : 'oco-ignore oco-dialogue'
      }
      style={{
        alignItems: 'center',
        borderBottom: '1px solid #e8e8e8',
        padding: '5px 0',
        cursor: 'pointer',
      }}>
      <InitialsAvatar
        user={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          userId: user.public_id,
        }}
        theme="dark"
      />
      <p>
        {getFullName({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          userId: user.public_id,
        })}
        <br />
        <UserEmail>{user.email}</UserEmail>
      </p>
    </Flex>
  );
};

export const ChatUserSelect = (props: any) => {
  const { addNotification } = useNotifications();
  const { state: userState } = useUserContext();
  const { state: buildingState } = useBuildingContext();
  const { updateChat } = useTagContext();
  const [users, setUsers] = useState<Array<any>>([]);
  const [filteredUsers, setFilteredUsers] = useState<Array<any>>([]);
  const [selectedUsers, setSelectedUsers] = useState<Array<any>>([]);

  const toggleSelectedUser = (user: any) => {
    if (selectedUsers.includes(user)) {
      setSelectedUsers(prevState => {
        let userIndex = prevState.findIndex(item => item === user);
        prevState.splice(userIndex, 1);
        return prevState;
      });
    } else {
      setSelectedUsers(prevState => {
        prevState.push(user);
        return prevState;
      });
    }
  };

  const getProjectUsers = (projectId: string) => {
    axios
      .get(`https://api.nexterarobotics.com/api/project/${projectId}/workers`)
      .then(r => {
        let users = r.data.data.map((item: { added_on: string; user: User }) => item.user);
        setUsers(users);
        setFilteredUsers(users);
      })
      .catch(err => globalErrorHandler(err));
  };

  const createAction = () => {
    if (selectedUsers.length > 0) {
      let addCurrentUser = selectedUsers.concat(userState.public_id);
      updateChat({ status: 'conversation', users: addCurrentUser });
      props.onCreate(addCurrentUser);
    } else {
      addNotification('Please select at least one user', 'error');
    }
  };

  const filterUsers = (query: string) => {
    let usersSearch = users.filter(
      user =>
        user.email.toLowerCase().includes(query.toLowerCase()) ||
        user.first_name?.toLowerCase().includes(query.toLowerCase()) ||
        user.last_name?.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredUsers(usersSearch);
  };

  useEffect(() => {
    getProjectUsers(buildingState.projectId);
    // eslint-disable-next-line
  }, []);

  return (
    <UserListContainer id="ul-c" className="oco-ignore oco-dialogue">
      <SearchBar placeholder="Search users" onChange={e => filterUsers(e.target.value)} />
      <UserListScrollContainer className="oco-ignore oco-dialogue">
        <UserListList
          users={filteredUsers}
          toggleSelectedUser={toggleSelectedUser}
          selectedUsers={selectedUsers}
        />
      </UserListScrollContainer>
      <Flex
        flexDirection="row"
        style={{
          position: 'absolute',
          bottom: '0',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '90%',
        }}>
        <CreateButtonContainer>
          <CreateButton onClick={() => createAction()}>Create Chat</CreateButton>
        </CreateButtonContainer>
      </Flex>
    </UserListContainer>
  );
};
